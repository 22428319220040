<template>
  <img alt="Rekprof logo" class="logo_min" src="../../assets/logo.png" />
  <v-menu-list :key="1"></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">Выберите регион для формирования документа</span>
    <v-select-open
      :options="client_type_options"
      :title_select="client_type_title"
      :default_select="storeDM.save_obj.client_type"
      :key="storeDM.save_obj.client_type"
      @change-select="client_type_select"
      :margin_top="'3px'"
    >
    </v-select-open>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRouter, useRoute } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepZero",
  components: { VSelectOpen, VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    //if (storeDM.initDM == false) {
    storeDM.init(route.query.hash, route.params.docID);
    storeDM.set_info({}, 0);
    //}

    const go_back = () => {
      router.go(-1);
    };

    const go_next = () => {
      storeDM.set_info({
        step: "1",
      });
      //storeDM.get_info_server(); //Странная строка. Волшебный баг
      router.push({
        path: "/step1/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const client_type_title = "Выберите регион для документа";
    const client_type_options = [
      { name: "Юридическое лицо", value: "1" },
      { name: "Физическое лицо", value: "2" },
      { name: "Индивидуальный предприниматель", value: "3" },
      //{ name: "Другое", value: "4" },
    ];

    const step_next = computed(() => {
      return true;
    });

    return {
      go_back,
      client_type_title,
      client_type_options,
      step_next,
      go_next,
      storeDM,
    };
  },
});
</script>

<style scoped></style>
