<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">10. Данные о проектировщике и изготовителе</span>
    <div class="hint_left hint_mini_top">Введите название проектировщика</div>
    <input
      class="main_input"
      placeholder="Введите название проектировщика"
      v-model="storeDM.save_obj.designer_name"
    />
    <br />
    <div class="hint_left">Введите название изготовителя</div>
    <input
      class="main_input"
      placeholder="Введите название изготовителя"
      v-model="storeDM.save_obj.manufacturer_name"
    />
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepTen",
  components: { VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "9",
      });
      router.push({
        path: "/step9/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "11",
      });
      router.push({
        path: "/step11/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
    });

    return {
      go_back,
      step_next,
      go_next,
      storeDM,
    };
  },
});
</script>

<style scoped></style>
