<template>
  <Dialog v-model:visible="sr_visible" modal header="Создание нового заявления" :style="{ width: '535px' }">
    <p>
      Выберите регион для заявления из списка
    </p>
    <Dropdown v-model="document_type_title"
              editable :options="document_type_options"
              optionLabel="name"
              :placeholder="document_type_default.name"
              style="width:480px"
              @change="document_type_change"
    />
    <template #footer>
      <button @click="createStatement">Далее</button>
    </template>
  </Dialog>
  <div class="statements">
    <div v-if="!newStatement" class="white__navbar">
      <!--
      <p>Личный кабинет</p>
      <p>/</p>
      <p>Заявления</p>
      <p>/</p>
      -->
      <p>Список заявлений</p>
    </div>
    <div class="statements__content" v-if="!newStatement">
      <div class="search">
        <!-- <button @click="createStatement()"><PlusIcon /> Новое заявление</button> -->
        <button @click="sr_visible = true"><PlusIcon /> Новое заявление</button>
        <input type="text" placeholder="Поиск" v-model="search_value" >
      </div>
      <div class="data">
      <v-table
    fixed-header
  >

    <thead>
      <tr style="color: rgba(0, 0, 0, 0.4509803922);">
        <th class="text-left" style="width: 76px;">
          №
        </th>
        <th class="text-left" style="width:120px;">
          Дата создания
        </th>
        <th class="text-left" style="width:160px;">
          Дата редактирования
        </th>
        <th class="text-left" style="width:280px;">
          Юридическое лицо
        </th>
        <th class="text-left">
          Адрес
        </th>
        <th class="text-left" style="width:110px;">
          Статус
        </th>
        <th class="text-left" style="width:80px;"></th>
        <th class="text-left" style="width:62px;">
          Избранное
        </th>
        <th class="text-left" style="width:62px">
          Операции
        </th>
      </tr>
    </thead>
    <tbody style="text-align: left;">
      <tr
        v-for="(input, index) in doc_lists"
        :key="index + ' ' + input.favorite"
      >
        <td class="text-left">{{ input.id }}</td>
        <td class="text-left">{{ input.date }}</td>
        <td class="text-left">{{ input.date_edit }}</td>
        <td class="text-left">{{ input.client_name }}</td>
        <td class="text-left">{{ input.address }}</td>
        <td class="text-left">
          <div v-if="input.complete=='1'" style="font-weight: bold; color: #2F80ED;">Готово</div>
          <div v-if="input.complete=='0'">Шаг {{ input.step }}</div>
          <div v-if="input.complete=='0'">
            <div v-for="n in Number(input.step_all)">
              <div
                style="width: 4px; height: 4px; background-color: red; float: left;"
                :style="(n>1?'margin-left: 2px; ':'')
                +
                (1==Number(input.step_arr[n-1])?'background-color: #03CB98;':'')
                +
                (n==Number(input.step) && 1==Number(input.step_arr[0]) ?'background-color: #2F80ED;':'')
                +
                (n==1 && 0==Number(input.step_arr[0])?'background-color: #2F80ED;':'')
                "></div>
            </div>
          </div>
        </td>
        <td class="text-left" style="text-align: center !important;">
          <span v-if="input.complete == '1'"
          style="cursor:pointer; color: #2F80ED; text-decoration: underline;"
          @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+input.id+'&hash='+route.query.hash+'&type=document', 'дизайн проект №'+input.id+'.pdf')"
          >Скачать</span>
          <span v-else>
            <Info style="margin-top: 6px; cursor: pointer;" @click="modalStat(input.id, input.step, input.step_all, input.step_arr, input.step_text)" />
          </span>
        </td>
        <td class="text-left" style="text-align: center !important;"><EmptyStar style="cursor: pointer;" v-if="input.favorite == 0" @click="edit_favorite(input.id, input.hash, input.favorite)"/><Star style="cursor: pointer;" v-if="input.favorite == 1" @click="edit_favorite(input.id, input.hash, input.favorite)"/></td>
        <td class="text-left">
          <div class="dots" @click="context_detail=input.id"><Dots  /></div>
          <div v-if="context_detail==input.id"
               style="position: absolute;
                      width: 200px;
                      margin-left: -180px;
                      margin-top: -35px;
                      z-index: 1;
                      text-align: center;
                      background: white;
                      -webkit-user-select: none; /* Safari */
                      -moz-user-select: none; /* Firefox */
                      -ms-user-select: none; /* IE10+/Edge */
                      user-select: none; /* Standard */
                      box-shadow: 0px 20px 22px 0px #B2BDE169;
                    ">
            <div @click="context_detail=0" style="text-align: right;"><LogoutIcon style="cursor: pointer;" /></div>
            <div style="cursor: pointer;" @click="open_doc(input.id)">Формирование заявления</div>
            <div style="cursor: pointer;" @click="copy_doc(input.id)">Дублировать заявление</div>
            <div style="cursor: pointer;" @click="edit_favorite(input.id, input.hash, 0)">Сохранить в черновики</div>
            <div style="cursor: pointer;" @click="delete_doc_modal(input.id)">Удалить заявление</div>
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
</div>
</div>
<vue-awesome-paginate
    :total-items="docs_lists.value.length"
    :items-per-page="pages_on_page"
    :max-pages-shown="17"
    v-model="page"
    prev-button-content="Назад"
    next-button-content="Далее"
    v-if="!newStatement"
    :style="
		'position: relative; float: left; transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); margin-top: -0.5rem; top: 0px; left: 0px; margin-left: 1.5rem;'
		//'top: '+screen_h+'px;'
	"
  />
<NewStatement v-if="newStatement" @edit-proxy="edit_proxy" @edit-signatory="edit_signatory" />

  </div>
  <ModalDel :key="del_key" :is_open="del_modal" :info_txt="'заявление №'" :info_num="del_num" @do_btn="delete_doc"></ModalDel>
  <ModalStatement :key="del_key" :is_open="stat_modal" :info_txt="step_text" :info_num="del_num" :step="info_step" :step_all="step_all" :step_arr="step_arr" @do_btn="open_doc"></ModalStatement>
</template>

<script lang="ts">
import PlusIcon from '../../assets/icons/plusIcon.vue';
import Star from '../../assets/icons/full-star.vue';
import EmptyStar from '../../assets/icons/emtyStar.vue';
import Info from '../../assets/icons/info.vue';
import Dots from '../../assets/icons/dots.vue';
import DotsBlack from '../../assets/icons/dotsblack.vue';
import NewStatement from './NewStatement.vue';
import { defineComponent, computed, ref, reactive, onMounted } from "vue";
import { useDMStore } from "@/store/dm_obj";
import { useRoute, useRouter } from "vue-router";
import LogoutIcon from '../../assets/icons/logoutIcon.vue';
import axios from "axios";
import ModalDel from "../ModalDel.vue";
import ModalStatement from "../ModalStatement.vue";

export default defineComponent ({
  components: {
    PlusIcon,
    Star,
    Dots,
    DotsBlack,
    LogoutIcon,
    EmptyStar,
    Info,
    NewStatement,
    ModalDel,
    ModalStatement,
  },
  props: {
    doc_list: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      doc_list: any;
    }, context
  ){
    const storeDM = useDMStore();
    storeDM.showSubscription = true;

    let router = useRouter();
    const route = useRoute();

    const sr_visible = ref(false);

    const context_detail = ref(0);

    const search_value = ref("");

    const docs_lists = reactive({value: props.doc_list});

    const document_type_title = ref();
    const document_type_options = ref();
    const document_type_default = ref({ name: "Санкт-Петербург", value: 1, construction_id: 1, region_id: 1 });
    let construction_id = "1";
    let region_id = "1";

    
    const downloadFileFromURL = async (url, fileName) => {


      if(fileName != ""){
        await axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      }
    }


    const download_doc = async (id, dataurl) => {
      
      var a = document.createElement("a");
      a.href = dataurl;
      a.setAttribute("download", "дизайн проект №"+id+".pdf");
      a.setAttribute("target", "_blank");
      a.click();
      
      //await axios
      //          .get(download, { responseType: "blob" })
      //          .then((response) => {
      //            const blob = new Blob([response.data], { type: "application/pdf" });
      //            const link = document.createElement("a");
      //            link.href = URL.createObjectURL(blob);
      //            link.download = "дизайн проект №"+id;
      //            link.click();
      //            URL.revokeObjectURL(link.href);
      //          })
      //          .catch(console.error);
      
    }
    

    /*
    function download_doc(id, dataurl) {
      fetch(dataurl).then(function(t) {
          return t.blob().then((b)=>{
              var a = document.createElement("a");
              a.href = URL.createObjectURL(b);
              a.setAttribute("download", "дизайн проект №"+id+".pdf");
              a.click();
          }
          );
      });
    }
    */

    const pagination_bottom_left = computed(() => {
      if(storeDM.rail == false){
        return '1.5rem;';//232
      } else {
        return '1.5rem;';//80
      }
    });

    
    const get_doc_list = async () => {

      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_doc_list",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      if (response_data["status"] == "success") {
        docs_lists.value = response_data["doc_list"];
      }

      //console.log(typeof docs_lists.value);
      //console.log(Array.isArray(docs_lists.value));
    };
    get_doc_list();
    

    const document_type_change = (e) => {
      construction_id = e.value.construction_id;
      region_id = e.value.region_id;
    };

    const get_regions = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_regions",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();
      document_type_options.value = response_data["regions"];
    }
    get_regions();

    //const newStatement = ref(false);
    const createStatement = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "create_new",
          hash: route.query.hash,
          region: region_id,
          construction: construction_id,
        }),
      });

      sr_visible.value = false;

      const response_data = await response.json();

      router.push({
        query: {
          hash: route.query.hash,
          type: "document",
          region: region_id,
          construction: construction_id,
          id: response_data["id"],
          step: 1,
          mode: "edit",
        },
      });


      //newStatement.value = true;
    }

    const newStatement = computed(() => {
      if(route.query.mode == "edit"){
        return true;
      } else {
        return false;
      }
    });

    
    const page = ref(1);
    const doc_lists = computed(() => {
      if(search_value.value != ""){
        let doc_list_filter = docs_lists.value.filter(val => val.address.toUpperCase().indexOf(search_value.value.toUpperCase()) !== -1 );
        //console.log(doc_list_filter);
        let tmp_arr = doc_list_filter.slice();
        return tmp_arr.slice(Number(page.value - 1) * pages_on_page.value, page.value * pages_on_page.value);
      } else if( Array.isArray(docs_lists.value) ){
        let tmp_arr = docs_lists.value.slice();
        return tmp_arr.slice(Number(page.value - 1) * pages_on_page.value, page.value * pages_on_page.value);
      } else {
        return [];
      }
    });

    const doc_favorite_list = ref([]);

    const edit_favorite = async (
      id: string,
      hash: string,
      favorite: string
    ) => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "edit_favorite",
          id: id,
          hash: route.query.hash,
          favorite: favorite,
        }),
      });
      const response_data = await response.json();
      let id_elem = docs_lists.value.findIndex(
        (x) => x["id"] === response_data.id
      );

      Object(docs_lists.value)[id_elem]["favorite"] = response_data.favorite;

      let tmp_arr = docs_lists.value.slice();
      let tmp_arr2: any = [];
      for (let i = 0; i < tmp_arr.length; i++) {
        if (tmp_arr[i]["favorite"] == "1") {
          tmp_arr2.push(tmp_arr[i]);
        }
      }
      doc_favorite_list.value = tmp_arr2;

      context_detail.value = 0;
    };

    const open_doc = async (id: string) => {
      stat_modal.value = false;
      del_key.value = del_key.value+1;
      
      storeDM.showSubscription = false;
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_region_construction",
          id: id,
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      router.push({
        query: {
          hash: route.query.hash,
          type: "document",
          region: response_data["region"],
          construction: response_data["construction"],
          id: id,
          step: 1,
          mode: "edit",
        },
      });
      
      context_detail.value = 0;
    };


    const del_modal  = ref(false);
    const del_num = ref('');
    const del_key = ref(0);
    const delete_doc_modal = async (id: string) => {
      context_detail.value = 0;
      del_modal.value = true;
      del_num.value = id;
      del_key.value = del_key.value+1;
    }

    const stat_modal = ref(false);
    const info_step = ref(0);
    const step_all = ref(0);
    const step_arr = ref([]);
    const step_text = ref("");
    const modalStat = async (id: string, step: number, stepAll: number, stepArr: any, stepTxt: string) => {
      //console.log(step);
      context_detail.value = 0;
      stat_modal.value = true;
      del_num.value = id;
      info_step.value = step;
      step_all.value = stepAll;
      step_arr.value = stepArr;
      step_text.value = stepTxt;

      del_key.value = del_key.value+1;
    }

    const delete_doc = async () => {
      let id = del_num.value;

      storeDM.showSubscription = false;
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "hide_document",
          id: id,
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();
      docs_lists.value = response_data.doc_list;
      //console.log(docs_lists.value);
      
      context_detail.value = 0;

      del_key.value = del_key.value+1;
      del_modal.value = false;
    };

    const copy_doc = async (id: string) => {
      storeDM.showSubscription = false;
      const response = await fetch(storeDM.api_url + "api3.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "copy_doc",
          id: id,
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      router.push({
        query: {
          hash: route.query.hash,
          type: "document",
          region: response_data["region"],
          construction: response_data["construction"],
          id: response_data["id"],
          step: 1,
          mode: "edit",
        },
      });
      
      context_detail.value = 0;
    };

    /*
    const cnc = () => {
      if(route.query.type == "document"){
        newStatement.value = true;
      }
    }

    cnc();
     */

    const edit_proxy = () => {
      context.emit("edit-proxy");
    }

    const edit_signatory = () => {
      context.emit("edit-signatory");
    }

    const pages_on_page = ref(11);
    const screen_h = ref(0);
    onMounted(() => {
      /*
      if(screen.availHeight <= 800){
        screen_h.value = 650;
        pages_on_page.value = 7;
      } else {
        screen_h.value = 844;
        pages_on_page.value = 11;
      }
      */
      screen_h.value = 844;
      pages_on_page.value = 11;
      //console.log(screen_h.value);
      
    });

    return {
      createStatement,
      page,
      newStatement,
      doc_lists,
      edit_favorite,
      open_doc,
      context_detail,
      edit_proxy,
      edit_signatory,
      sr_visible,
      document_type_title,
      document_type_options,
      document_type_default,
      document_type_change,
      construction_id,
      region_id,
      pagination_bottom_left,
      search_value,
      screen_h,
      pages_on_page,
      copy_doc,
      delete_doc,
      docs_lists,
      download_doc,
      downloadFileFromURL,
      route,
      delete_doc_modal,
      del_modal,
      del_num,
      del_key,
      modalStat,
      stat_modal,
      info_step,
      step_all,
      step_arr,
      step_text,
    }
  }
});
</script>

<style>
	.statements__content {
		min-height: 743px;
	}

  .dots_header {
    height: inherit;
    text-align: center;
    padding-top: 17px;
  }

  .dots {
    cursor: pointer;
    height: inherit;
    text-align: center;
    padding-top: 17px;
  }

  .v-table--fixed-header > .v-table__wrapper > table > thead {
    z-index: unset;
  }
</style>
