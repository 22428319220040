<template>
  <div class="address_two">
    <div class="left">
      <!--<p>Установка карты</p>-->
      <label for="title" style="margin-left: 10px;">
        <v-switch
          v-if="document.mapfile_type == true"
          style="display: flex; flex-direction: row; justify-content: flex-start"
          color="#2F80ED"
          v-model="document.mapfile_type"
          label="Яндекс Карта"
        ></v-switch>
      </label>
      <label for="title" style="margin-left: 10px;">
        <v-switch
          v-if="document.mapfile_type == false"
          style="display: flex; flex-direction: row; justify-content: flex-start"
          color="#2F80ED"
          v-model="document.mapfile_type"
          label="Свой файл"
        ></v-switch>
      </label>
      <br />
      <!--
      <v-radio-group
        v-model="document.mapfile_type"
      >
        <v-radio
          color="#2F80ED"
          label="Использовать сервис Яндекс.Карты"
          value="1">
        </v-radio>
        <v-radio
          color="#2F80ED"
          label="Самостоятельно загрузить файл карты, формата 1:2000"
          value="0">
        </v-radio>
      </v-radio-group>
      -->
      <div v-if="0 == document.mapfile_type" class="manual">
        <!--
        <span class="blueSpan">Карта должна быть соотношением 1.5 к 1, с сервиса РГИС, масштабом 1:2000 с указанным местом установки конструкции</span>
        <input
          ref="map_file"
          type="file"
          hidden
          @change="submitFile('map_file')"
        />
        <div v-if="document.map_file != ''" class="uploadFile" @click="$refs.map_file.click()" ref="fileform"
             :style="'background: url(\'https://api.rekprof.ru/files/'+document.map_file+'\'); background-position: center; background-size: contain; background-repeat: no-repeat; border: 1px solid black;'"
        >
          <p>Перенесите сюда файл <br> или нажмите для загрузки</p>
        </div>
        <div v-if="document.map_file == ''" class="uploadFile" ref="fileform"
             @click="$refs.map_file.click()"
        >
          <p>Перенесите сюда файл <br> или нажмите для загрузки</p>
        </div>
        -->
        <FileUpload :document="document" :file_upload_name="'map_file'" :upload_text="'Карта должна быть соотношением 1.5Шx1В, с сервиса РГИС, масштабом 1:2000 с указанным местом установки конструкции. Пример: 3480px x 2320px'" />
      </div>
      <div v-if="1 == document.mapfile_type">
        <YandexMap :document="document"
                   :address_line="
          'Санкт-Петербург, ' +
          document.map_street +
          ' ' +
          document.map_address +
          ', ' +
          document.map_house
        "
        />
      </div>
    </div>
    <div class="right">
      <div v-if="0 == document.mapfile_type" class="manual">
        <p style="margin-top: 69px;">Пример карты</p>
        <img v-bind:src="require('../../../../assets/images/exampleMap.jpg')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DropdownIcon from "../../../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../../../assets/icons/DropdownUp.vue";
import Map from '../../../../assets/icons/exampleMap.vue'
import YandexMap from "@/components/YandexMap.vue";
import FileUpload from  "@/components/FileUpload.vue";
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {useDMStore} from "@/store/dm_obj";
export default defineComponent ({
  components: {
    DropdownIcon,
    DropdownUp,
    Map,
    YandexMap,
    FileUpload
  },
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){

    const route = useRoute();

    const storeDM = useDMStore();

    const begin_address_default = ref({ name: "Выберите начало адреса", value: "" });
    const begin_address_options = ref([
      { name: "Улица", value: "Улица" },
      { name: "Бульвар", value: "Бульвар" },
      { name: "Шоссе", value: "Шоссе" },
      { name: "Проспект", value: "Проспект" },
    ]);

    const begin_address_title = computed(() => {
      if (props.document.map_street == "") {
        return null;
      } else {
        return props.document.map_street;
      }
    });


    const region_default = ref({ name: "Выберите район", value: "" });
    const region_options = ref([
      { name: "Выборгский", value: "Выборгский" },
      { name: "Московский", value: "Московский" },
      { name: "Петродворцовый", value: "Петродворцовый" },
      { name: "Курортный", value: "Курортный" },
      { name: "Приморский", value: "Приморский" },
      { name: "Пушкинский", value: "Пушкинский" },
      { name: "Петроградский", value: "Петроградский" },
      { name: "Васильеостровский", value: "Васильеостровский" },
      { name: "Красносельский", value: "Красносельский" },
      { name: "Невский", value: "Невский" },
      { name: "Центральный", value: "Центральный" },
      { name: "Адмиралтейский", value: "Адмиралтейский" },
      { name: "Фрунзенский", value: "Фрунзенский" },
      { name: "Калининский", value: "Калининский" },
      { name: "Кировский", value: "Кировский" },
      { name: "Кронштадский", value: "Кронштадский" },
      { name: "Колпинский", value: "Колпинский" },
      { name: "Красногвардейский", value: "Красногвардейский" },
    ]);

    const region_title = computed(() => {
      if (props.document.map_district == "") {
        return;
      } else {
        return props.document.map_district;
      }
    });

    //const region_title = reactive({name:props.document.map_district, value:props.document.map_district});


    const isShow = ref(false);
    const isRegion = ref(false);
    const isOpen = (obj, type) => {
      if(type == "begin_address"){
        props.document.map_street = obj.value;
        isShow.value = false;
      } else if(type == "region"){
        props.document.map_district = obj.value;
        isRegion.value = false;
      }
    }

    const subpage = ref(1);

    /*
    const submitFile = (f_name: string) => {
      let file = Object(eval(f_name).value).files[0];
      if(file == undefined){
        return;
      }

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.query.id));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          props.document[Object(req.data).file_name] = Object(req.data)[
            req.data.file_name
            ];
          //console.log(props.document[Object(req.data).file_name]);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };
    */

    const begin_address_change = (e) => {
      props.document.map_street = e.value.value;
    }

    const region_change = (e) => {
      props.document.map_district = e.value.value;
    }

    /*
    const determineDragAndDropCapable = () => {
      var div = document.createElement('div');
      return ( ( 'draggable' in div )
          || ( 'ondragstart' in div && 'ondrop' in div ) )
        && 'FormData' in window
        && 'FileReader' in window;
    }

    const map_file = ref<any | null>(null);
    const fileform = ref<HTMLElement | null>(null);

    onMounted(() => {

      const dragAndDropCapable = determineDragAndDropCapable();
      const th = this;
      if( dragAndDropCapable ) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
          fileform.value?.addEventListener(evt, function (e) {
            e.preventDefault();
            e.stopPropagation();
          }.bind(th), false);
        }.bind(this));
        fileform.value?.addEventListener('drop', function (e) {
          //for (let i = 0; i < e.dataTransfer.files.length; i++) {
          //  files.push(e.dataTransfer.files[i]);
          //}
          //map_file.value["files"] = [];
          map_file.value["files"] = e.dataTransfer.files;
        }.bind(this));
      }

      fileform.value?.addEventListener('drop', function(e){
        //for( let i = 0; i < e.dataTransfer.files.length; i++ ){
        //  files.push( e.dataTransfer.files[i] );
        //}
        //map_file.value["files"] = [];
        map_file.value["files"] = e.dataTransfer.files;
        //submitFiles();
        submitFile('map_file');
      }.bind(this));
    });
    */

    return{
      subpage,
      begin_address_default,
      begin_address_options,
      begin_address_title,
      begin_address_change,
      region_default,
      region_options,
      region_title,
      region_change,
      isShow,
      isRegion,
      isOpen,
      //submitFile,
      //map_file,
      //fileform,
    }
  },
});
</script>