import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4dfea92"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal_window"
}
const _hoisted_2 = { class: "info_txt" }
const _hoisted_3 = { style: {"margin-top":"100px","font-size":"16px"} }
const _hoisted_4 = { class: "info_txt" }
const _hoisted_5 = { style: {"margin-top":"20px","font-size":"16px"} }
const _hoisted_6 = { class: "info_txt" }
const _hoisted_7 = { style: {"margin-top":"2px","font-size":"16px","font-weight":"bold"} }
const _hoisted_8 = { class: "wrapper_btn" }
const _hoisted_9 = {
  key: 1,
  class: "modal_block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, "✖"),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, "Заявление №" + _toDisplayString(_ctx.info_num), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, "Шаг " + _toDisplayString(_ctx.step), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.info_txt), 1)
          ]),
          _createElementVNode("div", {
            class: "info_txt",
            style: _normalizeStyle(['margin-left: '+((350-(17*_ctx.step_all+8*(_ctx.step_all-1)))/2)+'px;', {"margin-top":"25px"}])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Number(_ctx.step_all), (n) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                  style: _normalizeStyle([{"width":"17px","height":"17px","background-color":"red","float":"left"}, (n>1?'margin-left: 8px; ':'')
                +
                (1==Number(_ctx.step_arr[n-1])?'background-color: #03CB98;':'')
                +
                (n==Number(_ctx.step) && 1==Number(_ctx.step_arr[0]) ?'background-color: #2F80ED;':'')
                +
                (n==1 && 0==Number(_ctx.step_arr[0])?'background-color: #2F80ED;':'')
                ])
                }, null, 4)
              ]))
            }), 256))
          ], 4),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "do_btn",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.do_btn && _ctx.do_btn(...args)))
            }, "Продолжить")
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9))
      : _createCommentVNode("", true)
  ], 64))
}