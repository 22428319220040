<script lang="ts">
import {computed, defineComponent, ref, onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useDMStore} from "@/store/dm_obj";
import axios from "axios";
import ModalTariff from "@/components/ModalTariff.vue";
export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){
    const load_process = ref(false);

    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    const info_save = ref("дизайн проект №" + route.query.id);

    //const last_info
    const docs = reactive({docs_time: "", docs_time_text: "", docs_count: "", docs_count_text: "", count_edit: "", count_edit_text: ""});

    onMounted(async () => {
      const response = await fetch(storeDM.api_url + "api3.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_counts_docs",
          hash: route.query.hash,
          id: route.query.id,
        }),
      });

      const response_data = await response.json();
      docs.docs_time = response_data.docs_time;
      if(docs.docs_time=="Возобновить"){
        docs.docs_time_text="Действие подписки прекращено";
      } else {
        docs.docs_time_text="Подписка до";
      }

      docs.docs_count = response_data.docs_count;
      if(docs.docs_count=="Пополнить"){
        docs.docs_count_text="Нет доступных заявлений";
      } else {
        docs.docs_count_text="Доступно заявлений";
      }

      docs.count_edit = response_data.count_edit;
      if(docs.count_edit=="Добавить"){
        //docs.count_edit_text="Правки закончились";
        docs.count_edit_text="";
      } else {
        docs.count_edit_text="Доступно правок";
      }

    });

    const goPaySerg = () => {
      let types = route.query.type;

      if(types == "proxy"){
        types = "documents";
      } else if(types == "signatory" ){
        types = "signatories";
      }

      if(route.query.id && route.query.type=="document"){
        window.location.href = "https://rekdoc.rekprof.ru/logon.php?hash=" + route.query.hash + "&type=" + types +"&id=" + route.query.id + "&region=" + route.query.region + "&construction=" + route.query.construction + "&step=" + route.query.step + "&mode=" + route.query.mode;
      } else {
        window.location.href = "https://rekdoc.rekprof.ru/logon.php?hash=" + route.query.hash + "&type=" + types;
      }
    }

    return{
      route,
      docs,
      goPaySerg,
    }
  },
  components: {
  },
});
</script>

<template>
  <div class="noselect" style="color: white; cursor:pointer;" @click="goPaySerg();">
    {{docs.docs_count_text}}:
    <div style="display: contents; cursor: pointer;" v-if="docs.docs_count=='Пополнить'"><u>{{docs.docs_count}}</u></div>
    <div style="display: contents;" v-if="docs.docs_count!='Пополнить'">{{docs.docs_count}}</div>
    / {{docs.docs_time_text}}: {{docs.docs_time}}
    <div style="display: contents;" v-if="docs.count_edit!='-' && route.query.type=='document' && docs.count_edit_text!=''">/ {{docs.count_edit_text}} - {{docs.count_edit}}</div>
  </div>
  <!--
  <div class="modalSubcribe">
    <div class="subsribe_row">
      <div class="row_element_one">{{docs.docs_count_text}}</div>
      <div class="row_element_two">
        <div class="my_pay" v-if="docs.docs_count=='Пополнить'" @click="goPaySerg();">{{docs.docs_count}}</div>
        <div v-else>{{docs.docs_count}}</div>
      </div>
    </div>
    <div class="subsribe_row" v-if="route.query.id">
      <div class="row_element_one">{{docs.count_edit_text}}</div>
      <div class="row_element_two">{{docs.count_edit}}</div>
    </div>
    <div class="subsribe_row">
      <div class="row_element_one">{{docs.docs_time_text}}</div>
      <div class="row_element_two">{{docs.docs_time}}</div>
    </div>
  </div>
  -->
</template>

<style scoped>
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .my_pay{
    cursor: pointer;
  }

  .modalSubcribe {
    position: absolute;
    top: 56px;
    z-index: 1;
    width: 380px;
    margin-left: -155px;
  }

  .subsribe_row {
    height: 40px;
    line-height: 40px;
    font-family: 'Roboto';
    font-size: 14px;
    border-bottom: 1px solid #E0E0E0;
    -webkit-box-shadow: -4px 0px 5px -4px rgba(34, 60, 80, 0.6) inset;
    -moz-box-shadow: -4px 0px 5px -4px rgba(34, 60, 80, 0.6) inset;
    box-shadow: -4px 0px 5px -4px rgba(34, 60, 80, 0.6) inset;
    background-color: white;
  }

  .row_element_one {
    float: left;
    padding-left: 15px;
    -webkit-box-shadow: 4px 0px 5px -4px rgba(34, 60, 80, 0.6) inset;
    -moz-box-shadow: 4px 0px 5px -4px rgba(34, 60, 80, 0.6) inset;
    box-shadow: 4px 0px 5px -4px rgba(34, 60, 80, 0.6) inset;
  }

  .row_element_two {
    float: right;
    margin-right: 15px;
    color: #2F80ED;
    font-weight: 700;
  }
</style>