<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">1.2 Заполнение данных вручную</span>
    <div class="hint_left">Введите ИНН</div>
    <input
      class="main_input"
      placeholder="Введите ИНН"
      v-model="storeDM.save_obj.client_inn"
      maxlength="12"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br />
    <div v-if="storeDM.save_obj.client_type == 3" class="hint_left">
      Введите ОГРНИП
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 3"
      class="main_input"
      placeholder="Введите ОГРНИП"
      v-model="storeDM.save_obj.client_ogrnip"
      maxlength="15"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br v-if="storeDM.save_obj.client_type == 3" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Введите ОГРН
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Введите ОГРН"
      v-model="storeDM.save_obj.client_ogrn"
      maxlength="13"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br v-if="storeDM.save_obj.client_type == 1" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Введите КПП
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Введите КПП"
      v-model="storeDM.save_obj.client_kpp"
      maxlength="9"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br v-if="storeDM.save_obj.client_type == 1" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Адрес регистрации (для доверенности)
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Адрес регистрации (для доверенности)"
      v-model="storeDM.save_obj.client_address"
    />
    <br v-if="storeDM.save_obj.client_type == 1" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Введите сокращённое название организации
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Введите сокращённое название организации"
      v-model="storeDM.save_obj.client_smallname"
    />
    <br v-if="storeDM.save_obj.client_type == 1" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Введите полное название организации
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Введите полное название организации"
      v-model="storeDM.save_obj.client_fullname"
    />
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepOneOne",
  components: { VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "1",
      });
      router.push({
        path: "/step1/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "2",
      });
      router.push({
        path: "/step2/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
      /*
      if (
        storeDM.save_obj.client_inn != "" &&
        storeDM.save_obj.client_ogrn != "" &&
        storeDM.save_obj.client_fullname != "" &&
        storeDM.save_obj.client_smallname != ""
      ) {
        return true;
      } else {
        return false;
      }
      */
    });

    return {
      go_back,
      step_next,
      go_next,
      storeDM,
    };
  },
});
</script>

<style scoped></style>
