<template>
    <div class="step__six">
      <p>Вы можете скачать доверенность</p>
      <span>Нажмите чтобы скачать доверенность</span>
      <button @click="download_signatory">Скачать доверенность</button>
      <br/>
      <div v-if="document.parent != 0">
        <!--
        <p>На этом работа с доверенностью окончена <br>
  и мы возвращаемся к формированию заявления</p>
        <button @click="go_doc">Использовать в текущем заявлении</button>
        -->
        <button
        style="
        width: 83px;
        height: 46px;
        position: absolute;
        top: 795px;
        border-radius: 4px;
        margin-left: 756px;
        font-size: 14px;
        "
        @click="go_doc">Далее</button>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import {useDMStore} from "@/store/dm_obj";
export default defineComponent({
    props: {
      document: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    emits: ["update_doc", "edit-statements"],
    setup(
      props: {
        document: any;
      }, context
    ){
        const router = useRouter();
        const route = useRoute();

        const storeDM = useDMStore();

        const download_signatory = () => {
            downloadItem(
              storeDM.api_url +
              "proxy.php?id=" +
              route.query.id +
              "&hash=" +
              route.query.hash +
              "&type=declarations",
              "Доверенность " + route.query.id
            );
        };

        const downloadItem = (url: string, label: string) => {
            axios
              .get(url, { responseType: "blob" })
              .then((response) => {
                  const blob = new Blob([response.data], { type: "application/pdf" });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = label;
                  link.click();
                  URL.revokeObjectURL(link.href);
              })
              .catch(console.error);
        };

        const go_doc = () => {
          let parent = props.document.parent;
          let parent_step = props.document.parent_step;

          props.document.parent = 0;
          props.document.parent_step = 0;
          context.emit("update_doc");

          router.push({
            query: {
              hash: route.query.hash,
              type: "document",
              id: parent,
              step: parent_step,
              mode: "edit",
            },
          });

          context.emit("edit-statements");
        };

        return{
          download_signatory,
          go_doc,
        }
    }
});
</script>