<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list :key="1"></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">Поиск по ИНН</span>
    <div class="hint_left hint_mini_top">
      Введите ИНН, дождитесь загрузки и нажмите далее
    </div>
    <input
      class="main_input"
      placeholder="Введите ИНН"
      v-model="storeDM.save_obj.client_inn"
      maxlength="12"
      @input="inn_change"
      @paste="inn_change"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br />
    <br />
    <br />
    <span class="main_field">Выберите тип заявителя</span>
    <v-select-open
      :options="client_type_options"
      :title_select="client_type_title"
      :default_select="storeDM.save_obj.client_type"
      :key="storeDM.save_obj.client_type"
      @change-select="client_type_select"
      :margin_top="'3px'"
    >
    </v-select-open>
    <br />
    <br />
    <div v-if="storeDM.save_obj.client_type == 3" class="hint_left">
      Введите ОГРНИП
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 3"
      class="main_input"
      placeholder="Введите ОГРНИП"
      v-model="storeDM.save_obj.client_ogrnip"
      maxlength="15"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br v-if="storeDM.save_obj.client_type == 3" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Введите ОГРН
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Введите ОГРН"
      v-model="storeDM.save_obj.client_ogrn"
      maxlength="13"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br v-if="storeDM.save_obj.client_type == 1" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Введите КПП
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Введите КПП"
      v-model="storeDM.save_obj.client_kpp"
      maxlength="9"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br v-if="storeDM.save_obj.client_type == 1" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Юридический адрес
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Юридический адрес"
      v-model="storeDM.save_obj.client_address"
    />
    <br v-if="storeDM.save_obj.client_type == 1" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Введите сокращённое название организации
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Введите сокращённое название организации"
      v-model="storeDM.save_obj.client_smallname"
    />
    <br v-if="storeDM.save_obj.client_type == 1" />
    <div v-if="storeDM.save_obj.client_type == 1" class="hint_left">
      Введите полное название организации
    </div>
    <input
      v-if="storeDM.save_obj.client_type == 1"
      class="main_input"
      placeholder="Введите полное название организации"
      v-model="storeDM.save_obj.client_fullname"
    />
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRouter, useRoute } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepOne",
  components: { VSelectOpen, VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    //if (storeDM.initDM == false) {
    storeDM.init(route.query.hash, route.params.docID);
    storeDM.set_info({}, 0);
    //}

    const go_back = () => {
      router.go(-1);
    };

    const go_next = () => {
      storeDM.set_info({
        step: "2",
      });
      //storeDM.get_info_server(); //Странная строка. Волшебный баг
      router.push({
        path: "/step2/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const inn_manual = () => {
      storeDM.set_info({
        step: "1_1",
      });
      router.push({
        path: "/step1_1/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const inn_change = async () => {
      /*
      if (
        (storeDM.save_obj.client_inn.length == 12 &&
          storeDM.save_obj.client_type == 3) ||
        (storeDM.save_obj.client_inn.length == 10 &&
          storeDM.save_obj.client_type == 1)
      ) {
      */
      if (
        storeDM.save_obj.client_inn.length == 10 ||
        storeDM.save_obj.client_inn.length == 12
      ) {
        const response = await fetch(storeDM.api_url + "inn.php", {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inn: storeDM.save_obj.client_inn,
          }),
        });

        const response_data = await response.json();

        if (
          storeDM.save_obj.client_inn.length == 10 ||
          storeDM.save_obj.client_inn.length == 12
        ) {
          if (response_data["address"] !== "undefined") {
            storeDM.save_obj.client_type = 1;
            storeDM.save_obj.client_ogrn = response_data["ogrn"];
            storeDM.save_obj.client_kpp = response_data["kpp"];
            storeDM.save_obj.client_address = response_data["address"];
            storeDM.save_obj.client_smallname = response_data["smallname"];
            storeDM.save_obj.client_fullname = response_data["fullname"];
          } else if (response_data["ogrnip"] !== "undefined") {
            storeDM.save_obj.client_type = 3;
            storeDM.save_obj.client_ogrnip = response_data["ogrnip"];
          } else {
            storeDM.save_obj.client_type = 2;
          }
        }

        /*
        if (
          typeof response_data["ogrnip"] !== "undefined" &&
          storeDM.save_obj.client_inn.length == 12 &&
          storeDM.save_obj.client_type == 3
        ) {
          storeDM.save_obj.client_ogrnip = response_data["ogrnip"];
        } else if (
          typeof response_data["address"] !== "undefined" &&
          storeDM.save_obj.client_inn.length == 10 &&
          storeDM.save_obj.client_type == 1
        ) {
          storeDM.save_obj.client_ogrn = response_data["ogrn"];
          storeDM.save_obj.client_kpp = response_data["kpp"];
          storeDM.save_obj.client_address = response_data["address"];
          storeDM.save_obj.client_smallname = response_data["smallname"];
          storeDM.save_obj.client_fullname = response_data["fullname"];
        }
         */
        //console.log(response_data);
      }
    };

    const client_type_title = "Выберите тип заявителя";
    const client_type_options = [
      { name: "Юридическое лицо", value: "1" },
      { name: "Физическое лицо", value: "2" },
      { name: "Индивидуальный предприниматель", value: "3" },
      //{ name: "Другое", value: "4" },
    ];

    const client_type_select = (opt: any) => {
      storeDM.save_obj.client_type = opt.value;
    };

    const step_next = computed(() => {
      return true;
      /*
      if (
        storeDM.save_obj.client_type != 0 &&
        storeDM.save_obj.client_inn != ""
      ) {
        return true;
      } else {
        return false;
      }
      */
    });

    return {
      go_back,
      client_type_title,
      client_type_options,
      client_type_select,
      step_next,
      go_next,
      inn_manual,
      storeDM,
      inn_change,
    };
  },
});
</script>

<style scoped></style>
