import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bd4c995"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "componentContainer",
  class: "pagination-container",
  style: 
  'margin-left: 1.5rem;'
  //'top: '+screen_h+'px;'
  
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick", "onMouseover"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      (_ctx.nav_btn)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
            _createElementVNode("button", {
              class: _normalizeClass(["back-button paginate-buttons", {'btn-disabled': _ctx.page_cur==1}]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.page_prev && _ctx.page_prev(...args)))
            }, " Назад ", 2)
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.page_arr, (item, index) => {
        return (_openBlock(), _createElementBlock("li", null, [
          _createElementVNode("button", {
            class: _normalizeClass(["paginate-buttons number-buttons", 
          {'complete-page': _ctx.page_complete[index] == true && item.complete.length!=0 && _ctx.page_cur!=index+1},
          {'incomplete-page': _ctx.page_complete[index] == false && item.complete.length!=0 && _ctx.page_cur!=index+1},//page_arr.length!=index+1
          {'active-page': _ctx.page_cur==index+1}
        ]),
            onClick: ($event: any) => (_ctx.page_cur_set(index+1)),
            onMouseover: ($event: any) => (_ctx.page_hint_over($event, index)),
            onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.page_hint_over($event, 'empty')))
          }, _toDisplayString(index+1) + _toDisplayString(_ctx.page_cur==index+1 ? " "+item.title : ""), 43, _hoisted_3)
        ]))
      }), 256)),
      (_ctx.nav_btn)
        ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
            _createElementVNode("button", {
              class: _normalizeClass(["paginate-buttons next-button", {'btn-disabled': _ctx.page_cur==_ctx.page_arr.length}]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.page_next && _ctx.page_next(...args)))
            }, " Далее ", 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["page_hint", {'display_none': 0==_ctx.hint_id || _ctx.page_cur==_ctx.hint_id}]),
      style: _normalizeStyle(
          'left:'+(_ctx.mouse_x-150)+'px;'+
          'border: 1px solid '+_ctx.hint_color+';'
       )
    }, _toDisplayString(_ctx.hint_text), 7)
  ], 64))
}