<template>
  <div class="steps">
    <button :class="{activeStep: subpage == 1}" @click="subpage = 1">Шаг 1</button>
    <button :class="{activeStep: subpage == 2}" @click="subpage = 2">Шаг 2</button>
    <!--
    <button :class="{activeStep: subpage == 3}" @click="subpage = 3">Шаг 3</button>
    <button :class="{activeStep: subpage == 4}" @click="subpage = 4">Шаг 4</button>
    -->
  </div>
  <div class="step__basic" v-if="subpage == 1">
    <div class="left">
      <div>
        <label for="name">Введите владельца средства размещения информации </label>
        <span class="blueSpan">Пример: ООО "АгроТехПромСервис"</span>
        <input class="input" type="text" placeholder="Введите название" v-model="document.information_owner">
      </div>
      <!--
      <div>
        <label for="name">Введите адрес</label>
        <span class="blueSpan">Пример: Российская Федерация, МО, г. Подольск, деревня Борисовка (АЗС)</span>
        <textarea class="input" type="text" placeholder="Введите адрес" v-model="document.address"></textarea>
      </div>
      -->
    </div>
  </div>
  <div class="step__basic" v-if="subpage == 2">
    <div class="left">
      <div>
        <label for="name">Выберите тип информации</label>
        <span>Нажмите, чтобы выбрать тип конструкции из списка</span>
        <Dropdown v-model="construction_type_title"
                  editable :options="construction_type_options"
                  optionLabel="name"
                  :placeholder="construction_type_default.name"
                  style="width:480px"
                  @change="construction_type_change"
        />
      </div>

      <div>
        <section>
          <div style="width: 200px;">
            <label for="title">Количество сторон</label>
            <input style="width: 199px;" class="input" placeholder="2" type="number" v-model="document.number_sides">
          </div>
          <div style="width: 200px; margin-top: -75px; margin-left: 280px;">
            <label for="title">Количество элементов</label>
            <input style="width: 199px;" class="input" placeholder="4" type="number" v-model="document.number_elements">
          </div>
        </section>
      </div>

      <div>
        <label for="name">Размер</label>
        <span class="blueSpan">Введите значение текстом, в мм. Пример: 2 х 2 мм</span>
        <input class="input" type="text" placeholder="Введите размер" v-model="document.size">
      </div>

      <div>
        <label for="name">Текст</label>
        <span class="blueSpan">Введите отображаемый текст. Пример: вывеска "Магнит"</span>
        <input class="input" type="text" placeholder="Введите текст" v-model="document.information_text">
      </div>


    </div>
    <div class="right">
      <div>
        <label for="name">Технологическая характеристика</label>
        <span>Пример: Внутренний подсвет, отсутствует, другое</span>
        <Dropdown v-model="technological_characteristic_title"
                  editable :options="technological_characteristic_options"
                  optionLabel="name"
                  :placeholder="technological_characteristic_default.name"
                  style="width:480px"
                  @change="technological_characteristic_change"
        />

        <input
          class="input"
          type="text"
          placeholder="Введите технологическую характеристику"
          v-model="document.technological_characteristic"
          v-if="document.technological_characteristic != 'Внутренний подсвет' && document.technological_characteristic != 'Отсутствует'">
      </div>
    </div>
  </div>

  <!--
  <div class="sixth" v-if="subpage == 3">
    <div class="left">
      <div>
        <label for="name">Добавьте фронтальную фотографию конструкции вблизи</label>
        <span>Добавьте фото в поле ниже</span>
      </div>
      <input
        ref="construction_around_file"
        type="file"
        hidden
        @change="submitFile('construction_around_file')"
      />
      <div v-if="document.construction_around_file != ''"  class="fileUpload" @click="$refs.construction_around_file.click()"
           :style="'background: url(\'https://api.rekprof.ru/files/'+document.construction_around_file+'\'); background-position: center; background-size: contain; background-repeat: no-repeat; border: 1px solid black;'"
      >
        <p>Перенесите сюда файл <br> или нажмите для загрузки</p>
      </div>
      <div v-if="document.construction_around_file == ''" class="fileUpload" @click="$refs.construction_around_file.click()">
        <p>Перенесите сюда файл <br> или нажмите для загрузки</p>
      </div>
      <span>Название файла, перспектива.jpg</span>
    </div>
    <div class="right">
      <p style="margin-top: -6px;">Пример изображения</p>
      <img src="https://api.rekprof.ru/files/sample/mo/construction_in.jpg" />
    </div>
  </div>

  <div class="sixth" v-if="subpage == 4">
    <div class="left">
      <div>
        <label for="name">Добавьте фронтальную фотографию конструкции издалека</label>
        <span>Добавьте фото в поле ниже</span>
      </div>
      <input
          ref="construction_away_file"
          type="file"
          hidden
          @change="submitFile('construction_away_file')"
      />
      <div v-if="document.construction_away_file != ''"  class="fileUpload" @click="$refs.construction_away_file.click()"
           :style="'background: url(\'https://api.rekprof.ru/files/'+document.construction_away_file+'\'); background-position: center; background-size: contain; background-repeat: no-repeat; border: 1px solid black;'"
      >
        <p>Перенесите сюда файл <br> или нажмите для загрузки</p>
      </div>
      <div v-if="document.construction_away_file == ''" class="fileUpload" @click="$refs.construction_away_file.click()">
        <p>Перенесите сюда файл <br> или нажмите для загрузки</p>
      </div>
      <span>Название файла, перспектива.jpg</span>
    </div>
    <div class="right">
      <p style="margin-top: -6px;">Пример изображения</p>
      <img src="https://api.rekprof.ru/files/sample/mo/construction_out.jpg" />
    </div>
  </div>
  -->

</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {useDMStore} from "@/store/dm_obj";

export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){

    const storeDM = useDMStore();
    const route = useRoute();

    const construction_type_default = ref({ name: "Выберите тип конструкции", value: "" });
    const construction_type_options = ref([
      { name: "Настенная вывеска", value: "Настенная вывеска" },
      { name: "Вывеска", value: "Вывеска" },
      { name: "Консольная вывеска", value: "Консольная вывеска" },
      { name: "Крышная установка", value: "Крышная установка" },
      { name: "Пилон", value: "Пилон" },
      { name: "Щит общий", value: "Щит общий" },
      { name: "Виниловое полотно на каркасе", value: "Виниловое полотно на каркасе" },
      { name: "Лайтбокс", value: "Лайтбокс" },
      { name: "Объёмные буквы", value: "Объёмные буквы" },
      { name: "Отнесённая вывеска", value: "Отнесённая вывеска" },
      { name: "Световой короб", value: "Световой короб" },
      { name: "Стелла", value: "Стелла" },
      { name: "Указатель", value: "Указатель" },
      { name: "Флагшток", value: "Флагшток" },
      { name: "Часы", value: "Часы" },
    ]);

    const construction_type_title = computed(() => {
      if (props.document.construction_type == "") {
        return null;
      } else {
        return props.document.construction_type;
      }
    });

    const construction_type_change = (e) => {
      props.document.construction_type = e.value.value;
    }

    const technological_characteristic_default = ref({ name: "Внутренний подсвет", value: "Внутренний подсвет" });
    const technological_characteristic_options = ref([
      { name: "Внутренний подсвет", value: "Внутренний подсвет" },
      { name: "Отсутствует", value: "Отсутствует" },
      { name: "Другой", value: "" },
    ]);

    const technological_characteristic_title = computed(() => {
      if("Внутренний подсвет" != props.document.technological_characteristic && "Отсутствует" != props.document.technological_characteristic){
        return "Другой";
      } else {
        return props.document.technological_characteristic;
      }
    });

    const technological_characteristic_change = (e) => {
      props.document.technological_characteristic = e.value.value;
    }

    const submitFile = (f_name: string) => {
      let file = Object(eval(f_name).value).files[0];
      if(file == undefined){
        return;
      }

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.query.id));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          props.document[Object(req.data).file_name] = Object(req.data)[
            req.data.file_name
            ];
          console.log(Object(req.data).file_name+" - "+Object(req.data)[req.data.file_name]);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    const construction_around_file = ref(null);
    const construction_away_file = ref(null);


    //const subpage = ref(Number(route.query.substep));
    const subpage = ref(1);

    return{
      subpage,
      construction_type_default,
      construction_type_options,
      construction_type_title,
      construction_type_change,
      technological_characteristic_title,
      technological_characteristic_options,
      technological_characteristic_default,
      technological_characteristic_change,
      submitFile,
      construction_around_file,
      construction_away_file,
    }
  }
});
</script>