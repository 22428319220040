<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">4.2 Ручной ввод адреса</span>
    <input ref="map_file" type="file" hidden @change="submitFile('map_file')" />
    <button
      type="button"
      class="blue_btn"
      @click="$refs.map_file.click()"
      style="float: left; margin-left: 10px"
    >
      Загрузить картинку с картой
    </button>
    <div class="hint_left">Введите адрес (улица Ильича, переулок Брежнева)</div>
    <input
      class="main_input"
      placeholder="Введите адрес (улицу)"
      v-model="storeDM.save_obj.map_address"
    />
    <br />
    <div class="hint_left">
      Введите
      район&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Введите номер дома
    </div>
    <input
      class="first_input"
      placeholder="Введите район"
      v-model="storeDM.save_obj.map_district"
    />
    <input
      class="two_input"
      placeholder="Введите номер дома"
      v-model="storeDM.save_obj.map_house"
    />
    <br />
    <div class="hint_left">
      Введите
      корпус&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Введите литеру или строение
    </div>
    <input
      class="first_input"
      placeholder="Введите корпус"
      v-model="storeDM.save_obj.map_korpus"
    />
    <input
      class="two_input"
      placeholder="Введите литеру или строение"
      v-model="storeDM.save_obj.map_build_litera"
    />
    <br />
    <div class="hint_left">
      Введите номер
      места&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Введите пересечение с домом или павильон
    </div>
    <input
      class="first_input"
      placeholder="Введите номер места"
      v-model="storeDM.save_obj.map_place"
    />
    <input
      class="two_input"
      placeholder="Введите пересечение с домом или павильон если имеется"
      v-model="storeDM.save_obj.map_cross"
    />
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";
import axios from "axios";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepFourTwo",
  components: { VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "4",
      });
      router.push({
        path: "/step4/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "5",
      });
      router.push({
        path: "/step5/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const map_file = ref(null);

    const submitFile = (f_name: string) => {
      let file = Object(eval(f_name).value).files[0];

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.params.docID));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          storeDM.save_obj[Object(req.data).file_name] = Object(req.data)[
            req.data.file_name
          ];
          //console.log(req);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    const map_file_hint = computed(() => {
      if (typeof storeDM.save_obj.map_file !== "undefined") {
        if (storeDM.save_obj.map_file == "") {
          return "Нажмите чтобы загрузить карту с местом";
        } else {
          return (
            'Имя загруженного файла - "' +
            storeDM.save_obj.map_file.split("/")[
              storeDM.save_obj.map_file.split("/").length - 1
            ] +
            '"'
          );
        }
      } else {
        return 'Имя загруженного файла - "-не загружен-"';
      }
    });

    const step_next = computed(() => {
      return true;
    });

    return {
      go_back,
      step_next,
      go_next,
      storeDM,
      submitFile,
      map_file,
      map_file_hint,
    };
  },
});
</script>

<style scoped></style>
