import { defineStore } from "pinia";
import { useRouter } from "vue-router";

interface IDMState {
  api_url: string;
  hash: string;
  random_hash: string;
  docID: string;
  client_id: string;
  email: string;
  phone: string;
  step: string;
  signatory_options: any;
  signatory_list: any;
  signatory_options_tpm: any;
  signatory_options_add: boolean;
  showSubscription: boolean;
  save_obj: any;
  dragScroll: boolean;
  initDM: boolean;
  rail: boolean;
  count: number;
  router: any;
}

export const useDMStore = defineStore({
  id: "dm_obj",
  state: (): IDMState => ({
    api_url: "https://api.rekprof.ru/",
    hash: "",
    random_hash: "",
    docID: "",
    client_id: "",
    email: "",
    phone: "",
    step: "",
    signatory_options: [],
    signatory_list: [],
    signatory_options_tpm: { signatory_genre: 0 },
    signatory_options_add: false,
    save_obj: {},
    showSubscription: false,
    dragScroll: false,
    initDM: false,
    rail: false,
    count: 0,
    router: useRouter(),
  }),
  actions: {
    incCount() {
      this.count++;
    },
    decCount() {
      this.count--;
    },
    async set_info(obj: { [index: string]: any }, one = 1) {
      for (const i in obj) {
        //???
        //signatory_list
        this.save_obj[i] = obj[i];

        if (i == "designer_name") {
          if (this.save_obj[i] == "") {
            this.save_obj[i] = 'ООО "Консалтинговая группа "СЕВЕР""';
          }
        }

        if (i == "manufacturer_name") {
          if (this.save_obj[i] == "") {
            this.save_obj[i] = 'ООО "Консалтинговая группа "СЕВЕР""';
          }
        }

        if (i == "construction_info_custom") {
          //this.save_obj[i] = JSON.parse(this.save_obj[i]);
          //this.save_obj[i] = []; //JSON.parse(this.save_obj[i]);
          if (typeof JSON.parse(this.save_obj[i]) == "string") {
            this.save_obj[i] = [];
          } else {
            this.save_obj[i] = JSON.parse(this.save_obj[i]);
          }
        }

        if (i == "declarer_patronymic_no") {
          if (this.save_obj.declarer_patronymic_no == 1) {
            this.save_obj.declarer_patronymic_no = true;
          } else {
            this.save_obj.declarer_patronymic_no = false;
          }
        }

        if (i == "construction_app6") {
          if (this.save_obj.construction_app6 == 1) {
            this.save_obj.construction_app6 = true;
          } else {
            this.save_obj.construction_app6 = false;
          }
        }

        if (i == "declarer_no") {
          if (this.save_obj.declarer_no == 1) {
            this.save_obj.declarer_no = true;
          } else {
            this.save_obj.declarer_no = false;
          }
        }

        if (this.signatory_options_add == true) {
          this.signatory_options_add = false;
          if (this.signatory_options_tpm.signatory_skip_patronymic == true) {
            this.signatory_options_tpm.signatory_skip_patronymic = 1;
          } else {
            this.signatory_options_tpm.signatory_skip_patronymic = 0;
          }
          await this.add_signatory_options();
        }
      }
      if (one == 1) {
        this.send_info_server(0);
      }

      this.save_obj.steps = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (
        this.save_obj.client_inn != "" &&
        this.save_obj.client_type != 0 // &&
        //this.save_obj.client_ogrn != "" &&
        //this.save_obj.client_ogrnip != "" &&
        //this.save_obj.client_kpp != "" &&
        //this.save_obj.client_address != "" &&
        //this.save_obj.client_fullname != "" &&
        //this.save_obj.client_smallname != ""
      ) {
        this.save_obj.steps[1] = 1;
      }
      if (this.save_obj.send_type != 0) {
        this.save_obj.steps[2] = 1;

        this.save_obj.steps[3] = 1; //!!!!!!!
      }

      if (this.save_obj.send_type == 1) {
        this.save_obj.steps[3] = 1;
      }

      if (
        this.save_obj.map_file != "" &&
        this.save_obj.map_address != "" &&
        this.save_obj.map_street != "" &&
        this.save_obj.map_district != "" &&
        this.save_obj.map_house != "" &&
        this.save_obj.map_house != "" &&
        this.save_obj.map_korpus != "" &&
        this.save_obj.map_build_litera != "" &&
        //this.save_obj.map_place != "" &&
        this.save_obj.map_cross != ""
      ) {
        this.save_obj.steps[4] = 1;
      }

      if (
        this.save_obj.place_number !== 0 &&
        this.save_obj.construction_type != "" &&
        this.save_obj.place_install != ""
      ) {
        this.save_obj.steps[5] = 1;
      }

      if (
        this.save_obj.construction_perspective_file != "" &&
        this.save_obj.construction_around_file != "" &&
        this.save_obj.construction_away_file != ""
      ) {
        this.save_obj.steps[6] = 1;
      }

      if (
        (this.save_obj.mounting_perspective_file != "" &&
          this.save_obj.mounting_around_file != "" &&
          this.save_obj.mounting_away_file != "") ||
        this.save_obj.construction_app6 == true
      ) {
        this.save_obj.steps[7] = 1;
      }

      if (
        this.save_obj.sketch_project_one_file != "" &&
        this.save_obj.sketch_project_two_file != ""
      ) {
        this.save_obj.steps[8] = 1;
      }

      if (
        this.save_obj.number_type_illumination != "" &&
        this.save_obj.construction_width != "" &&
        this.save_obj.construction_height != "" &&
        this.save_obj.construction_info_width != "" &&
        this.save_obj.construction_info_height != "" &&
        this.save_obj.info_count != 0
      ) {
        this.save_obj.steps[9] = 1;
      }

      if (
        this.save_obj.designer_name !== "" &&
        this.save_obj.manufacturer_name !== ""
      ) {
        this.save_obj.steps[10] = 1;
      }

      if (
        this.save_obj.steps[1] == 1 &&
        this.save_obj.steps[2] == 1 &&
        this.save_obj.steps[3] == 1 &&
        this.save_obj.steps[4] == 1 &&
        this.save_obj.steps[5] == 1 &&
        this.save_obj.steps[6] == 1 &&
        this.save_obj.steps[7] == 1 &&
        this.save_obj.steps[8] == 1 &&
        this.save_obj.steps[9] == 1 &&
        this.save_obj.steps[10] == 1
      ) {
        this.save_obj.steps[11] = 1;
      }

      //console.log(save_obj);
    },

    async add_signatory_options() {
      this.save_obj["signatory_options"].push(this.signatory_options_tpm);
      this.signatory_options_tpm = { signatory_genre: 0 };
      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "add_signatory_options",
          hash: this.hash,
          id: this.docID,
          client: this.save_obj["client"],
          signatory_type: "document",
          signatory_options: this.save_obj["signatory_options"],
        }),
      });
      const response_data = await response.json();
    },
    async send_info_server(one = 1) {
      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "set_info",
          hash: this.hash,
          id: this.docID,
          client: this.save_obj["client"],
          client_fullname: this.save_obj["client_fullname"],
          client_inn: this.save_obj["client_inn"],
          client_ogrn: this.save_obj["client_ogrn"],
          client_ogrnip: this.save_obj["client_ogrnip"],
          client_kpp: this.save_obj["client_kpp"],
          client_address: this.save_obj["client_address"],
          client_role_fio: this.save_obj["client_role_fio"],
          client_role: this.save_obj["client_role"],
          client_role_fio_genitive: this.save_obj["client_role_fio_genitive"],
          client_smallname: this.save_obj["client_smallname"],
          client_type: this.save_obj["client_type"],
          send_type: this.save_obj["send_type"],
          construction_app6: this.save_obj["construction_app6"],
          construction_around_file: this.save_obj["construction_around_file"],
          construction_away_file: this.save_obj["construction_away_file"],
          construction_height: this.save_obj["construction_height"],
          construction_info_height: this.save_obj["construction_info_height"],
          construction_info_width: this.save_obj["construction_info_width"],
          construction_perspective_file:
            this.save_obj["construction_perspective_file"],
          construction_type: this.save_obj["construction_type"],
          construction_width: this.save_obj["construction_width"],
          declarer_no: this.save_obj["declarer_no"],
          declarer_genre: this.save_obj["declarer_genre"],
          declarer_firstname: this.save_obj["declarer_firstname"],
          declarer_lastname: this.save_obj["declarer_lastname"],
          declarer_passport_issued: this.save_obj["declarer_passport_issued"],
          declarer_passport_number: this.save_obj["declarer_passport_number"],
          declarer_passport_registration:
            this.save_obj["declarer_passport_registration"],
          declarer_passport_seria: this.save_obj["declarer_passport_seria"],
          declarer_patronymic: this.save_obj["declarer_patronymic"],
          declarer_patronymic_no: this.save_obj["declarer_patronymic_no"],
          declarer_when_issued: this.save_obj["declarer_when_issued"],
          declarer_division_code: this.save_obj["declarer_division_code"],
          designer_name: this.save_obj["designer_name"],
          doc_signature_digital_file:
            this.save_obj["doc_signature_digital_file"],
          doc_signature_file: this.save_obj["doc_signature_file"],
          doc_signature_power_attorney_file:
            this.save_obj["doc_signature_power_attorney_file"],
          found: this.save_obj["found"],
          info_count: this.save_obj["info_count"],
          manufacturer_name: this.save_obj["manufacturer_name"],
          map_district: this.save_obj["map_district"],
          map_file: this.save_obj["map_file"],
          map_build_litera: this.save_obj["map_build_litera"],
          map_address: this.save_obj["map_address"],
          map_street: this.save_obj["map_street"],
          map_house: this.save_obj["map_house"],
          map_korpus: this.save_obj["map_korpus"],
          map_place: this.save_obj["map_place"],
          map_cross: this.save_obj["map_cross"],
          mounting_around_file: this.save_obj["mounting_around_file"],
          mounting_away_file: this.save_obj["mounting_away_file"],
          mounting_perspective_file: this.save_obj["mounting_perspective_file"],
          number_type_illumination: this.save_obj["number_type_illumination"],
          place_install: this.save_obj["place_install"],
          place_number: this.save_obj["place_number"],
          proxy_date: this.save_obj["proxy_date"],
          proxy_re: this.save_obj["proxy_re"],
          remote_ip: this.save_obj["remote_ip"],
          signature_digital_file: this.save_obj["signature_digital_file"],
          signature_file: this.save_obj["signature_file"],
          sketch_project_one_file: this.save_obj["sketch_project_one_file"],
          sketch_project_two_file: this.save_obj["sketch_project_two_file"],
          sketch_project_three_file: this.save_obj["sketch_project_three_file"],
          step: this.save_obj["step"],
          signatory_list: this.signatory_list,
          signatory_options: this.save_obj["signatory_options"],
          signatory_main: this.save_obj["signatory_main"],
          place_manual: this.save_obj["place_manual"],
          construction_info_custom: JSON.stringify(
            this.save_obj["construction_info_custom"]
          ),
          proxy_id: this.save_obj["proxy_id"],
          mapfile_type: this.save_obj["mapfile_type"],
          map_center_x: this.save_obj["map_center_x"],
          map_center_y: this.save_obj["map_center_y"],
          map_center_zoom: this.save_obj["map_center_zoom"],
          map_place_set: this.save_obj["map_place_set"],
          map_place_x: this.save_obj["map_place_x"],
          map_place_y: this.save_obj["map_place_y"],
          map_yandex_file: this.save_obj["map_yandex_file"],
        }),
      });
      //console.log(111);
      const response_data = await response.json();

      if (response_data.found == 0) {
        this.router.push({
          path: "/",
        });
        return;
      }

      if (one == 1) {
        this.set_info(response_data);
      }
    },

    async get_info_server() {
      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_info",
          hash: this.hash,
          id: this.docID,
        }),
      });

      const response_data = await response.json();

      if (response_data.found == 0) {
        this.router.push({
          path: "/",
        });
        return;
      }

      this.signatory_list = response_data.signatory_list;
      //console.log(222);
      //this.save_obj.steps = response_data.steps;
      //console.log(signatory_list.value);
      this.set_info(response_data, 0);
    },

    async signatory_list_push(obj: any) {
      this.signatory_list.push({
        id: 0,
        name: obj.name,
        signatories: obj.id,
        document: this.docID,
      });

      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "set_signatory_list",
          hash: this.hash,
          id: this.docID,
          signatory_list: this.signatory_list,
        }),
      });

      const response_data = await response.json();
      this.signatory_list = response_data.signatory_list;

      this.send_info_server(0);
    },

    async signatory_list_delete(obj: any) {
      const sl_del = this.signatory_list.splice(obj.num, 1);

      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "set_signatory_del",
          hash: this.hash,
          id: this.docID,
          sl_del: sl_del,
        }),
      });

      const response_data = await response.json();
      this.signatory_list = response_data.signatory_list;

      this.send_info_server(0);
    },
    init(hash: any, docID: any) {
      this.initDM = true;

      this.signatory_options_tpm = { signatory_genre: 0 };
      this.save_obj["signatory_options"] = [];
      this.save_obj["proxy_options"] = [];

      this.hash = hash;
      this.docID = docID;

      this.get_info_server();

      //this.set_info({}, 0);
    },
  },
  getters: {
    oddOrEven: (state) => {
      if (state.count % 2 === 0) return "even";
      return "odd";
    },
  },
});
