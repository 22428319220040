<template>
    <div class="step__one">
        <div class="left">
            <div>
                <label for="name">Введите входящий номер доверенности</label>
                <span>Введите номер доверенности</span>
                <input type="text" placeholder="Введите номер доверенности"
                       class="input"
                       v-model="document.proxy_number">
            </div>
            
            <div>
                <label for="name">Поиск по ИНН</label>
                <span>Введите ИНН, дождитесь загрузки и нажмите далее</span>
                <input type="text" placeholder="Введите ИНН"
                       class="input"
                       v-model="document.client_inn"
                       maxlength="12"
                       @input="inn_change"
                       @paste="inn_change"
                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">
            </div>

            <div>
                <label for="name">Выберите тип заявителя</label>
                <span>В выпадающем списке ниже</span>

                <Dropdown v-model="client_type_title"
                          editable :options="client_type_options"
                          optionLabel="name"
                          :placeholder="client_type_default.name"
                          style="width:480px"
                          @change="client_type_change"
                />

                <!--
                <v-select-open
                  :options="client_type_options"
                  :title_select="client_type_title"
                  :default_select="document.client_type"
                  :key="document.client_type"
                  @change-select="client_type_select"
                  :margin_top="'3px'"
                >
                </v-select-open>
                -->
            </div>

            <div v-if="document.client_type == 3">
                <label for="name">Введите ОГРНИП</label>
                <input class="input" type="text" placeholder="Введите ОГРНИП" v-model="document.client_ogrnip"
                       maxlength="15"
                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">
            </div>
            <div v-if="document.client_type == 1">
                <label for="name">Введите ОГРН</label>
                <input class="input" type="text" placeholder="Введите ОГРН" v-model="document.client_ogrn"
                       maxlength="13"
                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">
            </div>
            <div v-if="document.client_type == 1">
                <label for="name">Введите КПП</label>
                <input class="input" type="text" placeholder="Введите КПП" v-model="document.client_kpp"
                       maxlength="9"
                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">
            </div>
            <div v-if="document.client_type == 1">
              <label for="name">Юридический адрес</label>
              <textarea class="input" style="resize: none; overflow: hidden;" type="text" placeholder="Введите юридический адрес" v-model="document.client_address"></textarea>
            </div>

            <div v-if="document.parent != 0">
                <button
                style="
                width: 83px;
                height: 46px;
                position: absolute;
                top: 795px;
                border-radius: 4px;
                margin-left: 0px;
                font-size: 14px;
                background-color: #2F80ED;
                color: white;
                "
                @click="go_doc">Назад</button>
            </div>
        </div>

        <div class="right">
            <div v-if="document.client_type == 1">
                <label for="name">Введите сокращённое название организации</label>
                <span>Пример</span>
                <input class="input" type="text" placeholder="Введите сокращённое название организации" v-model="document.client_smallname">
            </div>
            <div v-if="document.client_type == 1" style="margin-bottom: 30px;">
                <label for="name">Введите полное название организации</label>
                <span>Пример</span>
                <input class="input" type="text" placeholder="Введите полное название организации" v-model="document.client_fullname">
            </div>
            
            <section v-if="document.client_type == 1">
                <label for="title">
                    <v-switch
                    style="display: flex; flex-direction: row; justify-content: flex-start"
                    color="#2F80ED"
                    v-model="document.declarer_passport_yes"
                    label="Выводить паспортные данные в доверенности"
                    ></v-switch>
                </label>
            </section>
        </div>
    </div>
</template>
<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from '../../assets/icons/DropdownUp.vue'
import {computed, defineComponent, ref} from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import { useDMStore } from "@/store/dm_obj";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
    components: {
        VSelectOpen,
        DropdownIcon,
        DropdownUp
    },
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    emits: ["update_doc", "edit-statements"],
    setup(
      props: {
          document: any;
      }, context
    ){
        const storeDM = useDMStore();
        const router = useRouter();
        const route = useRoute();

        const client_type_default = ref({ name: "Выберите тип заявителя", value: 0 });
        const client_type_options = ref([
          { name: "Юридическое лицо", value: "1" },
          { name: "Физическое лицо", value: "2" },
          { name: "Индивидуальный предприниматель", value: "3" },
          //{ name: "Другое", value: "4" },
        ]);

        const client_type_title = computed(() => {
          if (props.document.client_type == 0) {
            return null;
          } else {
            return client_type_options.value[Number(props.document.client_type)-1];
          }
        });

        const client_type_change = (e) => {
          props.document.client_type = e.value.value;
        }


        const inn_change = async () => {
            if (
              props.document.client_inn.length == 10 ||
              props.document.client_inn.length == 12
            ) {
                const response = await fetch(storeDM.api_url + "inn.php", {
                    method: "POST",
                    //mode: "no-cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        inn: props.document.client_inn,
                    }),
                });

                const response_data = await response.json();

                if (
                  props.document.client_inn.length == 10 ||
                  props.document.client_inn.length == 12
                ) {
                    if (response_data["address"] !== "undefined") {
                        props.document.client_type = 1;
                        props.document.client_ogrn = response_data["ogrn"];
                        props.document.client_kpp = response_data["kpp"];
                        props.document.client_address = response_data["address"];
                        props.document.client_smallname = response_data["smallname"];
                        props.document.client_fullname = response_data["fullname"];
                    } else if (response_data["ogrnip"] !== "undefined") {
                        props.document.client_type = 3;
                        props.document.client_ogrnip = response_data["ogrnip"];
                    } else {
                        props.document.client_type = 2;
                    }
                }
            }
        };

        const go_doc = () => {
          let parent = props.document.parent;
          let parent_step = props.document.parent_step;

          props.document.parent = 0;
          props.document.parent_step = 0;
          context.emit("update_doc");

          router.push({
            query: {
              hash: route.query.hash,
              type: "document",
              id: parent,
              step: parent_step,
              mode: "edit",
            },
          });

          context.emit("edit-statements");
        };


        return{
            client_type_title,
            client_type_default,
            client_type_options,
            client_type_change,
            inn_change,
            go_doc,
          }
    },
});
</script>