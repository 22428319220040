<template>
  <div class="login">
    <div class="logo">
      <LoginLogo />
      <h2>DOCUMENT <br> MAKER</h2>
    </div>
    <p>Поможем вам <br> сформировать документы</p>
    <div class="form">
      <div>
        <label for="email">Введите логин</label>
        <input type="text" placeholder="Введите логин" maxlength="50" v-model="login">
      </div>
      <div>
        <label for="password">Введите пароль</label>
        <input type="password" placeholder="Введите пароль" maxlength="50" onkeypress="return event.charCode >= 48 && event.charCode <= 57" v-model="pwd">
      </div>
      <div>
        <button type="button" class="blue_btn" @click="next_page()">Войти</button>
        <span>или</span>
        <button type="button" class="blue_btn" @click="signup()">Зарегистрироваться</button>
      </div>
      <span style="cursor: pointer;" @click="recovery()">Забыли пароль?</span>
    </div>
  </div>
</template>

<script lang="ts">
import LoginLogo from '../assets/icons/mainLogo.vue'
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";

export default defineComponent({
  name: "HomeView",
  components: {
    LoginLogo
  },
  setup() {
    const login = ref("");
    const pwd = ref("");
    const router = useRouter();

    const storeDM = useDMStore();

    const error_hint = ref("");

    const next_page = async () => {
      //
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "auth",
          login: login.value,
          pwd: pwd.value,
        }),
      });

      const response_data = await response.json();
      if (response_data["status"] == "success") {
        storeDM.client_id = response_data["client_id"];
        storeDM.email = response_data["email"];
        storeDM.phone = response_data["phone"];
        storeDM.random_hash = response_data["random_hash"];
        router.push({
          path: "/go",
          query: {
            hash: response_data["random_hash"],
            type: "statements",
          },
        });
      } else {
        error_hint.value = "Неправильный логин или пароль";
      }
    };

    const signup = () => {
      router.push("/signup");
    };

    const recovery = () => {
      router.push("/recovery");
    };

    return {
      next_page,
      signup,
      recovery,
      login,
      pwd,
      error_hint,
    };
  },
});
</script>


<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  text-decoration: none;
}

h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

/*
.home {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
 */
</style>
