<template>
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1143 0.487305L11.0724 1.5292C12.0458 2.50404 12.583 3.79941 12.583 5.17657C12.583 6.55373 12.0458 7.84909 11.0724 8.82394L12.1143 9.86583C13.367 8.61246 14.0566 6.9472 14.0566 5.17657C14.0566 3.40594 13.367 1.74067 12.1143 0.487305Z"
      fill="#333333"
    />
    <path
      d="M10.0298 2.5711L8.98788 3.61446C9.40567 4.03078 9.63557 4.58562 9.63557 5.17657C9.63557 5.76752 9.40567 6.32236 8.98788 6.73868L10.0298 7.78204C10.7254 7.08646 11.1093 6.16173 11.1093 5.17657C11.1093 4.19141 10.7254 3.26668 10.0298 2.5711Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.42506 4.43974C7.42506 6.06752 6.10548 7.3871 4.47769 7.3871C2.84991 7.3871 1.53032 6.06752 1.53032 4.43974C1.53032 2.81195 2.84991 1.49237 4.47769 1.49237C6.10548 1.49237 7.42506 2.81195 7.42506 4.43974ZM5.95138 4.43974C5.95138 5.25363 5.29159 5.91342 4.47769 5.91342C3.6638 5.91342 3.00401 5.25363 3.00401 4.43974C3.00401 3.62584 3.6638 2.96605 4.47769 2.96605C5.29159 2.96605 5.95138 3.62584 5.95138 4.43974Z"
      fill="#333333"
    />
    <path
      d="M7.42506 12.545H8.89875C8.89875 10.1033 6.91937 8.12395 4.47769 8.12395C2.03601 8.12395 0.0566406 10.1033 0.0566406 12.545H1.53032C1.53032 10.9172 2.84991 9.59763 4.47769 9.59763C6.10548 9.59763 7.42506 10.9172 7.42506 12.545Z"
      fill="#333333"
    />
  </svg>
</template>
