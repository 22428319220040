<template>
  <v-app>
    <v-main>
      <NavbarComponent :client_avatar="client.client_avatar" :client_name="client.client_name" :client_docs="client.client_docs" :client_docs_timeout="client.client_docs_timeout"/>

  <div class="home">
    <v-card style="height:3000px;">
      <v-layout style="height:3000px;">


        <!-- ============   SIDEBAR STAR   ====================== -->


        <v-navigation-drawer
          v-model="drawer"
          :rail="storeDM.rail"
          permanent
          @click="sidebar_click"
          :style="'padding: 0px; width: '+sidebar_width"
        >

          <v-list density="compact" nav>


            <!--================   STATEMENTS TAB   ==================== -->


            <v-list-item @click="click_statements(); openStatements(); selectComponent('statements')">
              <div class="v-list-item__prepend">
                <Folder
                  :class="{ active: componentType.value === 'statements' }"
                  class="mdi-account-group-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                  aria-hidden="true"
                  density="compact">
                </Folder>
                <div
                  :class="{ active: componentType.value === 'statements' }"
                  class="v-list-item-title">
                  Заявления
                </div>
              </div>
              <DropdownUp v-if="statementsShow && componentType.value === 'statements'" />
              <DropdownIcon v-else />
            </v-list-item>
            <div
              @click="click_statements(); selectComponent('statements')"
              class="dropdown"
              :class="{ dropdownActive: componentType.value === 'statements' }"
              v-if="statementsShow && componentType.value === 'statements'"
              v-show="!storeDM.rail"
            >
              <p>Список заявлений</p>
            </div>


            <!-- ========================    DRAFTS TAB   =================== -->


            <v-list-item @click="selectComponent('drafts')">
              <div class="v-list-item__prepend">
                <Rubbish
                  :class="{ active: componentType.value === 'drafts' }"
                  class="mdi-account-group-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                  aria-hidden="true"
                  density="compact">
                </Rubbish>
                <div
                  :class="{ active: componentType.value === 'drafts' }"
                  class="v-list-item-title">
                  Черновики
                </div>
              </div>
            </v-list-item>


            <!-- =======================    SIGNATORIES TAB   ======================== -->


            <v-list-item @click="openSignatories(); selectComponent('signatories');">
              <div class="v-list-item__prepend">
                <Subscribers
                  :class="{ active: componentType.value === 'signatories' || componentType.value == 'newSignatories' }"
                  class="mdi-account-group-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                  aria-hidden="true"
                  density="compact">
                </Subscribers>
                <div :class="{ active: componentType.value == 'signatories' || componentType.value == 'newSignatories' }"  class="v-list-item-title">Подписанты</div>
              </div>
              <!--
              <DropdownUp v-if="signatoriesShow && componentType.value === 'signatories'" />
              <DropdownIcon v-else />
              -->
            </v-list-item>
            <!--
            <div
              @click="selectComponent('newSignatories')"
              class="dropdown"
              :class="{ dropdownActive: componentType.value === 'newSignatories' }"
              v-if="signatoriesShow && componentType.value === 'signatories' || componentType.value === 'newSignatories'"
              v-show="!storeDM.rail"
            >
              <p>Новый подписант</p>
            </div>
            -->

            <!-- =================================  DOCUMENTS TAB  ========================== -->


            <v-list-item
              @click="
                openDocuments();
                selectComponent('documents');"
            >
              <div class="v-list-item__prepend">
                <Document
                  :class="{ active: componentType.value === 'documents' || componentType.value == 'newDocuments' }"
                  class="mdi-account-group-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                  aria-hidden="true"
                  density="compact"
                ></Document>
                <div class="v-list-item-title" :class="{ active: componentType.value == 'documents' || componentType.value == 'newDocuments' }">Доверенности</div>
              </div>
              <!--
              <DropdownIcon v-if="!documentsShow" />
              <DropdownUp v-else />
              -->
            </v-list-item>
            <!--
            <div class="dropdown"
                 @click="new_proxy"
                 :class="{ dropdownActive: componentType.value === 'newDocuments' }"
                 v-if="documentsShow && componentType.value === 'documents' || componentType.value === 'newDocuments'"
                 v-show="!storeDM.rail"
            >
              <p>Новая доверенность</p>
            </div>
            -->


            <!-- =================================  NOTIFICATION TAB  ================================ -->

            <!--
            <v-list-item @click="openProfile()">
              <div class="v-list-item__prepend">
                <ProfileSvg
                  :class="{ active: componentType.value === 'profile' || componentType.value === 'notification' }"
                  class="mdi-account-group-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                  aria-hidden="true"
                  density="compact"
                ></ProfileSvg>
                <div
                  :class="{ active: componentType.value === 'profile' || componentType.value === 'notification' }"
                  class="v-list-item-title"
                >
                  Профиль
                </div>
              </div>
              <DropdownIcon v-if="!profileShow" />
              <DropdownUp v-else />
            </v-list-item>
            <div :class="{ dropdownActive: componentType.value === 'profile' }" class="dropdown" v-if="profileShow && !storeDM.rail">
              <p @click="selectComponent('profile');">Данные профиля</p>
            </div>
            -->
            <!--
            <div :class="{ dropdownActive: componentType.value === 'notification' }" class="dropdown" v-if="profileShow  && !storeDM.rail">
              <p @click="selectComponent('notification')">Уведомления</p>
            </div>
            -->

            <v-list-item @click="openProfile(); selectComponent('profile');">
              <div class="v-list-item__prepend">
                <ProfileSvg
                  :class="{ active: componentType.value === 'profile' || componentType.value === 'notification' }"
                  class="mdi-account-group-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                  aria-hidden="true"
                  density="compact"
                ></ProfileSvg>
                <div
                  :class="{ active: componentType.value === 'profile' || componentType.value === 'notification' }"
                  class="v-list-item-title"
                >
                Профиль
                </div>
              </div>
            </v-list-item>

            <!-- =================================  STATISTIC TAB   ================================ -->

            
            <v-list-item class="mt-10"
            @click="
                openStatistics();
                selectComponent('statistics');">
              <div class="v-list-item__prepend">
                <Statistic
                  class="mdi-account-group-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                  aria-hidden="true"
                  density="compact"
                  :class="{ active: componentType.value == 'statistics' }"
                ></Statistic>
                <div class="v-list-item-title" :class="{ active: componentType.value == 'statistics' }">Статистика</div>
              </div>
              <!--<DropdownIcon />-->
            </v-list-item>
            
          </v-list>
          <v-btn
            v-if="!storeDM.rail"
            class="closeIcon"
            variant="text"
            icon="mdi-chevron-left"
            @click.stop="storeDM.rail = true"
          ></v-btn>
        </v-navigation-drawer>


        <!-- ====================   Sidebar End ==================  -->


        <!--       ======================   Active components   ==============================   -->

        <v-main :style="'height: 100%; padding-bottom: 20px; --v-layout-left: '+sidebar_layout_left">
        <!--<v-main :style="'height: 100vh; --v-layout-left: '+sidebar_layout_left">-->
          <Statements v-if="componentType.value === 'statements'" :doc_list="doc_list" ref="ref_statements" @edit-proxy="edit_proxy" @edit-signatory="edit_signatory"/>
          <Drafts v-if="componentType.value === 'drafts'" :doc_list="doc_list" @cnc="cnc"/>
          <Signatories v-if="componentType.value === 'signatories'" :signatory_list="signatory_list" @edit-signatory="edit_signatory" @new-signatory="new_signatory"/>
          <NewSignatories v-if="componentType.value === 'newSignatories'" @save-signatory="signatory_save" @edit-signatory="edit_signatory" @edit-proxy="edit_proxy" @edit-doc="edit_doc" />
          <Documents v-if="componentType.value === 'documents'" @edit-proxy="edit_proxy" @new-proxy="new_proxy"/>
          <NewDocuments v-if="componentType.value === 'newDocuments'" :proxy_id="proxy_id" @edit-signatory="edit_signatory" @edit-statements="cnc"/>
          <Profile v-if="componentType.value === 'profile'" @change-avatar="change_avatar" @change-name="change_name"/>
          <Notification v-if="componentType.value === 'notification'" />
          <Statistics v-if="componentType.value === 'statistics'" />
        </v-main>
      </v-layout>
    </v-card>
  </div>

    </v-main>
  </v-app>
  <ModalResolution :is_open="true"></ModalResolution>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";
import VSelectOpen from "@/components/v-select-open.vue";
import axios from "axios";
import { useDMDeclStore } from "@/store/dm_decl";
import  NavbarComponent  from '@/components/NavbarContent.vue'

import Folder from "../assets/icons/folder.vue";
import Rubbish from "../assets/icons/rubbish.vue";
import Subscribers from "../assets/icons/subscribers.vue";
import ProfileSvg from "../assets/icons/profile.vue";
import Statistic from "../assets/icons/statistic.vue";
import Document from '../assets/icons/document.vue'
import DropdownIcon from "../assets/icons/dropdownIcon.vue";
import DropdownUp from '../assets/icons/DropdownUp.vue'
// Vue components
import Statements from "../components/statements/Statements.vue";
import Drafts from "../components/Drafts.vue";
import Signatories from "../components/signatories/Signatories.vue";
import NewSignatories from '../components/signatories/NewSignatories.vue'
import Documents from "../components/documents/Documents.vue";
import NewDocuments from '../components/documents/NewDocuments.vue'
import Profile from '../components/Profile.vue'
import Notification from '../components/Notification.vue'
import Statistics from "../components/Statistics.vue"

import ModalResolution from "../components/ModalResolution.vue";

export default defineComponent({
  name: "GoDoc",
  components: {
    Folder,
    NewDocuments,
    Signatories,
    ProfileSvg,
    NewSignatories,
    Notification,
    Statistics,
    Subscribers,
    Documents,
    Profile,
    DropdownIcon,
    Drafts,
    Statements,
    Document,
    DropdownUp,
    Rubbish,
    Statistic,
    NavbarComponent,
    VSelectOpen,
    ModalResolution
  },
  setup() {
    const storeDM = useDMStore();
    const storeDMDecl = useDMDeclStore();

    const router = useRouter();
    const route = useRoute();

    const proxies_list = ref([]);

    const doc_list = ref([]);
    const doc_favorite_list = ref([]);

    const signatory_list = reactive({list:[]});

    const menu_active = ref(1);

    const menu_statement = ref(0);
    const menu_proxy_statement = ref(0);
    const menu_sign_statement = ref(0);
    const menu_sign_new_statement = ref(0);

    const menu_id = ref(0);
    const menu_hash = ref("");
    const menu_index = ref(0);

    const page_cur = ref(1);

    const save_yes = ref(0);


    const statementsShow = ref(false);
    const signatoriesShow = ref(false);
    const documentsShow = ref(false);
    const statisticsShow = ref(false);
    const profileShow = ref(false);
    const drawer = ref(true);
    //const rail = ref(true);
    storeDM.rail = true;

    //const componentType = ref();
    const componentType = reactive({value:''});
    function openStatements() {
      statementsShow.value = !statementsShow.value;
      // eslint-disable-next-line no-constant-condition
    }
    function openSignatories() {
      signatoriesShow.value = !signatoriesShow.value;
    }
    function openDocuments() {
      documentsShow.value = !documentsShow.value;
    }
    function openProfile() {
      profileShow.value = !profileShow.value;
    }
    function openStatistics() {
      statisticsShow.value = !statisticsShow.value;
    }

    function sidebar_click() {
      storeDM.rail = false;
    }

    const sidebar_width = computed(() => {
      if(storeDM.rail == false){
        return '208px;';
      } else {
        return '56px;';
      }
    });

    const sidebar_layout_left = computed(() => {
      if(storeDM.rail == false){
        return '208px;';
      } else {
        return '56px;';
      }
    });

    const update_page_query = () => {
      router.push({
        query: {
          hash: route.query.hash,
        },
      });
    }

    const updModal = () => {
      storeDM.showSubscription = true;
    }

    const selectComponent = async (value: any) => {

      storeDM.showSubscription = false;
      setTimeout(updModal,200);

      componentType.value = value;
      // Statement
      if(componentType.value === "statements"){
        await get_list();
        return;
      } else {
        statementsShow.value = false
      }
      // signatories
      if(componentType.value === "signatories" || componentType.value === 'newSignatories'){
        await get_signatories_list();
        if(componentType.value === "signatories"){
          router.push({
            query: {
              hash: route.query.hash,
              type: "signatories"
            },
          });
          return;
        }
        if(componentType.value === "newSignatories"){
          if(route.query.type == "signatory"){
          } else {
            signatory_save(signatory, false, 0);
            /*
            router.push({
              query: {
                hash: route.query.hash,
                type: "newsignatories"
              },
            });
            */
          }
          return;
        }
      } else {
        signatoriesShow.value = false
      }
      // documents
      if(componentType.value === "documents" || componentType.value === 'newDocuments'){
        await get_proxies_list();
        if(componentType.value === "documents"){
          router.push({
            query: {
              hash: route.query.hash,
              type: "documents"
            },
          });
        }
        return;
      } else {
        documentsShow.value = false
      }
      // profile
      if(componentType.value === "profile" || componentType.value === "notification"){
        if(componentType.value === "profile"){
          router.push({
            query: {
              hash: route.query.hash,
              type: "profile"
            },
          });
        }
        return;
      } else {
        profileShow.value = false
      }

      if (componentType.value === "statistics") {
        router.push({
          query: {
            hash: route.query.hash,
            type: "statistics"
          },
        });
        return;
      }
      

      if(componentType.value === "drafts"){
        await get_favorite_list();
        router.push({
          query: {
            hash: route.query.hash,
            type: "drafts"
          },
        });
        return;
      }
      
    }

    const client = reactive({
      client_name: "",
      client_company_name: "",
      client_pwd: "",
      client_email: "",
      client_phone: "",
      client_avatar: "",
      client_docs: "",
      client_docs_timeout: "",
    });

    const signatory = reactive({
      id: 0,
      signatory_lastname: "",
      signatory_firstname: "",
      signatory_patronymic: "",
      signatory_passport_number: "",
      signatory_passport_seria: "",
      signatory_skip_patronymic: 0,
      signatory_passport_issued: "",
      signatory_when_issued: "",
      signatory_division_code: "",
      signatory_passport_registration: "",
      signatory_genre: 0,
      signatory_file: "",
      signatory_parent: 0,
      signatory_parent_step: 0,
    });

    const signatory_save = async (signatory: any, close: boolean = false, new_id: number = 0) => {
      //console.log(1123);

      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "save_signatory",
          hash: route.query.hash,
          //id: signatory.id,
          id: new_id,
          signatory_lastname: signatory.signatory_lastname,
          signatory_firstname: signatory.signatory_firstname,
          signatory_patronymic: signatory.signatory_patronymic,
          signatory_passport_number: signatory.signatory_passport_number,
          signatory_passport_seria: signatory.signatory_passport_seria,
          signatory_skip_patronymic: signatory.signatory_skip_patronymic,
          signatory_passport_issued: signatory.signatory_passport_issued,
          signatory_when_issued: signatory.signatory_when_issued,
          signatory_division_code: signatory.signatory_division_code,
          signatory_passport_registration:
            signatory.signatory_passport_registration,
          signatory_genre: signatory.signatory_genre,
          signatory_file: signatory.signatory_file,
          signatory_parent: signatory.signatory_parent,
          signatory_parent_step: signatory.signatory_parent_step,
        }),
      });

      const response_data = await response.json();
      signatory_list.list = response_data["signatory_list"];
      //menu_sign_new_statement.value = 0;

      if(close == true){
        componentType.value = "signatories";
      }

      if(new_id == 0){
        router.push({
          query: {
            hash: route.query.hash,
            type: "signatory",
            id: response_data["signatory_id"],
            step: 1,
          },
        });
      }
    };

    const signatory_hide = async (id: string) => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "hide_signatory",
          hash: route.query.hash,
          id: id,
        }),
      });

      const response_data = await response.json();
      signatory_list.list = response_data["signatory_list"];
      menu_sign_statement.value = 0;
    };

    const go_back = () => {
      router.go(-1);
    };
    if (storeDM.client_id == "") {
      //router.push("/");
    }

    const go_step1 = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "create_new",
          hash: route.query.hash,
          region: 1,
          construction: 1,
        }),
      });

      const response_data = await response.json();

      storeDM.hash = String(route.query.hash);
      storeDM.docID = response_data["id"];

      storeDM.get_info_server();

      router.push({
        path: "/step1/" + storeDM.docID,
        query: {
          hash: storeDM.hash,
        },
      });
    };

    const proxy_id = ref (0);
    const new_proxy = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "create_declaration",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();
      proxy_id.value = response_data["id"];

      router.push({
        query: {
          hash: route.query.hash,
          type: "proxy",
          id: response_data["id"],
          step: 1,
        },
      });

      setTimeout(selectComponent, 500,"newDocuments");
      //selectComponent("newDocuments");
      /*
      storeDMDecl.hash = String(route.query.hash);
      storeDMDecl.docID = response_data["id"];

      storeDMDecl.get_info_server();

      router.push({
        path: "/proxy/step1/" + storeDMDecl.docID,
        query: {
          hash: storeDMDecl.hash,
        },
      });
      */
    };

    const go_list = () => {
      router.push({
        path: "/list",
        query: {
          hash: route.query.hash,
        },
      });
    };

    const page_plus = (max_items: number) => {
      if (page_cur.value + 1 > Math.ceil(max_items / 9)) {
        page_cur.value = Math.ceil(max_items / 9);
      } else {
        page_cur.value = page_cur.value + 1;
      }
    };

    const page_minus = () => {
      if (page_cur.value - 1 == 0) {
        page_cur.value = 1;
      } else {
        page_cur.value = page_cur.value - 1;
      }
    };

    const get_list = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_list",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      if (response_data["status"] == "success") {
        doc_list.value = response_data["doc_list"];
        client.client_name = response_data["client_name"];
        client.client_company_name = response_data["client_company_name"];
        client.client_pwd = response_data["client_pwd"];
        client.client_email = response_data["client_email"];
        client.client_phone = response_data["client_phone"];
        client.client_avatar = response_data["client_avatar"];
        client.client_docs = response_data["client_docs"];
        client.client_docs_timeout = response_data["client_docs_timeout"];
      } else {
        router.push("/");
      }
    };

    const get_favorite_list = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_favorites",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      if (response_data["status"] == "success") {
        doc_favorite_list.value = response_data["doc_list"];
      }
    };

    const get_signatories_list = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_signatories",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      if (response_data["status"] == "success") {
        signatory_list.list = response_data["signatory_list"];
      }
    };

    const get_proxies_list = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_proxies",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      if (response_data["status"] == "success") {
        proxies_list.value = response_data["proxies_list"];
      }
    };

    const mopen = async (
      m_stat: number,
      id: number,
      hash: string,
      index: number,
      ms: string
    ) => {
      if (ms == "menu_statement") {
        menu_statement.value = m_stat;
      } else if (ms == "menu_proxy_statement") {
        menu_proxy_statement.value = m_stat;
      } else if (ms == "menu_sign_statement") {
        menu_sign_statement.value = m_stat;
      } else if (ms == "menu_sign_new_statement") {
        menu_sign_new_statement.value = m_stat;
        signatory.id = id;
        signatory.signatory_lastname = "";
        signatory.signatory_firstname = "";
        signatory.signatory_patronymic = "";
        signatory.signatory_passport_number = "";
        signatory.signatory_passport_seria = "";
        signatory.signatory_skip_patronymic = 0;
        signatory.signatory_passport_issued = "";
        signatory.signatory_when_issued = "";
        signatory.signatory_division_code = "";
        signatory.signatory_passport_registration = "";
        signatory.signatory_genre = 0;
        signatory.signatory_file = "";
        signatory.signatory_parent = 0;
        signatory.signatory_parent_step = 0;

        if (signatory.id != 0) {
          const response = await fetch(storeDM.api_url + "api2.php", {
            method: "POST",
            //mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "get_signatory",
              hash: route.query.hash,
              id: signatory.id,
            }),
          });

          const response_data = await response.json();
          signatory.signatory_lastname = response_data.signatory_lastname;
          signatory.signatory_firstname = response_data.signatory_firstname;
          signatory.signatory_patronymic = response_data.signatory_patronymic;
          signatory.signatory_passport_number =
            response_data.signatory_passport_number;
          signatory.signatory_passport_seria =
            response_data.signatory_passport_seria;

          if (response_data.signatory_skip_patronymic == 1) {
            response_data.signatory_skip_patronymic = true;
          } else {
            response_data.signatory_skip_patronymic = false;
          }
          signatory.signatory_skip_patronymic =
            response_data.signatory_skip_patronymic;
          signatory.signatory_passport_issued =
            response_data.signatory_passport_issued;
          signatory.signatory_when_issued = response_data.signatory_when_issued;
          signatory.signatory_division_code =
            response_data.signatory_division_code;
          signatory.signatory_passport_registration =
            response_data.signatory_passport_registration;
          signatory.signatory_genre = response_data.signatory_genre;
          signatory.signatory_file = response_data.signatory_file;

          menu_sign_statement.value = 0;
        }
        //console.log(signatory.id);
      }

      menu_id.value = id;
      menu_hash.value = hash;
      menu_index.value = index;

      //console.log(ms);
    };

    const open_doc = (id: string) => {
      router.push({
        path: "/step1/" + id,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const open_proxy = (id: string) => {
      router.push({
        path: "/proxy/step1/" + id,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const proxy_save = async (id: string) => {
      downloadItem(
        storeDMDecl.api_url +
          "proxy.php?id=" +
          id +
          "&hash=" +
          route.query.hash +
          "&type=declarations",
        "Доверенность " + id
      );

      menu_proxy_statement.value = 0;
    };

    const downloadItem = (url: string, label: string) => {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    };

    const proxy_hide = async (id: string) => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "hide_proxy",
          hash: route.query.hash,
          id: id,
        }),
      });

      const response_data = await response.json();
      proxies_list.value = response_data["proxies_list"];
      menu_proxy_statement.value = 0;
    };

    const add_signatory = () => {
      console.log(1);
    };

    const menu_sel = (num: number) => {
      menu_active.value = num;
      menu_statement.value = 0;
      menu_proxy_statement.value = 0;
      menu_sign_statement.value = 0;
      menu_sign_new_statement.value = 0;
    };

    const add_favorite = async (id: string, hash: string) => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "add_favorite",
          id: id,
          hash: hash,
        }),
      });
      const response_data = await response.json();
      menu_statement.value = 0;
    };

    const save_client = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "save_client",
          hash: route.query.hash,
          client_email: client.client_email,
          client_name: client.client_name,
          client_pwd: client.client_pwd,
          client_company_name: client.client_company_name,
          client_phone: client.client_phone,
          client_avatar: client.client_avatar,
        }),
      });
      const response_data = await response.json();
      save_yes.value = 1;
      setTimeout(() => (save_yes.value = 0), 500);
    };

    const edit_favorite = async (
      id: string,
      hash: string,
      favorite: string
    ) => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "edit_favorite",
          id: id,
          hash: hash,
          favorite: favorite,
        }),
      });
      const response_data = await response.json();
      let id_elem = doc_list.value.findIndex(
        (x) => x["id"] === response_data.id
      );
      Object(doc_list.value)[id_elem]["favorite"] = response_data.favorite;

      let tmp_arr = doc_list.value.slice();
      let tmp_arr2: any = [];
      for (let i = 0; i < tmp_arr.length; i++) {
        if (tmp_arr[i]["favorite"] == "1") {
          tmp_arr2.push(tmp_arr[i]);
        }
      }
      doc_favorite_list.value = tmp_arr2;

      menu_statement.value = 0;
    };

    const doc_lists = computed(() => {
      let tmp_arr = doc_list.value.slice();
      return tmp_arr.slice(Number(page_cur.value - 1) * 9, page_cur.value * 9);
    });

    const doc_favorite_lists = computed(() => {
      let tmp_arr = doc_favorite_list.value.slice();
      return tmp_arr.slice(Number(page_cur.value - 1) * 9, page_cur.value * 9);
      /*
      let tmp_arr = doc_list.value.slice();
      let tmp_arr2: any = [];
      for (let i = 0; i < tmp_arr.length; i++) {
        if (tmp_arr[i]["favorite"] == "1") {
          tmp_arr2.push(tmp_arr[i]);
        }
      }
      let tmp_arr3 = Array(tmp_arr2).slice();
      return tmp_arr3.slice(Number(page_cur.value - 1) * 9, page_cur.value * 9);
       */
    });

    const signatory_lists = computed(() => {
      let tmp_arr = signatory_list.list.slice();
      return tmp_arr.slice(Number(page_cur.value - 1) * 9, page_cur.value * 9);
    });

    const signatory_genre_title = "Выберите пол";
    const signatory_genre_options = [
      { name: "Мужской", value: "1" },
      { name: "Женский", value: "2" },
    ];

    const signatory_genre_select = (opt: any) => {
      signatory.signatory_genre = opt.value;
    };

    const signatory_file = ref(null);
    const avatar_file = ref(null);

    const submitFile = (f_name: string, id: string) => {
      let file = Object(eval(f_name).value).files[0];

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", id);
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          if (f_name == "signatory_file") {
            signatory.signatory_file = Object(req.data)[req.data.file_name];
          } else if (f_name == "avatar_file") {
            client.client_avatar = Object(req.data)[req.data.file_name];
          } else {
            storeDM.save_obj[Object(req.data).file_name] = Object(req.data)[
              req.data.file_name
            ];
          }
          //console.log(req);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    const signatory_file_hint = computed(() => {
      if (typeof signatory.signatory_file !== "undefined") {
        if (signatory.signatory_file == "") {
          return "Нажмите чтобы загрузить файл подписи";
        } else {
          return (
            'Имя загруженного файла - "' +
            signatory.signatory_file.split("/")[
              signatory.signatory_file.split("/").length - 1
            ] +
            '"'
          );
        }
      } else {
        return 'Имя загруженного файла - "-не загружен-"';
      }
    });

    const avatar_file_hint = computed(() => {
      if (typeof client.client_avatar !== "undefined") {
        if (client.client_avatar == "") {
          return "Нажмите чтобы загрузить аватар";
        } else {
          return client.client_avatar.split("/")[
            client.client_avatar.split("/").length - 1
          ];
        }
      } else {
        return 'Имя загруженного файла - "-не загружен-"';
      }
    });

    get_list();
    get_favorite_list();
    get_signatories_list();
    get_proxies_list();

    onMounted(() => {
      document.addEventListener("click", hideMenuStatement.bind(this), true);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", hideMenuStatement);
    });

    const hideMenuStatement = () => {
      menu_statement.value = 0;
    };

    const change_avatar = (str: string) => {
      client.client_avatar = str;
    };

    const change_name = (str: string) => {
      client.client_name = str;
      //console.log(client.client_name);
    };

    const cnc = () => {
      //console.log(route.query.type);
      if (route.query.type == "proxy") {
        selectComponent("newDocuments");
      } else if(route.query.type == "newsignatories"){
        selectComponent("newSignatories");
      } else if(route.query.type == "signatories"){
        selectComponent("signatories");
      } else if(route.query.type == "statements"){
        selectComponent("statements");
      } else if(route.query.type == "drafts"){
        selectComponent("drafts");
      } else if(route.query.type == "documents"){
        selectComponent("documents");
      } else if(route.query.type == "profile"){
        selectComponent("profile");
      } else if(route.query.type == "document"){
        selectComponent("statements");
        //this.$refs.ref_statements.methodInChild();
      } else if(route.query.type == "signatory"){
        selectComponent("newSignatories");
      } else if (route.query.type == "statistics") {
        selectComponent("statistics");
      }
    }

    const click_statements = () => {
      router.push({
        query: {
          hash: route.query.hash,
          type: "statements"
        },
      });
    }

    cnc();


    const edit_proxy = () => {
      setTimeout(selectComponent,200,"newDocuments");
      //selectComponent("newDocuments");
    }

    const edit_doc = () => {
      setTimeout(selectComponent,200,"statements");
      setTimeout(cnc,210);
    }

    const edit_signatory = () => {
      console.log(11111);
      setTimeout(selectComponent,200,"newSignatories");
      //selectComponent('newSignatories');
    }

    const new_signatory = () => {
      selectComponent('newSignatories');
    }

    return {
      signatory_hide,
      signatory_save,
      signatory,
      save_client,
      client,
      go_back,
      go_step1,
      go_list,
      storeDM,
      menu_active,
      open_doc,
      menu_statement,
      menu_proxy_statement,
      menu_sign_statement,
      menu_sign_new_statement,
      mopen,
      menu_id,
      menu_hash,
      menu_index,
      doc_list,
      doc_lists,
      doc_favorite_list,
      doc_favorite_lists,
      signatory_list,
      signatory_lists,
      page_cur,
      page_minus,
      page_plus,
      add_favorite,
      add_signatory,
      menu_sel,
      edit_favorite,
      signatory_genre_select,
      signatory_genre_options,
      signatory_genre_title,
      signatory_file,
      signatory_file_hint,
      avatar_file,
      avatar_file_hint,
      submitFile,
      new_proxy,
      proxies_list,
      open_proxy,
      proxy_hide,
      proxy_save,
      save_yes,
      statementsShow,
      signatoriesShow,
      documentsShow,
      openSignatories,
      openStatements,
      openDocuments,
      componentType,
      selectComponent,
      profileShow,
      openProfile,
      openStatistics,
      drawer,
      //rail,
      change_avatar,
      change_name,
      proxy_id,
      cnc,
      click_statements,
      edit_proxy,
      edit_signatory,
      new_signatory,
      sidebar_click,
      sidebar_width,
      sidebar_layout_left,
      edit_doc,
    };
  },
});
</script>

<style scoped lang="scss">

.menu_icon_left {
  margin-left: 20px;
}

.v-list-item__prepend>.v-icon {
    margin-inline-end: 25px;
}

.new_signatory {
  position: absolute;
  top: 92px;
  left: 365px;
  height: 600px;
  background-color: white;
  box-shadow: 0 0 5px;
}

.signatory_col_1 {
  float: left;
  width: 305px;
}

.signatory_col_2 {
  float: left;
  width: 305px;
}

.signatory_col_3 {
  position: absolute;
  margin-top: 525px;
}

.upload_btn {
  float: left;
  margin-left: 10px;
  width: 285px;
  margin-top: 6px;
  color: #2f80ed;
  text-decoration: underline;
  text-align: left;
  cursor: pointer;
}

.input_user {
  border: none;
  width: 250px;
  height: 24px;
  background-color: #fff;
  font-size: 16px;
}

.file_user {
  border: none;
  width: 250px;
  height: 24px;
  background-color: #fff;
  font-size: 16px;
  text-decoration: underline;
  color: #4617ff;
  cursor: pointer;
}

.row_user {
  height: 30px;
  border: 2px solid #f2f2f2;
  border-left: none;
  border-top: none;
  border-right: none;
  width: 50%;
  margin-top: 15px;
}

.signatory_pages_all {
  display: table;
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
}

.page {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  display: table-cell;
}

.page_active {
  background-color: #2f80ed;
  color: white;
}

.page_prev {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  display: table-cell;
  padding-right: 20px;
}

.page_next {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  display: table-cell;
  padding-left: 20px;
}

.statement_detail {
  width: 300px;
  height: 387px;
  position: absolute;
  top: 110px;
  left: 80%;
  background-color: #fff;
  box-shadow: 0 0 5px;
}

.statement_element:first-child {
  margin-top: 56px;
}

.statement_element {
  line-height: 50px;
  margin-top: 6px;
  cursor: pointer;
  height: 50px;
}

.statement_element:hover {
  background-color: #2f80ed;
}

.statement_back {
  line-height: 50px;
  height: 50px;
  margin-top: 112px;
  background-color: #2f80ed;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: inherit;
}

.mopen {
  cursor: pointer;
  color: #2f80ed;
}

.td_right {
  text-align: right;
}

.td_left {
  text-align: left;
}

.user_info {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
}

.menu_user {
  width: 268px;
  height: 100vh;
  min-height: 900px;
  background-color: rgb(47, 128, 237);
  position: absolute;
  top: 0px;
}

.menu_right {
  margin-left: 268px;
}

.input_search {
  border: none;
  width: 97%;
  height: 24px;
  background-color: #fff;
  font-size: 16px;
}

.logo_rekprof {
  width: 24px;
  height: 30px;
  float: left;
  margin-left: 24px;
  margin-top: 18px;
}

.logo_text {
  float: left;
  font-weight: bold;
  margin-top: 22px;
  margin-left: 10px;
  color: white;
}

.logo_user {
  border-radius: 200px;
  width: 110px;
  height: 110px;
  background-image: url(https://api.rekprof.ru/files/users/test/4u3xanz_cjY.jpg);
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  margin-top: 100px;
  margin-left: 21px;
}

.menu_element {
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  float: left;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: #fff;
}

.menu_active {
  background-color: #fff;
  color: #2f80ed;
}

.menu_body {
  padding-left: 50px;
  padding-right: 50px;
}

.ads {
  background-color: #ff00f9;
  float: left;
  display: inline-block;
  width: 100%;
  height: 215px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.td_head {
  font-weight: bold;
}

.td_1 {
  width: 124px;
  float: left;
  overflow: hidden;
  text-align: left;
}

.td_sign_1 {
  width: 91%;
  float: left;
  text-align: left;
  overflow: hidden;
}

.td_2 {
  width: 124px;
  float: left;
  overflow: hidden;
  text-align: left;
}

.td_3 {
  width: 35%;
  float: left;
  overflow: hidden;
  text-align: left;
}

.td_4 {
  width: 37%;
  float: left;
  overflow: hidden;
  text-align: left;
}

.td_5 {
  width: 124px;
  float: left;
  overflow: hidden;
}

.td_6 {
  width: 124px;
  float: left;
  overflow: hidden;
}

.td_favorite {
  width: 30px;
  float: left;
  overflow: hidden;
}

.td_left_user {
  font-weight: bold;
  width: 200px;
  float: left;
  font-size: 16px;
  line-height: 24px;
}

.td_right_user {
  width: fit-content;
  float: left;
  font-size: 16px;
}

.line_element {
  width: 100%;
  height: 48px;
  display: flex;
  vertical-align: middle;
  line-height: 48px;
  border-bottom: 1px solid #f2f2f2;
}

.signatory_block {
  height: 48px;
  margin-bottom: 0px;
}

.create_new {
  color: #2f80ed;
  cursor: pointer;
}

.sign_input {
  font-family: "Inter";
  width: 285px;
  height: 40px;
  border: 1px solid #2f80ed;
  padding-left: 10px;
  box-sizing: border-box;
  float: left;
  margin-left: 10px;
}

.sign_input::-webkit-input-placeholder {
  font-family: "Inter";
}

.sign_input:-moz-placeholder {
  font-family: "Inter";
}
</style>
