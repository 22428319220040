<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list :key="2"></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">
      2. Подача осуществляется самостоятельно или по доверенности?
    </span>
    <v-select-open
      :options="send_type_options"
      :title_select="send_type_title"
      :default_select="storeDM.save_obj.send_type"
      :key="storeDM.save_obj.send_type"
      @change-select="send_type_select"
      :margin_top="'1px'"
    >
    </v-select-open>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { defineComponent, ref, computed } from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepTwo",
  components: { VSelectOpen, VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "1",
      });
      router.push({
        path: "/step1/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      if (storeDM.save_obj.send_type == "1") {
        storeDM.set_info({
          step: "2_1",
        });
        router.push({
          path: "/step2_1/" + route.params.docID,
          query: {
            hash: route.query.hash,
          },
        });
      } else {
        storeDM.set_info({
          step: "3",
        });
        router.push({
          path: "/step3/" + route.params.docID,
          query: {
            hash: route.query.hash,
          },
        });
      }
    };
    const send_type_title = "Выберите вариант подачи";
    const send_type_options = [
      { name: "Самостоятельно", value: "1" },
      { name: "По доверенности", value: "2" },
    ];

    const send_type_select = (opt: any) => {
      storeDM.save_obj.send_type = opt.value;
    };

    const step_next = computed(() => {
      return true;
      /*
      if (storeDM.save_obj.send_type != 0) {
        return true;
      } else {
        return false;
      }
      */
    });
    return {
      go_back,
      go_next,
      step_next,
      send_type_title,
      send_type_options,
      send_type_select,
      storeDM,
    };
  },
});
</script>

<style scoped></style>
