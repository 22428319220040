<template>
  <div class="hint_left hint_mini_top">
    {{ react.main_title }}
    <span class="modal_span" @click="showModalFile = true">
      (показать пример)
    </span>
    <transition name="modal">
      <v-modal-image v-if="showModalFile" @close="showModalFile = false">
        <template v-slot:header>
          <h3>{{ react.sample_title }}</h3>
        </template>
        <template v-slot:body>
          <img
            style="width: 100%"
            :src="api_url + 'files/sample/' + react.sample_file"
          />
        </template>
      </v-modal-image>
    </transition>
  </div>
  <div class="hint_left hint_mini_top">
    <div v-if="url_file == ''">
      {{ not_load_file_hint }}
    </div>
    <div v-if="url_file != ''">
      Имя загруженного файла - "<span
        class="modal_span"
        @click="showModalFilePreview = true"
      >
        {{ url_file.split("/")[url_file.split("/").length - 1] }} </span
      >"
      <transition name="modal">
        <v-modal-image
          v-if="showModalFilePreview"
          @close="showModalFilePreview = false"
        >
          <template v-slot:header>
            <h3>Просмотр загруженного файла</h3>
          </template>
          <template v-slot:body>
            <img style="width: 100%" :src="api_url + 'files/' + url_file" />
          </template>
        </v-modal-image>
      </transition>
    </div>
  </div>
  <input
    ref="upload_file"
    type="file"
    hidden
    @change="submitFile(react.file_name)"
  />
  <button
    type="button"
    class="blue_btn"
    @click="$refs.upload_file.click()"
    style="float: left; margin-left: 10px"
  >
    {{ react.button_title }}
  </button>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
} from "vue";

import VModalImage from "@/components/v-modal-image.vue";
import axios from "axios";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "v-file-uploader",
  emits: ["file-upload"],
  components: { VModalImage },
  props: {
    main_title: {
      type: String,
      default() {
        return "Нажмите, чтобы подгрузить файл";
      },
    },
    sample_title: {
      type: String,
      default() {
        return "Пример файла";
      },
    },
    sample_file: {
      type: String,
      default() {
        return "";
      },
    },
    url_file: {
      type: String,
      default() {
        return "";
      },
    },
    file_name: {
      type: String,
      default() {
        return "";
      },
    },
    button_title: {
      type: String,
      default() {
        return "Подгрузить фото";
      },
    },
  },
  setup(
    props: {
      main_title: string;
      sample_title: string;
      sample_file: string;
      url_file: string;
      file_name: string;
      button_title: string;
    },
    context
  ) {
    //console.log("12" + props.title_select);
    const api_url = "https://api.rekprof.ru/";
    let react = reactive({
      main_title: props.main_title,
      sample_title: props.sample_title,
      sample_file: props.sample_file,
      file_name: props.file_name,
      button_title: props.button_title,
    });

    const route = useRoute();

    const not_load_file_hint = 'Имя загруженного файла - "-не загружен-"';
    const upload_file = ref(null);

    const showModalFile = ref(false);
    const showModalFilePreview = ref(false);

    const submitFile = (f_name: string) => {
      //let file = Object(eval(f_name).value).files[0];
      let file = Object(upload_file.value).files[0];

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.params.docID));
      axios
        .post(api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          context.emit(
            "file-upload",
            Object(req.data).file_name,
            Object(req.data)[req.data.file_name]
          );
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    return {
      react,
      not_load_file_hint,
      upload_file,
      showModalFile,
      showModalFilePreview,
      submitFile,
      api_url,
    };
  },
});
</script>

<style lang="scss" scoped></style>
