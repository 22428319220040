<template>
    <div class="profile">
        <div class="white__navbar">
          <!--
            <p>Личный кабинет</p>
            <p>/</p>
          -->
            <p>Данные профиля</p>
        </div>
        <div class="profile__info">
            <div class="profile__info__photo"
                 v-if="client.client_avatar!=''"
                 :style="
                    'background-image: url(\'https://api.rekprof.ru/files/' +
                    client.client_avatar +
                    '\')'"
                 @click="$refs.avatar_file.click()"
            ></div>
          <div class="profile__info__photo"
               v-if="client.client_avatar==''"
               :style="
                    'background-image: url(\'https://api.rekprof.ru/files/blank/avatar.png\')'"
               @click="$refs.avatar_file.click()"
          ></div>
            <input
              ref="avatar_file"
              type="file"
              hidden
              @change="submitFile('avatar_file')"
            />

            <div class="profile__info__data">

                <!-- ====   FULLNAME ===== -->
              
                <div>
                  <h2>Имя пользователя</h2>
                  <input type="text" v-model="client.client_name">
                </div>
              
                <!-- ====   EMAIL ===== -->

                <div>
                  <h2>E-mail</h2>
                  <input type="email" v-model="client.client_email">
                </div>
              
                <!-- ====   TITLE ===== -->
              
                <div>
                  <h2>Название организации</h2>
                  <input type="text" v-model="client.client_company_name">
                </div>
              
                <!-- ====   PASSWORD ===== -->
              
                <div>
                  <h2>Пароль</h2>
                  <input type="password" v-model="client.client_pwd">
                </div>
              
                <!-- ====   NUMBER ===== -->
              
                <div>
                  <h2>Телефон</h2>
                  <input type="number" v-model="client.client_phone">
                </div>

            </div>

            <section>
              <label for="title">
                <v-switch
                        style="display: flex; flex-direction: row; justify-content: flex-start"
                        color="#2F80ED"
                        v-model="client.unstep"
                        label="Предупреждения о незаполненных шагах"
                        inline
                ></v-switch>
              </label>
            </section>

            <button @click="save_client()">Сохранить</button>
        </div>
    </div>
  </template>

  <script lang="ts">
  import { defineComponent, reactive, ref } from "vue";
  import { useDMStore } from "@/store/dm_obj";
  import { useRoute, useRouter } from "vue-router";
  import axios from "axios";

  export default defineComponent({
    name: "Profile",
    emits: ["change-avatar", "change-name"],
    setup(props, context) {
      const storeDM = useDMStore();
      const route = useRoute();
      const router = useRouter();

      const save_yes = ref(0);

      const client = reactive({
        client_name: "",
        client_company_name: "",
        client_pwd: "",
        client_email: "",
        client_phone: "",
        client_avatar: "",
        unstep: true,
      });

      const get_list = async () => {
        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "get_list",
            hash: route.query.hash,
          }),
        });

        const response_data = await response.json();

        if (response_data["status"] == "success") {
          client.client_name = response_data["client_name"];
          client.client_company_name = response_data["client_company_name"];
          client.client_pwd = response_data["client_pwd"];
          client.client_email = response_data["client_email"];
          client.client_phone = response_data["client_phone"];
          client.client_avatar = response_data["client_avatar"];
          if(response_data["unstep"]=="0"){
            client.unstep = false;
          } else {
            client.unstep = true;
          }
        } else {
          router.push("/");
        }
      };
      const save_client = async () => {
        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "save_client",
            hash: route.query.hash,
            client_email: client.client_email,
            client_name: client.client_name,
            client_pwd: client.client_pwd,
            client_company_name: client.client_company_name,
            client_phone: client.client_phone,
            client_avatar: client.client_avatar,
            unstep: client.unstep,
          }),
        });
        const response_data = await response.json();
        save_yes.value = 1;
        context.emit("change-name", client.client_name);
        setTimeout(() => (save_yes.value = 0), 500);
      };


      const avatar_file = ref(null);

      const submitFile = (f_name: string, id: string) => {
        let file = Object(eval(f_name).value).files[0];

        let formData = new FormData();
        formData.append(f_name, file);
        formData.append("file_name", f_name);
        formData.append("hash", String(route.query.hash));
        formData.append("id", id);
        axios
          .post(storeDM.api_url + "upload.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (req) {
            if (f_name == "avatar_file") {
              client.client_avatar = Object(req.data)[req.data.file_name];
              context.emit("change-avatar", client.client_avatar);
            }
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      };


      get_list();

      return {
        save_client,
        get_list,
        client,
        avatar_file,
        submitFile
        /*
        username: "nevis178rus",
        fullname: "Любовь Андреевна",
        email: "nevis@yandex.ru",
        title: "Аптеки Невис, ООО “Кора”",
        password: "12345" ,       
        number: "79085083598"
         */
      }
    }
  });
  </script>
  
  <style></style>