<template>
    <div class="steps">
        <button :class="{activeStep: subpage == 1}" @click="subpage = 1">Шаг 1</button>
        <button v-if="document.app4_file_checked == 0" :class="{activeStep: subpage == 2}" @click="subpage = 2">Шаг 2</button>
        <button v-if="document.app4_file_checked == 0" :class="{activeStep: subpage == 3}" @click="subpage = 3">Шаг 3</button>
        <button v-if="document.app4_file_checked == 0" :class="{activeStep: subpage == 4}" @click="subpage = 4">Шаг 4</button>
    </div>
    <div class="step__basic" v-if="subpage == 1">
      <div class="left">
        <section>
          <label for="title">
            <v-switch
              style="display: flex; flex-direction: row; justify-content: flex-start"
              color="#2F80ED"
              v-model="document.app4_file_checked"
              label="Использовать готовое приложение №4"
              inline
            ></v-switch>

            <div v-if="document.app4_file_checked == 1">
              <input
                ref="app4_file"
                type="file"
                accept=".pdf"
                hidden
                @change="submitFile('app4_file')"
              />

              <span>Нажмите чтобы загрузить готовое приложение</span>
              <button class="btn_upload" style="width: 480px;" @click="$refs.app4_file.click()">Загрузить файл приложения №4 на сервер</button>
              <span style="cursor: pointer;" @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app4_file', document.app4_file.split('/')[document.app4_file.split('/').length-1]);">Файл приложения №4 - {{document.app4_file.split('/')[document.app4_file.split('/').length-1] == "" ? 'не загружен' : document.app4_file.split('/')[document.app4_file.split('/').length-1]}}</span>
            </div>
          </label>
        </section>
      </div>
    </div>
    <div class="uploadfile_photo" v-if="subpage == 2">
        <article>
            <div class="left">
                <section class="eskiz_top">
                    <label for="name">Добавьте первую часть эскизного проекта</label>
                </section>
                <FileUpload :document="document" :file_upload_name="'sketch_project_one_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 3480px x 2320px'" />
            </div>
            <div class="right">
                <p style="margin-top: 18px;">Пример изображения</p>
                <img src="https://api.rekprof.ru/files/sample/spb/eskiz1.jpg" />
            </div>
        </article>
    </div>
    <div class="uploadfile_photo" v-if="subpage == 3">
        <article>
            <div class="left">
                <section class="eskiz_top">
                    <label for="name">Добавьте вторую часть эскизного проекта</label>
                </section>
                <FileUpload :document="document" :file_upload_name="'sketch_project_two_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 3480px x 2320px'" />
            </div>
            <div class="right">
                <p style="margin-top: 18px;">Пример изображения</p>
              <img src="https://api.rekprof.ru/files/sample/spb/eskiz2.jpg" />
            </div>
        </article>
    </div>
    <div class="uploadfile_photo" v-if="subpage == 4">
        <v-switch
            v-if="subpage == 3"
            style="display: flex; flex-direction: row; justify-content: flex-start;"
            color="#2F80ED"
            v-model="document.sketch_project_cpu"
            label="Использовать в этой части компьютерный монтаж издали (по умолчанию)"
            inline
         ></v-switch>
        <article>
            <div class="left" v-if="!document.sketch_project_cpu">
              <section class="eskiz_top">
                 <label for="name">Добавьте третью часть эскизного проекта</label>
              </section>
              <FileUpload :document="document" :file_upload_name="'sketch_project_three_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 3480px x 2320px'" />
            </div>
            <div class="right" v-if="document.sketch_project_cpu">
                <p>Загруженное изображение</p>
                <!-- <img v-bind:src="require('../../assets/images/exampleMarket.jpg')" /> -->
                <img v-bind:src="storeDM.api_url+'files/'+document.mounting_away_file" />
            </div>
            <div class="right" v-if="document.sketch_project_cpu == false">
                <p style="margin-top: 18px;">Пример изображения</p> <!-- третий файл -->
              <img src="https://api.rekprof.ru/files/sample/spb/comp_out.jpg" />
            </div>
        </article>
    </div>
</template>

<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../assets/icons/DropdownUp.vue";
import Map from '../../assets/icons/exampleMap.vue';
import FileUpload from  "@/components/FileUpload.vue";
import {defineComponent, ref} from "vue";
import axios from "axios";
import {useDMStore} from "@/store/dm_obj";
import {useRoute, useRouter} from "vue-router";
export default defineComponent({
    components: {
        DropdownIcon,
        DropdownUp,
        Map,
        FileUpload
    },
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(
      props: {
          document: any;
      }, context
    ){
        const storeDM = useDMStore();

        const route = useRoute();
        const router = useRouter();

        const downloadFileFromURL = async (url, fileName) => {

          if (fileName != "") {
            await axios
              .get(url, {responseType: "blob"})
              .then((response) => {
                const blob = new Blob([response.data], {});
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);
              })
              .catch(console.error);
          }
        }

        const app4_file = ref(null);

        const submitFile = (f_name: string) => {
          let file = Object(eval(f_name).value).files[0];
          if(file == undefined){
            return;
          }

          let formData = new FormData();
          formData.append(f_name, file);
          formData.append("file_name", f_name);
          formData.append("hash", String(route.query.hash));
          formData.append("id", String(route.query.id));
          axios
            .post(storeDM.api_url + "upload.php", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (req) {
              props.document[Object(req.data).file_name] = Object(req.data)[
                req.data.file_name
                ];
              console.log(Object(req.data).file_name+" - "+Object(req.data)[req.data.file_name]);
            })
            .catch(function () {
              console.log("FAILURE!!");
            });
        };

        const subpage = ref(1);
        return{
            subpage,
            storeDM,
            downloadFileFromURL,
            app4_file,
            submitFile,
            route,
        }
    },
});
</script>

<style scoped>
  .eskiz_top {
    margin-top: 24px;
  }
</style>