import { defineStore } from "pinia";
import { useRouter } from "vue-router";

interface IDMDeclState {
  api_url: string;
  hash: string;
  random_hash: string;
  docID: string;
  client_id: string;
  email: string;
  phone: string;
  step: string;
  signatory_options: any;
  signatory_list: any;
  signatory_options_tpm: any;
  signatory_options_add: boolean;
  save_obj: any;
  dragScroll: boolean;
  initDM: boolean;
  router: any;
}

export const useDMDeclStore = defineStore({
  id: "dm_decl",
  state: (): IDMDeclState => ({
    api_url: "https://api.rekprof.ru/",
    hash: "",
    random_hash: "",
    docID: "",
    client_id: "",
    email: "",
    phone: "",
    step: "",
    signatory_options: [],
    signatory_list: [],
    signatory_options_tpm: { signatory_genre: 0 },
    signatory_options_add: false,
    save_obj: {},
    dragScroll: false,
    initDM: false,
    router: useRouter(),
  }),
  actions: {
    async set_info(obj: { [index: string]: any }, one = 1) {
      for (const i in obj) {
        //???
        this.save_obj[i] = obj[i];

        if (i == "declarer_patronymic_no") {
          if (this.save_obj.declarer_patronymic_no == 1) {
            this.save_obj.declarer_patronymic_no = true;
          } else {
            this.save_obj.declarer_patronymic_no = false;
          }
        }

        if (i == "declarer_no") {
          if (this.save_obj.declarer_no == 1) {
            this.save_obj.declarer_no = true;
          } else {
            this.save_obj.declarer_no = false;
          }
        }

        if (this.signatory_options_add == true) {
          this.signatory_options_add = false;
          if (this.signatory_options_tpm.signatory_skip_patronymic == true) {
            this.signatory_options_tpm.signatory_skip_patronymic = 1;
          } else {
            this.signatory_options_tpm.signatory_skip_patronymic = 0;
          }
          await this.add_signatory_options();
        }
      }
      if (one == 1) {
        this.send_info_server(0);
      }

      this.save_obj.steps = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (
        this.save_obj.client_inn != "" &&
        this.save_obj.client_type != 0 // &&
        //this.save_obj.client_ogrn != "" &&
        //this.save_obj.client_ogrnip != "" &&
        //this.save_obj.client_kpp != "" &&
        //this.save_obj.client_address != "" &&
        //this.save_obj.client_fullname != "" &&
        //this.save_obj.client_smallname != ""
      ) {
        this.save_obj.steps[1] = 1;
      }

      if (
        this.save_obj.declarer_lastname != "" &&
        this.save_obj.declarer_firstname != "" &&
        this.save_obj.declarer_patronymic != "" &&
        this.save_obj.client_role != "" &&
        this.save_obj.client_role_fio_genitive != "" &&
        this.save_obj.declarer_genre != 0
      ) {
        this.save_obj.steps[2] = 1;
      }

      if (
        this.save_obj.declarer_passport_number != "" &&
        this.save_obj.declarer_passport_seria != "" &&
        this.save_obj.declarer_passport_issued != "" &&
        this.save_obj.declarer_when_issued != "" &&
        this.save_obj.declarer_division_code != "" &&
        this.save_obj.declarer_passport_registration != ""
      ) {
        this.save_obj.steps[3] = 1;
      }

      if (
        this.save_obj.proxy_re != 0 &&
        this.save_obj.proxy_date != "" &&
        this.save_obj.proxy_date != "'0'"
      ) {
        this.save_obj.steps[4] = 1;
      }

      //console.log(this.signatory_list.length);
      if (this.signatory_list.length != 0) {
        this.save_obj.steps[5] = 1;
      }

      if (
        this.save_obj.steps[1] == 1 &&
        this.save_obj.steps[2] == 1 &&
        this.save_obj.steps[3] == 1 &&
        this.save_obj.steps[4] == 1 &&
        this.save_obj.steps[5] == 1
      ) {
        this.save_obj.steps[6] = 1;
      }

      //console.log(save_obj);
    },

    async add_signatory_options() {
      this.save_obj["signatory_options"].push(this.signatory_options_tpm);
      this.signatory_options_tpm = { signatory_genre: 0 };
      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "add_signatory_options",
          hash: this.hash,
          id: this.docID,
          client: this.save_obj["client"],
          signatory_type: "declaration",
          signatory_options: this.save_obj["signatory_options"],
        }),
      });
      const response_data = await response.json();
    },
    async send_info_server(one = 1) {
      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "set_info_decl",
          hash: this.hash,
          id: this.docID,
          client: this.save_obj["client"],
          client_fullname: this.save_obj["client_fullname"],
          client_inn: this.save_obj["client_inn"],
          client_ogrn: this.save_obj["client_ogrn"],
          client_ogrnip: this.save_obj["client_ogrnip"],
          client_kpp: this.save_obj["client_kpp"],
          client_address: this.save_obj["client_address"],
          client_role_fio: this.save_obj["client_role_fio"],
          client_role: this.save_obj["client_role"],
          client_role_fio_genitive: this.save_obj["client_role_fio_genitive"],
          client_smallname: this.save_obj["client_smallname"],
          client_type: this.save_obj["client_type"],
          declarer_no: this.save_obj["declarer_no"],
          declarer_genre: this.save_obj["declarer_genre"],
          declarer_firstname: this.save_obj["declarer_firstname"],
          declarer_lastname: this.save_obj["declarer_lastname"],
          declarer_passport_issued: this.save_obj["declarer_passport_issued"],
          declarer_passport_number: this.save_obj["declarer_passport_number"],
          declarer_passport_registration:
            this.save_obj["declarer_passport_registration"],
          declarer_passport_seria: this.save_obj["declarer_passport_seria"],
          declarer_patronymic: this.save_obj["declarer_patronymic"],
          declarer_patronymic_no: this.save_obj["declarer_patronymic_no"],
          declarer_when_issued: this.save_obj["declarer_when_issued"],
          declarer_division_code: this.save_obj["declarer_division_code"],
          designer_name: this.save_obj["designer_name"],
          proxy_date: this.save_obj["proxy_date"],
          proxy_re: this.save_obj["proxy_re"],
          step: this.save_obj["step"],
          signatory_list: this.signatory_list,
          signatory_options: this.save_obj["signatory_options"],
          signatory_main: this.save_obj["signatory_main"],
          signature_decl_file: this.save_obj["signature_decl_file"],
        }),
      });
      const response_data = await response.json();

      if (response_data.found == 0) {
        this.router.push({
          path: "/",
        });
        return;
      }

      if (one == 1) {
        this.set_info(response_data);
      }
    },

    async get_info_server() {
      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_info_decl",
          hash: this.hash,
          id: this.docID,
        }),
      });

      const response_data = await response.json();

      if (response_data.found == 0) {
        this.router.push({
          path: "/",
        });
        return;
      }

      this.signatory_list = response_data.signatory_list;
      //console.log(111);
      //this.save_obj.steps = response_data.steps;
      //console.log(signatory_list.value);
      this.set_info(response_data, 0);
    },

    async signatory_list_push(obj: any) {
      this.signatory_list.push({
        id: 0,
        name: obj.name,
        signatories: obj.id,
        document: this.docID,
      });

      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "set_signatory_list_decl",
          hash: this.hash,
          id: this.docID,
          signatory_list: this.signatory_list,
        }),
      });

      const response_data = await response.json();
      this.signatory_list = response_data.signatory_list;

      this.send_info_server(0);
    },

    async signatory_list_delete(obj: any) {
      const sl_del = this.signatory_list.splice(obj.num, 1);

      const response = await fetch(this.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "set_signatory_del_decl",
          hash: this.hash,
          id: this.docID,
          sl_del: sl_del,
        }),
      });

      const response_data = await response.json();
      this.signatory_list = response_data.signatory_list;

      this.send_info_server(0);
    },
    init(hash: any, docID: any) {
      this.initDM = true;
      this.signatory_options_tpm = { signatory_genre: 0 };
      this.save_obj["signatory_options"] = [];

      this.hash = hash;
      this.docID = docID;

      this.get_info_server();

      //this.set_info({}, 0);
    },
  },
});
