<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">
      6. Фотофиксация конструкций{{ app6_title }}
    </span>
    <br />
    <span class="main_field">
      6.1 Подгрузите горизонтальное обзорное фото вывески сбоку
    </span>
    <v-file-uploader
      :main_title="'Нажмите, чтобы подгрузить перспективу'"
      :url_file="storeDM.save_obj.construction_perspective_file"
      :sample_title="'Пример горизонтальное обзорное фото вывески сбоку'"
      :sample_file="'3.png'"
      :file_name="'construction_perspective_file'"
      :button_title="'Подгрузить перспективу'"
      @file-upload="fileUpload"
      :key="construction_perspective_file"
    >
    </v-file-uploader>
    <br />
    <br />
    <br />
    <span class="main_field">
      6.2 Подгрузите горизонтальное фото вывески вблизи
    </span>
    <v-file-uploader
      :main_title="'Подгрузите фото, сделанное вблизи'"
      :url_file="storeDM.save_obj.construction_around_file"
      :sample_title="'Пример горизонтальное фото вывески вблизи'"
      :sample_file="'4.png'"
      :file_name="'construction_around_file'"
      :button_title="'Подгрузить фото'"
      @file-upload="fileUpload"
      :key="construction_around_file"
    >
    </v-file-uploader>
    <br />
    <br />
    <br />
    <span class="main_field">
      6.3 Подгрузите горизонтальное фото вывески издалека
    </span>
    <v-file-uploader
      :main_title="'Подгрузите фото, сделанное издалека'"
      :url_file="storeDM.save_obj.construction_away_file"
      :sample_title="'Пример горизонтальное фото вывески издалека'"
      :sample_file="'5.png'"
      :file_name="'construction_away_file'"
      :button_title="'Подгрузить фото'"
      @file-upload="fileUpload"
      :key="construction_away_file"
    >
    </v-file-uploader>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";
import VFileUploader from "@/components/v-file-uploader.vue";

export default defineComponent({
  name: "StepSix",
  components: { VMenuList, VFileUploader },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "5",
      });
      router.push({
        path: "/step5/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const not_load_file_hint = 'Имя загруженного файла - "-не загружен-"';

    const construction_perspective_file = ref(0);
    const construction_around_file = ref(0);
    const construction_away_file = ref(0);

    const fileUpload = (file_name: any, file_name_url: any) => {
      eval(file_name).value = eval(file_name).value + 1;
      storeDM.save_obj[file_name] = file_name_url;
    };

    const go_next = () => {
      storeDM.set_info({
        step: "7",
      });
      router.push({
        path: "/step7/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
    });

    const app6_title = computed(() => {
      if (storeDM.save_obj.construction_app6 == true) {
        return ", Приложение 6 (Согласование по факту)";
      } else {
        return "";
      }
    });

    return {
      go_back,
      step_next,
      go_next,
      storeDM,
      construction_perspective_file,
      not_load_file_hint,
      construction_around_file,
      construction_away_file,
      app6_title,
      fileUpload,
    };
  },
});
</script>

<style scoped></style>
