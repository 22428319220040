<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.27786 5.71806C6.3903 5.71806 7.32998 4.97836 7.63187 3.96398H14.0483V2.56072H7.63187C7.32998 1.54634 6.3903 0.806641 5.27786 0.806641C3.92161 0.806641 2.82215 1.9061 2.82215 3.26235C2.82215 4.6186 3.92161 5.71806 5.27786 5.71806ZM6.33031 3.26235C6.33031 3.8436 5.85911 4.3148 5.27786 4.3148C4.69661 4.3148 4.22541 3.8436 4.22541 3.26235C4.22541 2.6811 4.69661 2.2099 5.27786 2.2099C5.85911 2.2099 6.33031 2.6811 6.33031 3.26235Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1893 9.92785C11.3017 9.92785 12.2414 9.18815 12.5433 8.17377H14.0483V6.77051H12.5433C12.2414 5.75613 11.3017 5.01643 10.1893 5.01643C8.83303 5.01643 7.73357 6.11589 7.73357 7.47214C7.73357 8.82839 8.83303 9.92785 10.1893 9.92785ZM10.1893 8.52458C10.7705 8.52458 11.2417 8.05339 11.2417 7.47214C11.2417 6.89089 10.7705 6.41969 10.1893 6.41969C9.60803 6.41969 9.13683 6.89089 9.13683 7.47214C9.13683 8.05339 9.60803 8.52458 10.1893 8.52458Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.8746 14.1376C5.23085 14.1376 6.33031 13.0382 6.33031 11.6819C6.33031 10.3257 5.23085 9.22622 3.8746 9.22622C2.76215 9.22622 1.82248 9.96591 1.52058 10.9803H0.015625V12.3836H1.52058C1.82248 13.3979 2.76215 14.1376 3.8746 14.1376ZM3.8746 12.7344C4.45585 12.7344 4.92704 12.2632 4.92704 11.6819C4.92704 11.1007 4.45585 10.6295 3.8746 10.6295C3.29335 10.6295 2.82215 11.1007 2.82215 11.6819C2.82215 12.2632 3.29335 12.7344 3.8746 12.7344Z"
      fill="#333333"
    />
    <path
      d="M7.03194 10.9803H14.0483V12.3836H7.03194V10.9803Z"
      fill="#333333"
    />
    <path
      d="M7.03194 6.77051H0.015625V8.17377H7.03194V6.77051Z"
      fill="#333333"
    />
    <path
      d="M2.12052 2.56072H0.015625V3.96398H2.12052V2.56072Z"
      fill="#333333"
    />
  </svg>
</template>
