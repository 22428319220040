<template>
    <div class="steps">
        <button :class="{activeStep: subpage == 1}" @click="subpage = 1">Шаг 1</button>
        <button :class="{activeStep: subpage == 2}" @click="subpage = 2">Шаг 2</button>
    </div>
    <div class="step__basic" v-if="subpage == 1">
        <div class="left">
            <div>
                <label for="name">Введите фамилию</label>
                <input class="input" type="text" placeholder="Введите фамилию" v-model="document.declarer_lastname">
            </div>
            <div>
                <label for="name">Введите имя</label>
                <input class="input" type="text" placeholder="Введите имя" v-model="document.declarer_firstname">
            </div>
            <div>
                <label for="name">Введите должность</label>
                <span class="blueSpan">В лице кого подаём. Пример: Генерального директора</span>
                <input class="input" type="text" placeholder="Введите должность" v-model="document.client_role">
            </div>
            <div>
                <label for="name">Введите ФИО в родительном падеже</label>
                <span class="blueSpan">В лице кого подаём. Пример: Иванова Ивана Ивановича</span>
                <input class="input" type="text" placeholder="Введите ФИО в родительном падеже" v-model="document.client_role_fio_genitive">
            </div>
        </div>
        <div class="right" style="padding-top: 15px; gap: 0rem;">
                <section>
                    <label for="title">
                        <v-switch
                        style="display: flex; flex-direction: row; justify-content: flex-start"
                        color="#2F80ED"
                        v-model="document.declarer_patronymic_no"
                        label="Отчество"
                        ></v-switch>
                    </label>
                    <input class="input lastname" style="margin-top: -0.6rem;" type="text" placeholder="Введите отчество" v-model="document.declarer_patronymic">
                </section>
                <section class="gender">
                    <label for="gender">Выберите пол</label>
                    <v-radio-group
                    v-model="document.declarer_genre"
                    inline>
                    <v-radio
                        color="#2F80ED"
                        label="Мужчина"
                        value="1">
                    </v-radio>
                    <v-radio
                        color="#2F80ED"
                        label="Женщина"
                        value="2">
                    </v-radio>
                    </v-radio-group>
                </section>
                <section>
                    <label for="title">Загрузите или перенесите сюда файл подписи</label>
                    <span class="blueSpan">Подпись должны быть в формате png, размером не менее 100 × 100 px</span>
                    <input
                      ref="signature_file"
                      type="file"
                      hidden
                      @change="submitFile('signature_file')"
                    />
                    <div v-if="document.signature_file != ''" class="uploadFile" @click="$refs.signature_file.click()"
                         :style="'background: url(\'https://api.rekprof.ru/files/'+document.signature_file+'\'); background-position: center; background-size: contain; background-repeat: no-repeat; border: 1px solid black;'"
                    >
                        <p>Перенесите сюда <br> подпись или нажмите <br> для загрузки</p>
                    </div>
                    <div v-if="document.signature_file == ''" class="uploadFile" @click="$refs.signature_file.click()">
                      <p>Перенесите сюда <br> подпись или нажмите <br> для загрузки</p>
                    </div>
                </section>
        </div>
</div>
<div class="step__basic" v-if="subpage == 2">
        <div class="left">
            <div>
                <label for="name">Введите серию паспорта</label>
                <span class="blueSpan">Пример: 2212</span>
                <input class="input"  type="text" placeholder="0000"
                   v-model="document.declarer_passport_seria"
                   maxlength="4"
                   onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                >
            </div>
            <div>
                <label for="name">Введите номер паспорта</label>
                <span class="blueSpan">Пример: 999654</span>
                <input class="input" type="text" placeholder="000000"
                   v-model="document.declarer_passport_number"
                   maxlength="6"
                   onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                >
            </div>
            <div>
                <label for="name">Дата выдачи</label>
                <span class="blueSpan">Пример: 20.02.2002</span>
                <input class="input" type="date" placeholder="__.__.____"
                   v-model="document.declarer_when_issued"
                   maxlength="10"
                   onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                >
            </div>
        </div>
        <div class="right">
            <div>
                <label for="name">Кем выдан паспорт</label>
                <span class="blueSpan">Пример: Отделом УФМС России по гор. Москва по району Митино</span>
                <textarea style="resize: none; overflow: hidden;" class="input" type="text" placeholder="Введите учреждение" v-model="document.declarer_passport_issued"></textarea>
            </div>
            <div>
                <label for="name">Код подразделения</label>
                <span class="blueSpan">Пример: 770-093</span>
                <input class="input" type="text" placeholder="000-000"
                   v-model="document.declarer_division_code"
                   maxlength="6"
                   onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                   pattern="[0-9]{3}-[0-9]{3}"
                >
            </div>
            <div>
                <label for="name">Где зарегистрирован</label>
                <span class="blueSpan">Пример: г. Москва, ул. Ленина, д. 74, кв. 105</span>
                <textarea style="resize: none; overflow: hidden;" class="input" type="text" placeholder="Введите адрес регистрации" v-model="document.declarer_passport_registration"></textarea>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {useDMStore} from "@/store/dm_obj";

export default defineComponent({
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(
      props: {
          document: any;
      }, context
    ){

        const storeDM = useDMStore();

        const route = useRoute();

        const signature_file = ref(null);

        const submitFile = (f_name: string) => {
            let file = Object(eval(f_name).value).files[0];
            if(file == undefined){
              return;
            }

            let formData = new FormData();
            formData.append(f_name, file);
            formData.append("file_name", f_name);
            formData.append("hash", String(route.query.hash));
            formData.append("id", String(route.query.id));
            axios
              .post(storeDM.api_url + "upload.php", formData, {
                  headers: {
                      "Content-Type": "multipart/form-data",
                  },
              })
              .then(function (req) {
                  props.document[Object(req.data).file_name] = Object(req.data)[
                    req.data.file_name
                    ];
                  //console.log(req);
              })
              .catch(function () {
                  console.log("FAILURE!!");
              });
        };

        //const subpage = ref(Number(route.query.substep));
        const subpage = ref(1);

        return{
            signature_file,
            submitFile,
            subpage,
        }
    }
});
</script>