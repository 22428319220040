<template>
  <!--
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  -->
  <div class="app_main" @mouseup="mouseup_do">
    <div class="app_body">
      <router-view />
    </div>
  </div>
  <!--
  <div class="menu_user_lk" v-if="route.query.hash" @click="menu_user_lk = 1">
    <i class="fa-solid fa-bars"></i>
  </div>
  -->
  <!-- <div class="statement_detail" v-if="menu_user_lk == 1"> -->
  <div class="statement_detail" v-if="false">
    <!--
    <div class="statement_element" @click="menu_user_lk = 0">
      <i class="fa-solid fa-arrow-rotate-right"></i>&nbsp;&nbsp;Продолжить
      работу
    </div>
    -->
    <div class="statement_element" @click="lk_go()">
      <i class="fa-solid fa-address-card"></i>&nbsp;&nbsp;Личный кабинет
    </div>
    <div class="statement_element" @click="lk_exit()">
      <i class="fa-solid fa-person-walking-arrow-right"></i>&nbsp;&nbsp;Выйти
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { useDMStore } from "@/store/dm_obj";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  props: {},
  setup() {
    const route = useRoute();
    const router = useRouter();

    const menu_user_lk = ref(0);

    const storeDM = useDMStore();
    const mouseup_do = (evt: MouseEvent) => {
      storeDM.dragScroll = false;
    };

    const lk_exit = () => {
      router.push({
        path: "/",
      });
      menu_user_lk.value = 0;
    };

    const lk_go = () => {
      router.push({
        path: "/go",
        query: {
          hash: route.query.hash,
        },
      });
      menu_user_lk.value = 0;
    };

    onMounted(() => {
      document.addEventListener("click", hideLK.bind(this), true);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", hideLK);
    });

    const hideLK = () => {
      menu_user_lk.value = 0;
    };

    return {
      mouseup_do,
      route,
      menu_user_lk,
      lk_exit,
      lk_go,
    };
  },
});
</script>

<style lang="scss">
html {
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0px;
  display: contents;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter/Inter-V.eot"),
    url("./fonts/Inter/Inter-V.ttf") format("truetype"),
    url("./fonts/Inter/Inter-V.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.statement_element:first-child {
  margin-top: 56px;
}

.statement_element {
  line-height: 50px;
  margin-top: 6px;
  cursor: pointer;
  height: 50px;
  /* color: white; */
}

.statement_element:hover {
  background-color: #2f80ed;
  color: white;
}

.statement_detail {
  width: 300px;
  height: 230px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px;
}

.app_main {
  /* width: 360px; */
  /*min-height: 900px;*/

  /** height: 900px; **/
  margin: auto;
}

.app_body {
  /* width: 302px; */
  margin: auto;
  /* padding-top: 10px; */
  height: 100%;
  position: relative;
  /*min-height: 900px;*/
  /** height: 890px; **/
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.logo {
  width: 160px;
  height: 204px;
}

.logo_min {
  width: 24px;
  height: 30px;
  float: left;
  margin-left: 10px;
}

.hint {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #2f80ed;
}

.hint_left {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #2f80ed;
  margin-bottom: 5px;
  text-align: left;
  /* width: 300px; */
  width: 574px;
  /*margin-top: 16px;*/
  margin-top: 32px;
  margin-left: 10px;
}

.hint_mini_top {
  margin-top: 16px;
}

.red_color {
  color: red;
}

.green_color {
  color: green;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

.wrapper {
  position: relative;
  user-select: none;
}

.wrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 290px;
  height: 570px;
}

.blue_btn {
  background: #2f80ed;
  display: block;
  width: 573px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin: auto;
  margin-top: 5px;
  border-width: 0px;
}

.blue_complete_btn {
  background: #2f80ed;
  display: block;
  width: 339px; /* 573px; */
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
  border-width: 0px;
  text-align: left;
  padding-left: 13px;
}

.blue_active_btn {
  background: #2f80ed;
  display: block;
  width: 366px; /* 593px; */
  height: 46px; /* 60px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin-top: -8px;
  margin-bottom: 2px;
  margin-left: -14px;
  border-width: 0px;
  text-align: left;
  padding-left: 27px;
}

.blue_inactive_btn {
  background: #c2c2c2;
  display: block;
  width: 339px; /* 573px; */
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin: auto;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
  border-width: 0px;
  text-align: left;
  padding-left: 13px;
}

.red_btn {
  background: red;
  display: block;
  width: 339px; /* 573px; */
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin: auto;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
  border-width: 0px;
  text-align: left;
  padding-left: 13px;
}

.blue_half_btn {
  background: #2f80ed;
  display: block;
  width: 140px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 15px;
  border-width: 0px;

  margin-left: 199px;
}

.blue_small_btn {
  background: #2f80ed;
  display: block;
  width: 40px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin: auto;
  margin-top: 5px;
  float: right;
  border-width: 1px;
}

.signatory_block {
  height: 48px;
  margin-bottom: 30px;
}

.signatory_input {
  width: 255px;
  float: left;
  height: 40px;
  border: 1px solid #2f80ed;
  padding-left: 10px;
  box-sizing: border-box;
  margin-top: 5px;
}

.white_btn {
  background: white;
  display: block;
  width: 573px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2f80ed;
  margin: auto;
  margin-top: 5px;
  border-width: 1px;
  border-color: #2f80ed;
}

.transparent_btn {
  display: block;
  width: 573px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2f80ed;
  margin: auto;
  margin-top: 5px;
  border: none;
  background-color: transparent;
  border-width: 1px;
}

.white_s_btn {
  background: white;
  display: block;
  width: 140px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2f80ed;
  margin-top: 5px;
  margin-left: 10px;

  position: absolute;
  bottom: 20px;
  border-width: 1px;

  border-color: #2f80ed;
}

.next_btn {
  background: #e0e0e0;
  color: white;
  display: block;
  width: 140px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;

  position: absolute;
  bottom: 20px;
  left: 162px;
  border-width: 0px;
}

.blue_next_btn {
  background: #e0e0e0;
  color: white;
  display: block;
  width: 573px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;
  margin: auto;
  border-width: 0px;
}

.active_btn {
  background: #2f80ed !important;
  border-width: 0px;
  color: white !important;
}

.active_btn:hover {
  filter: brightness(133%);
}

.blue_complete_btn:hover {
  filter: brightness(133%);
}

.red_btn:hover {
  filter: brightness(133%);
}

.white_btn:hover {
  filter: brightness(133%);
}

.white_s_btn:hover {
  filter: brightness(133%);
}

.blue_inactive_btn:hover {
  filter: brightness(110%);
}

.blue_btn:hover {
  filter: brightness(133%);
}

.inner_box {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.inner_step_box {
  margin-top: 50px;
}

.main_field {
  text-align: left;
  display: flex;
  width: 573px;
  margin-left: 10px;
}

.main_input {
  width: 573px;
  height: 40px;
  border: 1px solid #2f80ed;
  padding-left: 10px;
  box-sizing: border-box;

  float: left;
  margin-left: 10px;
}

.bold {
  font-weight: bold;
}

.first_input {
  width: 278px;
  height: 40px;
  border: 1px solid #2f80ed;
  padding-left: 10px;
  box-sizing: border-box;
  float: left;
  margin-left: 10px;
}

.two_input {
  width: 278px;
  height: 40px;
  border: 1px solid #2f80ed;
  padding-left: 10px;
  box-sizing: border-box;
  float: left;
  margin-left: 17px;
}

.first_sm_input {
  width: 251px;
  height: 40px;
  border: 1px solid #2f80ed;
  padding-left: 10px;
  box-sizing: border-box;
  float: left;
  margin-left: 10px;
}

.two_sm_input {
  width: 251px;
  height: 40px;
  border: 1px solid #2f80ed;
  padding-left: 10px;
  box-sizing: border-box;
  float: left;
  margin-left: 17px;
}

.main_checkbox {
  text-align: left;
  color: #2f80ed;
  font-size: 16px;
  margin-left: 10px;
}

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked) {
  position: absolute;
  /* left: -9999px; */
}

input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label {
  display: inline-block;
  position: relative;
  padding-left: 40px;
  padding-top: 6px;
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  border: 1px solid #2f80ed;
  background-color: #ffffff;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  border-radius: 2px;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  left: 8px;
  top: 8px;
  width: 12px;
  height: 6px;
  border-radius: 1px;
  border-left: 4px solid #2f80ed;
  border-bottom: 4px solid #2f80ed;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.modal_span {
  cursor: pointer;
  text-decoration: underline;
  color: #2c3e4f;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

.menu_user_lk {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #2f80ed;
  cursor: pointer;
  font-size: 30px;
}
</style>
