<!-- eslint-disable prettier/prettier -->
<template>
  <div class="navbar">
    <!-- =====  LOGO  ===== -->
    
      <div class="navbar__logo">
          <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.36667 1.56168C10.4556 2.02834 11.2333 2.49501 12.0111 3.27279C12.7889 4.05056 13.4111 4.82834 13.8778 5.91723C14.3444 7.00612 14.5 7.93945 14.5 9.02834C14.5 10.1172 14.3444 11.2061 13.8778 12.1395C13.4111 13.0728 12.7889 14.1617 12.0111 14.7839C11.2333 15.5617 10.3 16.0283 9.36667 16.495C8.43333 16.9617 7.18889 17.1172 6.1 17.1172H0.5V0.939453H6.1C7.18889 0.939453 8.27778 1.09501 9.36667 1.56168Z" fill="white"/>
            <path d="M2.98956 11.8284C3.67685 11.8284 4.23401 11.2712 4.23401 10.5839C4.23401 9.89663 3.67685 9.33948 2.98956 9.33948C2.30227 9.33948 1.74512 9.89663 1.74512 10.5839C1.74512 11.2712 2.30227 11.8284 2.98956 11.8284Z" fill="#2F80ED"/>
            <path d="M5.16632 8.40613C5.85361 8.40613 6.41076 7.84897 6.41076 7.16168C6.41076 6.47439 5.85361 5.91724 5.16632 5.91724C4.47903 5.91724 3.92188 6.47439 3.92188 7.16168C3.92188 7.84897 4.47903 8.40613 5.16632 8.40613Z" fill="#2F80ED"/>
            <path d="M7.18878 11.8284C7.87607 11.8284 8.43322 11.2712 8.43322 10.5839C8.43322 9.89663 7.87607 9.33948 7.18878 9.33948C6.50149 9.33948 5.94434 9.89663 5.94434 10.5839C5.94434 11.2712 6.50149 11.8284 7.18878 11.8284Z" fill="#2F80ED"/>
            <path d="M9.36651 8.40613C10.0538 8.40613 10.611 7.84897 10.611 7.16168C10.611 6.47439 10.0538 5.91724 9.36651 5.91724C8.67923 5.91724 8.12207 6.47439 8.12207 7.16168C8.12207 7.84897 8.67923 8.40613 9.36651 8.40613Z" fill="#2F80ED"/>
            <path d="M11.389 11.8284C12.0763 11.8284 12.6334 11.2712 12.6334 10.5839C12.6334 9.89663 12.0763 9.33948 11.389 9.33948C10.7017 9.33948 10.1445 9.89663 10.1445 10.5839C10.1445 11.2712 10.7017 11.8284 11.389 11.8284Z" fill="#2F80ED"/>
          </svg>
          <h1>REKDOC</h1>
      </div>
      <div v-if="false">
        <button class="zoom_btn" @click="zoom_click">Подогнать масштаб</button>
      </div>  

      <!--<div class="docs_left">Документов осталось {{client_docs}} до {{new Date(Number(client_docs_timeout)*1000).getUTCDate()}}.{{new Date(Number(client_docs_timeout)*1000).getUTCMonth()+1}}.{{new Date(Number(client_docs_timeout)*1000).getUTCFullYear()}} {{new Date(Number(client_docs_timeout)*1000).getUTCHours()}}:{{new Date(Number(client_docs_timeout)*1000).getUTCMinutes()}} </div>-->
      <!-- ===== LOGOUT  ======= -->
      <div class="navbar__profile" v-if="logout">
        <!--
        <svg style="cursor:pointer" @click="storeDM.showSubscription = !storeDM.showSubscription" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66666 1.66667H12.9833C15.7447 1.66667 17.9833 3.90525 17.9833 6.66667V12.9833C17.9833 15.7448 15.7447 17.9833 12.9833 17.9833H6.66666C3.90523 17.9833 1.66666 15.7448 1.66666 12.9833V6.66667C1.66666 3.90525 3.90523 1.66667 6.66666 1.66667ZM12.9833 16.7333C15.0525 16.7288 16.7287 15.0525 16.7333 12.9833V6.66667C16.7287 4.59751 15.0525 2.92125 12.9833 2.91667H6.66666C4.59749 2.92125 2.92124 4.59751 2.91666 6.66667V12.9833C2.92124 15.0525 4.59749 16.7288 6.66666 16.7333H12.9833Z" fill="white"/>
          <path d="M9.82499 6.70001C9.48169 6.70449 9.20448 6.9817 9.19999 7.32501C9.19996 7.67156 9.47847 7.95378 9.82499 7.95834C9.99291 7.96067 10.1545 7.89442 10.2725 7.77488C10.3904 7.65535 10.4545 7.49288 10.45 7.32501C10.45 6.97983 10.1702 6.70001 9.82499 6.70001Z" fill="white"/>
          <path d="M9.82499 9.10001C9.47981 9.10001 9.19999 9.37983 9.19999 9.72501V12.325C9.19999 12.6702 9.47981 12.95 9.82499 12.95C10.1702 12.95 10.45 12.6702 10.45 12.325V9.74167C10.4569 9.57238 10.3938 9.40771 10.2756 9.28633C10.1574 9.16496 9.99441 9.09758 9.82499 9.10001Z" fill="white"/>
        </svg>
        -->
        <modalSubscription v-if="storeDM.showSubscription" />
        <!--<modalSubscription v-if="storeDM.showSubscription" />-->
          <!-- <v-badge content="2" color="#FF4D4F"> -->
          <!-- <v-icon color="white">mdi-bell-outline</v-icon> -->
          <!-- </v-badge> -->
          
          <!--
          <div class="avatar">
              <v-avatar  size="36">
                <v-img v-if="client_avatar!=''" :src="'https://api.rekprof.ru/files/' + client_avatar" style="background-color: white;"></v-img>
                <v-img v-if="client_avatar==''" v-bind:src="require('../assets/images/avatar.png')" style="background-color: white;"></v-img>
              </v-avatar>
              <h2>{{ client_name }}</h2>
          </div>
          -->
          <div class="logout"  @click="logout = false">
              <v-avatar  size="36">
                <v-img v-if="client_avatar!=''" :src="'https://api.rekprof.ru/files/' + client_avatar" style="background-color: white;"></v-img>
                <v-img v-if="client_avatar==''" v-bind:src="require('../assets/images/avatar.png')" style="background-color: white;"></v-img>
              </v-avatar>
              <!--
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2.01958" cy="2.25395" r="2.00395" fill="white"/>
                  <circle cx="2.01958" cy="8.24999" r="2.00395" fill="white"/>
                  <circle cx="2.01958" cy="14.246" r="2.00395" fill="white"/>
                  <circle cx="8.01567" cy="2.25395" r="2.00395" fill="white"/>
                  <circle cx="8.01567" cy="8.24999" r="2.00395" fill="white"/>
                  <circle cx="8.01567" cy="14.246" r="2.00395" fill="white"/>
                  <circle cx="14.0118" cy="2.25395" r="2.00395" fill="white"/>
                  <circle cx="14.0118" cy="8.24999" r="2.00395" fill="white"/>
                  <circle cx="14.0118" cy="14.246" r="2.00395" fill="white"/>
              </svg>
              -->
          </div>
      </div>

      <!-- ========  PROFILE AVATAR   =======  -->
      
      <div class="logout__container" v-if="!logout">
          <!-- <v-badge content="2" color="#FF4D4F"> -->
          <!-- <v-icon color="#2F80ED">mdi-bell-outline</v-icon> -->
          <!-- </v-badge> -->
          <div class="logout__icon exit_btn">
              <LogoutIcon @click="logout = true" />
          </div>
          <!--
          <div class="avatar">
              <v-avatar  size="36">
                  <v-img :src="'https://api.rekprof.ru/files/' + client_avatar" style="background-color: white;"></v-img>
              </v-avatar>
              <h2>{{ client_name }}</h2>
          </div>
          -->
          <div class="profile_pay">
            <p @click="goPaySerg()">
              Профиль и оплата
            </p>
          </div>
          <div class="profile_pay">
              <p @click="lk_exit()">Выйти из системы</p>
          </div>
      </div>
  </div>
</template>

<script>
import LogoutIcon from '../assets/icons/logoutIcon.vue';
import modalSubscription from  "@/components/modalSubscription.vue";
import { useRoute, useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";
export default {
  name: 'Navbar',
  data(){
    const storeDM = useDMStore();

    const route = useRoute();
    const router = useRouter();
    const lk_exit = () => {
      router.push({
        path: "/",
      });
    };

    const zoom_click = () => {
      
      let scale = 'scale(1)';
      if(screen.availHeight <= 800){
        window.document.body.style["zoom"] = "75%"; //+
        //window.document.body.style["zoom"] = 0.75;
        //scale = 'scale(0.85)';
        //window.document.body.style["webkitTransform"] = scale;
        //window.document.body.style["msTransform"] = scale;
        //window.document.body.style["transform"] = scale;
      } else if(screen.availHeight <= 900){
        window.document.body.style["zoom"] = "90%";
      } else {
        window.document.body.style["zoom"] = "100%";
      }
      //console.log("resolution - "+document.body.style["zoom"]);
      //console.log(screen.availHeight);
      //console.log(document);
    }

    const goPaySerg = () => {
      let types = route.query.type;

      if(types == "proxy"){
        types = "documents";
      } else if(types == "signatory" ){
        types = "signatories";
      }

      if(route.query.id && route.query.type=="document"){
        window.location.href = "https://rekdoc.rekprof.ru/logon.php?hash=" + route.query.hash + "&type=" + types +"&id=" + route.query.id + "&region=" + route.query.region + "&construction=" + route.query.construction + "&step=" + route.query.step + "&mode=" + route.query.mode;
      } else {
        window.location.href = "https://rekdoc.rekprof.ru/logon.php?hash=" + route.query.hash + "&type=" + types;
      }
    }

    return{
      lk_exit,
      logout: true,
      data: [
        {
          date: "24.10.2022",
          user: "ООО “ЮГ-СЕРВИСГРУПП”",
          address: "СПБ, район Октябрьский, улица Таганрогская, дом 112, корпус 1, литера А",
          
        }
      ],
      storeDM,
      zoom_click,
      goPaySerg,
    }
  },
  components: {
    LogoutIcon,
    modalSubscription
  },
  props: {
    client_avatar: {
      type: String,
      default() {
        return "";
      },
    },
    client_name: {
      type: String,
      default() {
        return "";
      },
    },
    client_docs: {
      type: String,
      default() {
        return "";
      },
    },
    client_docs_timeout: {
      type: String,
      default() {
        return "";
      },
    },
  }
};
</script>
<style>

.navbar {
  top: 0;
}

.zoom_btn {
  position: absolute;
    top: 2vh;
    left: 15vh;
    background-color: blue;
    color: white;
    width: 180px;
    height: 30px;
    border-radius: 3px;
}

.zoom_btn:hover{
  background-color: rgb(0, 0, 139);
}

.docs_left{
  font-size: 15px;
  color: white;
  cursor: default;
}

.docs_left::selection {
  background: transparent;
}

.docs_left::-moz-selection {
  background: transparent;
}

.logout__container {
    height: 110px !important;
    width: 225px;
    gap: 0 !important;
    padding-left: 0 !important;
    box-shadow: 0px 20px 22px 0px #B2BDE169;
}

.logout__container div {
  width: 225px;
}

.exit_btn {
  height: 20px !important;
  text-align: right;
  padding-right: 6px;
  padding-top: 4px;

  margin-right: 0px !important;
  margin-top: 25px !important;
}

.profile_pay {
  cursor: pointer;

  font-weight: 400;
  font-size: 14px;

  padding-bottom: 7px;
}

</style>
