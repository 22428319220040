<script lang="ts">
import Trash from '../assets/icons/trash.vue';
import { defineComponent, onMounted, onUnmounted, ref, reactive, computed } from "vue";
import {useDMStore} from "@/store/dm_obj";
import axios from "axios";
import {useRoute} from "vue-router";

export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
    is_open: {
      type: Boolean,
      default() {
        return true;
      }
    },
    id: {
      type: Number,
      default() {
        return 0;
      }
    },
    file_upload_name: {
      type: String,
      default() {
        return "";
      }
    },
    upload_text: {
      type: String,
      default() {
        return "";
      }
    }
  },
  emits: ["change-page"],
  components: {Trash},
  setup(props: {
    is_open: boolean;
    id: number;
    document: any;
    file_upload_name: string;
    upload_text: string;
  }) {

    const route = useRoute();
    const storeDM = useDMStore();

    const proc_upload = ref(false);

    let unicFile = ref(Math.random());
    const submitFile = (f_name: string, del: string = '0') => {
      //let file = Object(eval(f_name).value).files[0];
      proc_upload.value = true;

      let file = Object(eval("upload_file").value).files[0];
      if(del == '0'){
        if(file == undefined){
          return;
        }
      }

      let formData = new FormData();
      if(del == '0'){
        formData.append(f_name, file);
        formData.append("del", "0"); 
      } else {
        formData.append(f_name, "");
        formData.append("del", "1");
      }
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.query.id));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          props.document[Object(req.data).file_name] = Object(req.data)[
            req.data.file_name
            ];
          unicFile.value = Math.random();
          proc_upload.value = false;
          //console.log(props.document[Object(req.data).file_name]);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };


    const determineDragAndDropCapable = () => {
      var div = document.createElement('div');
      return ( ( 'draggable' in div )
          || ( 'ondragstart' in div && 'ondrop' in div ) )
        && 'FormData' in window
        && 'FileReader' in window;
    }

    const upload_file = ref<any | null>(null);
    const fileform = ref<HTMLElement | null>(null);
    const fileformuploaded = ref<HTMLElement | null>(null);

    onMounted(() => {

      const dragAndDropCapable = determineDragAndDropCapable();
      const th = this;
      if( dragAndDropCapable ) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
          fileform.value?.addEventListener(evt, function (e) {
            e.preventDefault();
            e.stopPropagation();
          }.bind(th), false);
        }.bind(this));
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
          fileformuploaded.value?.addEventListener(evt, function (e) {
            e.preventDefault();
            e.stopPropagation();
          }.bind(th), false);
        }.bind(this));
        fileform.value?.addEventListener('drop', function(e){
          upload_file.value["files"] = e.dataTransfer.files;
          submitFile(props.file_upload_name);
        }.bind(this));
        fileformuploaded.value?.addEventListener('drop', function(e){
          upload_file.value["files"] = e.dataTransfer.files;
          submitFile(props.file_upload_name);
        }.bind(this));
      }


    });

    const downloadFileFromURL = async (url, fileName) => {

      if(fileName != ""){
        await axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      }

    }

    return {
      submitFile,
      upload_file,
      fileform,
      fileformuploaded,
      unicFile,
      route,
      downloadFileFromURL,
      proc_upload,
    };
  },
});
</script>

<template>
  <span>Подпись должна быть на прозрачном фоне в формате png</span>
  <input
    ref="upload_file"
    type="file"
    hidden
    @change="submitFile(file_upload_name)"
  />
  <div ref="fileformuploaded" class="uploadFile" @click="$refs.upload_file.click()" :class="document[file_upload_name] == '' ? 'uploadhide' : ''"
       :style="'background: url(\'https://api.rekprof.ru/files/'+document[file_upload_name]+'?a='+unicFile+'\'); background-position: center; background-size: contain; background-repeat: no-repeat; border: 1px solid black;'"
  >
    <p>Перенесите сюда <br> подпись или нажмите <br> для загрузки</p>
  </div>
  <div ref="fileform" class="uploadFile" @click="$refs.upload_file.click()" :class="document[file_upload_name] != '' ? 'uploadhide' : ''">
    <p>Перенесите сюда <br> подпись или нажмите <br> для загрузки</p>
  </div>
  <div>
    <span class="filenameSpan">{{document[file_upload_name]==''?'не загружен':document[file_upload_name].split("/")[document[file_upload_name].split("/").length-1]}}</span>
    <span v-if="document[file_upload_name]!=''" class="downloadSpan" @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=fileupload&filename='+file_upload_name, document[file_upload_name].split('/')[document[file_upload_name].split('/').length-1]);">Скачать</span>
    <span v-if="document[file_upload_name]!=''" class="delSpan" @click="submitFile(file_upload_name, '1')"><Trash /></span>
  </div>
  <section v-if="proc_upload" class="dots-container">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </section>
</template>

<style scoped>
  .dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 171px;
    width: 480px;
    position: absolute;
    z-index: 1;
    flex-direction: initial !important;
    margin-top: 50px;
  }

  .dot {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #b3d4fc;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .dot:last-child {
    margin-right: 0;
  }

  .dot:nth-child(1) {
    animation-delay: -0.3s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.1s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.1s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }

    50% {
      transform: scale(1.2);
      background-color: #6793fb;
      box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }

    100% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
  }


  .uploadhide {
    display: none !important;
  }

  .filenameSpan {
    /* margin-top: 8px !important; */
    position: absolute;
    width: fit-content;
    display: flex;
  }

  .downloadSpan {
    cursor: pointer;
    display: flex;
    width: fit-content;
    position: absolute;
    margin-left: 413px;
  }

  .delSpan {
    cursor: pointer;
    display: flex;
    width: fit-content;
    position: absolute;
    margin-left: 470px;
  }
</style>