<template>
  <img alt="Rekprof logo" class="logo_min" src="../../assets/logo.png" />
  <v-menu-proxy-list></v-menu-proxy-list>
  <div class="inner_step_box">
    <span class="main_field">3.1.6 Добавление нового подписанта</span>
    <br />
    <span class="main_field">
      3.1.8 Введите паспортные данные нового подписанта
    </span>
    <div class="hint_left hint_mini_top">Номер</div>
    <input
      class="main_input"
      placeholder="Введите номер"
      v-model="storeDM.signatory_options_tpm.signatory_passport_number"
      maxlength="6"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <br />
    <div class="hint_left">Серия</div>
    <input
      class="main_input"
      placeholder="Введите серию"
      v-model="storeDM.signatory_options_tpm.signatory_passport_seria"
      maxlength="5"
      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 32"
    />
    <br />
    <div class="hint_left">Кем выдан</div>
    <input
      class="main_input"
      placeholder="Введите кем выдан"
      v-model="storeDM.signatory_options_tpm.signatory_passport_issued"
    />
    <br />
    <div class="hint_left">Дата выдачи</div>
    <input
      class="main_input"
      placeholder="Введите дату выдачи"
      v-model="storeDM.signatory_options_tpm.signatory_when_issued"
      maxlength="10"
      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
    />
    <br />
    <div class="hint_left">Код подразделения</div>
    <input
      class="main_input"
      placeholder="Введите код подразделения"
      v-model="storeDM.signatory_options_tpm.signatory_division_code"
      maxlength="7"
      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 45"
    />
    <br />
    <div class="hint_left">Где зарегистрирован</div>
    <input
      class="main_input"
      placeholder="Введите место регистрации"
      v-model="storeDM.signatory_options_tpm.signatory_passport_registration"
    />
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import VMenuProxyList from "@/components/v-menu-proxy-list.vue";
import { useDMDeclStore } from "@/store/dm_decl";

export default defineComponent({
  name: "ProxyStep7",
  emits: ["get_info_event"],
  props: {},
  components: { VMenuProxyList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMDeclStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "6",
      });
      router.push({
        path: "/proxy/step6/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.signatory_options_add = true;
      storeDM.set_info({
        step: "5",
      });
      router.push({
        path: "/proxy/step5/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
      /*
      if (
        storeDM.signatory_options_tpm.signatory_passport_number != "" &&
        storeDM.signatory_options_tpm.signatory_passport_seria != "" &&
        storeDM.signatory_options_tpm.signatory_passport_issued != "" &&
        storeDM.signatory_options_tpm.signatory_passport_registration != "" &&
        storeDM.signatory_options_tpm.signatory_when_issued != "" &&
        storeDM.signatory_options_tpm.signatory_division_code != ""
      ) {
        return true;
      } else {
        return false;
      }
      */
    });

    return {
      go_back,
      step_next,
      go_next,
      storeDM,
    };
  },
});
</script>

<style scoped></style>
