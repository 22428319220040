<template>
        <div class="step__basic">
          <div class="left">
            <p>2. Подача осуществляется самостоятельно или по доверенности</p>
            <Dropdown v-model="send_type_title"
                      editable :options="send_type_options"
                      optionLabel="name"
                      :placeholder="send_type_default.name"
                      style="width:520px"
                      @change="send_type_change"
            />
          </div>
        </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
  export default defineComponent ({
    props: {
      document: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    setup(
      props: {
        document: any;
      }, context
    ){
      const send_type_default = ref({ name: "Выберите способ подачи", value: 0 });
      const send_type_options = [
        { name: "По доверенности", value: 1 },
        { name: "Самостоятельно", value: 2 },
        //{ name: "Другое", value: "4" },
      ];

      const send_type_optionss = ref([
        { name: "По доверенности", value: 1 },
        { name: "Самостоятельно", value: 2 },
      ]);

      const send_type_title = computed(() => {
        if (props.document.send_type == 0) {
          return null;
        } else {
          return send_type_optionss.value[Number(props.document.send_type)-1];
        }
      });

      const page = ref(1);

      const send_type_change = (e) => {
        props.document.send_type = e.value.value;
      }

      return{
        page,
        send_type_default,
        send_type_options,
        send_type_title,
        send_type_change,
      }
    },
  });
</script>