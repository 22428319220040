<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8698 11.8281H1.32812V0.45313C1.32812 0.372922 1.2625 0.307297 1.18229 0.307297H0.161458C0.08125 0.307297 0.015625 0.372922 0.015625 0.45313V12.9948C0.015625 13.075 0.08125 13.1406 0.161458 13.1406H13.8698C13.95 13.1406 14.0156 13.075 14.0156 12.9948V11.974C14.0156 11.8938 13.95 11.8281 13.8698 11.8281ZM3.25677 9.01537C3.31328 9.07188 3.40443 9.07188 3.46276 9.01537L5.98385 6.50704L8.3099 8.84766C8.36641 8.90417 8.45938 8.90417 8.51589 8.84766L13.5362 3.82917C13.5927 3.77266 13.5927 3.67969 13.5362 3.62318L12.8143 2.90131C12.7869 2.87417 12.7499 2.85894 12.7113 2.85894C12.6728 2.85894 12.6357 2.87417 12.6083 2.90131L8.41562 7.09219L6.09323 4.75521C6.06582 4.72807 6.02881 4.71285 5.99023 4.71285C5.95166 4.71285 5.91465 4.72807 5.88724 4.75521L2.53672 8.08568C2.50958 8.11309 2.49435 8.1501 2.49435 8.18868C2.49435 8.22725 2.50958 8.26426 2.53672 8.29167L3.25677 9.01537Z"
      fill="#333333"
    />
  </svg>
</template>
