<template>
    <div class="steps">
        <button :class="{activeStep: subpage == 1}" @click="subpage = 1">Шаг 1</button>
        <button v-if="(document.app3_file_checked == 0 && document.construction_app6 == false) || (document.app6_file_checked == 0 && document.construction_app6)" :class="{activeStep: subpage == 2}" @click="subpage = 2">Шаг 2</button>
        <button v-if="(document.app3_file_checked == 0 && document.construction_app6 == false) || (document.app6_file_checked == 0 && document.construction_app6)" :class="{activeStep: subpage == 3}" @click="subpage = 3">Шаг 3</button>
        <button v-if="(document.app3_file_checked == 0 && document.construction_app6 == false) || (document.app6_file_checked == 0 && document.construction_app6)" :class="{activeStep: subpage == 4}" @click="subpage = 4">Шаг 4</button>
    </div>
    <div class="step__basic" v-if="subpage == 1">
      <div class="left">
        <section v-if="document.construction_app6 == false">
          <label for="title">
            <v-switch
              style="display: flex; flex-direction: row; justify-content: flex-start"
              color="#2F80ED"
              v-model="document.app3_file_checked"
              label="Использовать готовое приложение №3"
              inline
            ></v-switch>

            <div v-if="document.app3_file_checked == 1">
              <input
                ref="app3_file"
                type="file"
                accept=".pdf"
                hidden
                @change="submitFile('app3_file')"
              />

              <span>Нажмите чтобы загрузить готовое приложение</span>
              <button class="btn_upload" style="width: 480px;" @click="$refs.app3_file.click()">Загрузить файл приложения №3 на сервер</button>
              <span style="cursor: pointer;" @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app3_file', document.app3_file.split('/')[document.app3_file.split('/').length-1]);">Файл приложения №3 - {{document.app3_file.split('/')[document.app3_file.split('/').length-1] == "" ? 'не загружен' : document.app3_file.split('/')[document.app3_file.split('/').length-1]}}</span>
            </div>
          </label>
        </section>

        <section v-if="document.construction_app6">
          <label for="title">
            <v-switch
              style="display: flex; flex-direction: row; justify-content: flex-start"
              color="#2F80ED"
              v-model="document.app6_file_checked"
              label="Использовать готовое приложение №6"
              inline
            ></v-switch>

            <div v-if="document.app6_file_checked == 1">
              <input
                ref="app6_file"
                type="file"
                accept=".pdf"
                hidden
                @change="submitFile('app6_file')"
              />

              <span>Нажмите чтобы загрузить готовое приложение</span>
              <button class="btn_upload" style="width: 480px;" @click="$refs.app6_file.click()">Загрузить файл приложения №6 на сервер</button>
              <span style="cursor: pointer;" @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app6_file', document.app6_file.split('/')[document.app6_file.split('/').length-1]);">Файл приложения №6 - {{document.app6_file.split('/')[document.app6_file.split('/').length-1] == "" ? 'не загружен' : document.app6_file.split('/')[document.app6_file.split('/').length-1]}}</span>
            </div>
          </label>
        </section>
      </div>
    </div>
    <div class="uploadfile_photo" v-if="subpage == 2">
        <div class="left">
            <div>
                <label for="name">Добавьте горизонтальное обзорное фото вывески сбоку</label>
            </div>
            <FileUpload :document="document" :file_upload_name="'construction_perspective_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 604px x 402px'" />
        </div>
        <div class="right">
                <p style="margin-top: 18px;">Пример изображения</p>
                <img src="https://api.rekprof.ru/files/sample/spb/perspectiva.jpg" />
        </div>
    </div>
    <div class="uploadfile_photo" v-if="subpage == 3">
        <div class="left">
            <div>
                <label for="name">Добавьте горизонтальное обзорное фото вывески вблизи</label>
            </div>
            <FileUpload :document="document" :file_upload_name="'construction_around_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 604px x 402px'" />
        </div>
        <div class="right">
                <p style="margin-top: 18px;">Пример изображения</p>
                <img src="https://api.rekprof.ru/files/sample/spb/front_in.jpg" />
                <!--<img v-bind:src="require('../../assets/images/exampleMarket.jpg')" />-->
        </div>
    </div>
    <div class="uploadfile_photo" v-if="subpage == 4">
        <div class="left">
            <div>
                <label for="name">Добавьте горизонтальное обзорное фото вывески издалека</label>
            </div>
            <FileUpload :document="document" :file_upload_name="'construction_away_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 604px x 402px'" />
        </div>
        <div class="right">
          <p style="margin-top: 18px;">Пример изображения</p>
          <img src="https://api.rekprof.ru/files/sample/spb/front_out.jpg" />
        </div>
    </div>
</template>

<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../assets/icons/DropdownUp.vue";
import Map from '../../assets/icons/exampleMap.vue';
import FileUpload from  "@/components/FileUpload.vue";
import {defineComponent, ref} from "vue";
import axios from "axios";
import {useDMStore} from "@/store/dm_obj";
import {useRoute, useRouter} from "vue-router";
export default defineComponent({
    components: {
        DropdownIcon,
        DropdownUp,
        Map,
        FileUpload
    },
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(
      props: {
          document: any;
      }, context
    ){

        const storeDM = useDMStore();

        const route = useRoute();
        const router = useRouter();

        const downloadFileFromURL = async (url, fileName) => {

          if (fileName != "") {
            await axios
              .get(url, {responseType: "blob"})
              .then((response) => {
                const blob = new Blob([response.data], {});
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);
              })
              .catch(console.error);
          }
        }

        const app3_file = ref(null);
        const app6_file = ref(null);

        const submitFile = (f_name: string) => {
          let file = Object(eval(f_name).value).files[0];
          if(file == undefined){
            return;
          }

          let formData = new FormData();
          formData.append(f_name, file);
          formData.append("file_name", f_name);
          formData.append("hash", String(route.query.hash));
          formData.append("id", String(route.query.id));
          axios
            .post(storeDM.api_url + "upload.php", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (req) {
              props.document[Object(req.data).file_name] = Object(req.data)[
                req.data.file_name
                ];
              console.log(Object(req.data).file_name+" - "+Object(req.data)[req.data.file_name]);
            })
            .catch(function () {
              console.log("FAILURE!!");
            });
        };

        const subpage = ref(1);
        return{
          subpage,
          downloadFileFromURL,
          app3_file,
          app6_file,
          submitFile,
          route,
        }
    },
});
</script>