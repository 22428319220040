<template>
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.131461 12.402C0.262039 12.5985 0.481776 12.7172 0.71766 12.7172H11.2482C11.529 12.7172 11.7831 12.5501 11.8934 12.2918L13.9995 7.37751C14.0928 7.16058 14.0704 6.91206 13.9405 6.71479C13.8099 6.51752 13.5902 6.39888 13.3543 6.39888H12.6523V3.59073C12.6523 2.81639 12.0225 2.18666 11.2482 2.18666H7.66781L6.06436 0.932126C5.9408 0.835245 5.78916 0.782593 5.63191 0.782593H1.4197C0.645351 0.782593 0.015625 1.41232 0.015625 2.18666V12.0152H0.0205392C0.0205392 12.1499 0.054939 12.2854 0.131461 12.402ZM10.7855 11.3131H1.78265L3.28711 7.80295H12.29L10.7855 11.3131ZM11.2482 3.59073V6.39888H2.82377C2.54295 6.39888 2.28882 6.56596 2.1786 6.82431L1.4197 8.59554V3.59073H11.2482Z"
      fill="#333333"
    />
  </svg>
</template>
