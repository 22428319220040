<template>
    <div class="step__one">
        <div class="left">
            <div>
                <label for="name">Введите фамилию</label>
                <input class="input" type="text" placeholder="Введите фамилию" v-model="document.signatory_lastname">
            </div>
            <div>
                <label for="name">Введите имя</label>
                <input class="input" type="text" placeholder="Введите имя" v-model="document.signatory_firstname">
            </div>
            <!--
            <div>
                <label for="name">Введите должность</label>
                <span>В лице кого подаём. Пример: Генерального директора</span>
                <input type="text" placeholder="Введите должность">
            </div>
            <div>
                <label for="name">Введите ФИО в родительном падеже</label>
                <span>В лице кого подаём. Пример: Иванова Ивана Ивановича</span>
                <input type="text" placeholder="Введите ФИО в родительном падеже">
            </div>
            -->
            <section>
                <label for="title">Загрузите или перенесите сюда файл подписи</label>
                <FileUploadMini :document="document" :file_upload_name="'signatory_file'" :upload_text="'Подпись должны быть на прозрачном фоне в формате png'" />
                <!--
                <span>Подпись должны быть в формате png, размером не менее 100 × 100 px</span>
                <input
                  ref="signatory_file"
                  type="file"
                  hidden
                  @change="submitFile('signatory_file', signatory.id)"
                />
                <div v-if="signatory.signatory_file != ''" class="uploadFile" @click="$refs.signatory_file.click()"
                     :style="'background: url(\'https://api.rekprof.ru/files/'+signatory.signatory_file+'\'); background-position: center; background-size: contain; background-repeat: no-repeat; border: 1px solid black;'"
                >
                    <p>Перенесите сюда <br> подпись или нажмите <br> для загрузки</p>
                </div>
                <div v-if="signatory.signatory_file == ''" class="uploadFile" @click="$refs.signatory_file.click()">
                  <p>Перенесите сюда <br> подпись или нажмите <br> для загрузки</p>
                </div>
                -->
            </section>

            <div v-if="document.signatory_parent != 0">
                <button
                style="
                width: 83px;
                height: 46px;
                position: absolute;
                top: 779px;
                border-radius: 4px;
                margin-left: 0px;
                font-size: 14px;
                background-color: #2F80ED;
                color: white;
                "
                @click="go_doc">Назад</button>
            </div>
        </div>
        <div class="right" style="margin-top: -11px; justify-content: initial;">
            <section>
               <!-- <label for="title"> -->
                    <v-switch
                      style="display: flex; flex-direction: row; justify-content: flex-start"
                      color="#2F80ED"
                      v-model="document.signatory_skip_patronymic"
                      label="Отчество"
                    ></v-switch>
               <!-- </label> -->
                <input class="input lastname" type="text" placeholder="Введите отчество" v-model="document.signatory_patronymic">
            </section>
            <section class="gender" style="margin-top: 24px;">
                <label for="gender">Выберите пол</label>
                <v-radio-group
                  v-model="document.signatory_genre"
                  inline>
                    <v-radio
                      color="#2F80ED"
                      label="Мужчина"
                      value="1">
                    </v-radio>
                    <v-radio
                      color="#2F80ED"
                      label="Женщина"
                      value="2">
                    </v-radio>
                </v-radio-group>
            </section>
        </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import axios from "axios";
import FileUploadMini from  "@/components/FileUploadMini.vue";
import { useDMStore } from "@/store/dm_obj";
import { useRoute, useRouter } from "vue-router";
import DropdownIcon from "@/assets/icons/dropdownIcon.vue";

export default defineComponent({
    emits: ["go-doc"],
    components: {
      FileUploadMini,
    },
    props: {
      document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(
      props: {
          document: any;
      }, context
    ){
        const storeDM = useDMStore();
        const route = useRoute();
        const router = useRouter();

        const signatory_file = ref(null);

        const inline = ref(null);

        const submitFile = (f_name: string, id: string) => {
            let file = Object(eval(f_name).value).files[0];
            if(file == undefined){
              return;
            }

            let formData = new FormData();
            formData.append(f_name, file);
            formData.append("file_name", f_name);
            formData.append("hash", String(route.query.hash));
            formData.append("id", id);
            axios
              .post(storeDM.api_url + "upload.php", formData, {
                  headers: {
                      "Content-Type": "multipart/form-data",
                  },
              })
              .then(function (req) {
                  if (f_name == "signatory_file") {
                      props.document.signatory_file = Object(req.data)[req.data.file_name];
                  }
              })
              .catch(function () {
                  console.log("FAILURE!!");
              });
        };

        const go_doc = () => {
          context.emit("go-doc");
        };

        return{
            inline,
            submitFile,
            signatory_file,
            go_doc
        }
    }
});
</script>