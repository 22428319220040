<script lang="ts">
  import { defineComponent, onMounted, onUnmounted, ref, reactive, computed } from "vue";
  import {useDMStore} from "@/store/dm_obj";
  import {useRoute, useRouter} from "vue-router";

  export default defineComponent({
    props: {
      is_open: {
        type: Boolean,
        default() {
          return true;
        }
      },
      id: {
        type: Number,
        default() {
          return 0;
        }
      },
    },
    emits: ["change-page"],
    setup(props: {
      is_open: boolean;
      id: number;
    }) {
      const isOpen = ref(props.is_open);
      const router = useRouter();
      const route = useRoute();

      const close = () => {
        isOpen.value = false;
      }

      const goPaySerg = () => {
        window.location.href = "https://rekdoc.rekprof.ru/logon.php?hash="+route.query.hash+"&type=document&id="+route.query.id+"&region="+route.query.region+"&construction="+route.query.construction+"&step="+route.query.step+"&mode="+route.query.mode;
        /*
        router.push({
          path: "https://recdoc.profcoding.ru/logon.php",
          query: {
            hash: route.query.hash,
            type: "document",
            id: route.query.id,
            region: route.query.region,
            construction: route.query.construction,
            step: route.query.step,
            mode: route.query.mode,
          },
        });
        */
      }

      return {
        close,
        isOpen,
        goPaySerg,
      };
    },
  });
</script>

<template>
  <div class="modal_window" v-if="isOpen">
    <div class="close" @click="close">✖</div>
    <div class="navbar__logo logo_modal">
      <svg width="37" height="42" viewBox="0 0 37 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.3828 1.69112C26.1457 2.87522 28.1192 4.05933 30.0928 6.03284C32.0663 8.00636 33.6451 9.97987 34.8292 12.7428C36.0133 15.5057 36.408 17.8739 36.408 20.6368C36.408 23.3998 36.0133 26.1627 34.8292 28.5309C33.6451 30.8991 32.0663 33.662 30.0928 35.2408C28.1192 37.2144 25.751 38.3985 23.3828 39.5826C21.0146 40.7667 17.857 41.1614 15.0941 41.1614H0.884766V0.112305H15.0941C17.857 0.112305 20.6199 0.507007 23.3828 1.69112Z" fill="#2F80ED"/>
        <path d="M7.20027 27.7414C8.94417 27.7414 10.3579 26.3277 10.3579 24.5838C10.3579 22.8399 8.94417 21.4261 7.20027 21.4261C5.45636 21.4261 4.04265 22.8399 4.04265 24.5838C4.04265 26.3277 5.45636 27.7414 7.20027 27.7414Z" fill="white"/>
        <path d="M12.7256 19.0582C14.4695 19.0582 15.8832 17.6444 15.8832 15.9005C15.8832 14.1566 14.4695 12.7429 12.7256 12.7429C10.9817 12.7429 9.56796 14.1566 9.56796 15.9005C9.56796 17.6444 10.9817 19.0582 12.7256 19.0582Z" fill="white"/>
        <path d="M17.8566 27.7414C19.6006 27.7414 21.0143 26.3277 21.0143 24.5838C21.0143 22.8399 19.6006 21.4261 17.8566 21.4261C16.1127 21.4261 14.699 22.8399 14.699 24.5838C14.699 26.3277 16.1127 27.7414 17.8566 27.7414Z" fill="white"/>
        <path d="M23.3823 19.0582C25.1262 19.0582 26.5399 17.6444 26.5399 15.9005C26.5399 14.1566 25.1262 12.7429 23.3823 12.7429C21.6384 12.7429 20.2247 14.1566 20.2247 15.9005C20.2247 17.6444 21.6384 19.0582 23.3823 19.0582Z" fill="white"/>
        <path d="M28.5137 27.7414C30.2576 27.7414 31.6713 26.3277 31.6713 24.5838C31.6713 22.8399 30.2576 21.4261 28.5137 21.4261C26.7698 21.4261 25.3561 22.8399 25.3561 24.5838C25.3561 26.3277 26.7698 27.7414 28.5137 27.7414Z" fill="white"/>
      </svg>
      <div class="logo_txt">REKDOC</div>
    </div>
    <div class="end_tariff_txt">Подписка на создание документов закончилась</div>
    <div class="change_tariff_txt"><div style="width: 260px;">Для продолжения работы, выберите удобный тариф</div></div>
    <div class="wrapper_btn"><button class="change_tariff_btn" @click="goPaySerg">Перейти к выбору тарифа</button></div>
  </div>
  <div class="modal_block" v-if="isOpen">
  </div>
</template>

<style scoped>
  @font-face {
    font-family: "MontserratBlack";
    src: local("Montserrat"), url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
  }

  .close {
    cursor: pointer;
    color: #2F80ED;
    width: 14px;
    line-height: 14px;
    margin-left: 327px;
    margin-top: 10px;
  }

  .close:hover {
    color: #1D76EF;
  }

  .change_tariff_btn {
    width: 285px;
    height: 48px;
    border-radius: 2px;
    background-color: #2F80ED;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    color: white;
  }

  .change_tariff_btn:hover {
    background-color: #1D76EF;
  }

  .wrapper_btn {
    justify-content: center;
    flex-direction: row !important;
    margin-top: 10px;
  }

  .change_tariff_txt {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;

    justify-content: center;
    flex-direction: row !important;
  }

  .end_tariff_txt {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10px;
  }

  .logo_txt {
    font-family: MontserratBlack;
    font-size: 26px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #2F80ED;

    width: 130px;
  }

  .logo_modal {
    justify-content: center;
    flex-direction: row !important;
  }

  .modal_window {
    width: 350px;
    height: 270px;
    border-radius: 2px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    background-color: white;
    position: absolute;
    margin: auto auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .modal_block{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    z-index: 0;
    background-color: rgba(50,50,50, 0.5);
  }
</style>