<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, reactive, computed } from "vue";
import {useDMStore} from "@/store/dm_obj";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  props: {
    is_open: {
      type: Boolean,
      default() {
        return true;
      }
    },
    unstep: {
      type: Boolean,
      default() {
        return false;
      }
    },
    id: {
      type: Number,
      default() {
        return 0;
      }
    },
    info_num: {
      type: String,
      default() {
        return '';
      }
    },
    info_txt: {
      type: String,
      default() {
        return '';
      }
    },
  },
  emits: ["do_btn", "skip_btn","unskip_change"],
  setup(props: {
    is_open: boolean;
    id: number;
    info_num: string;
    unstep: boolean;
  }, context) {
    const storeDM = useDMStore();

    const isOpen = ref(props.is_open);
    const router = useRouter();
    const route = useRoute();
    
    const unstep = ref(props.unstep);

    const close = () => {
      isOpen.value = false;
    }

    const do_btn = () => {
      //context.emit("do_btn", props.info_num);
      close();
    }

    const skip_btn = () => {
      close();
      context.emit("skip_btn", props.info_num);
    }

    const change_btn = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "edit_skip",
          id: route.query.id,
          hash: route.query.hash,
          unstep: unstep.value,
        }),
      });
      const response_data = await response.json();
      context.emit("unskip_change", response_data.unstep);
    }

    return {
      close,
      isOpen,
      do_btn,
      unstep,
      skip_btn,
      change_btn,
    };
  },
});
</script>

<template>
  <div class="modal_window" v-if="isOpen">
    <div class="close" @click="close">✖</div>
    <div class="info_txt"><div style="width: 225px; margin-top: 102px; margin-left: 62.5px;">Для корректного формирования документа нужно заполнить все обязательные поля</div></div>
    <div style="width: 248px; text-align: center; margin-left: 51px; line-height: 0px; margin-top: 27px;"><span>Пожалуйста, заполните подсвеченные поля на текущем шаге создания заявления</span></div>
    <div class="wrapper_btn"><button class="do_btn" @click="do_btn">Заполнить</button><div style="width:16px; display: inline-block;"></div><button class="skip_btn" @click="skip_btn">Пропустить</button></div>
    <section class="unskip">
        <v-switch
                style="display: flex; flex-direction: row; justify-content: flex-start"
                color="#2F80ED"
                v-model="unstep"
                label="Не показывать предупреждение в будущем"
                inline
                @change="change_btn"
        ></v-switch>
    </section>
  </div>
  <div class="modal_block" v-if="isOpen">
  </div>
</template>

<style scoped>
@font-face {
  font-family: "MontserratBlack";
  src: local("Montserrat"), url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

.close {
  cursor: pointer;
  color: #2F80ED;
  width: 14px;
  line-height: 14px;
  margin-left: 327px;
  margin-top: 10px;
}

.close:hover {
  color: #1D76EF;
}

.unskip {
    width: 310px;
    text-align: left;
    margin-left: 20px;
    margin-top: 12px;
}

.v-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: black;
    opacity: 1;
}
.v-selection-control-group {
    margin-left: -10px;
    display: flex;
    gap: 3rem;
    padding: 0;
    height: 48px;
}


.do_btn {
  width: 147px;
  height: 48px;
  border-radius: 2px;
  background-color: #2F80ED;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: white;

  margin-top: 8px;
}

.skip_btn {
  width: 147px;
  height: 48px;
  border-radius: 2px;
  background-color: white;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #2F80ED;

}

.do_btn:hover {
  background-color: #1D76EF;
}

.wrapper_btn {
  justify-content: center;
  flex-direction: row !important;
  margin-top: 10px;
}

.info_txt {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  justify-content: center;
  flex-direction: row !important;
}

.end_tariff_txt {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
}

.logo_txt {
  font-family: MontserratBlack;
  font-size: 26px;
  font-weight: 900;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: #2F80ED;

  width: 130px;
}

.logo_modal {
  justify-content: center;
  flex-direction: row !important;
}

.modal_window {
  width: 350px;
  height: 380px;
  border-radius: 2px;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  background-color: white;
  position: absolute;
  /* margin: auto auto; */
  left: 50%;
  right: 0;
  top: 7%;
  bottom: 0;
  z-index: 1;
  transform: translate(-50%, 0%);
}

.modal_block{
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index: 0;
  background-color: rgba(50,50,50, 0.5);
}
</style>