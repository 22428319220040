<template>
    <div class="create__signatories">
        <div class="white__navbar">
            <!--
              <p>Подписанты</p>
              <p>/</p>
            -->
            <p style="cursor: pointer;" v-if="document.signatory_parent != 0 && document.signatory_parent_type=='proxy'" @click="open_doc">
              Доверенность №{{ document.signatory_parent }}
            </p>
            <p style="cursor: pointer;" v-if="document.signatory_parent != 0 && document.signatory_parent_type=='document'" @click="open_doc">
              Заявление №{{ document.signatory_parent }}
            </p>
            <p v-if="document.signatory_parent != 0">/</p>
            <p>Подписант {{document.signatory_lastname+' '+document.signatory_firstname+' '+document.signatory_patronymic}}</p>
        </div>
        <div class="create__signatories__content">
          <div class="content">
            <!--
            <div class="steps">
              <button :class="{activeStep: page === 1}" @click="page = 1">Шаг 1</button>
              <button :class="{activeStep: page === 2}" @click="page = 2">Шаг 2</button>
            </div>
            <div class="example">
              <p>Примеры отображения</p>
              <div class="rectangle">
                <svg width="140" height="203" viewBox="0 0 140 203" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="5.75049" y="6.77979" width="128" height="188" stroke="black"/>
                  <rect x="56.3379" y="53.0396" width="29.9125" height="8" fill="#2F80ED"/>
                  <rect x="39.0034" y="63.7476" width="64.5819" height="8" fill="#2F80ED"/>
                  <rect x="39.0034" y="135.75" width="64.5819" height="8" fill="#2F80ED"/>
                  <rect x="39.0034" y="74.4556" width="64.5819" height="8" fill="#2F80ED"/>
                  <rect x="94.2505" y="13.7798" width="31.9623" height="8" fill="#E0E0E0"/>
                  <rect x="94.2505" y="26.1353" width="31.9623" height="8" fill="#E0E0E0"/>
                  <rect x="94.2505" y="38.4907" width="31.9623" height="8" fill="#E0E0E0"/>
                </svg>
              </div>
            </div>
            -->
      </div>
<StepOne v-if="page === 1" :document="document" @go-doc="open_doc" />
<StepTwo v-if="page === 2" :document="document" @save="signatory_save" @edit-signatory="edit_signatory" @edit-proxy="edit_proxy" />
</div>
      <pagination
        v-model="page"
        :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
        :page_arr="[
          {name: '1', title: 'ФИО доверителя', complete: ['signatory_lastname','signatory_firstname']},
          {name: '2', title: 'Паспортные данные', complete: ['signatory_passport_seria','signatory_passport_number','signatory_when_issued','signatory_passport_issued','signatory_division_code','signatory_passport_registration']},
        ]"
        :nav_btn="true"
        :page="page"
        :document="document"
        @change-page="change_page"
      />
      <!--
<vue-awesome-paginate
    class="right-pagination"
    :total-items="2"
    :items-per-page="1"
    :max-pages-shown="77"
    v-model="page"
    prev-button-content="Назад"
    next-button-content="Далее"
    @click="next_button()"
    :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
  />
  -->
        </div>
</template>

<script lang="ts">
  import StepOne from './StepOne.vue'
  import StepTwo from './StepTwo.vue'
  import {computed, defineComponent, reactive, ref} from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useDMStore } from "@/store/dm_obj";
  import Pagination from "@/components/Pagination.vue";
  export default defineComponent({
    components: {
      Pagination,
      StepOne,
      StepTwo
    },
    emits: ["save-signatory", "edit-signatory", "edit-proxy", "edit-doc"],
    setup(props, context){
      const storeDM = useDMStore();
      let router = useRouter();
      const route = useRoute();

      const pagination_bottom_left = computed(() => {
        if(storeDM.rail == false){
          return '232px;';
        } else {
          return '80px;';
        }
      });

      let initDM = false;

      const page = ref(1);

      const signatory_list = ref([]);

      const document = reactive({
        id: 0,
        signatory_lastname: "",
        signatory_firstname: "",
        signatory_patronymic: "",
        signatory_passport_number: "",
        signatory_passport_seria: "",
        signatory_skip_patronymic: 0,
        signatory_passport_issued: "",
        signatory_when_issued: "",
        signatory_division_code: "",
        signatory_passport_registration: "",
        signatory_genre: "",
        signatory_file: "",
        signatory_parent: 0,
        signatory_parent_type: "",
        signatory_parent_step: 0,
      });
      //console.log(777);
      //console.log(signatory);


      const signatory_save = async (document, close: boolean = false) => {
        context.emit("save-signatory", document, true, route.query.id);
      };


      const get_info_server = async() => {
        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "get_signatory",
            hash: route.query.hash,
            id: route.query.id,
          }),
        });

        const response_data = await response.json();

        if (response_data.found == 0) {
          router.push({
            path: "/",
          });
          return;
        }

        document.id = Number(response_data.id);
        document.signatory_lastname = response_data.signatory_lastname;
        document.signatory_firstname = response_data.signatory_firstname;
        document.signatory_patronymic = response_data.signatory_patronymic;
        document.signatory_passport_number = response_data.signatory_passport_number;
        document.signatory_passport_seria = response_data.signatory_passport_seria;
        document.signatory_skip_patronymic = response_data.signatory_skip_patronymic;
        document.signatory_passport_issued = response_data.signatory_passport_issued;
        document.signatory_when_issued = response_data.signatory_when_issued;
        document.signatory_division_code = response_data.signatory_division_code;
        document.signatory_passport_registration = response_data.signatory_passport_registration;
        document.signatory_genre = response_data.signatory_genre;
        document.signatory_file = response_data.signatory_file;
        document.signatory_parent = response_data.signatory_parent;
        document.signatory_parent_type = response_data.signatory_parent_type;
        document.signatory_parent_step = response_data.signatory_parent_step;
      };

      setTimeout(get_info_server,200);

      function next_button(){
        //console.log(15);
        context.emit("save-signatory", document, null, route.query.id);
        //console.log(page.value);
      }

      const change_page = (page_new_set: number) => {
        page.value = page_new_set;
        next_button();
      }

      function edit_signatory(){
        context.emit("edit-signatory");
      }

      function edit_proxy(){
        context.emit("edit-proxy");
      }

      const update_page_query = () => {
        router.push({
          query: {
            hash: route.query.hash,
            type: document.signatory_parent_type,
            id: route.query.id,
            step: page.value,
          },
        });

        context.emit("save-signatory", document, true, route.query.id);
        //send_info_server(0);
      };

      const open_doc = () => {
        let parent = document.signatory_parent;
        let parent_step = document.signatory_parent_step;

        document.signatory_parent = 0;
        document.signatory_parent_step = 0;
        update_page_query();

        console.log(document.signatory_parent_type);

        router.push({
          query: {
            hash: route.query.hash,
            type: document.signatory_parent_type,
            id: parent,
            step: parent_step,
            mode: "edit",
          },
        });

        if (document.signatory_parent_type == "proxy") {
          edit_proxy();
        } else if (document.signatory_parent_type == "document") {
          edit_doc();
        }
      }

      const edit_doc = () => {
        //setTimeout(edit_doc_to,1000);
        context.emit("edit-doc");
      };

      const edit_doc_to = () => {
        //context.emit("save-signatory", document, true, route.query.id);
        //context.emit("edit-doc");
      };

      return{
        page,
        document,
        signatory_save,
        next_button,
        edit_signatory,
        edit_proxy,
        pagination_bottom_left,
        change_page,
        open_doc,
        edit_doc,
        edit_doc_to,
      }
    }
  });
</script>

<style>
.create__signatories__content {
	height: 719px !important;
}
</style>