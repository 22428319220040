<template>
  <div class="uploadfile_photo">
    <div class="left">
      <section>
        <label for="name">Добавьте фото эскизного проекта</label>
      </section>
      <FileUpload :document="document" :file_upload_name="'sketchmini_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 3480px x 2320px'" />
    </div>
    <div class="right">
      <p style="margin-top: -6px;">Пример изображения</p>
      <img src="https://api.rekprof.ru/files/sample/mo/sketchmini.png" />
    </div>
  </div>
</template>

<script lang="ts">
import DropdownIcon from "../../../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../../../assets/icons/DropdownUp.vue";
import Map from '../../../../assets/icons/exampleMap.vue';
import FileUpload from  "@/components/FileUpload.vue";
import {defineComponent, ref} from "vue";
import axios from "axios";
import {useDMStore} from "@/store/dm_obj";
import {useRoute} from "vue-router";
export default defineComponent({
  components: {
    DropdownIcon,
    DropdownUp,
    Map,
    FileUpload
  },
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){
    const subpage = ref(1);
    return{
      subpage,
    }
  },
});
</script>