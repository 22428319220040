<template>
  <div class="register">
    <div class="logo">
      <LoginLogo />
      <h2>DOCUMENT <br> MAKER</h2>
    </div>
    <p>Регистрация</p>
    <div class="form">
      <div>
        <label for="email">Введите E-mail (на него будет отправлен пароль)</label>
        <input type="email" placeholder="nevis@yandex.ru" maxlength="50" v-model="email">
      </div>
      <div>
        <label for="email">Введите телефон в формате 79008007060</label>
        <input type="email" placeholder="79008006070" maxlength="11" onkeypress="return event.charCode >= 48 && event.charCode <= 57" v-model="phone">
      </div>
      <div>
        <input
          v-if="phone_check == 1"
          class="main_input"
          placeholder="Введите код подтверждения"
          maxlength="6"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          v-model="phone_code"
        />
        <button
          type="button"
          @click="check_phone()"
          style="margin-left: 10px"
          :disabled="!step_phone"
          :class="{ active_btn: step_phone }"
          v-if="!step_next"
        >
          {{ name_check_phone }}
        </button>
        <div
          class="hint_left"
          style="width: 300px"
          :class="{ red_color: phone_check == 0 || phone_check == 3 }"
        >
          {{ phone_status }}
        </div>
        <!--
        <button
          type="button"
          @click="send_pwd()"
          style="margin-left: 10px"
          :disabled="!step_next"
          :class="{ active_btn: step_next }"
          v-if="step_next"
        >
          Отправить пароль на E-mail
        </button>
        -->
      </div>
      <button type="button" class="button_back" @click="go_back()" v-if="step_next">Далее</button>
      <button type="button" class="button_back" @click="go_back()" v-if="!step_next">Назад</button>
    </div>


  </div>

  <!--
  <img alt="Rekprof logo" class="logo_min" src="../assets/logo.png" />
  <div class="inner_box">
    <span class="main_field">
      Введите ваш E-mail (на него будет отправлен пароль)
    </span>
    <input
      class="main_input"
      placeholder="Введите E-mail"
      maxlength="50"
      v-model="email"
    />
    <br />
    <br />
    <br />
    <span class="main_field">Введите телефон</span>
    <input
      class="main_input"
      placeholder="Введите номер телефона в формате - 79008007060"
      maxlength="11"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
      v-model="phone"
    />
    <div class="hint_left">
      Проверка, что телефон действительно существует и принадлежит именно вам
    </div>
    <button
      type="button"
      class="blue_next_btn"
      @click="check_phone()"
      style="margin-left: 10px"
      :disabled="!step_phone"
      :class="{ active_btn: step_phone }"
    >
      {{ name_check_phone }}
    </button>
    <input
      v-if="phone_check == 1"
      class="main_input"
      placeholder="Введите код подтверждения"
      maxlength="6"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
      v-model="phone_code"
    />
    <div
      class="hint_left"
      :class="{ red_color: phone_check == 0 || phone_check == 3 }"
    >
      {{ phone_status }}
    </div>
    <br />
    <br />
    <br />
    <button
      type="button"
      class="blue_next_btn"
      @click="send_pwd()"
      style="margin-left: 10px"
      :disabled="!step_next"
      :class="{ active_btn: step_next }"
      v-if="step_next"
    >
      Отправить пароль (пароль будет отправлен на E-mail)
    </button>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  -->
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";
import LoginLogo from '../assets/icons/mainLogo.vue'

export default defineComponent({
  name: "SignUp",
  components: {
    LoginLogo
  },
  setup() {
    const phone = ref("");
    const email = ref("");
    const phone_code = ref("");

    const phone_status = ref(" ");
    const phone_check = ref(0);

    const storeDM = useDMStore();

    const router = useRouter();

    const go_back = () => {
      router.go(-1);
    };

    const step_phone = computed(() => {
      if (phone.value.length == 11 && validateEmail(email.value)) {
        return true;
      } else {
        return false;
      }
    });

    const step_next = computed(() => {
      if (phone_check.value == 2) {
        //if (validateEmail(email.value)) {
        return true;
      } else {
        return false;
      }
      //return false;
      /*
      if (
        storeDM.save_obj.client_type != 0 &&
        storeDM.save_obj.client_inn != ""
      ) {
        return true;
      } else {
        return false;
      }
      */
    });

    const name_check_phone = computed(() => {
      if (phone_check.value == 0) {
        return "Подтвердить номер телефона";
      } else if (phone_check.value == 1) {
        return "Проверить код";
      } else if (phone_check.value == 2) {
        return "Телефон подтверждён";
      } else if (phone_check.value == 3) {
        return "Отправить новый код";
      } else {
        return "Подтвердить номер телефона";
      }
    });

    const check_phone = async () => {
      if (phone_check.value == 0 || phone_check.value == 3) {
        const response = await fetch(storeDM.api_url + "check_phone.php", {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "send_code",
            email: email.value,
            phone: phone.value,
          }),
        });

        const response_data = await response.json();
        if (
          response_data.status == "code_sender" ||
          response_data.status == "code_sender_update"
        ) {
          phone_check.value = 1;
          phone_status.value = "Код подтверждения отправлен на ваш телефон";
        } else if (response_data.status == "code_duplicate") {
          phone_status.value =
            "Аккаунт с таким E-mail или телефоном уже существует";
        }
      } else if (phone_check.value == 1) {
        const response = await fetch(storeDM.api_url + "check_phone.php", {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "check_code",
            email: email.value,
            phone: phone.value,
            phone_code: phone_code.value,
          }),
        });

        const response_data = await response.json();
        if (response_data.status == "code_correct") {
          phone_check.value = 2;
          phone_status.value = "Телефон прошёл проверку";

          const response = await fetch(storeDM.api_url + "api2.php", {
            method: "POST",
            //mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "send_pwd",
              email: email.value,
              phone: phone.value,
              phone_code: phone_code.value,
            }),
          });

          phone_status.value = "Пароль отправлен на email";

        } else if (response_data.status == "code_incorrect") {
          phone_check.value = 3;
          phone_status.value =
            "Телефон не прошёл проверку. Код введён с ошибкой";
        }
        //console.log(response_data);
      }
    };

    const validateEmail = (email: string) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const send_pwd = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "send_pwd",
          email: email.value,
          phone: phone.value,
          phone_code: phone_code.value,
        }),
      });

      //const response_data = await response.json();
      //console.log(response_data);
      router.push({
        path: "/",
      });
    };

    const go_step1 = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "clear_hash",
        }),
      });

      const response_data = await response.json();

      storeDM.hash = response_data["hash"];
      storeDM.docID = response_data["id"];

      storeDM.get_info_server();

      router.push({
        path: "/step1/" + storeDM.docID,
        query: {
          hash: storeDM.hash,
        },
      });
    };

    return {
      go_back,
      go_step1,
      step_next,
      phone,
      email,
      step_phone,
      check_phone,
      phone_code,
      phone_check,
      name_check_phone,
      phone_status,
      send_pwd,
    };
  },
});
</script>

<style scoped lang="scss"></style>
