<template>
  <div class="drafts">
    <div class="white__navbar">
      <!--
      <p>Личный кабинет</p>
      <p>/</p>
      -->
      <p>Черновики</p>
    </div>
    <div class="drafts__content">
      <div class="search">
        <input type="text" placeholder="Поиск" v-model="search_value">
      </div>
      <div class="data">
      <v-table
    fixed-header
  >

    <thead>
      <tr style="color: rgba(0, 0, 0, 0.4509803922);">
        <th class="text-left" style="width: 76px;">
          №
        </th>
        <th class="text-left" style="width:120px;">
          Дата создания
        </th>
        <th class="text-left" style="width:160px;">
          Дата редактирования
        </th>
        <th class="text-left" style="width:280px;">
          Юридическое лицо
        </th>
        <th class="text-left">
          Адрес
        </th>
        <th class="text-left" style="width:110px;">
          Статус
        </th>
        <th class="text-left" style="width:80px;"></th>
        <th class="text-left" style="width:62px;">
          Избранное
        </th>
        <th class="text-left" style="width:62px">
          Операции
        </th>
      </tr>
    </thead>
    <tbody style="text-align: left;">
      <tr
        v-for="(input, index) in doc_favorite_lists"
        :key="index"
      >
        <td style="width: 76px;">{{ input.id }}</td>
        <td>{{ input.date }}</td>
        <td>{{ input.date_edit }}</td>
        <td>{{ input.client_name }}</td>
        <td>{{ input.address }}</td>
        <td class="text-left">
          <div v-if="input.complete=='1'" style="font-weight: bold; color: #2F80ED;">Готово</div>
          <div v-if="input.complete=='0'">Шаг {{ input.step }}</div>
          <div v-if="input.complete=='0'">
            <div v-for="n in Number(input.step_all)">
              <div
                style="width: 4px; height: 4px; background-color: red; float: left;"
                :style="(n>1?'margin-left: 2px; ':'')
                +
                (1==Number(input.step_arr[n-1])?'background-color: #03CB98;':'')
                +
                (n==Number(input.step) && 1==Number(input.step_arr[0]) ?'background-color: #2F80ED;':'')
                +
                (n==1 && 0==Number(input.step_arr[0])?'background-color: #2F80ED;':'')
                "></div>
            </div>
          </div>
        </td>
        <td class="text-left" style="text-align: center !important;">
          <span v-if="input.complete == '1'"
          style="cursor:pointer; color: #2F80ED; text-decoration: underline;"
          @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+input.id+'&hash='+route.query.hash+'&type=document', 'дизайн проект №'+input.id+'.pdf')"
          >Скачать</span>
          <span v-else>
            <Info style="margin-top: 6px; cursor: pointer;" @click="modalStat(input.id, input.step, input.step_all, input.step_arr, input.step_text)" />
          </span>
        </td>
        <td class="text-left" style="text-align: center !important;"><Star style="cursor: pointer;" @click="edit_favorite(input.id, input.hash, 1)" /></td>
        <td style="width:62px;">
          <div class="dots" @click="context_detail=input.id"><Dots  /></div>
          <div v-if="context_detail==input.id"
               style="position: absolute;
                      width: 200px;
                      margin-left: -180px;
                      margin-top: -35px;
                      z-index: 1;
                      text-align: center;
                      background: white;
                      -webkit-user-select: none; /* Safari */
                      -moz-user-select: none; /* Firefox */
                      -ms-user-select: none; /* IE10+/Edge */
                      user-select: none; /* Standard */
                      box-shadow: 0px 20px 22px 0px #B2BDE169;
                    ">
            <div @click="context_detail=0" style="text-align: right;"><LogoutIcon style="cursor: pointer;" /></div>
            <div style="cursor: pointer;" @click="open_doc(input.id)">Формирование заявления</div>
            <div style="cursor: pointer;" @click="copy_doc(input.id)">Дублировать заявление</div>
            <div style="cursor: pointer;" @click="edit_favorite(input.id, input.hash, 1)">Удалить из черновиков</div>
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
</div>
</div>
<vue-awesome-paginate
    :total-items="doc_favorite_list.length"
    :items-per-page="pages_on_page"
    :max-pages-shown="17"
    v-model="page"
    prev-button-content="Назад"
    next-button-content="Далее"
    :style="
		'position: relative; float: left; transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); margin-top: -0.5rem; top: 0px; left: 0px; margin-left: 1.5rem;'
		//'top: '+screen_h+'px;'
	"
  />
  </div>

  <ModalStatement :key="del_key" :is_open="stat_modal" :info_txt="step_text" :info_num="del_num" :step="info_step" :step_all="step_all" :step_arr="step_arr" @do_btn="open_doc"></ModalStatement>
</template>

<script lang="ts">
import PlusIcon from '../assets/icons/plusIcon.vue'
import Star from '../assets/icons/full-star.vue'
import EmptyStar from '../assets/icons/emtyStar.vue'
import Info from '../assets/icons/info.vue';
import Dots from '../assets/icons/dots.vue'
import DotsBlack from '../assets/icons/dotsblack.vue'
import {computed, defineComponent, onMounted, ref} from "vue";
import { useDMStore } from "@/store/dm_obj";
import {useRoute, useRouter} from "vue-router";
import LogoutIcon from "@/assets/icons/logoutIcon.vue";
import axios from "axios";

import ModalStatement from "./ModalStatement.vue";

export default defineComponent ({
  components: {
    LogoutIcon,
    PlusIcon,
    Star,
    Dots,
    DotsBlack,
    EmptyStar,
    Info,
    ModalStatement
  },
  emits: ["cnc"],
  props: {
    doc_list: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      doc_list: any;
    }, context
  ){
    const storeDM = useDMStore();
    const route = useRoute();
    let router = useRouter();

    const pagination_bottom_left = computed(() => {
      if(storeDM.rail == false){
        return '232px;';
      } else {
        return '80px;';
      }
    });

    const context_detail = ref(0);

    const doc_favorite_list = ref([]);

    const page = ref(1);

    const search_value = ref("");

    const downloadFileFromURL = async (url, fileName) => {
      if(fileName != ""){
        await axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      }
    }

    const doc_favorite_lists = computed(() => {
      if(search_value.value != ""){
        let doc_list_filter = doc_favorite_list.value.filter(val => String(val["address"]).toUpperCase().indexOf(search_value.value.toUpperCase()) !== -1 );
        //console.log(doc_list_filter);
        let tmp_arr = doc_list_filter.slice();
        return tmp_arr.slice(Number(page.value - 1) * pages_on_page.value, page.value * pages_on_page.value);
      } else {
        let tmp_arr = doc_favorite_list.value.slice();
        return tmp_arr.slice(Number(page.value - 1) * pages_on_page.value, page.value * pages_on_page.value);
      }
    });
    const edit_favorite = async (
      id: string,
      hash: string,
      favorite: string
    ) => {
      const storeDM = useDMStore();

      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "edit_favorite",
          id: id,
          hash: route.query.hash,
          favorite: favorite,
        }),
      });
      const response_data = await response.json();
      let id_elem = props.doc_list.findIndex(
        (x) => x["id"] === response_data.id
      );
      //console.log("000 - "+response_data.favorite);
      //console.log(Object(props.doc_list)[id_elem]);
      //console.log("111 - "+Object(props.doc_list)[id_elem]["favorite"]);

      Object(props.doc_list)[id_elem]["favorite"] = response_data.favorite;

      let tmp_arr = props.doc_list.slice();
      let tmp_arr2: any = [];
      for (let i = 0; i < tmp_arr.length; i++) {
        if (tmp_arr[i]["favorite"] == "1") {
          tmp_arr2.push(tmp_arr[i]);
        }
      }
      doc_favorite_list.value = tmp_arr2;

      context_detail.value = 0;
    };

    const get_favorite_list = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_favorites",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      if (response_data["status"] == "success") {
        doc_favorite_list.value = response_data["doc_list"];
      }
    };

    get_favorite_list();

    const open_doc = (id: string) => {

      router.push({
        query: {
          hash: route.query.hash,
          type: "document",
          region: 1,
          construction: 1,
          id: id,
          step: 1,
          mode: "edit",
        },
      });

      context_detail.value = 0;

      setTimeout(do_cnc, 300);
    };

    const copy_doc = async (id: string) => {
      storeDM.showSubscription = false;
      const response = await fetch(storeDM.api_url + "api3.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "copy_doc",
          id: id,
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      router.push({
        query: {
          hash: route.query.hash,
          type: "document",
          region: response_data["region"],
          construction: response_data["construction"],
          id: response_data["id"],
          step: 1,
          mode: "edit",
        },
      });
      
      context_detail.value = 0;

      setTimeout(do_cnc, 300);
    };

    const do_cnc = () => {
      context.emit("cnc");
    };

    const pages_on_page = ref(11);
    const screen_h = ref(0);
    onMounted(() => {
      /*
      if(screen.availHeight <= 800){
        screen_h.value = 650;
        pages_on_page.value = 7;
      } else {
        screen_h.value = 844;
        pages_on_page.value = 11;
      }
      */
      screen_h.value = 844;
      pages_on_page.value = 11;
      //console.log(screen_h.value);
    });

    const del_num = ref('');
    const del_key = ref(0);
    const stat_modal = ref(false);
    const info_step = ref(0);
    const step_all = ref(0);
    const step_arr = ref([]);
    const step_text = ref("");

    const modalStat = async (id: string, step: number, stepAll: number, stepArr: any, stepTxt: string) => {
      //console.log(step);
      context_detail.value = 0;
      stat_modal.value = true;
      del_num.value = id;
      info_step.value = step;
      step_all.value = stepAll;
      step_arr.value = stepArr;
      step_text.value = stepTxt;

      del_key.value = del_key.value+1;
    }

    return {
      page,
      doc_favorite_list,
      doc_favorite_lists,
      edit_favorite,
      context_detail,
      open_doc,
      pagination_bottom_left,
      search_value,
      pages_on_page,
      screen_h,
      copy_doc,
      downloadFileFromURL,
      del_num,
      del_key,
      stat_modal,
      info_step,
      step_all,
      step_arr,
      step_text,
      modalStat,
    }
  }
});
</script>

<style>
	.drafts__content {
		min-height: 743px;
	}

  .dots_header {
    height: inherit;
    text-align: center;
    padding-top: 17px;
  }

  .dots {
    cursor: pointer;
    height: inherit;
    text-align: center;
    padding-top: 17px;
  }
</style>
