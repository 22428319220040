import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bc92508"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"display":"contents","cursor":"pointer"}
}
const _hoisted_2 = {
  key: 1,
  style: {"display":"contents"}
}
const _hoisted_3 = {
  key: 2,
  style: {"display":"contents"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "noselect",
    style: {"color":"white","cursor":"pointer"},
    onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.goPaySerg();})
  }, [
    _createTextVNode(_toDisplayString(_ctx.docs.docs_count_text) + ": ", 1),
    (_ctx.docs.docs_count=='Пополнить')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("u", null, _toDisplayString(_ctx.docs.docs_count), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.docs.docs_count!='Пополнить')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.docs.docs_count), 1))
      : _createCommentVNode("", true),
    _createTextVNode(" / " + _toDisplayString(_ctx.docs.docs_time_text) + ": " + _toDisplayString(_ctx.docs.docs_time) + " ", 1),
    (_ctx.docs.count_edit!='-' && _ctx.route.query.type=='document' && _ctx.docs.count_edit_text!='')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "/ " + _toDisplayString(_ctx.docs.count_edit_text) + " - " + _toDisplayString(_ctx.docs.count_edit), 1))
      : _createCommentVNode("", true)
  ]))
}