<template>
  <div class="step__basic">
    <div class="left">
      <div>
        <label for="name">Для чего разрабатывается дизайн-проект</label>
        <span>Пример: Проект информационных конструкций «РОСБАНК»</span>
        <input class="input" type="text" placeholder="Введите текст в родительном падеже" v-model="document.purpose_design">
      </div>
      <div>
        <label for="name">Введите адрес размещения вывески</label>
        <span>Пример: г. Сургут, пр-кт Ленина, д 51/1</span>
        <input class="input" type="text" placeholder="Введите адрес" v-model="document.address">
      </div>
    </div>
    <div class="right">
      <div>
        <label for="name">Проектировщик дизайн-проекта</label>
        <span>Пример: ООО "Консалтинговая группа "СЕВЕР"", другое</span>
        <Dropdown v-model="designer_name_title"
                  editable :options="designer_name_options"
                  optionLabel="name"
                  :placeholder="designer_name_default.name"
                  style="width:480px"
                  @change="designer_name_change"
        />
        <input class="input" type="text" 
        placeholder="Введите название проектировщика" v-model="document.designer_name" 
        v-if="document.designer_name != designer_name_default.name">
      </div>
      <div>
        <label for="name">Юридический адрес проектировщика</label>
        <span>Пример: г. Санкт-Петербург, наб.р. Фонтанки, д. 59, оф. 302</span>
        <input class="input" type="text" placeholder="Введите адрес" v-model="document.address_law">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { useDMStore } from "@/store/dm_obj";

export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){
    const storeDM = useDMStore();
    const route = useRoute();

    const designer_name_default = ref({ name: 'ООО "Консалтинговая группа "СЕВЕР""', value: 'ООО "Консалтинговая группа "СЕВЕР""' });
    const designer_name_options = ref([
      designer_name_default.value,
      { name: "Другой", value: "" },
    ]);

    const designer_name_title = computed(() => {
      if('ООО "Консалтинговая группа "СЕВЕР""' != props.document.designer_name){
        return "Другой";
      } else {
        return props.document.designer_name;
      }
    });

    const designer_name_change = (e) => {
      props.document.designer_name = e.value.value;
    }

    return {
      designer_name_default,
      designer_name_options,
      designer_name_title,
      designer_name_change,
    }
  }
});
</script>