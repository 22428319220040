<template>
  <img alt="Rekprof logo" class="logo_min" src="../assets/logo.png" />
</template>

<script lang="ts">
//http://localhost:8081/continue/9?hash=WZ0GNTM9N613A5MKXGH216D4M0ITT1

import { useRouter, useRoute } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";

export default defineComponent({
  name: "StepOne",
  //get_info_event = set_info
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    //const hash = route.query.hash;
    //const docID = route.params.docID;

    //console.log(hash);
    //console.log(docID);
    const go_next = async () => {
      const response = await fetch(storeDM.api_url + "api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_continue",
          hash: route.query.hash,
          id: route.params.docID,
        }),
      });

      const response_data = await response.json();

      //console.log(1);
      if (response_data["found"] == 1) {
        router.push({
          path: "/step" + response_data["step"] + "/" + response_data["id"],
          query: {
            hash: response_data["hash"],
          },
        });
      }
      storeDM.set_info({
        client: response_data["client"],
        client_fullname: response_data["client_fullname"],
        client_inn: response_data["client_inn"],
        client_ogrn: response_data["client_ogrn"],
        client_smallname: response_data["client_smallname"],
        client_type: response_data["client_type"],
        construction_app6: response_data["construction_app6"],
        construction_around_file: response_data["construction_around_file"],
        construction_away_file: response_data["construction_away_file"],
        construction_height: response_data["construction_height"],
        construction_info_height: response_data["construction_info_height"],
        construction_info_width: response_data["construction_info_width"],
        construction_perspective_file:
          response_data["construction_perspective_file"],
        construction_type: response_data["construction_type"],
        construction_width: response_data["construction_width"],
        declarer_firstname: response_data["declarer_firstname"],
        declarer_lastname: response_data["declarer_lastname"],
        declarer_passport_issued: response_data["declarer_passport_issued"],
        declarer_passport_number: response_data["declarer_passport_number"],
        declarer_passport_registration:
          response_data["declarer_passport_registration"],
        declarer_passport_seria: response_data["declarer_passport_seria"],
        declarer_patronymic: response_data["declarer_patronymic"],
        declarer_patronymic_no: response_data["declarer_patronymic_no"],
        designer_name: response_data["designer_name"],
        doc_signature_digital_file: response_data["doc_signature_digital_file"],
        doc_signature_file: response_data["doc_signature_file"],
        doc_signature_power_attorney_file:
          response_data["doc_signature_power_attorney_file"],
        found: response_data["found"],
        hash: response_data["hash"],
        id: response_data["id"],
        info_count: response_data["info_count"],
        manufacturer_name: response_data["manufacturer_name"],
        map_district: response_data["map_district"],
        map_file: response_data["map_file"],
        map_house_korpus_litera: response_data["map_house_korpus_litera"],
        map_korpus_litera: response_data["map_korpus_litera"],
        mounting_around_file: response_data["mounting_around_file"],
        mounting_away_file: response_data["mounting_away_file"],
        mounting_perspective_file: response_data["mounting_perspective_file"],
        number_type_illumination: response_data["number_type_illumination"],
        place_install: response_data["place_install"],
        place_number: response_data["place_number"],
        proxy_date: response_data["proxy_date"],
        proxy_re: response_data["proxy_re"],
        remote_ip: response_data["remote_ip"],
        signature_digital_file: response_data["signature_digital_file"],
        signature_file: response_data["signature_file"],
        sketch_project_one_file: response_data["sketch_project_one_file"],
        sketch_project_two_file: response_data["sketch_project_two_file"],
        step: response_data["step"],
        time_create: response_data["time_create"],
        time_edit: response_data["time_edit"],
      });
    };
    go_next();
    return {};
  },
});
</script>

<style scoped></style>
