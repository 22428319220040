<template>
  <div class="documents">
    <div class="white__navbar">
      <!--
      <p>Личный кабинет</p>
      <p>/</p>
      -->
      <p>Доверенности</p>
    </div>
    <div class="documents__content">
      <div class="search">
        <button @click="createProxy" style="width: 210px;"><PlusIcon /> Новая доверенность</button>
        <input type="text" placeholder="Поиск" v-model="search_value">
      </div>
      <div class="data">
      <v-table
    fixed-header
  >

    <thead>
      <tr style="color: rgba(0, 0, 0, 0.4509803922);">
        <th class="text-left">
          №
        </th>
        <th class="text-left">
          Дата выдачи
        </th>
        <th class="text-left">
          Юридическое лицо
        </th>
        <th class="text-left">
          Заявитель
        </th>
        <th class="text-left" style="width:62px">
          Операции
          <!--
          <div class="dots_header">
            <DotsBlack />
          </div>
          -->
        </th>
      </tr>
    </thead>
    <tbody style="text-align: left;">
      <tr
        v-for="(input, index) in proxies_lists"
        :key="index"
      >
        <td>{{ input.id }}</td>
        <td>{{ input.date }}</td>
        <td>{{ input.client_name }}</td>
        <td>{{ input.declarer }}</td>
        <td style="width:62px;">
          <div class="dots" @click="context_detail=input.id"><Dots  /></div>
          <div v-if="context_detail==input.id"
               style="position: absolute;
                      width: 200px;
                      margin-left: -180px;
                      margin-top: -35px;
                      z-index: 1;
                      text-align: center;
                      background: white;
                      -webkit-user-select: none; /* Safari */
                      -moz-user-select: none; /* Firefox */
                      -ms-user-select: none; /* IE10+/Edge */
                      user-select: none; /* Standard */
                      box-shadow: 0px 20px 22px 0px #B2BDE169;
                    ">
            <div @click="context_detail=0" style="text-align: right;"><LogoutIcon style="cursor: pointer;" /></div>
            <div style="cursor: pointer;" @click="open_doc(input.id)">Просмотреть доверенность</div>
            <div style="cursor: pointer;" @click="save_doc(input.id)">Скачать доверенность</div>
            <div style="cursor: pointer;" @click="hide_doc_modal(input.id)">Удалить доверенность</div>
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
</div>
</div>
<vue-awesome-paginate
    :total-items="proxies_list.length"
    :items-per-page="pages_on_page"
    :max-pages-shown="17"
    v-model="page"
    prev-button-content="Назад"
    next-button-content="Далее"
    :style="
		'position: relative; float: left; transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); margin-top: -0.5rem; top: 0px; left: 0px; margin-left: 1.5rem;'
		//'top: '+screen_h+'px;'
	"
  />
  </div>

  <ModalDel :key="del_key" :is_open="del_modal" :info_txt="'доверенность №'" :info_num="del_num" @do_btn="hide_doc"></ModalDel>
</template>

<script lang="ts">
import PlusIcon from '../../assets/icons/plusIcon.vue'
import Star from '../../assets/icons/full-star.vue'
import EmptyStar from '../../assets/icons/emtyStar.vue'
import Dots from '../../assets/icons/dots.vue'
import DotsBlack from '../../assets/icons/dotsblack.vue'
import {defineComponent, ref, computed, onMounted} from "vue";
import { useDMStore } from "@/store/dm_obj";
import {useRoute, useRouter} from "vue-router";
import LogoutIcon from "@/assets/icons/logoutIcon.vue";
import axios from "axios";
import ModalDel from "../ModalDel.vue";

export default defineComponent ({
  emits: ["edit-proxy","new-proxy"],
  components: {
    LogoutIcon,
    PlusIcon,
    Star,
    Dots,
    DotsBlack,
    EmptyStar,
    ModalDel
  },
  setup(props, context){
    const storeDM = useDMStore();

    const search_value = ref("");

    const pagination_bottom_left = computed(() => {
      if(storeDM.rail == false){
        return '232px;';
      } else {
        return '80px;';
      }
    });

    let router = useRouter();
    const route = useRoute();

    const context_detail = ref(0);

    const proxies_list = ref([]);

    const get_proxies_list = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_proxies",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      if (response_data["status"] == "success") {
        proxies_list.value = response_data["proxies_list"];
      }
    };

    get_proxies_list();

    const open_doc = (id: string) => {

      router.push({
        query: {
          hash: route.query.hash,
          type: "proxy",
          id: id,
          step: 1,
        },
      });

      context_detail.value = 0;

      context.emit("edit-proxy", id);

    };

    const save_doc = (id: string) => {
      downloadItem(
        storeDM.api_url +
        "proxy.php?id=" +
        id +
        "&hash=" +
        route.query.hash +
        "&type=declarations",
        "Доверенность " + id
      );

      context_detail.value = 0;
    };

    const downloadItem = (url: string, label: string) => {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    };


    const del_modal  = ref(false);
    const del_num = ref('');
    const del_key = ref(0);
    const hide_doc_modal = async (id: string) => {
      context_detail.value = 0;
      del_modal.value = true;
      del_num.value = id;
      del_key.value = del_key.value+1;
    }

    const hide_doc = async () => {
      let id = del_num.value;

      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "hide_proxy",
          hash: route.query.hash,
          id: id,
        }),
      });

      const response_data = await response.json();
      proxies_list.value = response_data["proxies_list"];
      context_detail.value = 0;

      del_key.value = del_key.value+1;
      del_modal.value = false;
    };

    //go?hash=UUJ8L96ZMC4EQQX3K89TCCETV3UHP5&type=proxy&id=28&step=1

    const page = ref(1);
    const proxies_lists = computed(() => {
      if(search_value.value != ""){
        let doc_list_filter = proxies_list.value.filter(val => String(val["client_name"]).toUpperCase().indexOf(search_value.value.toUpperCase()) !== -1 );
        //console.log(doc_list_filter);
        let tmp_arr = doc_list_filter.slice();
        return tmp_arr.slice(Number(page.value - 1) * pages_on_page.value, page.value * pages_on_page.value);
      } else {
        let tmp_arr = proxies_list.value.slice();
        return tmp_arr.slice(Number(page.value - 1) * pages_on_page.value, page.value * pages_on_page.value);
      }
    });

    const createProxy = () => {
      context.emit("new-proxy");
    }

    const pages_on_page = ref(11);
    const screen_h = ref(0);
    onMounted(() => {
      /*
      if(screen.availHeight <= 800){
        screen_h.value = 650;
        pages_on_page.value = 7;
      } else {
        screen_h.value = 844;
        pages_on_page.value = 11;
      }
      */
      screen_h.value = 844;
      pages_on_page.value = 11;
      //console.log(screen_h.value);
    });

    return {
      page,
      proxies_list,
      proxies_lists,
      context_detail,
      open_doc,
      save_doc,
      hide_doc,
      createProxy,
      pagination_bottom_left,
      search_value,
      pages_on_page,
      screen_h,
      hide_doc_modal,
      del_modal,
      del_num,
      del_key,
    }
  }
});
</script>

<style>
	.documents__content {
		min-height: 743px;
	}

  .dots_header {
    height: inherit;
    text-align: center;
    padding-top: 17px;
  }

  .dots {
    cursor: pointer;
    height: inherit;
    text-align: center;
    padding-top: 17px;
  }
</style>
