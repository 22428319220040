<template>
  <img alt="Rekprof logo" class="logo_min" src="../../assets/logo.png" />
  <v-menu-proxy-list></v-menu-proxy-list>
  <div
    class="wrapper"
    :style="{ display: storeDM.dragScroll ? 'block' : 'none' }"
  ></div>
  <div class="inner_step_box">
    <span class="main_field unselectable">Подписанты доверенности</span>
    <br class="unselectable" />
    <span class="main_field unselectable">
      Добавьте подписантов или выберите из списка
    </span>
    <!--<div>{{ dragScroll }}</div>-->
    <div class="signatory_list_block" @wheel="signatory_scroll">
      <div
        class="signatory_content_block"
        :style="{ marginTop: signatory_y_pos.val + 'px' }"
      >
        <div
          class="signatory_block"
          v-for="(input, index) in storeDM.signatory_list"
          :key="index"
        >
          <div
            class="hint_left unselectable"
            style="margin-left: 0px; margin-top: 9px"
          >
            Подписант номер {{ index + 1 }}
          </div>
          <!--
          <input
            type="radio"
            class="signatory_radio"
            name="signatory_radio"
            :value="input.id"
            :checked="input.id == storeDM.save_obj.signatory_main"
            @click="storeDM.save_obj.signatory_main = input.id"
          />
          -->
          <div class="signatory_input unselectable" :key="index">
            {{ input.name }}
          </div>
          <button
            type="button"
            class="blue_small_btn"
            @click="signatory_delete(index)"
          >
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>
      </div>
      <div
        class="scroll unselectable"
        :style="{ top: 132 + signatory_y_pos.val1 + 'px' }"
        @mousedown="signatory_drag"
        @mousemove="signatory_drag"
        v-touch:drag="signatory_drag"
        v-touch:press="signatory_drag"
        v-touch:release="signatory_drag"
      >
        <div class="scroll_body"></div>
      </div>
    </div>
    <div class="hint_left unselectable" style="margin-bottom: 9px">
      Выбрать подписанта
    </div>
    <v-select-open
      :options="storeDM.save_obj.signatory_options"
      :title_select="signatory_title"
      @change-select="signatory_select"
      :z_index="1"
      :margin_top="'-9px'"
    >
    </v-select-open>
    <br />
    <br />
    <div class="hint_left unselectable">
      Нажмите для добавления нового подписанта
    </div>
    <button
      type="button"
      class="blue_btn"
      @click="signatory_add()"
      style="float: left; margin-left: 10px"
    >
      Добавить подписанта
    </button>
    <div style="position: absolute; top: 60px; left: 590px">
      <span class="main_field unselectable">
        3.1.6 Выбрать главного подписанта для доверенности
      </span>
      <br />
      <v-select-open
        :options="storeDM.signatory_list"
        :title_select="signatory_main_title"
        @change-select="signatory_main_select"
        :default_select="storeDM.save_obj.signatory_main"
        :key="storeDM.save_obj.signatory_main"
        :z_index="1"
        :margin_top="'-9px'"
      >
      </v-select-open>
    </div>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import {
  ref,
  computed,
  defineComponent,
  reactive,
  toRef,
  onMounted,
} from "vue";
import { useCounterStore } from "@/store/counter";
import VSelectOpen from "@/components/v-select-open.vue";
import VMenuProxyList from "@/components/v-menu-proxy-list.vue";
import { useDMDeclStore } from "@/store/dm_decl";

export default defineComponent({
  name: "ProxyStep5",
  components: { VMenuProxyList, VSelectOpen },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMDeclStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const signatory = ref({ name: "Выбрать подписанта", value: 0 });
    const signatory_title = "Выбрать подписанта";

    let signatory_y_pos = reactive({ val: 0, val1: 0 });

    let main_select_key = ref(0);

    const signatory_select = (opt: any) => {
      //signatory.value = opt.value;
      signatory.value = { name: opt.name, value: opt.value };
    };

    const signatory_main_title = "Выбрать главного подписанта";
    const signatory_main_select = (opt: any) => {
      //signatory.value = opt.value;
      //signatory.value = { name: opt.name, value: opt.value };
      storeDM.save_obj.signatory_main = opt.id;
      main_select_key.value = main_select_key.value + 1;
      //console.log(opt);
    };

    const go_back = () => {
      storeDM.set_info({
        step: "4",
      });
      router.push({
        path: "/proxy/step4/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "8",
      });
      router.push({
        path: "/proxy/step8/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      //console.log(signatory);
      //if (signatory.value != 0) {
      return true;
      //} else {
      //  return false;
      //}
    });

    const signatory_add = () => {
      if (signatory.value.value == 0) {
        storeDM.set_info({
          step: "6",
        });

        storeDM.signatory_options_tpm.id = 0;
        storeDM.signatory_options_tpm.signatory_lastname = "";
        storeDM.signatory_options_tpm.signatory_firstname = "";
        storeDM.signatory_options_tpm.signatory_patronymic = "";
        storeDM.signatory_options_tpm.signatory_skip_patronymic = false;
        storeDM.signatory_options_tpm.signatory_passport_number = "";
        storeDM.signatory_options_tpm.signatory_passport_seria = "";
        storeDM.signatory_options_tpm.signatory_passport_issued = "";
        storeDM.signatory_options_tpm.signatory_passport_registration = "";

        router.push({
          path: "/proxy/step6/" + route.params.docID,
          query: {
            hash: route.query.hash,
          },
        });
      } else {
        //console.log(signatory);

        let findElement = Array(storeDM.signatory_list as any)[0].filter(
          function (val: any) {
            return val.name == signatory.value.name;
          }
        );

        if (findElement.length == 0) {
          storeDM.signatory_list_push({
            id: signatory.value.value,
            name: signatory.value.name,
          });

          main_select_key.value = main_select_key.value + 1;
        }
      }
    };

    const signatory_delete = (num: number) => {
      storeDM.signatory_list_delete({
        num: num,
      });

      signatory_scroll_clear();

      main_select_key.value = main_select_key.value + 1;
    };

    const unselected_input = (evt: MouseEvent) => {
      evt.preventDefault();
    };

    const signatory_scroll = (evt: WheelEvent) => {
      let step_y = 78; //78
      let step_max = 337; //181
      if (evt.deltaY > 0) {
        signatory_y_pos.val = signatory_y_pos.val - step_y;
        /*
        if (0 - (signatory_list.val.length - 3) * step_y > signatory_y_pos.val) {
          signatory_y_pos.val = 0 - (signatory_list.val.length - 3) * step_y;
        }
        if (signatory_list.val.length <= 3) {
          signatory_y_pos.val = 0;
        }
        */
        //document.getElementsByClassName("signatory_content_block")[0].style.marginTop = document.getElementsByClassName("signatory_content_block")[0].style.marginTop+75;
      } else if (evt.deltaY < 0) {
        signatory_y_pos.val = signatory_y_pos.val + step_y;
        /*
        if (signatory_y_pos.val > 0) {
          signatory_y_pos.val = 0;
        }
        */
        //document.getElementsByClassName("signatory_content_block")[0].style.marginTop = document.getElementsByClassName("signatory_content_block")[0].style.marginTop-75;
      }

      /*
      let currenMT: number = Math.abs(signatory_y_pos.val);
      let maxMT: number = Math.abs(0 - (signatory_list.val.length - 3) * step_y);

      if (currenMT == 0) {
        signatory_y_pos.val1 = 0;
      } else {
        let deltaMT: number = maxMT / currenMT;
        signatory_y_pos.val1 = step_max / deltaMT;
      }
      */

      signatory_scroll_clear();
      //console.log(signatory_y_pos.val);
    };

    const signatory_scroll_clear = () => {
      let step_y = 78;
      let step_max = 337;
      if (
        0 - ((storeDM.signatory_list as any).length - 5) * step_y >
        signatory_y_pos.val
      ) {
        signatory_y_pos.val =
          0 - ((storeDM.signatory_list as any).length - 5) * step_y;
      }
      if ((storeDM.signatory_list as any).length <= 5) {
        signatory_y_pos.val = 0;
      }

      if (signatory_y_pos.val > 0) {
        signatory_y_pos.val = 0;
      }

      let currenMT: number = Math.abs(signatory_y_pos.val);
      let maxMT: number = Math.abs(
        0 - ((storeDM.signatory_list as any).length - 5) * step_y
      );

      if (currenMT == 0) {
        signatory_y_pos.val1 = 0;
      } else {
        let deltaMT: number = maxMT / currenMT;
        signatory_y_pos.val1 = step_max / deltaMT;
      }
    };

    const signatory_scroll_set = () => {
      let step_y = 78;
      let step_max = 337;
      let maxMT: number = Math.abs(
        0 - ((storeDM.signatory_list as any).length - 5) * step_y
      );
      if ((storeDM.signatory_list as any).length <= 5) {
        maxMT = 0;
      }
      //signatory_y_pos.val;
      let deltaMT: number = step_max / signatory_y_pos.val1;
      signatory_y_pos.val = 0 - maxMT / deltaMT;
    };

    let scroll_old_offset_y = 0;

    const signatory_drag = (evt: MouseEvent | TouchEvent) => {
      //console.log(evt);
      let step_max = 337;
      if (evt.type == "touchstart") {
        storeDM.dragScroll = true;

        scroll_old_offset_y =
          (evt as TouchEvent).touches[0].clientY - signatory_y_pos.val1 - 132;
      } else if (evt.type == "mousedown") {
        storeDM.dragScroll = true;
        scroll_old_offset_y = (evt as MouseEvent).offsetY;
      } else if (evt.type == "touchend") {
        storeDM.dragScroll = false;
      } else if (evt.type == "touchmove" && storeDM.dragScroll) {
        signatory_y_pos.val1 =
          (evt as TouchEvent).touches[0].clientY - 132 - scroll_old_offset_y;
        if (signatory_y_pos.val1 > step_max) {
          signatory_y_pos.val1 = step_max;
        }
        if (signatory_y_pos.val1 < 0) {
          signatory_y_pos.val1 = 0;
        }
      } else if (evt.type == "mousemove" && storeDM.dragScroll) {
        signatory_y_pos.val1 =
          (evt as MouseEvent).pageY - 132 - scroll_old_offset_y;
        if (signatory_y_pos.val1 > step_max) {
          signatory_y_pos.val1 = step_max;
        }
        if (signatory_y_pos.val1 < 0) {
          signatory_y_pos.val1 = 0;
        }
        //console.log(evt);
        //signatory_scroll_clear();
      }

      signatory_scroll_set();
    };

    return {
      go_back,
      step_next,
      signatory,
      signatory_title,
      signatory_select,
      signatory_add,
      signatory_delete,
      go_next,
      signatory_scroll,
      signatory_y_pos,
      signatory_drag,
      signatory_scroll_clear,
      unselected_input,
      storeDM,
      signatory_main_title,
      signatory_main_select,
      main_select_key,
    };
  },
});
</script>

<style scoped>
.signatory_list_block {
  width: 563px;
  overflow: hidden;
  /* height: 230px; */
  height: 386px;
  margin-left: 10px;
}

.signatory_input {
  width: 515px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin-left: 0px;
}

.signatory_radio {
  float: left;
  width: 30px;
  height: 42px;
}

.scroll {
  width: 9.5px;
  position: absolute;
  left: 576px;
  /*padding-left: 6px;*/
  cursor: pointer;
}

.scroll_body {
  margin-left: 3px;
  width: 3.5px;
  height: 45px;
  background-color: #2f80ed;
}
</style>
