<template>
    <div class="steps">
      <button :key="1" :class="{activeStep: subpage == 1}" @click="subpage = 1"
              style="width:104px;">Шаг 1</button>
      <button v-if="document.app5_file_checked == 0" v-for="n in Number(document.comp_count)" :key="Number(n+1)" :class="{activeStep: subpage == n+1}" @click="prepareFileUpload_click(Number(n+1));"
              style="width:104px;">Шаг {{Number(n+1)}}</button>
    </div>
    <div class="step__basic" v-if="subpage == 1">
      <div class="left">
  
        <section>
          <label for="title">
            <v-switch
              style="display: flex; flex-direction: row; justify-content: flex-start"
              color="#2F80ED"
              v-model="document.app5_file_checked"
              label="Использовать готовое приложение №5"
              inline
            ></v-switch>
  
            <div v-if="document.app5_file_checked == 1">
              <input
                ref="app5_file"
                type="file"
                accept=".pdf"
                hidden
                @change="submitFile('app5_file')"
              />
  
              <span>Нажмите чтобы загрузить готовое приложение</span>
              <button class="btn_upload" style="width: 480px;" @click="$refs.app5_file.click()">Загрузить файл приложения №5 на сервер</button>
              <span style="cursor: pointer;" @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app5_file', document.app5_file.split('/')[document.app5_file.split('/').length-1]);">Файл приложения №5 - {{document.app5_file.split('/')[document.app5_file.split('/').length-1] == "" ? 'не загружен' : document.app5_file.split('/')[document.app5_file.split('/').length-1]}}</span>
            </div>
          </label>
        </section>
  
  
        <div v-if="document.app5_file_checked == 0">
          <label for="name">Выберите количество эскизов</label>
          <span>Выберите из списка</span>
          <Dropdown v-model="comp_title"
                    editable :options="comp_options"
                    optionLabel="name"
                    :placeholder="comp_default.name"
                    style="width:480px"
                    @change="comp_change($event)"
          />
        </div>
      </div>
    </div>
    <input
      ref="comp_multiple_file"
      type="file"
      hidden
      @change="submitFile('comp_multiple_file', subpage-2, document.comp_arr[subpage-2]['name'])"
    />
    <div v-for="n in Number(document.comp_count)+1">
      <div class="uploadfile_photo" v-if="subpage == n+1">
        <div class="left">
          <div v-if="subpage == 2">
            <label for="name">Добавьте компьютерный монтаж вывески сбоку</label>
          </div>
          <div v-if="subpage == 3">
            <label for="name">Добавьте компьютерный монтаж вывески вблизи</label>
          </div>
          <div v-if="subpage == 4">
            <label for="name">Добавьте компьютерный монтаж вывески издалека</label>
          </div>
          <div v-if="subpage > 4 && subpage < Number(document.comp_count)+1">
            <label for="name">Добавьте дополнительный компьютерный монтаж</label>
          </div>
          <div v-if="subpage == Number(document.comp_count)+1">
            <label for="name">Добавьте компьютерный монтаж вывески с размерами</label>
          </div>

          <span class="blueSpan">Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 604px x 402px</span>

          <div v-if="document.comp_arr[subpage-2]['file'] != ''" class="fileUpload" @click="$refs.comp_multiple_file.click()"
            ref="fileformuploaded"
               :style="'background: url(\'https://api.rekprof.ru/files/'+document.comp_arr[subpage-2]['file']+'?a='+unicFile+'\'); background-position: center; background-size: contain; background-repeat: no-repeat; border: 1px solid black;'"
          >
            <p>Перенесите сюда файл <br> или нажмите для загрузки</p>
          </div>
          <div v-if="document.comp_arr[subpage-2]['file'] == ''" 
            ref="fileform"
            class="fileUpload" @click="$refs.comp_multiple_file.click()">
            <p>Перенесите сюда файл <br> или нажмите для загрузки</p>
          </div>
          <div>
            <span class="filenameSpan">{{document.comp_arr[subpage-2]['file']==''?'не загружен':document.comp_arr[subpage-2]['file'].split("/")[document.comp_arr[subpage-2]['file'].split("/").length-1]}}</span>
            <span v-if="document.comp_arr[subpage-2]['file']!=''" class="downloadSpan" @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=fileupload&filename=comp_multiple_file&element='+(subpage-2), document.comp_arr[subpage-2]['file'].split('/')[document.comp_arr[subpage-2]['file'].split('/').length-1]);">Скачать</span>
            <span v-if="document.comp_arr[subpage-2]['file']!=''" class="delSpan" @click="submitFile('comp_multiple_file', subpage-2, document.comp_arr[subpage-2]['name'], '1')"><Trash /></span>
          </div>
          <section v-if="proc_upload" class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </section>
        </div>
        <div class="right" style="position: absolute; margin-left: 505px; top: 175px;">
          <p>Пример изображения</p>
          <img src="https://api.rekprof.ru/files/sample/spb/comp_perspectiva.jpg" />
        </div>
      </div>
    </div>
  
  
  </template>
  
  <script lang="ts">
  import Trash from '../../assets/icons/trash.vue';
  import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
  import DropdownUp from "../../assets/icons/DropdownUp.vue";
  import Map from '../../assets/icons/exampleMap.vue'
  import { computed, defineComponent, ref } from "vue";
  import axios from "axios";
  import {useDMStore} from "@/store/dm_obj";
  import {useRoute} from "vue-router";
  export default defineComponent({
    components: {
      DropdownIcon,
      DropdownUp,
      Map,
      Trash,
    },
    props: {
      document: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    setup(
      props: {
        document: any;
      }, context
    ){
      const storeDM = useDMStore();
  
      const route = useRoute();
  
      let unicFile = ref(Math.random());
  
      const comp_default = ref({ name: "4", value: "4" });
      const comp_options = ref([
        { name: "4", value: "4" },
        { name: "5", value: "5" },
        { name: "6", value: "6" },
        { name: "7", value: "7" },
        { name: "8", value: "8" },
        //{ name: "9", value: "9" },
        //{ name: "10", value: "10" },
      ]);
  
      const comp_title = computed(() => {
        if (props.document.comp_count == 0) {
          return null;
        } else {
          return props.document.comp_count;
        }
      });
  
      const comp_change = (e) => {
        props.document.comp_count = e.value.value;
        for (let i = 0; i < props.document.comp_count; i++) {
          if ( typeof(props.document.comp_arr[i]) !== "undefined" && props.document.comp_arr[i] !== null ) {
          } else {
            props.document.comp_arr[i] = {};
          }
          if ( typeof(props.document.comp_arr[i]["name"]) !== "undefined" && props.document.comp_arr[i]["name"] !== null ) {
          } else {
            props.document.comp_arr[i]["name"] = "";
          }
          if ( typeof(props.document.comp_arr[i]["file"]) !== "undefined" && props.document.comp_arr[i]["file"] !== null ) {
          } else {
            props.document.comp_arr[i]["file"] = "";
          }
        }
      }
  
      const comp_multiple_file = ref();
  
      const noSubmitFile = (f_name: string, element: number, m_name: string) => {
  
        //console.log(1);
  
        let formData = new FormData();
        formData.append("element", String(element));
        formData.append("m_name", m_name);
        formData.append("file_name", f_name);
        formData.append("hash", String(route.query.hash));
        formData.append("id", String(route.query.id));
        axios
          .post(storeDM.api_url + "noupload.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (req) {
            console.log("SUCCESS!!");
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
  
      }

      const proc_upload = ref(false);
      const submitFile = (f_name: string, element: number, m_name: string, del: string = "0") => {
        proc_upload.value = true;

        let file = Object(eval(f_name).value).files[0];
        if(del == '0'){
          if(file == undefined){
            return;
          }
        }
  
        let formData = new FormData();
        if(del == '0'){
          formData.append(f_name, file);
          formData.append("del", "0"); 
        } else {
          formData.append(f_name, "");
          formData.append("del", "1");
        }
        formData.append("element", String(element));
        formData.append("m_name", m_name);
        formData.append("file_name", f_name);
        formData.append("hash", String(route.query.hash));
        formData.append("id", String(route.query.id));
        axios
          .post(storeDM.api_url + "upload.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (req) {
            proc_upload.value = false;
            unicFile.value = Math.random();
            if (Object(req.data).file_name == "comp_multiple_file") {
              props.document["comp_arr"] = JSON.parse(Object(req.data)[
                req.data.file_name
                ]);
              for(let ii=0; ii<props.document["comp_arr"].length; ii++){
                if(props.document["comp_arr"][ii]["name"] == "false"){
                  props.document["comp_arr"][ii]["name"] = false;
                } else if(props.document["comp_arr"][ii]["name"] == "true"){
                  props.document["comp_arr"][ii]["name"] = true;
                }
              }
              //console.log(props.document["comp_arr"]);
            } else {
              props.document[Object(req.data).file_name] = Object(req.data)[
                req.data.file_name
                ];
            }
            console.log(Object(req.data).file_name+" - "+Object(req.data)[req.data.file_name]);
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      };
  
      const subpage = ref(1);

      const determineDragAndDropCapable = () => {
        var div = document.createElement('div');
        return ( ( 'draggable' in div )
                || ( 'ondragstart' in div && 'ondrop' in div ) )
            && 'FormData' in window
            && 'FileReader' in window;
      }

    //const upload_file = ref<any | null>(null);
      const fileform = ref<HTMLElement | null>(null);
      const fileformuploaded = ref<HTMLElement | null>(null);
    

      const prepareFileUpload = () => {
        

        const dragAndDropCapable = determineDragAndDropCapable();
        const th = this;
        if( dragAndDropCapable ) {
          ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {

            if(fileform.value !== null){
              if(typeof(fileform.value[0]) !== 'undefined'){
                fileform.value[0].addEventListener(evt, function (e) {
                  e.preventDefault();
                  e.stopPropagation();
                }.bind(th), false);
              }
            }
            
            if(fileformuploaded.value !== null){
              if(typeof(fileformuploaded.value[0]) !== 'undefined'){
                fileformuploaded.value[0].addEventListener(evt, function (e) {
                  e.preventDefault();
                  e.stopPropagation();
                }.bind(th), false);
              }
            }
          }.bind(this));
          
          if(fileform.value !== null){
            if(typeof(fileform.value[0]) !== 'undefined'){
              fileform.value[0].addEventListener('drop', function(e){
                comp_multiple_file.value["files"] = e.dataTransfer.files;
                submitFile('comp_multiple_file', subpage.value-2, props.document.comp_arr[subpage.value-2]['name']);
              }.bind(this));
            }
          }

          if(fileformuploaded.value !== null){
            if(typeof(fileformuploaded.value[0]) !== 'undefined'){
              fileformuploaded.value[0].addEventListener('drop', function(e){
                comp_multiple_file.value["files"] = e.dataTransfer.files;
                submitFile('comp_multiple_file', subpage.value-2, props.document.comp_arr[subpage.value-2]['name']);
              }.bind(this));
            }
          }
        }
      }


      const prepareFileUpload_click = (num) => {
        subpage.value = num;

        setTimeout(prepareFileUpload,100);
      }

      const downloadFileFromURL = async (url, fileName) => {

        if(fileName != ""){
          await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], { });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch(console.error);
        }

      }

      return{
        submitFile,
        noSubmitFile,
        subpage,
        comp_default,
        comp_options,
        comp_title,
        comp_change,
        comp_multiple_file,
        unicFile,
        fileform,
        fileformuploaded,
        prepareFileUpload_click,
        route,
        downloadFileFromURL,
        proc_upload,
      }
    },
  });
  </script>

<style scoped>

  .dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 444px;
    width: 480px;
    position: absolute;
    z-index: 1;
    flex-direction: initial !important;
    margin-top: 50px;
  }

  .dot {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #b3d4fc;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .dot:last-child {
    margin-right: 0;
  }

  .dot:nth-child(1) {
    animation-delay: -0.3s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.1s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.1s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }

    50% {
      transform: scale(1.2);
      background-color: #6793fb;
      box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }

    100% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
  }

  .filenameSpan {
    position: absolute;
    width: fit-content;
    display: flex;
    width: 400px;
  }

  .downloadSpan {
    cursor: pointer;
    display: flex;
    width: fit-content;
    position: absolute;
    margin-left: 413px;
  }

  .delSpan {
    cursor: pointer;
    display: flex;
    width: fit-content;
    position: absolute;
    margin-left: 470px;
  }
</style>