<script lang="ts">
import { useDMStore } from "@/store/dm_obj";
import { defineComponent, onMounted, onUnmounted, ref, reactive, computed } from "vue";
import { loadScript, unloadScript } from "vue-plugin-load-script";

import axios from "axios";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    page_arr: {
      type: Object,
      default() {
        return "";
      },
    },
    nav_btn: {
      type: Boolean,
      default() {
        return true;
      }
    },
    page: {
      type: Number,
      default() {
        return 1;
      }
    },
    document: {
      type: Object,
      default() {
        return {};
      },
    },
    check_next: {
      type: Boolean,
      default() {
        return 1;
      }
    },
    doc_type: {
      type: String,
      default() {
        return "";
      }
    },
  },
  emits: ["change-page","modalskip"],
  setup(props: {
    page_arr: any;
    nav_btn: boolean;
    page: number;
    document: any;
    check_next: boolean;
    doc_type: string;
  }, contextt) {
    const storeDM = useDMStore();

    const page_cur = ref(props.page);

    const page_cur_set = (page_set: number) => {
      page_cur.value = page_set;
      contextt.emit("change-page", page_cur.value);
    }

    const page_prev = () => {
      if(page_cur.value > 1){
        page_cur.value = page_cur.value-1;
      }
      page_cur_set(page_cur.value);
    }

    const page_next = () => {
      let next_doit = true;
      if(props.doc_type == "statement"){
        if(props.document["unstep"] == true){

          for(let i=0; i<(props.page_arr[page_cur.value-1].complete.length); i++){
            if(test_page_complete(props.page_arr[page_cur.value-1].complete[i]) == false){
              next_doit = test_page_complete(props.page_arr[page_cur.value-1].complete[i]);
            }
          }

        }
      }

      if(props.check_next == false){
        next_doit = true;
      }

      //console.log(props.page_arr);

      if(next_doit){
        if(page_cur.value < Number(props.page_arr.length)){
          page_cur.value = page_cur.value+1;
        }
        page_cur_set(page_cur.value);
      } else {
        contextt.emit("modalskip", page_cur.value);
      }
      
    }

    /*
    if(props.document[props.page_arr[0].complete[1]]==""){
      console.log(false);
    } else {
      console.log(true);
    }
    */

    const test_page_complete = (key_value: string) => {

      if(key_value == "mapfile_type" || key_value == "map_file" || key_value == "map_yandex_file"){
        if(props.document["mapfile_type"] == 0 && props.document["map_file"] == ""){
          return false;
        }
        if(props.document["mapfile_type"] == 1 && props.document["map_yandex_file"] == ""){
          return false;
        }
      } else if(key_value == "sketch_count") {
        if(props.document[key_value]==0) {
          return false;
        }
      } else if(key_value == "signatory_main") {
        if(props.document[key_value]==0) {
          return false;
        }
      } else if(key_value == "send_type") {
        if(props.document[key_value]==0) {
          return false;
        }
      } else if(key_value == "proxy_date") {
        if(props.document[key_value]==0 || props.document[key_value]=="") {
          return false;
        }
      } else if(key_value == "proxy_end_date") {
        if(props.document[key_value]==0 || props.document[key_value]=="") {
          return false;
        }
      } else if(key_value == "sketch_arr") {
        for(let iz=0; iz<props.document.sketch_count; iz++){
          if(props.document[key_value][iz]["file"]=="") {
            return false;
          }
        }
        if(props.document.sketch_count == 0){
          return false;
        }
      } else if(key_value == "comp_arr") {
        for(let iz=0; iz<props.document.comp_count; iz++){
          if(props.document[key_value][iz]["file"]=="") {
            return false;
          }
        }
        if(props.document.comp_count == 0){
          return false;
        }
      } else {
        if (props.document[key_value] == "") {
          return false;
        }
      }

      return true;

    }

    const page_complete = computed(() => {
      let page_complete_arr: any = [];

      for(let i=0; i<props.page_arr.length; i++){
        let page_complete_tmp = true;

        for(let k=0; k<props.page_arr[i].complete.length; k++){
          if(test_page_complete(props.page_arr[i].complete[k])==false){
            page_complete_tmp = test_page_complete(props.page_arr[i].complete[k]);
          }
          /*
          if(props.page_arr[i].complete[k] == "mapfile_type" || props.page_arr[i].complete[k] == "map_file" || props.page_arr[i].complete[k] == "map_yandex_file"){
            if(props.document["mapfile_type"] == 0 && props.document["map_file"] == ""){
              page_complete_tmp = false;
            }
            if(props.document["mapfile_type"] == 1 && props.document["map_yandex_file"] == ""){
              page_complete_tmp = false;
            }
          } else if(props.page_arr[i].complete[k] == "sketch_count") {
            if(props.document[props.page_arr[i].complete[k]]==0) {
              page_complete_tmp = false;
            }
          } else if(props.page_arr[i].complete[k] == "signatory_main") {
            if(props.document[props.page_arr[i].complete[k]]==0) {
              page_complete_tmp = false;
            }
          } else if(props.page_arr[i].complete[k] == "send_type") {
            if(props.document[props.page_arr[i].complete[k]]==0) {
              page_complete_tmp = false;
            }
          } else if(props.page_arr[i].complete[k] == "proxy_date") {
            if(props.document[props.page_arr[i].complete[k]]==0 || props.document[props.page_arr[i].complete[k]]=="") {
              page_complete_tmp = false;
            }
          } else if(props.page_arr[i].complete[k] == "proxy_end_date") {
            if(props.document[props.page_arr[i].complete[k]]==0 || props.document[props.page_arr[i].complete[k]]=="") {
              page_complete_tmp = false;
            }
          } else if(props.page_arr[i].complete[k] == "sketch_arr") {
            //console.log(props.document[props.page_arr[i].complete[k]]);
            for(let iz=0; iz<props.document.sketch_count; iz++){
              if(props.document[props.page_arr[i].complete[k]][iz]["file"]=="") {
                page_complete_tmp = false;
              }
            }
            if(props.document.sketch_count == 0){
              page_complete_tmp = false;
            }
          } else if(props.page_arr[i].complete[k] == "comp_arr") {
            for(let iz=0; iz<props.document.comp_count; iz++){
              if(props.document[props.page_arr[i].complete[k]][iz]["file"]=="") {
                page_complete_tmp = false;
              }
            }
            if(props.document.comp_count == 0){
              page_complete_tmp = false;
            }
          } else {
            if (props.document[props.page_arr[i].complete[k]] == "") {
              page_complete_tmp = false;
            }
          }
          */
        }

        page_complete_arr[i] = page_complete_tmp;
      }
      return page_complete_arr;
      //props.page_arr
      //page_cur.value
      /*
      if(search_value.value != ""){
        let doc_list_filter = doc_favorite_list.value.filter(val => String(val["address"]).toUpperCase().indexOf(search_value.value.toUpperCase()) !== -1 );
        //console.log(doc_list_filter);
        let tmp_arr = doc_list_filter.slice();
        return tmp_arr.slice(Number(page.value - 1) * 11, page.value * 11);
      } else {
        let tmp_arr = doc_favorite_list.value.slice();
        return tmp_arr.slice(Number(page.value - 1) * 11, page.value * 11);
      }
      */
    });

    const screen_h = ref(0);
    onMounted(() => {
      /*
      if(screen.availHeight <= 800){
        screen_h.value = 650;
      } else {
        screen_h.value = 844;
      }
      */
      screen_h.value = 844;
      //console.log(screen_h.value);
    });

    const hint_id = ref(0);
    const hint_text = ref('');
    const hint_color = ref('');
    const mouse_x = ref(0);
    const page_hint_over = (e, id_elem) => {
      if(id_elem=='empty'){
        hint_id.value = 0;
      } else {
        hint_id.value = id_elem+1;
        hint_text.value = props.page_arr[id_elem].title;
        mouse_x.value = e.x-e.offsetX+22;

        if(page_complete.value[id_elem] == true){
          hint_color.value = '#6FCF97';
        } else if(page_complete.value[id_elem] == false){
          hint_color.value = '#EB5757';
        }

        if(id_elem+1 == props.page_arr.length){
          hint_color.value = '#000000';
        }
        //console.log(page_complete.value[id_elem]);
      }
      //console.log(e);
      //console.log(page_cur);
    }

    return {
      page_cur,
      page_cur_set,
      page_prev,
      page_next,
      page_complete,
      screen_h,
      page_hint_over,
      hint_id,
      hint_text,
      hint_color,
      mouse_x,
    };
  },
});
</script>

<template>
  <ul id="componentContainer" class="pagination-container"
  :style="
  'margin-left: 1.5rem;'
  //'top: '+screen_h+'px;'
  ">
    <li v-if="nav_btn">
      <button
        class="back-button paginate-buttons"
        :class="{'btn-disabled': page_cur==1}"
        @click="page_prev">
        Назад
      </button>
    </li>
    <li v-for="(item, index) in page_arr">
      <button
        class="paginate-buttons number-buttons"
        :class="
          {'complete-page': page_complete[index] == true && item.complete.length!=0 && page_cur!=index+1},
          {'incomplete-page': page_complete[index] == false && item.complete.length!=0 && page_cur!=index+1},//page_arr.length!=index+1
          {'active-page': page_cur==index+1}
        "
        @click="page_cur_set(index+1)"
        @mouseover="page_hint_over($event, index)"
        @mouseout="page_hint_over($event, 'empty')"
      >
        {{index+1}}{{page_cur==index+1 ? " "+item.title : ""}}
      </button>
    </li>
    <li v-if="nav_btn">
      <button
        class="paginate-buttons next-button"
        @click="page_next"
        :class="{'btn-disabled': page_cur==page_arr.length}"
      >
        Далее
      </button>
    </li>
  </ul>
  <div class="page_hint"
       :style="
          'left:'+(mouse_x-150)+'px;'+
          'border: 1px solid '+hint_color+';'
       "
       :class="{'display_none': 0==hint_id || page_cur==hint_id}"
  >
    {{hint_text}}
  </div>
</template>

<style scoped>
  .display_none {
    display: none;
  }

  .page_hint {
    width: 300px;
    height: 28px;
    position: absolute;
    top: 757px;
    border-radius: 5px;
    background-color: white;
  }

  .pagination-container .back-button {
    background-color: #2F80ED;
    color: white;
    font-size: 14px;
    width: 83px;
    height: 46px;
  }

  .pagination-container .next-button{
    background-color: #2F80ED;
    color: white;
    font-size: 14px;
    width: 83px;
    height: 46px;
  }

  .active-page {
    padding-left: 18px;
    padding-right: 18px;
    /*width: auto;*/
    width: 365px !important;
    border: 1px solid #2F80ED !important;
  }

  .active-page:hover {
    border: 1px solid #2F80ED !important;
  }

  .complete-page {
    border: 1px solid #6FCF97;
  }

  .complete-page:hover {
    border: 1px solid #8BFDBA !important;;
    background-color: white !important;
  }

  .number-buttons:hover {
    background-color: white !important;
    border: 1px solid #2F80ED;
  }

  .incomplete-page {
    border: 1px solid #EB5757;
  }

  .incomplete-page:hover {
    border: 1px solid #FF7171 !important;
    background-color: white !important;
  }

  .btn-disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #E0E0E0 !important;
    background-color: white !important;
  }
</style>