<template>
    <div class="step__three">
        <div class="left">
            <div>
                <label for="name">Введите серию паспорта</label>
                <span>Пример: 2212</span>
                <input
                  class="input"
                  type="text"
                  placeholder="0000"
                  v-model="document.declarer_passport_seria"
                  maxlength="4"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                >
            </div>
            <div>
                <label for="name">Введите номер паспорта</label>
                <span>Пример: 999654</span>
                <input
                  class="input"
                  type="text"
                  placeholder="000000"
                  v-model="document.declarer_passport_number"
                  maxlength="6"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                >
            </div>
            <div>
                <label for="name">Дата выдачи</label>
                <span>Пример: 20.02.2002</span>
                <input
                  class="input"
                  type="date"
                  placeholder="__.__.____"
                  v-model="document.declarer_when_issued"
                  maxlength="10"
                  :max="currentDay"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                >
            </div>
        </div>
        <div class="right">
            <div>
                <label for="name">Кем выдан паспорт</label>
                <span>Пример: Отделом УФМС России по гор. Москва по району Митино</span>
                <textarea style="resize: none; overflow: hidden;" class="input" type="text" placeholder="Введите учреждение" v-model="document.declarer_passport_issued"></textarea>
            </div>
            <div>
                <label for="name">Код подразделения</label>
                <span>Пример: 770-093</span>
                <InputMask v-model="document.declarer_division_code" mask="999-999" placeholder="999-999" />
                <!--
                <input class="input" type="text" placeholder="000-000"
                   v-model="document.declarer_division_code"
                   maxlength="6"
                   onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                   pattern="[0-9]{3}-[0-9]{3}"
                >
                -->
            </div>
            <div>
                <label for="name">Где зарегистрирован</label>
                <span>Пример: г. Москва, ул. Ленина, д. 74, кв. 105</span>
                <textarea style="resize: none; overflow: hidden;" class="input" type="text" placeholder="Введите адрес регистрации" v-model="document.declarer_passport_registration"></textarea>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(
      props: {
          document: any;
      }, context
    ){

        const currentDay = computed(() => {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1).getFullYear()+"-"
                        + ("0"+(new Date(date.getFullYear(), date.getMonth(), 1).getMonth()+1)).slice(-2)+"-"
                        + ("0"+(date.getDate())).slice(-2);  
        });


        return{
            inline: null,
            currentDay
        }
    }
});
</script>