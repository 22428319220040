<template>
  <img alt="Rekprof logo" class="logo_min" src="../../assets/logo.png" />
  <v-menu-proxy-list></v-menu-proxy-list>
  <div class="inner_step_box">
    <span class="main_field">Введите ФИО заявителя</span>
    <div class="hint_left hint_mini_top">Фамилия</div>
    <input
      class="main_input"
      placeholder="Введите фамилию"
      v-model="storeDM.save_obj.declarer_lastname"
    />
    <br />
    <div class="hint_left">Имя</div>
    <input
      class="main_input"
      placeholder="Введите имя"
      v-model="storeDM.save_obj.declarer_firstname"
    />
    <br />
    <div class="hint_left">Отчество</div>
    <input
      class="main_input"
      placeholder="Введите отчество"
      v-model="storeDM.save_obj.declarer_patronymic"
    />
    <br />
    <div class="hint_left">В лице (Генерального Директора)</div>
    <input
      class="main_input"
      placeholder="Генерального Директора"
      v-model="storeDM.save_obj.client_role"
    />
    <br />
    <div class="hint_left">В лице (Иванова Ивана Ивановича)</div>
    <input
      class="main_input"
      placeholder="Иванова Ивана Ивановича"
      v-model="storeDM.save_obj.client_role_fio_genitive"
    />
    <br />
    <div class="hint_left">Выберите пол</div>
    <v-select-open
      :options="declarer_genre_options"
      :title_select="declarer_genre_title"
      :default_select="storeDM.save_obj.declarer_genre"
      :key="storeDM.save_obj.declarer_genre"
      @change-select="declarer_genre_select"
      :margin_top="'-6px'"
      :z_index="1"
    >
    </v-select-open>
    <br />
    <br />
    <br />
    <p class="main_checkbox">
      <input
        type="checkbox"
        id="skip_patronymic"
        v-model="storeDM.save_obj.declarer_patronymic_no"
      />
      <label for="skip_patronymic">Отчество отсутствует</label>
    </p>
    <br />
    <div class="hint_left hint_mini_top">
      {{ signature_decl_file_hint }}
    </div>
    <input
      ref="signature_decl_file"
      type="file"
      hidden
      @change="submitFile('signature_decl_file')"
    />
    <button
      type="button"
      class="blue_btn"
      @click="$refs.signature_decl_file.click()"
      style="float: left; margin-left: 10px"
    >
      Загрузить файл подписи
    </button>
    <br />
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import axios from "axios";
import VMenuProxyList from "@/components/v-menu-proxy-list.vue";
import { useDMDeclStore } from "@/store/dm_decl";

export default defineComponent({
  name: "ProxyStep2",
  emits: ["get_info_event"],
  props: {},
  components: { VMenuProxyList, VSelectOpen },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMDeclStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "3",
      });
      router.push({
        path: "/proxy/step1/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "3_2",
      });
      router.push({
        path: "/proxy/step3/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
      /*
      if (
        storeDM.save_obj.declarer_lastname != "" &&
        storeDM.save_obj.declarer_firstname != "" &&
        ((storeDM.save_obj.declarer_patronymic != "" &&
          storeDM.save_obj.declarer_patronymic_no == false) ||
          storeDM.save_obj.declarer_patronymic_no == true)
      ) {
        return true;
      } else {
        return false;
      }
      */
    });

    const declarer_genre_title = "Выберите пол";
    const declarer_genre_options = [
      { name: "Мужской", value: "1" },
      { name: "Женский", value: "2" },
    ];

    const declarer_genre_select = (opt: any) => {
      storeDM.save_obj.declarer_genre = opt.value;
    };

    const signature_decl_file = ref(null);

    const submitFile = (f_name: string) => {
      let file = Object(eval(f_name).value).files[0];

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.params.docID));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          storeDM.save_obj[Object(req.data).file_name] = Object(req.data)[
            req.data.file_name
          ];
          //console.log(req);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    const signature_decl_file_hint = computed(() => {
      if (typeof storeDM.save_obj.signature_decl_file !== "undefined") {
        if (storeDM.save_obj.signature_decl_file == "") {
          return "Нажмите чтобы загрузить файл подписи";
        } else {
          return (
            'Имя загруженного файла - "' +
            storeDM.save_obj.signature_decl_file.split("/")[
              storeDM.save_obj.signature_decl_file.split("/").length - 1
            ] +
            '"'
          );
        }
      } else {
        return 'Имя загруженного файла - "-не загружен-"';
      }
    });

    return {
      go_back,
      step_next,
      go_next,
      storeDM,
      declarer_genre_title,
      declarer_genre_options,
      declarer_genre_select,
      signature_decl_file,
      signature_decl_file_hint,
      submitFile,
    };
  },
});
</script>

<style scoped></style>
