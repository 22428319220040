<template>
  <div class="step__basic">
    <div class="left">
      <div>
        <label for="name">Выберите количество подписантов</label>
        <span>Выберите из списка</span>
        <Dropdown v-model="employee_title"
                  editable :options="employee_options"
                  optionLabel="name"
                  :placeholder="employee_default.name"
                  style="width:480px"
                  @change="employee_change($event)"
        />
      </div>

      <div style="width: 230px;" v-if="document.employee_count >= 1">
        <label for="name">Выберите подписанта 1</label>
        <span>Выберите подписанта из списка</span>
        <Dropdown v-model="signatory1_name_title"
                  editable :options="signatory_name_options"
                  optionLabel="name"
                  :placeholder="signatory_name_default.name"
                  @change="signatory_name_change($event,1)"
        />
      </div>

      <div v-if="document.employee1_id != 0 && document.employee_count >= 1" style="width: 230px; margin-left: 250px; margin-top: -125px;">
        <label for="name">Укажите должность</label>
        <span>Пример: Ген. дир., Выполнил</span>
        <input style="width: 230px;" class="input" type="text" placeholder="Укажите должность" v-model="document.employee1_name">
      </div>



      <div v-if="document.employee_count >= 3" style="width: 230px;">
        <label for="name">Выберите подписанта 3</label>
        <span>Выберите подписанта из списка</span>
        <Dropdown v-model="signatory3_name_title"
                  editable :options="signatory_name_options"
                  optionLabel="name"
                  :placeholder="signatory_name_default.name"
                  style="width:230px"
                  @change="signatory_name_change($event,3)"
        />
      </div>

      <div v-if="document.employee3_id != 0 && document.employee_count >= 3" style="width: 230px; margin-left: 250px; margin-top: -125px;">
        <label for="name">Укажите должность</label>
        <span>Пример: Ген. дир., Выполнил</span>
        <input style="width: 230px;" class="input" type="text" placeholder="Укажите должность" v-model="document.employee3_name">
      </div>



      <div v-if="document.employee_count >= 5" style="width: 230px;">
        <label for="name">Выберите подписанта 5</label>
        <span>Выберите подписанта из списка</span>
        <Dropdown v-model="signatory5_name_title"
                  editable :options="signatory_name_options"
                  optionLabel="name"
                  :placeholder="signatory_name_default.name"
                  style="width:230px"
                  @change="signatory_name_change($event,5)"
        />
      </div>

      <div v-if="document.employee5_id != 0 && document.employee_count >= 5" style="width: 230px; margin-left: 250px; margin-top: -125px;">
        <label for="name">Укажите должность</label>
        <span>Пример: Ген. дир., Выполнил</span>
        <input style="width: 230px;" class="input" type="text" placeholder="Укажите должность" v-model="document.employee5_name">
      </div>

    </div>
    <div class="right">

      <div v-if="document.employee_count >= 2" style="width: 230px; margin-top: 125px;">
        <label for="name">Выберите подписанта 2</label>
        <span>Выберите подписанта из списка</span>
        <Dropdown v-model="signatory2_name_title"
                  editable :options="signatory_name_options"
                  optionLabel="name"
                  :placeholder="signatory_name_default.name"
                  style="width:230px"
                  @change="signatory_name_change($event,2)"
        />
      </div>

      <div v-if="document.employee2_id != 0 && document.employee_count >= 2" style="width: 230px; margin-left: 250px; margin-top: -125px;">
        <label for="name">Укажите должность</label>
        <span>Пример: Ген. дир., Выполнил</span>
        <input style="width: 230px;" class="input" type="text" placeholder="Укажите должность" v-model="document.employee2_name">
      </div>

      <div v-if="document.employee_count >= 4" style="width: 230px;">
        <label for="name">Выберите подписанта 4</label>
        <span>Выберите подписанта из списка</span>
        <Dropdown v-model="signatory4_name_title"
                  editable :options="signatory_name_options"
                  optionLabel="name"
                  :placeholder="signatory_name_default.name"
                  style="width:230px"
                  @change="signatory_name_change($event,4)"
        />
      </div>

      <div v-if="document.employee4_id != 0 && document.employee_count >= 4" style="width: 230px; margin-left: 250px; margin-top: -125px;">
        <label for="name">Укажите должность</label>
        <span>Пример: Ген. дир., Выполнил</span>
        <input style="width: 230px;" class="input" type="text" placeholder="Укажите должность" v-model="document.employee4_name">
      </div>



      <div v-if="document.employee_count >= 6" style="width: 230px;">
        <label for="name">Выберите подписанта 6</label>
        <span>Выберите подписанта из списка</span>
        <Dropdown v-model="signatory6_name_title"
                  editable :options="signatory_name_options"
                  optionLabel="name"
                  :placeholder="signatory_name_default.name"
                  style="width:230px"
                  @change="signatory_name_change($event,6)"
        />
      </div>

      <div v-if="document.employee6_id != 0 && document.employee_count >= 6" style="width: 230px; margin-left: 250px; margin-top: -125px;">
        <label for="name">Укажите должность</label>
        <span>Пример: Ген. дир., Выполнил</span>
        <input style="width: 230px;" class="input" type="text" placeholder="Укажите должность" v-model="document.employee6_name">
      </div>


    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { useDMStore } from "@/store/dm_obj";
import * as events from "events";

export default defineComponent({
  computed: {
    events() {
      return events
    }
  },
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){
    const storeDM = useDMStore();
    const route = useRoute();


    const employee_default = ref({ name: "0", value: "0" });
    const employee_options = ref([
      { name: "0", value: "0" },
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
      { name: "5", value: "5" },
      { name: "6", value: "6" },
    ]);

    const employee_title = computed(() => {
      if (props.document.employee_count == 0) {
        return null;
      } else {
        return props.document.employee_count;
      }
    });

    const employee_change = (e) => {
      props.document.employee_count = e.value.value;
      for (let i = 0; i < props.document.sketch_count; i++) {
        if ( typeof(props.document.sketch_arr[i]) !== "undefined" && props.document.sketch_arr[i] !== null ) {
        } else {
          props.document.sketch_arr[i] = {};
        }
        if ( typeof(props.document.sketch_arr[i]["name"]) !== "undefined" && props.document.sketch_arr[i]["name"] !== null ) {
        } else {
          props.document.sketch_arr[i]["name"] = "";
        }
        if ( typeof(props.document.sketch_arr[i]["file"]) !== "undefined" && props.document.sketch_arr[i]["file"] !== null ) {
        } else {
          props.document.sketch_arr[i]["file"] = "";
        }
      }
    }


    const signatory_name_default = ref({ name: "Не выбран", value: "0" });
    let signatory_name_options = props.document.signatory_options;
    signatory_name_options[0] = {name: 'Не выбран', value: '0'};

    const signatory1_name_title = computed(() => {
      if(0 == props.document.employee1_id){
        return "Не выбран";
      } else {
        console.log(signatory_name_options);
        let obj = signatory_name_options.find(el => el.value == props.document.employee1_id);
        return Object(obj);
      }
    });

    const signatory2_name_title = computed(() => {
      if(0 == props.document.employee2_id){
        return "Не выбран";
      } else {
        let obj = signatory_name_options.find(el => el.value == props.document.employee2_id);
        return Object(obj);
      }
    });

    const signatory3_name_title = computed(() => {
      if(0 == props.document.employee3_id){
        return "Не выбран";
      } else {
        let obj = signatory_name_options.find(el => el.value == props.document.employee3_id);
        return Object(obj);
      }
    });

    const signatory4_name_title = computed(() => {
      if(0 == props.document.employee4_id){
        return "Не выбран";
      } else {
        let obj = signatory_name_options.find(el => el.value == props.document.employee4_id);
        return Object(obj);
      }
    });

    const signatory5_name_title = computed(() => {
      if(0 == props.document.employee5_id){
        return "Не выбран";
      } else {
        let obj = signatory_name_options.find(el => el.value == props.document.employee5_id);
        return Object(obj);
      }
    });

    const signatory6_name_title = computed(() => {
      if(0 == props.document.employee6_id){
        return "Не выбран";
      } else {
        let obj = signatory_name_options.find(el => el.value == props.document.employee6_id);
        return Object(obj);
      }
    });

    const signatory_name_change = (e, th) => {
      props.document["employee"+th+"_id"] = e.value.value;
    }

    return {
      signatory_name_default,
      signatory_name_options,
      signatory1_name_title,
      signatory2_name_title,
      signatory3_name_title,
      signatory4_name_title,
      signatory5_name_title,
      signatory6_name_title,
      signatory_name_change,
      employee_default,
      employee_options,
      employee_title,
      employee_change,
    }
  }
});
</script>