<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">9. Данные о констукции</span>
    <div class="hint_left hint_mini_top">Наличие и вид подсветки</div>
    <input
      class="main_input"
      placeholder="Введите номер"
      v-model="storeDM.save_obj.number_type_illumination"
    />
    <br />
    <div class="hint_left">Габаритные размеры, м</div>
    <input
      class="first_input"
      placeholder="Введите ширину"
      v-model="storeDM.save_obj.construction_width"
    />
    <input
      class="two_input"
      placeholder="Введите высоту"
      v-model="storeDM.save_obj.construction_height"
    />
    <br />
    <div class="hint_left">Размер информационного поля, м</div>
    <input
      class="first_input"
      placeholder="Введите ширину"
      v-model="storeDM.save_obj.construction_info_width"
    />
    <input
      class="two_input"
      placeholder="Введите высоту"
      v-model="storeDM.save_obj.construction_info_height"
    />
    <br />
    <br />
    <div class="hint_left hint_mini_top">Количество информационных полей</div>
    <v-select-open
      :options="fieldcounts_options"
      :title_select="fieldcounts_title"
      :default_select="storeDM.save_obj.info_count"
      :key="storeDM.save_obj.info_count"
      @change-select="fieldcounts_select"
      :margin_top="'-6px'"
    >
    </v-select-open>
    <br />
    <br />
    <br />
    <button
      class="blue_btn"
      @click="info_field_add"
      style="float: left; margin-left: 10px"
    >
      Добавить дополнительный размер информационного поля
    </button>
    <div>
      <div
        v-for="(input, index) in storeDM.save_obj.construction_info_custom"
        :key="index"
      >
        <br />
        <div class="hint_left">Размер информационного поля, м</div>
        <input
          class="first_sm_input"
          placeholder="Введите ширину"
          :value="input[0]"
          @input="info_set_width(index, $event)"
          @change="info_set_width(index, $event)"
        />
        <input
          class="two_sm_input"
          placeholder="Введите высоту"
          :value="input[1]"
          @input="info_set_height(index, $event)"
          @change="info_set_height(index, $event)"
        />
        <button
          class="blue_small_btn"
          style="float: left; margin-left: 15px; margin-top: 0px"
          @click="info_custom_delete(index)"
        >
          <i class="fa-solid fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepNine",
  components: { VSelectOpen, VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "8",
      });
      router.push({
        path: "/step8/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "10",
      });
      router.push({
        path: "/step10/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const fieldcounts_title = "Выберите количество информационных полей";
    const fieldcounts_options = [
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
    ];
    const fieldcounts_select = (opt: any) => {
      storeDM.save_obj.info_count = opt.value;
    };

    const step_next = computed(() => {
      return true;
    });

    const info_field_add = () => {
      if (storeDM.save_obj.construction_info_custom.length < 5) {
        storeDM.save_obj.construction_info_custom.push(["", ""]);
      }
    };

    const info_custom_delete = (num: any) => {
      storeDM.save_obj.construction_info_custom.splice(num, 1);
    };

    const info_set_width = (num: any, e: any) => {
      storeDM.save_obj.construction_info_custom[num][0] = e.target.value;
    };

    const info_set_height = (num: any, e: any) => {
      storeDM.save_obj.construction_info_custom[num][1] = e.target.value;
    };

    return {
      go_back,
      fieldcounts_title,
      fieldcounts_options,
      fieldcounts_select,
      step_next,
      go_next,
      storeDM,
      info_field_add,
      info_custom_delete,
      info_set_width,
      info_set_height,
    };
  },
});
</script>

<style scoped></style>
