<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9605 5.09939C13.9509 5.07594 13.943 5.05323 13.9316 5.03051C13.8887 4.95285 13.8354 4.87885 13.7601 4.81584L8.51013 0.419754C8.43488 0.356743 8.3465 0.312049 8.25375 0.276148C8.2275 0.265891 8.1995 0.260029 8.1715 0.25197C8.098 0.231455 8.02275 0.218266 7.94488 0.214603C7.92563 0.213138 7.909 0.205078 7.89062 0.205078H1.76562C0.8005 0.205078 0.015625 0.862293 0.015625 1.67044V13.3933C0.015625 14.2015 0.8005 14.8587 1.76562 14.8587H12.2656C13.2308 14.8587 14.0156 14.2015 14.0156 13.3933V5.33384C14.0156 5.31846 14.006 5.30454 14.0042 5.28842C13.9999 5.22394 13.985 5.16093 13.9605 5.09939ZM11.0284 4.60116H8.76562V2.70645L11.0284 4.60116ZM1.76562 13.3933V1.67044H7.01562V5.33384C7.01562 5.73902 7.40675 6.06652 7.89062 6.06652H12.2656L12.2674 13.3933H1.76562Z"
      fill="#333333"
    />
  </svg>
</template>
