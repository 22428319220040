<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">3. Доверенность</span>
    <br />
    <br />
    <span class="main_field">
      Предлагаем Вам воспользоваться услугой формирования доверенности. Данные
      указанные в ней автоматически отобразятся в необходимых для заполнения
      полях заявления
    </span>
    <br />
    <div class="hint_left unselectable" style="margin-bottom: 9px">
      Выбрать доверенность
    </div>
    <v-select-open
      :options="storeDM.save_obj.proxy_options"
      :title_select="storeDM.save_obj.proxy_name"
      @change-select="proxy_select"
      :z_index="1"
      :margin_top="'-9px'"
      :key="storeDM.save_obj.proxy_id"
    >
    </v-select-open>
    <!--
    <br />
    <br />
    <span class="main_field">
      Либо пропустить этот шаг и подгрузить свою доверенность
    </span>
    <br />
    <button
      type="button"
      class="white_btn"
      @click="upload_proxy()"
      style="float: left; margin-left: 10px"
    >
      Подгрузить доверенность
    </button>
    <br />
    <br />
    <br />
    <br />
    <br />
    -->
    <!--
    <p class="main_checkbox">
      <input
        type="checkbox"
        id="skip_proxy"
        v-model="storeDM.save_obj.declarer_no"
      />
      <label for="skip_proxy">Продолжить без доверенности</label>
    </p>
    -->
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { defineComponent, ref, computed } from "vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";
import VSelectOpen from "@/components/v-select-open.vue";

export default defineComponent({
  name: "StepThree",
  components: { VMenuList, VSelectOpen },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const proxy_title = "Продолжить вручную";
    const proxy_select = (opt: any) => {
      //signatory.value = opt.value;
      storeDM.save_obj.proxy_id = opt.value;
      storeDM.save_obj.proxy_name = opt.name;
      //console.log(storeDM.save_obj.proxy_id);
    };

    const go_back = () => {
      storeDM.set_info({
        step: "2",
      });
      router.push({
        path: "/step2/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      if (storeDM.save_obj.declarer_no == true) {
        storeDM.set_info({
          step: "4",
        });
        router.push({
          path: "/step4/" + route.params.docID,
          query: {
            hash: route.query.hash,
          },
        });
      } else {
        if (storeDM.save_obj.proxy_id != 0) {
          storeDM.set_info({
            step: "3_4",
          });
          router.push({
            path: "/step3_4/" + route.params.docID,
            query: {
              hash: route.query.hash,
            },
          });
        } else {
          storeDM.set_info({
            step: "3_1",
          });
          router.push({
            path: "/step3_1/" + route.params.docID,
            query: {
              hash: route.query.hash,
            },
          });
        }
      }
    };
    const step_next = computed(() => {
      return true;
    });

    return {
      go_back,
      go_next,
      step_next,
      storeDM,
      proxy_select,
      proxy_title,
    };
  },
});
</script>

<style scoped></style>
