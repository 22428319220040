<template>
  <div class="menu_block">
    <!--
    <button
      type="button"
      class="blue_half_btn"
      @click="go_list()"
      style="float: left; margin-left: -1px"
    >
      К списку
    </button>
    -->
    <!-- <button type="button" class="blue_half_btn" @click="go_new()">Выход</button> -->
    <br />
    <br />
    <br />
    <button
      v-for="(val, key) in pages_arr"
      :key="key"
      type="button"
      :class="val.class"
      @click="go_step(val.key)"
    >
      {{ val.name }}
    </button>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
} from "vue";

import { useRoute, useRouter } from "vue-router";
import { useDMDeclStore } from "@/store/dm_decl";

export default defineComponent({
  name: "v-menu-proxy-list",
  emits: ["change-menu"],
  setup(props, context) {
    const storeDM = useDMDeclStore();
    const router = useRouter();
    const route = useRoute();
    //blue_active_btn
    //blue_inactive_btn
    //red_btn
    //blue_btn

    const pages_arr = computed(() => {
      let p_arr = [
        {
          name: "1. Выберите тип заявителя",
          key: "1",
          class: "blue_inactive_btn",
        },
        {
          name: "2. ФИО заявителя",
          key: "2",
          class: "blue_inactive_btn",
        },
        {
          name: "3. Паспорт заявителя",
          key: "3",
          class: "blue_inactive_btn",
        },
        {
          name: "4. Срок действия",
          key: "4",
          class: "blue_inactive_btn",
        },
        {
          name: "5. Подписанты доверенности",
          key: "5",
          class: "blue_inactive_btn",
        },
        {
          name: "6. Сохранение доверенности",
          key: "8",
          class: "blue_inactive_btn",
        },
      ];

      if (
        typeof storeDM.save_obj.steps !== "undefined" &&
        storeDM.save_obj.steps !== null
      ) {
        let latest_step = 1;
        let red_it = false;
        for (let i = 0; i < p_arr.length; i++) {
          if (storeDM.save_obj.steps[i + 1] == 1) {
            p_arr[i].class = "blue_complete_btn";
          } else {
            //console.log(1);
            if (latest_step == 1 && red_it == false) {
              red_it = true;
              latest_step = i;
              //p_arr[i].class = "red_btn";
              p_arr[i].class = "blue_inactive_btn";
            } else {
              p_arr[i].class = "blue_inactive_btn";
            }
          }

          let route_name = String(route.name).substr(4);
          route_name = route_name.split("_")[0];
          let route_name_num = Number(route_name) - 1;
          Object(p_arr[Number(route_name_num)]).class = "blue_active_btn";

          if (Number(route_name_num) > latest_step && red_it == true) {
            p_arr[latest_step].class = "red_btn";
          }
        }
      }

      return p_arr;
    });

    /*
    const pages_arr = reactive([
      {
        name: "1. Выберите тип заявителя",
        key: "1",
        class: "blue_inactive_btn",
      },
      {
        name: "2. Выбор способа подачи",
        key: "2",
        class: "blue_inactive_btn",
      },
      {
        name: "3. Доверенность",
        key: "3",
        class: "blue_inactive_btn",
      },
      {
        name: "4. Адрес и карта",
        key: "4",
        class: "blue_inactive_btn",
      },
      {
        name: "5. Информация о конструкции",
        key: "5",
        class: "blue_inactive_btn",
      },
      {
        name: "6. Фотофиксация о конструкции",
        key: "6",
        class: "blue_inactive_btn",
      },
      {
        name: "7. Компьютерный монтаж конструкций",
        key: "7",
        class: "blue_inactive_btn",
      },
      {
        name: "8. Эскизный проект конструкций",
        key: "8",
        class: "blue_inactive_btn",
      },
      {
        name: "9. Данные о конструкции",
        key: "9",
        class: "blue_inactive_btn",
      },
      {
        name: "10. Данные о проектировщике",
        key: "10",
        class: "blue_inactive_btn",
      },
      {
        name: "11. Предварительный просмотр",
        key: "11",
        class: "blue_inactive_btn",
      },
    ]);

    const updateMenu = async () => {
      if (
        typeof storeDM.save_obj.steps == "undefined" &&
        storeDM.save_obj.steps == null
      ) {
        setTimeout(() => {
          updateMenu();
        }, 100);
      }

      //console.log(pages_arr);
      let latest_step = 1;
      let red_it = false;
      for (let i = 0; i < pages_arr.length; i++) {
        if (storeDM.save_obj.steps[i + 1] == 1) {
          pages_arr[i].class = "blue_complete_btn";
        } else {
          //console.log(1);
          if (latest_step == 1 && red_it == false) {
            red_it = true;
            latest_step = i;
            //pages_arr[i].class = "red_btn";
            pages_arr[i].class = "blue_inactive_btn";
          } else {
            pages_arr[i].class = "blue_inactive_btn";
          }
        }

        let route_name = String(route.name).substr(4);
        route_name = route_name.split("_")[0];
        let route_name_num = Number(route_name) - 1;
        Object(pages_arr[Number(route_name_num)]).class = "blue_active_btn";

        if (Number(route_name_num) > latest_step && red_it == true) {
          pages_arr[latest_step].class = "red_btn";
        }
      }
      //console.log(pages_arr);
    };

    if (
      typeof storeDM.save_obj.steps !== "undefined" &&
      storeDM.save_obj.steps !== null
    ) {
      updateMenu();
    } else {
      setTimeout(() => {
        updateMenu();
      }, 100);
    }
    */

    const isMobile = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    };

    const go_step = (step_str: string) => {
      storeDM.set_info({
        step: step_str,
      });
      router.push({
        path: "/proxy/step" + step_str + "/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_new = () => {
      router.push({
        path: "/",
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_list = () => {
      router.push({
        path: "/list",
        query: {
          hash: route.query.hash,
        },
      });
    };

    return {
      isMobile,
      go_step,
      go_new,
      go_list,
      pages_arr,
      //updateMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.menu_block {
  position: absolute;
  right: 10px;
}
</style>
