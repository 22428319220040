<template>
    <div class="step__basic">
        <div class="left">
            <div>
              <label for="name">Выберите начало адреса</label>
              <Dropdown v-model="begin_address_title"
                        editable :options="begin_address_options"
                        optionLabel="name"
                        :placeholder="begin_address_default.name"
                        style="width:480px"
                        @change="begin_address_change"
              />
            </div>
            <div>
                <label for="name">Введите продолжение адреса</label>
                <span class="blueSpan">Пример: Ленина, реки Фонтанки</span>
                <input class="input" type="text" placeholder="Введите продолжение адреса" v-model="document.map_address">
            </div>
            <div class="input__groups">
                    <div>
                        <label for="name">Номер дома</label>
                        <span class="blueSpan">Пример: 132</span>
                        <input class="input"
                         type="text"
                         v-model="document.map_house"
                         maxlength="10"
                         >
                    </div>
                    <div>
                        <label for="title">Корпус</label>
                        <span class="blueSpan">Пример: 1, отсутствует, другое</span>
                        <input class="input" type="text" placeholder="1" v-model="document.map_korpus">
                    </div>
                    <div>
                        <label for="name">Литера или строение</label>
                        <span class="blueSpan">Пример: литера А, стр 2; стр 15; -</span>
                        <input class="input" type="text" placeholder="0" v-model="document.map_build_litera">
                    </div>
            </div>
            <div>
                <label for="name">Введите пересечение с домом или павильон, если имеется</label>
                <input class="input" type="text" placeholder="Павильон, пересечение с домом Ленина, д.23" v-model="document.map_cross">
            </div>
        </div>
        <div class="right">
            <div>
                <label for="name">Выберите район</label>
                <Dropdown v-model="region_title"
                          editable :options="region_options"
                          optionLabel="name"
                          :placeholder="region_default.name"
                          style="width:480px"
                          @change="region_change"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../assets/icons/DropdownUp.vue";
import Map from '../../assets/icons/exampleMap.vue'
import YandexMap from "@/components/YandexMap.vue";
import {computed, defineComponent, reactive, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {useDMStore} from "@/store/dm_obj";
export default defineComponent ({
    components: {
        DropdownIcon,
        DropdownUp,
        Map,
        YandexMap
    },
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){

    const route = useRoute();

    const storeDM = useDMStore();

    const begin_address_default = ref({ name: "Выберите начало адреса", value: "" });
    const begin_address_options = ref([
      { name: "Улица", value: "Улица" },
      { name: "Бульвар", value: "Бульвар" },
      { name: "Дорога", value: "Дорога" },
      { name: "Переулок", value: "Переулок" },
      { name: "Площадь", value: "Площадь" },
      { name: "Проспект", value: "Проспект" },
      { name: "Шоссе", value: "Шоссе" },
    ]);

    const begin_address_title = computed(() => {
      if (props.document.map_street == "") {
        return null;
      } else {
        return props.document.map_street;
      }
    });


    const region_default = ref({ name: "Выберите район", value: "" });
    const region_options = ref([
      { name: "Выборгский", value: "Выборгский" },
      { name: "Московский", value: "Московский" },
      { name: "Петродворцовый", value: "Петродворцовый" },
      { name: "Курортный", value: "Курортный" },
      { name: "Приморский", value: "Приморский" },
      { name: "Пушкинский", value: "Пушкинский" },
      { name: "Петроградский", value: "Петроградский" },
      { name: "Василеостровский", value: "Василеостровский" },
      { name: "Красносельский", value: "Красносельский" },
      { name: "Невский", value: "Невский" },
      { name: "Центральный", value: "Центральный" },
      { name: "Адмиралтейский", value: "Адмиралтейский" },
      { name: "Фрунзенский", value: "Фрунзенский" },
      { name: "Калининский", value: "Калининский" },
      { name: "Кировский", value: "Кировский" },
      { name: "Кронштадский", value: "Кронштадский" },
      { name: "Колпинский", value: "Колпинский" },
      { name: "Красногвардейский", value: "Красногвардейский" },
    ]);

    const region_title = computed(() => {
      if (props.document.map_district == "") {
        return;
      } else {
        return props.document.map_district;
      }
    });

    //const region_title = reactive({name:props.document.map_district, value:props.document.map_district});


    const isShow = ref(false);
    const isRegion = ref(false);
    const isOpen = (obj, type) => {
      if(type == "begin_address"){
        props.document.map_street = obj.value;
        isShow.value = false;
      } else if(type == "region"){
        props.document.map_district = obj.value;
        isRegion.value = false;
      }
    }

    const subpage = ref(1);

    const map_file = ref(null);

    const submitFile = (f_name: string) => {
      let file = Object(eval(f_name).value).files[0];
      if(file == undefined){
        return;
      }

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.query.id));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          props.document[Object(req.data).file_name] = Object(req.data)[
            req.data.file_name
            ];
          //console.log(props.document[Object(req.data).file_name]);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    const begin_address_change = (e) => {
      props.document.map_street = e.value.value;
    }

    const region_change = (e) => {
      props.document.map_district = e.value.value;
    }

    return{
        subpage,
        begin_address_default,
        begin_address_options,
        begin_address_title,
        begin_address_change,
        region_default,
        region_options,
        region_title,
        region_change,
        isShow,
        isRegion,
        isOpen,
        map_file,
        submitFile,
    }
  },
});
</script>