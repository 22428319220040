<template>
  <div class="step__basic">
    <div class="left">
      <div>
        <label for="name">Выберите или введите название проектировщика</label>
        <span>Пример: ООО "КГ "Север""</span>
        <Dropdown v-model="designer_name_title"
                  editable :options="designer_name_options"
                  optionLabel="name"
                  :placeholder="designer_name_default.name"
                  style="width:480px"
                  @change="designer_name_change"
        />
        <input class="input" type="text" placeholder="Введите название проектировщика" v-model="document.designer_name" v-if="document.designer_name != designer_name_default.value">
      </div>
      <div>
        <label for="name">Выберите или введите название изготовителя</label>
        <span>Пример: ООО "КГ "Север""</span>
        <Dropdown v-model="manufacturer_name_title"
                  editable :options="manufacturer_name_options"
                  optionLabel="name"
                  :placeholder="manufacturer_name_default.name"
                  style="width:480px"
                  @change="manufacturer_name_change"
        />
        <input class="input" type="text" placeholder="Введите название изготовителя" v-model="document.manufacturer_name" v-if="document.manufacturer_name != designer_name_default.value">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../assets/icons/DropdownUp.vue";
import {computed, defineComponent, ref} from "vue";
export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){

    const designer_name_default = ref({ name: 'ООО "КГ "Север""', value: 'ООО "КГ "Север""' });
    const designer_name_options = ref([
      { name: 'ООО "КГ "Север""', value: 'ООО "КГ "Север""' },
      { name: "Другой", value: "" },
    ]);

    const designer_name_title = computed(() => {
      if('ООО "КГ "Север""' != props.document.designer_name){
        return "Другой";
      } else {
        return props.document.designer_name;
      }
    });

    const manufacturer_name_default = ref({ name: 'ООО "КГ "Север""', value: 'ООО "КГ "Север""' });
    const manufacturer_name_options = ref([
      { name: 'ООО "КГ "Север""', value: 'ООО "КГ "Север""' },
      { name: "Другой", value: "" },
    ]);

    const manufacturer_name_title = computed(() => {
      if('ООО "КГ "Север""' != props.document.manufacturer_name){
        return "Другой";
      } else {
        return props.document.manufacturer_name;
      }
    });

    const designer_name_change = (e) => {
      props.document.designer_name = e.value.value;
    }

    const manufacturer_name_change = (e) => {
      props.document.manufacturer_name = e.value.value;
    }

    return{
      designer_name_default,
      designer_name_options,
      designer_name_title,
      designer_name_change,
      manufacturer_name_default,
      manufacturer_name_options,
      manufacturer_name_title,
      manufacturer_name_change,
    }
  },
  components: {
    DropdownIcon,
    DropdownUp,
  },
});
</script>

<style>

</style>