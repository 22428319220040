import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-060c7df2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal_window"
}
const _hoisted_2 = {
  key: 0,
  class: "info_txt"
}
const _hoisted_3 = { style: {"width":"225px","margin-top":"120px","margin-left":"62.5px"} }
const _hoisted_4 = {
  key: 1,
  class: "info_txt"
}
const _hoisted_5 = { style: {"width":"225px","margin-top":"120px","margin-left":"62.5px"} }
const _hoisted_6 = { class: "wrapper_btn" }
const _hoisted_7 = {
  key: 1,
  class: "modal_block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, "✖"),
          (_ctx.info_txt!='этого подписанта')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, "Вы действительно хотите удалить " + _toDisplayString(_ctx.info_txt) + " " + _toDisplayString(_ctx.info_num) + "?", 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, "Вы действительно хотите удалить " + _toDisplayString(_ctx.info_txt) + "?", 1)
              ])),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "do_btn",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.do_btn && _ctx.do_btn(...args)))
            }, "Удалить")
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
      : _createCommentVNode("", true)
  ], 64))
}