<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">3.2 Сохранение доверенности</span>
    <br />
    <span class="main_field">
      Вы можете подписать доверенность электронной подписью или скачать её для
      подписания в удобном Вам формате
    </span>
    <br />
    <div class="hint_left">Нажмите чтобы скачать доверенность</div>
    <button
      type="button"
      class="blue_btn"
      @click="download_signatory"
      style="float: left; margin-left: 10px"
    >
      Скачать доверенность
    </button>
    <br />
    <br />
    <br />
    <br />
    <span class="main_field">
      На этом работа с доверенностью окончена и мы переходим к формированию
      заявления
    </span>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";
import axios from "axios";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepThreeSeven",
  components: { VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      router.go(-1);
    };

    const go_next = () => {
      storeDM.set_info({
        step: "4",
      });
      router.push({
        path: "/step4/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
    });

    const download_signatory = () => {
      if (storeDM.save_obj.proxy_id != 0) {
        downloadItem(
          storeDM.api_url +
            "proxy.php?id=" +
            storeDM.save_obj.proxy_id +
            "&hash=" +
            route.query.hash +
            "&type=declarations",
          "Доверенность " + storeDM.save_obj.proxy_id
        );
      } else {
        downloadItem(
          storeDM.api_url +
            "proxy.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&type=documents",
          "Доверенность " + route.params.docID
        );
      }
    };

    const downloadItem = (url: string, label: string) => {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    };

    return {
      go_back,
      step_next,
      go_next,
      download_signatory,
    };
  },
});
</script>

<style scoped></style>
