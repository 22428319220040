<template>
    <div v-if="stat.statuser==0" :key="stat.statuser_key">
    </div>
    <div v-if="stat.statuser==2" :key="stat.statuser_key">
        <div class="select_user">
        <Dropdown v-model="client_name_title"
                  editable :options="client_name_options"
                  optionLabel="name"
                  :placeholder="client_name_default.name"
                  @change="client_name_change($event)"
                  style="width: 494px; margin-left: 20px; margin-top: 20px;"
        />
        </div>
        <div v-if="stat.statuser==2 && client_id=='0'">
            <div class="pie2_admin">
                <div class="pie_admin_el">
                    <div class="pie_title" style="padding-left: 30px;">Всего заявлений</div>
                    <div class="pie_admin_num1">{{ stat.statements_all }}</div>
                    <GChart
                        type="PieChart" v-if="stat.statements_pie.length>1"
                        :data="stat.statements_pie"
                        :options="pie1Options_admin"
                        :key="stat.statements_key"
                    />
                </div>
                <div class="pie_admin_el">
                    <div class="pie_title">Всего доверенностей</div>
                    <div class="pie_admin_num2">{{ stat.declarations_all }}</div>
                    <GChart
                        type="PieChart" v-if="stat.declarations_pie.length>1"
                        :data="stat.declarations_pie"
                        :options="pie2Options_admin"
                        :key="stat.declarations_key"
                    />
                </div>

                <div class="user_list">
                    <table class="user_list_table">
                        <tr class="user_list_head"><td>Пользователь</td><td>Заявления</td><td>Доверенности</td></tr>
                        <tr class="user_list_content" v-for="item in stat.info_arr"><td>{{ item[0] }}</td><td>{{ item[1] }}</td><td>{{ item[2] }}</td></tr>
                    </table>
                </div>
            </div>

            <div class="users_signs">
                <div class="users_block">
                    <div class="users_block_title">Посетителей всего/сегодня</div>
                    <div class="users_block_all">{{ stat.users_all }}</div>
                    <div class="users_slash">/</div>
                    <div class="users_block_today">{{ stat.users_today }}</div>
                </div>
                <div class="signs_block">
                    <div class="signs_block_title">Добавлено подписантов всего/сегодня</div>
                    <div class="signs_block_all">{{ stat.signs_all }}</div>
                    <div class="signs_slash">/</div>
                    <div class="signs_block_today">{{ stat.signs_today }}</div>
                </div>
            </div>

            <div class="calendar" style="height: 406px !important;">
                <input class="date_el" v-model="firstDay_admin" type="date" @change="get_stat" />
                <input class="date_el" v-model="lastDay_admin" style="margin-left:7px;" type="date" @change="get_stat" />
                <VCalendar style="width:328px; height:341px; margin-top: 7px; border: none; border-radius: 2px;" @dayclick="change_modal_cal_admin" />
            </div>

            <div class="new_action">
                <div class="new_action_title">Новых действий сегодня</div>
                <div class="new_action_num" v-if="stat.actions_day_all!=0">{{ stat.actions_day_all }}</div>
                <div class="new_action_num" v-if="stat.actions_day_all==0">Новых действий произведено не было</div>
                <table class="new_action_table" v-if="stat.actions_day_all!=0">
                    <tr class="new_action_head"><td>{{ stat.statements_day_print }}</td><td>{{ stat.statements_day_noprint }}</td><td>{{ stat.declarations_day_all }}</td><td>{{ stat.signatories_day_all }}</td></tr>
                    <tr class="new_action_content"><td>Завершённые заявления</td><td>Незавершённые заявления</td><td>Новые доверенности</td><td>Новые подписанты</td></tr>
                </table>
            </div>
        </div>
        <div v-if="stat.statuser==2 && client_id!='0'">
            <div class="pie2">
                <div class="pie_el">
                    <div class="pie_title" style="padding-left: 30px;">Всего заявлений</div>
                    <div class="pie_num1">{{ stat.statements_all }}</div>
                    <GChart v-if="stat.statements_pie.length>1"
                        type="PieChart"
                        :data="stat.statements_pie"
                        :options="pie1Options"
                        :key="stat.statements_key"
                    />
                </div>
                <div class="pie_el">
                    <div class="pie_title">Всего доверенностей</div>
                    <div class="pie_num2">{{ stat.declarations_all }}</div>
                    <GChart v-if="stat.declarations_pie.length>1"
                        type="PieChart"
                        :data="stat.declarations_pie"
                        :options="pie2Options"
                        :key="stat.declarations_key"
                    />
                </div>
            </div>

            <div class="calendar">
                <input class="date_el" type="date" v-model="firstDay" @change="get_stat" />
                <input class="date_el" style="margin-left:7px;" type="date" v-model="lastDay" @change="get_stat" />
                <VCalendar style="width:328px; height:378px; margin-top: 7px; border: none; border-radius: 2px;" @dayclick="change_modal_cal" />
            </div>

            <div class="area1">
                <div class="area_title">Активность в текущем месяце</div>
                <div class="area_num">{{ stat.areachart_all }}</div>
                <GChart
                    type="AreaChart"
                    :data="stat.areachart"
                    :options="chartOptions"
                    :key="stat.areachart_key"
                />
            </div>

            <div class="new_action">
                <div class="new_action_title">Новых действий сегодня</div>
                <div class="new_action_num" v-if="stat.actions_day_all!=0">{{ stat.actions_day_all }}</div>
                <div class="new_action_num" v-if="stat.actions_day_all==0">Новых действий произведено не было</div>
                <table class="new_action_table" v-if="stat.actions_day_all!=0">
                    <tr class="new_action_head"><td>{{ stat.statements_day_print }}</td><td>{{ stat.statements_day_noprint }}</td><td>{{ stat.declarations_day_all }}</td><td>{{ stat.signatories_day_all }}</td></tr>
                    <tr class="new_action_content"><td>Завершённые заявления</td><td>Незавершённые заявления</td><td>Новые доверенности</td><td>Новые подписанты</td></tr>
                </table>
            </div>
        </div>
    </div>
    <div v-if="stat.statuser==1" :key="stat.statuser_key">
        <div class="pie2">
            <div class="pie_el">
                <div class="pie_title" style="padding-left: 30px;">Всего заявлений</div>
                <div class="pie_num1">{{ stat.statements_all }}</div>
                <GChart v-if="stat.statements_pie.length>1"
                    type="PieChart"
                    :data="stat.statements_pie"
                    :options="pie1Options"
                    :key="stat.statements_key"
                />
            </div>
            <div class="pie_el">
                <div class="pie_title">Всего доверенностей</div>
                <div class="pie_num2">{{ stat.declarations_all }}</div>
                <GChart v-if="stat.declarations_pie.length>1"
                    type="PieChart"
                    :data="stat.declarations_pie"
                    :options="pie2Options"
                    :key="stat.declarations_key"
                />
            </div>
        </div>

        <div class="calendar">
            <input class="date_el" type="date" v-model="firstDay" @change="get_stat" />
            <input class="date_el" style="margin-left:7px;" type="date" v-model="lastDay" @change="get_stat" />
            <VCalendar style="width:328px; height:378px; margin-top: 7px; border: none; border-radius: 2px;" @dayclick="change_modal_cal" />
        </div>

        <div class="area1">
            <div class="area_title">Активность в текущем месяце</div>
            <div class="area_num">{{ stat.areachart_all }}</div>
            <GChart
                type="AreaChart"
                :data="stat.areachart"
                :options="chartOptions"
                :key="stat.areachart_key"
            />
        </div>

        <div class="new_action">
            <div class="new_action_title">Новых действий сегодня</div>
            <div class="new_action_num" v-if="stat.actions_day_all!=0">{{ stat.actions_day_all }}</div>
            <div class="new_action_num" v-if="stat.actions_day_all==0">Новых действий произведено не было</div>
            <table class="new_action_table" v-if="stat.actions_day_all!=0">
                <tr class="new_action_head"><td>{{ stat.statements_day_print }}</td><td>{{ stat.statements_day_noprint }}</td><td>{{ stat.declarations_day_all }}</td><td>{{ stat.signatories_day_all }}</td></tr>
                <tr class="new_action_content"><td>Завершённые заявления</td><td>Незавершённые заявления</td><td>Новые доверенности</td><td>Новые подписанты</td></tr>
            </table>
        </div>
    </div>
    
    
   
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useDMStore } from "@/store/dm_obj";
import { useRoute, useRouter } from "vue-router";
import { GChart } from 'vue-google-charts';
import axios from "axios";

export default defineComponent({
    name: "Statistics",
    components: {
        GChart,
    },
    setup(props, context) {
        const storeDM = useDMStore();
        const route = useRoute();
        const router = useRouter();

        
        

        const client_name_default = ref({ name: "Все пользователи", value: "0" });
        let client_name_options = ref();
        client_name_options[0] = client_name_default.value;
        const client_name_title = computed(() => {
            let obj = client_name_options.value.find(el => el.value == Number(client_id.value));
            return Object(obj);
        });

        const client_id = ref("0");
        const client_name_change = (e) => {
            client_id.value = e.value.value;

            get_stat();
        }
        

        let date = new Date();

        //2024-02-23
        let firstDay = ref(new Date(date.getFullYear(), date.getMonth(), 1).getFullYear()+"-"
                        + ("0"+(new Date(date.getFullYear(), date.getMonth(), 1).getMonth()+1)).slice(-2)+"-"
                        + ("0"+(new Date(date.getFullYear(), date.getMonth(), 1).getDate())).slice(-2) );
        let lastDay = ref(new Date(date.getFullYear(), date.getMonth() + 1, 0).getFullYear()+"-"
                        + ("0"+(new Date(date.getFullYear(), date.getMonth() + 1, 0).getMonth()+1)).slice(-2)+"-"
                        + ("0"+(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate())).slice(-2) );

        let firstDay_admin = ref();
        let lastDay_admin = ref();
        
        const change_modal_cal = (d1, d2) => {
            firstDay.value = d1.id;
            lastDay.value = d1.id;
            
            get_stat();
        }

        const change_modal_cal_admin = (d1, d2) => {
            firstDay_admin.value = d1.id;
            lastDay_admin.value = d1.id;
            
            get_stat();
        }

        let chartData = [
            ['День', 'Заявления', 'Доверенности', 'Подписанты'],
            ['1', 0, 0, 0],
            ['2', 0, 0, 0],
            ['3', 0, 0, 0],
            ['4', 0, 0, 0],
            ['5', 0, 0, 0],
            ['6', 0, 0, 0],
            ['7', 0, 0, 0],
            ['8', 0, 0, 0],
            ['9', 0, 0, 0],
            ['10', 0, 0, 0],
            ['11', 0, 0, 0],
            ['12', 0, 0, 0],
            ['13', 0, 0, 0],
            ['14', 0, 0, 0],
            ['15', 0, 0, 0],
            ['16', 0, 0, 0],
            ['17', 0, 0, 0],
            ['18', 0, 0, 0],
            ['19', 0, 0, 0],
            ['20', 0, 0, 0],
            ['21', 0, 0, 0],
            ['22', 0, 0, 0],
            ['23', 0, 0, 0],
            ['24', 0, 0, 0],
            ['25', 0, 0, 0],
            ['26', 0, 0, 0],
            ['27', 0, 0, 0],
            ['28', 0, 0, 0],
            ['29', 0, 0, 0],
            ['30', 0, 0, 0],
            ['31', 0, 0, 0],
        ];
        const chartOptions = {
            title: 'Активность в текущем месяце',
            titlePosition: 'none',
            hAxis: { title: '', titleTextStyle: { color: '#333' }, textStyle: {fontSize: '8'} },
            vAxis: { minValue: 0, textPosition: 'none', gridlines: {color:"transparent"} },
            chartArea: { width: '90%', height: '70%' },
            legend: { position: 'bottom', alignment: 'start' },
            backgroundColor: 'transparent',
            width: 656,
            height: 200,
        };

        const pie1Data = [
            ['Заявлений', 'Заявлений в день'],
            ['1', 100],
        ];

        const pie2Data = [
            ['Доверенностей', 'Доверенностей в день'],
            ['1', 100],
        ];

        const pie1Options = {
            title: 'Всего заявлений',
            pieHole: 0.8,
            backgroundColor: 'transparent',
            width: 328,
            height: 437,
            legend: {position: 'none'},
            pieSliceText: 'none',
            chartArea: {left:30, top:0, width:'85%', height:'85%'},
            slices: { 0: { color: '#369CFB' }, 1: { color: '#32C7C7' }, 2: { color: '#4AC76F' }, 3: { color: '#F6CF33' }, 4: { color: '#EE5F77' },
                      5: { color: '#935BE0' }, 6: { color: '#7CBAF3' }, 7: { color: '#7AD3D3' }, 8: { color: '#88D39F' }, 9: { color: '#F0D87B' },
                      10: { color: '#EB95A2' }, 11: { color: '#B493E1' } },
        };

        const pie2Options = {
            title: 'Всего доверенностей',
            pieHole: 0.8,
            backgroundColor: 'transparent',
            width: 328,
            height: 437,
            legend: {position: 'none'},
            pieSliceText: 'none',
            chartArea: {left:15, top:0, width:'85%', height:'85%'},
            slices: { 0: { color: '#369CFB' }, 1: { color: '#32C7C7' }, 2: { color: '#4AC76F' }, 3: { color: '#F6CF33' }, 4: { color: '#EE5F77' },
                      5: { color: '#935BE0' }, 6: { color: '#7CBAF3' }, 7: { color: '#7AD3D3' }, 8: { color: '#88D39F' }, 9: { color: '#F0D87B' },
                      10: { color: '#EB95A2' }, 11: { color: '#B493E1' } },
        };

        const pie1Options_admin = {
            title: 'Всего заявлений',
            pieHole: 0.8,
            backgroundColor: 'transparent',
            width: 238,
            height: 280,
            legend: {position: 'none'},
            pieSliceText: 'none',
            chartArea: {left:30, top:0, width:'85%', height:'85%'},
            slices: { 0: { color: '#369CFB' }, 1: { color: '#32C7C7' }, 2: { color: '#4AC76F' }, 3: { color: '#F6CF33' }, 4: { color: '#EE5F77' },
                      5: { color: '#935BE0' }, 6: { color: '#7CBAF3' }, 7: { color: '#7AD3D3' }, 8: { color: '#88D39F' }, 9: { color: '#F0D87B' },
                      10: { color: '#EB95A2' }, 11: { color: '#B493E1' } },
        };

        const pie2Options_admin = {
            title: 'Всего доверенностей',
            pieHole: 0.8,
            backgroundColor: 'transparent',
            width: 238,
            height: 280,
            legend: {position: 'none'},
            pieSliceText: 'none',
            chartArea: {left:15, top:0, width:'85%', height:'85%'},
            slices: { 0: { color: '#369CFB' }, 1: { color: '#32C7C7' }, 2: { color: '#4AC76F' }, 3: { color: '#F6CF33' }, 4: { color: '#EE5F77' },
                      5: { color: '#935BE0' }, 6: { color: '#7CBAF3' }, 7: { color: '#7AD3D3' }, 8: { color: '#88D39F' }, 9: { color: '#F0D87B' },
                      10: { color: '#EB95A2' }, 11: { color: '#B493E1' } },
        };
        
        const stat = reactive({actions_day_all:0, declarations_day_all:0,
            signatories_day_all:0, statements_day_print:0,
            statements_day_noprint:0, 
            areachart: chartData, areachart_key:0, areachart_all: 0,
            statements_pie: pie1Data, statements_key: 0, statements_all: 0,
            declarations_pie: pie2Data, declarations_key: 0, declarations_all: 0,
            statuser: 0, statuser_key: 0, info_arr: [],
            users_all: 0, users_today: 0, signs_all: 0, signs_today: 0
        });

        const get_stat = () => {

            let formData = new FormData();
            formData.append("type", "get_statistics");
            formData.append("hash", String(route.query.hash));
            formData.append("firstDay", firstDay.value);
            formData.append("lastDay", lastDay.value);
            formData.append("firstDay_admin", firstDay_admin.value);
            formData.append("lastDay_admin", lastDay_admin.value);
            formData.append("client_id", client_id.value);
            axios
                .post(storeDM.api_url + "statistics.php", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                })
                .then(function (req) {
                    if(Object(req.data).statuser==1 || (Object(req.data).statuser==2 && client_id.value != "0") ){
                        stat.actions_day_all = Object(req.data).actions_day_all;
                        stat.declarations_day_all = Object(req.data).declarations_day_all;
                        stat.signatories_day_all = Object(req.data).signatories_day_all;
                        stat.statements_day_print = Object(req.data).statements_day_print;
                        stat.statements_day_noprint = Object(req.data).statements_day_noprint;
                        
                        //console.log(Object.values(Object(req.data).areachart));
                        stat.areachart = Object.values(Object(req.data).areachart);
                        stat.areachart_key = Object(req.data).areachart_key;
                        stat.areachart_all = Object(req.data).areachart_all;
                        
                        
                        stat.statements_pie = Object.values(Object(req.data).statements_pie);
                        stat.statements_key = Object(req.data).statements_key;
                        stat.statements_all = Object(req.data).statements_all;

                        stat.declarations_pie = Object.values(Object(req.data).declarations_pie);
                        stat.declarations_key = Object(req.data).declarations_key;
                        stat.declarations_all = Object(req.data).declarations_all;

                        stat.statuser = Object(req.data).statuser;
                    } else if( Object(req.data).statuser==2 && client_id.value == "0" ){
                        client_name_options.value = Object.values(Object(req.data).clients_arr);

                        stat.statements_pie = Object.values(Object(req.data).statements_pie);
                        stat.statements_key = Object(req.data).statements_key;
                        stat.statements_all = Object(req.data).statements_all;

                        stat.declarations_pie = Object.values(Object(req.data).declarations_pie);
                        stat.declarations_key = Object(req.data).declarations_key;
                        stat.declarations_all = Object(req.data).declarations_all;

                        stat.info_arr = Object.values(Object(req.data).info_arr);


                        stat.actions_day_all = Object(req.data).actions_day_all;
                        stat.declarations_day_all = Object(req.data).declarations_day_all;
                        stat.signatories_day_all = Object(req.data).signatories_day_all;
                        stat.statements_day_print = Object(req.data).statements_day_print;
                        stat.statements_day_noprint = Object(req.data).statements_day_noprint;

                        stat.statuser = Object(req.data).statuser;

                        stat.users_all = Object(req.data).users_all;
                        stat.users_today = Object(req.data).users_today;
                        stat.signs_all = Object(req.data).signs_all;
                        stat.signs_today = Object(req.data).signs_today;
                    }               
                })
                .catch(function () {
                    console.log("FAILURE!!");
                });
        }

        onMounted(() => {
            get_stat();
        });

        return {
            chartOptions, pie1Data, pie1Options, pie2Data, pie2Options, pie1Options_admin, pie2Options_admin,
            firstDay, lastDay, change_modal_cal,
            stat, get_stat,
            firstDay_admin, lastDay_admin, change_modal_cal_admin,
            client_name_default, client_name_options, client_name_title, client_name_change,
            client_id
        }
    }
});
</script>
  
<style>
    .pie2 {
        width: 656px;
        height: 437px;
        background-color: white;
        border-radius: 2px;
        margin-top: 20px;
        margin-left: 20px;
        float: left;
    }

    .pie2_admin {
        width: 494px;
        height: 667px;
        background-color: white;
        border-radius: 2px;
        margin-top: 20px;
        margin-left: 20px;
        float: left;
    }

    .calendar {
        float: left;
        margin-top: 20px;
        margin-left: 20px;
        width: 640px;
        text-align: left;
        height: 437px;
    }

    .area1 {
        width: 656px;
        height: 241px;
        background-color: white;
        border-radius: 2px;
        margin-top: 20px;
        margin-left: 20px;
        float: left;
    }

    .pie_el {
        width: 328px;
        display: inline-block;
    }

    .pie_admin_el {
        width: 247px;
        display: inline-block;
    }

    .pie_title {
        margin-top: 15px;
        text-align: left;
        padding-left: 15px;
        margin-bottom: 40px;
    }

    .area_title {
        color: #ccc;
        font-size: 14px;
        font-family: 'Roboto';
        text-align: left;
        padding-left: 34px;
        padding-top: 20px;
    }

    .date_el {
        width: 160px;
        height: 52px;
        text-align: center;
        border-radius: 2px;
    }

    .area_num {
        display: inline-block;
        position: absolute;
        font-size: 30px;
        font-weight: bold;
        margin-left: -294px;
        margin-top: 10px;
        width: 300px;
        text-align: left;
    }

    .pie_num1 {
        display: inline-block;
        position: absolute;
        font-size: 30px;
        font-weight: bold;
        margin-left: -102px;
        margin-top: 162px;
        width: 216px;
        text-align: center;
    }

    .pie_admin_num1 {
        display: inline-block;
        position: absolute;
        font-size: 30px;
        font-weight: bold;
        margin-left: -71px;
        margin-top: 98px;
        width: 155px;
        text-align: center;
    }

    .pie_num2 {
        display: inline-block;
        position: absolute;
        font-size: 30px;
        font-weight: bold;
        margin-left: -117px;
        margin-top: 162px;
        width: 216px;
        text-align: center;
    }

    .pie_admin_num2 {
        display: inline-block;
        position: absolute;
        font-size: 30px;
        font-weight: bold;
        margin-left: -85px;
        margin-top: 98px;
        width: 155px;
        text-align: center;
    }

    .new_action{
        display: inline-block;
        width: 666px;
        height: 241px;
        border-radius: 2px;
        float: left;
        background-color: white;
        margin-top: 20px;
        margin-left: 20px;
    }

    

    .new_action_title {
        color: #ccc;
        font-size: 14px;
        font-family: 'Roboto';
        text-align: left;
        padding-left: 34px;
        padding-top: 20px;
    }

    .new_action_num {
        display: inline-block;
        position: absolute;
        font-size: 30px;
        font-weight: bold;
        margin-left: -299px;
        margin-top: 10px;
        width: 600px;
        text-align: left;
    }

    .new_action_table {
        margin-top: 80px;
        text-align: left;
        width: 595px;
        margin-left: 33px;
    }

    .new_action_head {
        font-family: 'Roboto';
        font-size: 16px;
    }

    .new_action_content {
        font-family: 'Roboto';
        font-size: 14px;
    }

    .new_action_content td {
        padding-top: 30px;
    }

    .user_list_table {
        margin-top: 0px;
        text-align: left;
        width: 460px;
        margin-left: 33px;
        font-family: 'Roboto';
        font-size: 12px;
    }

    .user_list_head td {
        padding-bottom: 30px;
    }

    .users_signs {
        float: left;
        width: 314px;
        height: 200px;
        margin-top: 20px;
        margin-left: 20px;
    }

    .users_block {
        width: 314px;
        height: 190px;
        background-color: white;
        border-radius: 2px;
    }

    .users_block_title {
        color: #ccc;
        font-size: 14px;
        font-family: 'Roboto';
        text-align: left;
        padding-left: 34px;
        padding-top: 20px;
    }

    .users_block_all {
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        margin-left: 0px;
        width: 150px;
        text-align: center;
    }

    .users_slash {
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        margin-left: 0px;
        margin-top: 45px;
        width: 14px;
        text-align: center;
    }

    .users_block_today {
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        margin-left: 0px;
        width: 150px;
        text-align: center;
    }

    .signs_block {
        width: 314px;
        height: 190px;
        background-color: white;
        border-radius: 2px;
        margin-top: 20px;
    }

    .signs_block_title {
        color: #ccc;
        font-size: 14px;
        font-family: 'Roboto';
        text-align: left;
        padding-left: 34px;
        padding-top: 20px;
    }

    .signs_block_all {
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        margin-left: 0px;
        width: 150px;
        text-align: center;
    }

    .signs_slash {
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        margin-left: 0px;
        margin-top: 45px;
        width: 14px;
        text-align: center;
    }

    .signs_block_today {
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        margin-left: 0px;
        width: 150px;
        text-align: center;
    }

    .select_user {
        display: inline-block;
        width: 100%;
        float: left;
        text-align: left;
    }

</style>