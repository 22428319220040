<template>
    <div class="step__two">
        <div class="left">
            <div>
                <label for="name">Введите серию паспорта</label>
                <span>Пример: 2212</span>
                <input class="input" type="text" placeholder="0000" v-model="document.signatory_passport_seria"
                       maxlength="4"
                       onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                >
            </div>
            <div>
                <label for="name">Введите номер паспорта</label>
                <span>Пример: 999654</span>
                <input class="input" type="text" placeholder="000000" v-model="document.signatory_passport_number"
                       maxlength="6"
                       onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                >
            </div>
            <div>
                <label for="name">Дата выдачи</label>
                <span>Пример: 20.02.2002</span>
                <input class="input" type="date" placeholder="__.__.____" v-model="document.signatory_when_issued"
                       maxlength="10"
                       onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                       :max="currentDay"
                >
            </div>
            <button @click="save_complete" class="save_btn">Готово</button>
        </div>
        <div class="right">
            <div>
                <label for="name">Кем выдан паспорт</label>
                <span>Пример: Отделом УФМС России по гор. Москва по району Митино</span>
              <textarea class="input" style="padding-top: 17px; resize: none; overflow: hidden;" type="text" placeholder="Введите учреждение" v-model="document.signatory_passport_issued"></textarea>
            </div>
            <div>
                <label for="name">Код подразделения</label>
                <span>Пример: 770-093</span>
                <!--
                <input class="input" type="text" placeholder="000-000" v-model="document.signatory_division_code"
                       maxlength="6"
                       onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                       v-maska="['###-###']"
                >
                -->
                <InputMask v-model="document.signatory_division_code" mask="999-999" placeholder="999-999" />
            </div>
            <div>
                <label for="name">Где зарегистрирован</label>
                <span>Пример: г. Москва, ул. Ленина, д. 74, кв. 105</span>
              <textarea class="input" style="padding-top: 16px; resize: none; overflow: hidden;" type="text" placeholder="Введите адрес регистрации" v-model="document.signatory_passport_registration"></textarea>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {useDMStore} from "@/store/dm_obj";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
    emits: ["save", "edit-signatory", "edit-proxy"],
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(
      props: {
        document: any;
      }, context
    ){
        const storeDM = useDMStore();

        let router = useRouter();
        const route = useRoute();


        let date = new Date();

        let currentDay = ref(new Date(date.getFullYear(), date.getMonth(), 1).getFullYear()+"-"
                        + ("0"+(new Date(date.getFullYear(), date.getMonth(), 1).getMonth()+1)).slice(-2)+"-"
                        + ("0"+(date.getDate())).slice(-2) );

        function save_complete() {
            if(props.document.signatory_parent == 0){
              context.emit("save", props.document, true);
              //context.emit("edit-signatory");
            } else {
              const old_parent = props.document.signatory_parent;

              props.document.signatory_parent = 0;
              context.emit("save", props.document, false);


              //http://172.20.11.104:8080/go?hash=UUJ8L96ZMC4EQQX3K89TCCETV3UHP5&type=proxy&id=68&step=5
              router.push({
                query: {
                  hash: route.query.hash,
                  type: "proxy",
                  id: old_parent,
                  step: props.document.signatory_parent_step,
                },
              });
              context.emit("edit-proxy");
            }
        }

        return {
            save_complete,
            currentDay,
        }
    }
});
</script>

<style scoped>
    .save_btn {
      position: absolute;
      margin-left: 527px;
      top: 779px;
      width: 83px !important;
      height: 46px !important;
      font-size: 14px;
    }
</style>