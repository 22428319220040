<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.712891 1.40234V13.0969C0.712891 13.6492 1.16061 14.0969 1.71289 14.0969H12.3362C12.8885 14.0969 13.3362 13.6492 13.3362 13.0969V1.55528"
      stroke="#333333"
      stroke-width="1.4"
      stroke-linecap="round"
    />
    <path
      d="M3.91016 4.52312L10.2139 4.52314"
      stroke="#333333"
      stroke-width="1.4"
      stroke-linecap="round"
    />
    <path
      d="M3.91016 7.38797L10.2139 7.38799"
      stroke="#333333"
      stroke-width="1.4"
      stroke-linecap="round"
    />
    <path
      d="M3.91016 10.2528L10.2139 10.2528"
      stroke="#333333"
      stroke-width="1.4"
      stroke-linecap="round"
    />
  </svg>
</template>
