<template>
  <div class="step__basic">
    <div class="left">
      <section>
        <label for="title">
          <v-switch
            style="display: flex; flex-direction: row; justify-content: flex-start"
            color="#2F80ED"
            v-model="document.title"
            label="Титульный лист (рекомендуется)"
          ></v-switch>
        </label>
      </section>
      <section style="margin-top: -45px;">
        <label for="contents">
          <v-switch
            style="display: flex; flex-direction: row; justify-content: flex-start"
            color="#2F80ED"
            v-model="document.contents"
            label="Оглавление (рекомендуется)"
          ></v-switch>
        </label>
      </section>
      <div>
        <label for="name">Адрес здания</label>
        <span>Пример: Российская Федерация, М.О., г. Подольск, деревня Борисовка (АЗС)</span>
        <textarea class="input" type="text" placeholder="Введите адрес здания" v-model="document.address"></textarea>
      </div>
      <div>
        <label for="name">Основные особенности и характеристики здания</label>
        <span>Пример: помещение расположено на первом этаже, тип здания - нежилое</span>
        <textarea class="input" type="text" placeholder="Введите особенности и характеристики здания" v-model="document.characteristics_building"></textarea>
      </div>
    </div>
    <div class="right">
      <div style="margin-top: 115px;">
        <label for="name">Характеристика средства размещения информации</label>
        <span>Пример: Вывеска со светодиодной подсветкой, Боковина ПВХ - 4 мм.</span>
        <textarea style="height: 171px;" class="input" type="text" placeholder="Введите характеристику средств размещения информации" v-model="document.characteristics_info"></textarea>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { useDMStore } from "@/store/dm_obj";

export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){
    const storeDM = useDMStore();
    const route = useRoute();



    return {
    }
  }
});
</script>