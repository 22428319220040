<template>
  <img alt="Rekprof logo" class="logo_min" src="../../assets/logo.png" />
  <v-menu-proxy-list></v-menu-proxy-list>
  <div class="inner_step_box">
    <span class="main_field">Сохранение доверенности</span>
    <div class="hint_left">Нажмите чтобы скачать доверенность</div>
    <button
      type="button"
      class="blue_btn"
      @click="download_signatory"
      style="float: left; margin-left: 10px"
    >
      Скачать доверенность
    </button>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Готово
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import axios from "axios";
import VMenuProxyList from "@/components/v-menu-proxy-list.vue";
import { useDMDeclStore } from "@/store/dm_decl";

export default defineComponent({
  name: "ProxyStep8",
  components: { VMenuProxyList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMDeclStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "5",
      });
      router.push({
        path: "/proxy/step5/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "8",
      });
      router.push({
        path: "/go",
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
    });

    const download_signatory = () => {
      downloadItem(
        storeDM.api_url +
          "proxy.php?id=" +
          route.params.docID +
          "&hash=" +
          route.query.hash +
          "&type=declarations",
        "Доверенность " + route.params.docID
      );
    };

    const downloadItem = (url: string, label: string) => {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    };

    return {
      go_back,
      step_next,
      go_next,
      download_signatory,
    };
  },
});
</script>

<style scoped></style>
