<template>
    <!--<div class="step__two" v-if="document.client_type != 3">-->
    <div class="step__two">
        <div class="left" style="justify-content: flex-start;">
            <div>
                <label for="name">Введите фамилию</label>
                <input class="input" type="text" placeholder="Введите фамилию" v-model="document.declarer_lastname">
            </div>
            <div>
                <label for="name">Введите имя</label>
                <input class="input" type="text" placeholder="Введите имя" v-model="document.declarer_firstname">
            </div>
            <div>
                <label for="name">Введите должность</label>
                <span>В лице кого подаём. Пример: Генерального директора</span>
                <input class="input" type="text" placeholder="Введите должность" v-model="document.client_role">
            </div>
            <div>
                <label for="name">Введите ФИО в родительном падеже</label>
                <span>В лице кого подаём. Пример: Иванова Ивана Ивановича</span>
                <input class="input" type="text" placeholder="Введите ФИО в родительном падеже" v-model="document.client_role_fio_genitive">
            </div>

            
        </div>
        <div class="right" style="margin-top: -11px; justify-content: initial;">
                <section>
                    <label for="title">
                        <v-switch
                        style="display: flex; flex-direction: row; justify-content: flex-start;"
                        color="#2F80ED"
                        v-model="document.declarer_patronymic_no"
                        label="Отчество"
                        ></v-switch>
                    </label>
                    <input class="input lastname" type="text" placeholder="Введите отчество" v-model="document.declarer_patronymic">
                </section>
                <section class="gender" style="margin-top: 29px;">
                    <label for="gender">Выберите пол</label>
                    <v-radio-group
                    v-model="document.declarer_genre"
                    inline>
                    <v-radio
                        color="#2F80ED"
                        label="Мужчина"
                        value="1">
                    </v-radio>
                    <v-radio
                        color="#2F80ED"
                        label="Женщина"
                        value="2">
                    </v-radio>
                    </v-radio-group>
                </section>
                <section style="margin-top: -3px;">
                    <label for="title">Загрузите или перенесите сюда файл подписи</label>
                    <FileUploadMini :document="document" :file_upload_name="'signature_decl_file'" :upload_text="'Подпись должны быть на прозрачном фоне в формате png'" />
                </section>

        </div>
    </div>

    <!--
    <div class="step__two" v-if="document.client_type == 3">
        <div class="left">
            <div>
                <label for="name">Введите фамилию</label>
                <input class="input" type="text" placeholder="Введите фамилию" v-model="document.declarer_lastname">
            </div>
            <div>
                <label for="name">Введите имя</label>
                <input class="input" type="text" placeholder="Введите имя" v-model="document.declarer_firstname">
            </div>
            
            <section>
                <label for="title">
                    <v-switch
                    style="display: flex; flex-direction: row; justify-content: flex-start"
                    color="#2F80ED"
                    v-model="document.declarer_patronymic_no"
                    label="Отчество"
                    ></v-switch>
                </label>
                <input class="input lastname" type="text" placeholder="Введите отчество" v-model="document.declarer_patronymic">
            </section>
            
        </div>
        <div class="right">
                <section class="gender" style="margin-top: auto;">
                    <label for="gender">Выберите пол</label>
                    <v-radio-group
                    v-model="document.declarer_genre"
                    inline>
                    <v-radio
                        color="#2F80ED"
                        label="Мужчина"
                        value="1">
                    </v-radio>
                    <v-radio
                        color="#2F80ED"
                        label="Женщина"
                        value="2">
                    </v-radio>
                    </v-radio-group>
                </section>
                <section>
                    <label for="title">Загрузите или перенесите сюда файл подписи</label>
                    <FileUploadMini :document="document" :file_upload_name="'signature_decl_file'" :upload_text="'Подпись должны быть на прозрачном фоне в формате png'" />
                </section>

        </div>
    </div>
    -->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { useDMStore } from "@/store/dm_obj";
import FileUploadMini from "@/components/FileUploadMini.vue";

export default defineComponent({
  components: {
    FileUploadMini
  },
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(
      props: {
          document: any;
      }, context
    ){
        const storeDM = useDMStore();

        const route = useRoute();

        const signature_decl_file = ref(null);

        const submitFile = (f_name: string) => {
            let file = Object(eval(f_name).value).files[0];

            let formData = new FormData();
            formData.append(f_name, file);
            formData.append("file_name", f_name);
            formData.append("hash", String(route.query.hash));
            formData.append("id", String(route.query.id));
            axios
              .post(storeDM.api_url + "upload.php", formData, {
                  headers: {
                      "Content-Type": "multipart/form-data",
                  },
              })
              .then(function (req) {
                  props.document[Object(req.data).file_name] = Object(req.data)[
                    req.data.file_name
                    ];
                  //console.log(req);
              })
              .catch(function () {
                  console.log("FAILURE!!");
              });
        };

        return{
            signature_decl_file,
            submitFile,
        }
    }
});
</script>