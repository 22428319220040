<template>
    <div class="step__fifth">
      <label for="title">Список подписантов доверенности</label>
      <span>После добавления подписантов, сделайте активным того, от которого будет подаваться заявление</span>
      <div class="selected__signatures">
          <div v-for="(input, index) in document.signatory_list"
               :key="index">
              <section @click="document.signatory_main = input.id" style="cursor: pointer;">
                  <input
                    type="radio"
                    name="signatory"
                    style="cursor: pointer;"
                    :value="input.id"
                    :checked="input.id == document.signatory_main"
                    @click="document.signatory_main = input.id"
                  >
                  <p>{{ input.name }}</p>
              </section>
              <LogoutIcon style="cursor: pointer;" @click="signatory_delete(index)" />
          </div>
      </div>
      <label style="margin-top: 1rem;" for="title" v-if="signatory_options_comp.length == 0">Вы добавили всех подписантов – создайте новых</label>
      <label style="margin-top: 1rem;" for="title" v-if="signatory_options_comp.length > 0">Добавить подписантов из списка</label>
      <div style="margin-top: 1rem;" v-if="signatory_options_comp.length > 0">
          <div
            v-if="signatory_options_comp.length > 0"
            v-for="(input, index) in signatory_options_comp"
            :key="index" @click="signatory_add(input.id, input.name)"
            style="cursor: pointer;"
          >{{ input.name }}</div>
      </div>
      <button @click="new_sign">Создать нового подписанта</button>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import LogoutIcon from '../../assets/icons/logoutIcon.vue';
import { useRoute, useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";

export default defineComponent({
    emits: ["signatory-list-push", "signatory-list-delete", "edit-signatory"],
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(
      props: {
          document: any;
      }, context
    ){
      const storeDM = useDMStore();

      const router = useRouter();
      const route = useRoute();
        const signatory_add = (id:string, name: string) => {

              let findElement = Array(props.document.signatory_list as any)[0].filter(
                function (val: any) {
                    return val.name == name;
                }
              );

              if (findElement.length == 0) {
                  context.emit("signatory-list-push", {
                      id: id,
                      name: name,
                  });
              }



            //main_select_key.value = main_select_key.value + 1;
        };

        const signatory_delete = (num: number) => {
            context.emit("signatory-list-delete", {
                num: num,
            });
            //signatory_scroll_clear();
            //main_select_key.value = main_select_key.value + 1;
        };

        const newsignatories = () => {
          context.emit("edit-signatory");
          /*
          router.push({
            query: {
              hash: route.query.hash,
              type: "newsignatories"
            },
          });
          */
        }
        
        const signatory_options_comp = computed(() => {
          let tmp_arr:any = [];
          let isAdded = false;
          for(let i=0; i<props.document.signatory_options.length; i++){
            isAdded = false;
            for(let j=0; j<props.document.signatory_list.length; j++){
              if(props.document.signatory_options[i].name == props.document.signatory_list[j].name){
                isAdded = true;
              }
            }
            if(isAdded == false){
              tmp_arr.push({id:props.document.signatory_options[i].id, name:props.document.signatory_options[i].name});
            }
          }
          
          return tmp_arr;
        });


      const new_sign = async () => {
        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "create_signatory",
            hash: route.query.hash,
            parent: route.query.id,
            parent_step: 5,
            parent_type: "proxy",
          }),
        });

        const response_data = await response.json();
        //proxy_id.value = response_data["id"];

        router.push({
          query: {
            hash: route.query.hash,
            type: "signatory",
            id: response_data["id"],
            step: 1,
          },
        });

        context.emit("edit-signatory");
        //setTimeout(selectComponent, 500,"newDocuments");

      };


        return{
            signatory_add,
            signatory_delete,
            newsignatories,
            signatory_options_comp,
            new_sign,
        }
    },
    components: {
        LogoutIcon,
    }
});
</script>