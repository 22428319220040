<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">4.1 Установка карты</span>
    <div style="margin-top: 10px; margin-left: 6px">
      <input
        type="radio"
        class="map_radio"
        name="map_radio"
        value="1"
        id="r1"
        :checked="1 == storeDM.save_obj.mapfile_type"
        @click="storeDM.save_obj.mapfile_type = 1"
      />
      <label class="main_field unselectable" style="line-height: 47px" for="r1">
        &nbsp;Использовать сервис Яндекс.Карты
      </label>
    </div>
    <div style="margin-bottom: 10px; margin-left: 6px">
      <input
        type="radio"
        class="map_radio"
        name="map_radio"
        value="0"
        id="r2"
        :checked="0 == storeDM.save_obj.mapfile_type"
        @click="storeDM.save_obj.mapfile_type = 0"
      />
      <label class="main_field unselectable" style="line-height: 47px" for="r2">
        &nbsp;Загрузить файл карты формата 1:2000
      </label>
    </div>
    <!--
    <br />
    <span class="main_field">4.1 Ручной ввод адреса</span>
    -->
    <div v-if="0 == storeDM.save_obj.mapfile_type">
      <div class="hint_left hint_mini_top">
        {{ map_file_hint }}
      </div>
      <input
        ref="map_file"
        type="file"
        hidden
        @change="submitFile('map_file')"
      />
      <button
        type="button"
        class="blue_btn"
        @click="$refs.map_file.click()"
        style="float: left; margin-left: 10px"
      >
        Загрузить картинку с картой - 1.5 к 1 (горизонтальная картинка)
      </button>
    </div>
    <div v-if="1 == storeDM.save_obj.mapfile_type" style="margin-left: 10px">
      <YandexMap
        :address_line="
          'Санкт-Петербург, ' +
          storeDM.save_obj.map_street +
          ' ' +
          storeDM.save_obj.map_address +
          ', ' +
          storeDM.save_obj.map_house
        "
      />
    </div>
    <!--
    <span class="main_field">4.1 РГИС</span>
    <br />
    <div class="wrapper_map">
      <iframe
        class="iframe_map"
        src="https://rgis.spb.ru/mapui?location=33730"
      ></iframe>
    </div>
    <div class="hint_left">Нажмите чтобы указать место на карте</div>
    <button
      type="button"
      class="blue_btn"
      @click="manual_rgis"
      style="float: left; margin-left: 10px"
    >
      Выбрать место установки
    </button>
    -->
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: true }"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";
import YandexMap from "@/components/YandexMap.vue";
import axios from "axios";

export default defineComponent({
  name: "StepFourOne",
  components: { VMenuList, YandexMap },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "4",
      });
      router.push({
        path: "/step4/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "5",
      });
      router.push({
        path: "/step5/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const manual_rgis = () => {
      storeDM.set_info({
        step: "4_2",
      });
      router.push({
        path: "/step4_2/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return false;
    });

    const map_file = ref(null);

    const submitFile = (f_name: string) => {
      let file = Object(eval(f_name).value).files[0];

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.params.docID));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          storeDM.save_obj[Object(req.data).file_name] = Object(req.data)[
            req.data.file_name
          ];
          //console.log(req);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    const map_file_hint = computed(() => {
      if (typeof storeDM.save_obj.map_file !== "undefined") {
        if (storeDM.save_obj.map_file == "") {
          return "Нажмите чтобы загрузить карту с местом";
        } else {
          return (
            'Имя загруженного файла - "' +
            storeDM.save_obj.map_file.split("/")[
              storeDM.save_obj.map_file.split("/").length - 1
            ] +
            '"'
          );
        }
      } else {
        return 'Имя загруженного файла - "-не загружен-"';
      }
    });

    return {
      go_back,
      step_next,
      go_next,
      manual_rgis,
      storeDM,
      map_file,
      submitFile,
      map_file_hint,
    };
  },
});
</script>

<style scoped>
.map_radio {
  float: left;
  width: 30px;
  height: 42px;
}
</style>
