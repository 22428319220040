<!-- eslint-disable no-undef -->
<script lang="ts">
import { useDMStore } from "@/store/dm_obj";
import { defineComponent, onMounted, onUnmounted, ref, reactive } from "vue";
import { loadScript, unloadScript } from "vue-plugin-load-script";
import imagePlacemark from "../assets/Placemark.png";
import imagePlacemarkLight from "../assets/PlacemarkLight.png";
import axios from "axios";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "yandex-map",
  props: {
    address_line: {
      type: String,
      default() {
        return "";
      },
    },
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props: {
    document: any;
    address_line: string;
  }, contextt) {
    const storeDM = useDMStore();

    let map: any;
    let placeCoords: any;

    let exportedMapReserve: HTMLImageElement = new Image();

    const urlToUpload = "/";

    const searchBar = ref<HTMLInputElement | null>(null);
    const fScreen = ref<HTMLDivElement | null>(null);
    const sScreen = ref<HTMLDivElement | null>(null);

    const reactive_vars = reactive({ open_menu: false });

    const api_url = "https://api.rekprof.ru/";
    const route = useRoute();

    const map_random = ref(Math.random());

    let map_yes = true;

    onMounted(() => {
      map_yes = true;
      loadScript(
        "https://api-maps.yandex.ru/2.1/?apikey=17e6ce90-fce7-465a-b699-a5d8994cd64c&suggest_apikey=d01b7b1d-336a-4a98-9a45-5667bd20a723&lang=ru_RU"
      )
        .then(() => {
          // Script is loaded, do something
          //console.log(map_yes);
          if(map_yes){

            ymaps.ready(() => {
              map = new ymaps.Map(
                "map",
                {
                  center: [
                    props.document.map_center_x,
                    props.document.map_center_y,
                  ],
                  zoom: props.document.map_center_zoom,
                  controls: ["zoomControl"],
                  type: "yandex#map",
                },
                {
                  balloonMaxWidth: 200,
                  searchControlProvider: "yandex#search",
                }
              );

              let suggestView = new ymaps.SuggestView("search");
              suggestView.events.add("select", (event: any) => {
                RawSearch(event.get("item")["displayName"]);
              });

              if (props.document.map_place_set == 1) {
                reactive_vars.open_menu = true;
                placeCoords = [
                  props.document.map_place_x,
                  props.document.map_place_y,
                ];
                //console.log(reactive_vars.open_menu);
                map.balloon.open(placeCoords, {
                  contentHeader: "Место установки",
                  contentBody:
                    "Координаты: " +
                    [
                      props.document.map_place_x,
                      props.document.map_place_y,
                    ].join(", "),
                });

                if (map.getZoom() < 16) {
                  map.setZoom(16);
                }
                map.setCenter(placeCoords);
              }

              map.events.add("click", (event: any) => {
                if (!map.balloon.isOpen()) {
                  props.document.map_place_set = 1;
                  reactive_vars.open_menu = true;
                  placeCoords = event.get("coords");
                  props.document.map_place_x = placeCoords[0];
                  props.document.map_place_y = placeCoords[1];
                  props.document.map_center_x = placeCoords[0];
                  props.document.map_center_y = placeCoords[1];
                  //console.log(reactive_vars.open_menu);
                  map.balloon.open(placeCoords, {
                    contentHeader: "Место установки",
                    contentBody:
                      "Координаты: " +
                      [
                        placeCoords[0].toPrecision(6),
                        placeCoords[1].toPrecision(6),
                      ].join(", "),
                  });

                  if (map.getZoom() < 16) {
                    map.setZoom(16);
                  }
                  props.document.map_center_zoom = map.getZoom();

                  map.setCenter(placeCoords);
                } else {
                  props.document.map_place_set = 0;
                  map.balloon.close();
                  placeCoords = null;
                  reactive_vars.open_menu = false;
                  //console.log(reactive_vars.open_menu);
                }
              });
            });

          }
        })
        .catch(() => {
          // Failed to fetch script
        });
    });

    onUnmounted(() => {
      if (typeof map !== "undefined") {
        map.destroy();
      }
      map_yes = false;
      unloadScript(
        "https://api-maps.yandex.ru/2.1/?apikey=17e6ce90-fce7-465a-b699-a5d8994cd64c&suggest_apikey=d01b7b1d-336a-4a98-9a45-5667bd20a723&lang=ru_RU"
      )
        .then(() => {
          // Script was unloaded successfully
        })
        .catch(() => {
          // Script couldn't be found to unload; make sure it was loaded and that you passed the same URL
        });
    });

    // Map Utils
    function SearchBarHandler(): void {
      if (
        searchBar.value?.value.length != undefined &&
        searchBar.value.value.length > 0
      ) {
        RawSearch(searchBar.value?.value);
      }
    }

    function RawSearch(search: string): void {
      ymaps.geocode(search, { results: 1 }).then((res: any) => {
        let geoObject = res.geoObjects.get(0),
          bounds = geoObject.properties.get("boundedBy");

        map.setBounds(bounds, {
          checkZoomRange: true,
        });
      });
    }

    function SelectPlace(): void {
      if (placeCoords == null || !map.balloon.isOpen()) {
        return;
      }

      if (fScreen.value != undefined) {
        fScreen.value.style.display = "none";
      }

      if (sScreen.value != undefined) {
        sScreen.value.style.display = "block";
      }

      placeCoords = [
        props.document.map_center_x,
        props.document.map_center_y,
      ];
      map.setCenter(placeCoords);
      map.setZoom(18);

      CaptureAndDrawMaps();
    }

    //setTimeout(SearchBarHandler,1000);

    function GoBack(): void {
      if (fScreen.value != undefined) {
        fScreen.value.style.display = "block";
      }

      if (sScreen.value != undefined) {
        sScreen.value.style.display = "none";
      }
    }

    function CaptureAndDrawMaps(): void {
      let baseUrl = "https://static-maps.yandex.ru/1.x/?";
      //props.document.map_center_x = map.getCenter()[0];
      //props.document.map_center_y = map.getCenter()[1];
      //props.document.map_center_zoom = map.getZoom();
      let mapUrl: string =
        baseUrl +
        "ll=" +
        map.getCenter()[1] +
        "," +
        map.getCenter()[0] +
        "&z=" +
        map.getZoom() +
        "&l=map&size=450,450";

      //console.log(map.getCenter()[1]);
      let sateliteUrl: string = //Карта спутник
        baseUrl +
        "ll=" +
        Number(map.getCenter()[1] - 0.00004) + //X
        "," +
        Number(map.getCenter()[0] + 0.000001) + //Y
        "&z=" +
        map.getZoom() +
        "&l=sat&size=450,450";

      let _canvas: HTMLCanvasElement = document.getElementById(
        "mapCanvas"
      ) as HTMLCanvasElement;
      let _context: CanvasRenderingContext2D | null = _canvas.getContext("2d");

      let mapImage: HTMLImageElement = new Image();
      mapImage.crossOrigin = "Anonymous";

      let sateliteImage: HTMLImageElement = new Image();
      sateliteImage.crossOrigin = "Anonymous";

      mapImage.src = mapUrl;
      mapImage.onload = () => {
        sateliteImage.src = sateliteUrl;

        sateliteImage.onload = () => {
          _context?.drawImage(mapImage, 0, 0, 450, 450);
          _context?.drawImage(sateliteImage, 450, 0, 450, 450);

          if (_context != null) {
            DrawPlacemark(_context, 450 / 2 - 45, 450 / 2 - 45);
            DrawPlacemark(_context, 450 + 450 / 2 - 45, 450 / 2 - 45, true);
          }

          exportedMapReserve.src = _canvas.toDataURL("image/jpeg");
          InitDraw(mapUrl);
        };
      };
    }

    async function SaveAndUploadMapImages(): Promise<void> {
      /*
      const embeddedCanvas: HTMLCanvasElement = document.getElementById(
        "embeddedCanvas"
      ) as HTMLCanvasElement;
      const _context: CanvasRenderingContext2D | null =
        embeddedCanvas.getContext("2d");
      embeddedCanvas.width = 450 * 3;
      embeddedCanvas.height = 450;

      const mapImageData: ImageData | undefined = (
        document.getElementById("mapCanvas") as HTMLCanvasElement
      )
        .getContext("2d")
        ?.getImageData(0, 0, 900, 450);

      const editImageData: ImageData | undefined = (
        document.getElementById("editCanvas") as HTMLCanvasElement
      )
        .getContext("2d")
        ?.getImageData(0, 0, 450, 450);

      if (mapImageData != undefined) {
        _context?.putImageData(mapImageData, 0, 0);
      }
      if (editImageData != undefined) {
        _context?.putImageData(editImageData, 900, 0);
      }

      const embeddedMapData: string = embeddedCanvas.toDataURL("image/jpeg");

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "image/jpeg" },
        body: embeddedMapData,
      };
      */
      const mapCanvas: HTMLCanvasElement = document.getElementById(
        "mapCanvas"
      ) as HTMLCanvasElement;

      const exportedMapData: string = mapCanvas.toDataURL("image/jpeg");

      let formData = new FormData();
      formData.append("map_yandex_file", exportedMapData);
      formData.append("file_name", "map_yandex_file");
      formData.append("hash", String(route.query.hash));
      //formData.append("id", String(route.params.docID));
      formData.append("id", String(route.query.id));
      axios
        .post(api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          //Object(req.data).file_name,
          //  Object(req.data)[req.data.file_name]
          //console.log("SUCCESS!!");
          props.document.map_yandex_file = Object(req.data)[
            req.data.file_name
          ];
          map_random.value = Math.random();
        })
        .catch(function () {
          console.log("FAILURE!!");
        });

      /*
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "image/jpeg" },
        body: exportedMapData,
      };

      await fetch(urlToUpload, requestOptions);
      */
    }

    // Draw Utils
    let canvas: HTMLCanvasElement;
    let exportedCanvas: HTMLCanvasElement;
    let context: CanvasRenderingContext2D | null;
    let exportedCanvasContext: CanvasRenderingContext2D | null;

    let drawingMode: number;

    let pointerCoords = { x: 0, y: 0 };
    let startCoords = { x: 0, y: 0 };

    let DrawMap: any;
    let bufferImageData: ImageData | null | undefined;
    let exportedBufferImageData: ImageData | null | undefined;

    let mapImage: any;
    function InitDraw(url: string) {
      canvas = document.getElementById("editCanvas") as HTMLCanvasElement;
      exportedCanvas = document.getElementById(
        "mapCanvas"
      ) as HTMLCanvasElement;
      context = canvas.getContext("2d");
      exportedCanvasContext = exportedCanvas.getContext("2d");

      drawingMode = 0;
      bufferImageData = null;

      pointerCoords = { x: 0, y: 0 };
      startCoords = { x: 0, y: 0 };

      canvas?.addEventListener("mousedown", BeginDrawing);

      mapImage = new Image();
      mapImage.crossOrigin = "Anonymous";
      mapImage.src = url;

      mapImage.onload = () => DrawMap();
      /*
      DrawMap = () => {
        console.log(777);
        if (context) {
          context.drawImage(mapImage, 0, 0, 450, 450);
          DrawPlacemark(context, 450 / 2 - 45, 450 / 2 - 45);
          console.log(1112);
        }
      };
      */
    }

    DrawMap = () => {
      if (context) {
        context.drawImage(mapImage, 0, 0, 450, 450);
        DrawPlacemark(context, 450 / 2 - 45, 450 / 2 - 45);
      }

      if (exportedCanvasContext) {
        exportedCanvasContext.drawImage(exportedMapReserve, 0, 0, 900, 450);
        DrawPlacemark(exportedCanvasContext, 450 / 2 - 45, 450 / 2 - 45);
        DrawPlacemark(
          exportedCanvasContext,
          450 + 450 / 2 - 45,
          450 / 2 - 45,
          true
        );
      }
    };

    function SetDrawingMode(mode: number): void {
      drawingMode = mode;
    }

    function PointerReposition(event: MouseEvent): void {
      const rect = canvas?.getBoundingClientRect();
      pointerCoords.x = event?.clientX - rect.x;
      pointerCoords.y = event?.clientY - rect.y;
    }

    function DrawPlacemark(
      context: CanvasRenderingContext2D,
      dx: number,
      dy: number,
      light = false
    ): void {
      const placemark = new Image();
      placemark.onload = () => {
        context.drawImage(placemark, dx, dy, 206, 118);
      };

      if (light) {
        placemark.src = imagePlacemarkLight;
      } else {
        placemark.src = imagePlacemark;
      }
    }

    function BeginDrawing(event: MouseEvent): void {
      canvas?.addEventListener("mousemove", Draw);
      document.addEventListener("mouseup", EndDrawing);
      PointerReposition(event);

      bufferImageData = context?.getImageData(0, 0, 450, 450);
      exportedBufferImageData = exportedCanvasContext?.getImageData(
        0,
        0,
        900,
        450
      );

      const rect = canvas?.getBoundingClientRect();
      startCoords.x = event?.clientX - rect.x;
      startCoords.y = event?.clientY - rect.y;
    }

    function Draw(event: MouseEvent): void {
      switch (drawingMode) {
        case 0:
          DrawRaw(event);
          break;
        case 1:
          DrawLine(event);
          break;
      }
    }

    function DrawRaw(event: MouseEvent): void {
      if (context == null || exportedCanvasContext == null) {
        return;
      }

      //console.log(1);

      // Edited map
      let x1, x2, y1, y2;

      context.beginPath();
      context.lineWidth = 2;
      context.lineCap = "round";
      context.strokeStyle = "#ff0000";
      context.moveTo(pointerCoords.x, pointerCoords.y);

      x1 = pointerCoords.x;
      y1 = pointerCoords.y;

      PointerReposition(event);

      x2 = pointerCoords.x;
      y2 = pointerCoords.y;

      context.lineTo(pointerCoords.x, pointerCoords.y);
      context.stroke();

      // Left part of exported canvas
      exportedCanvasContext.beginPath();
      exportedCanvasContext.lineWidth = 2;
      exportedCanvasContext.lineCap = "round";
      exportedCanvasContext.strokeStyle = "#ff0000";
      exportedCanvasContext.moveTo(x1, y1);
      exportedCanvasContext.lineTo(x1, y1);
      exportedCanvasContext.stroke();

      // Right part of exported canvas
      exportedCanvasContext.beginPath();
      exportedCanvasContext.lineWidth = 2;
      exportedCanvasContext.lineCap = "round";
      exportedCanvasContext.strokeStyle = "#ff0000";
      exportedCanvasContext.moveTo(x1 + 450, y1);
      exportedCanvasContext.lineTo(x1 + 450, y1);
      exportedCanvasContext.stroke();
    }

    function DrawLine(event: MouseEvent): void {
      if (context == null || exportedCanvasContext == null) {
        return;
      }

      if (bufferImageData != undefined || bufferImageData != null) {
        context.putImageData(bufferImageData, 0, 0);
      }

      if (
        exportedBufferImageData != undefined ||
        exportedBufferImageData != null
      ) {
        exportedCanvasContext.putImageData(exportedBufferImageData, 0, 0);
      }

      const start = startCoords;
      PointerReposition(event);
      const end = pointerCoords;

      const width = Math.sqrt(
        Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
      );
      const height = 0.5;
      const angle = Math.atan((end.y - start.y) / (end.x - start.x));

      context.save();

      context.translate(450 / 2, 450 / 2);
      context.rotate(angle);

      context.strokeStyle = "#ff0000";
      context.strokeRect(-width / 2, -height / 2, width, height);

      context.restore();

      // Left part of exported canvas
      exportedCanvasContext.save();
      exportedCanvasContext.translate(450 / 2, 450 / 2);
      exportedCanvasContext.rotate(angle);
      exportedCanvasContext.strokeStyle = "#ff0000";
      exportedCanvasContext.strokeRect(-width / 2, -height / 2, width, height);
      exportedCanvasContext.restore();

      // Right part of exported canvas
      exportedCanvasContext.save();
      exportedCanvasContext.translate(450 / 2 + 450, 450 / 2);
      exportedCanvasContext.rotate(angle);
      exportedCanvasContext.strokeStyle = "#ff0000";
      exportedCanvasContext.strokeRect(-width / 2, -height / 2, width, height);
      exportedCanvasContext.restore();
    }

    function EndDrawing(): void {
      canvas.removeEventListener("mousemove", Draw);
      document.removeEventListener("mouseup", EndDrawing);
    }
    return {
      reactive_vars,
      SelectPlace,
      SetDrawingMode,
      SearchBarHandler,
      DrawMap,
      GoBack,
      SaveAndUploadMapImages,
      fScreen,
      sScreen,
      searchBar,
      storeDM,
      map_random,
    };
  },
});
</script>

<template>
  <div ref="fScreen">
    <div class="ymap-search-bar" style="position: absolute; top: 164px; z-index: 1;">
      <input
        ref="searchBar"
        v-on:keydown.enter="SearchBarHandler"
        type="text"
        id="search"
        class="ymap-search-bar__input"
        placeholder="Найти на карте"
        :value="address_line"
      />
      <button
        @click="SearchBarHandler"
        type="button"
        class="ymap-search-bar__button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.914"
          height="21.914"
          viewBox="0 0 21.914 21.914"
        >
          <g id="Group" data-name="Group" transform="translate(-1102 -279)">
            <g
              id="Circle"
              data-name="Circle"
              transform="translate(1102 279)"
              fill="none"
              stroke="#000"
              stroke-width="2"
            >
              <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
              <circle cx="7.5" cy="7.5" r="6.5" fill="none" />
            </g>
            <line
              id="Line"
              data-name="Line"
              x1="8"
              y1="8"
              transform="translate(1114.5 291.5)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </button>
    </div>
    <div id="map"></div>
    <div class="ymap-select-place" style="position: absolute; top: 601px; z-index: 1;">
      <button
        @click="SelectPlace"
        class="ymap-select-place__button ymap_common-button"
        style="margin-left: 0px"
        :class="{ active_btn: reactive_vars.open_menu }"
      >
        Указать место
      </button>
    </div>
    <!-- <div style="position: absolute; top: 184px; left: 799px"> -->
    <div style="margin-top: -524px; margin-left: 525px;">
      <p class="small-title">Сохранённая для дизайн-проекта карта</p>
      <img
        :src="
          'https://api.rekprof.ru/files/' +
          document.map_yandex_file +
          '?a=' +
          map_random
        "
        style="width: 1000px; height: auto; box-shadow: 0 3px 6px #00000026; border-radius: 5px;"
      />
        <!-- width="900"
        height="450" -->
    </div>
  </div>
  <div ref="sScreen" style="display: none" class="save-screen">
    <div class="edit-canvas">
      <div class="edit-tools">
        <button
          @click="SetDrawingMode(0)"
          class="edit-tools__button edit-tools__button-draw"
        ></button>
        <button
          @click="SetDrawingMode(1)"
          class="edit-tools__button edit-tools__button-line"
        ></button>
        <button
          @click="DrawMap()"
          class="edit-tools__button edit-tools__button-reset"
        ></button>
      </div>
      <canvas id="editCanvas" width="450" height="450"></canvas>
    </div>
    <div class="ymap-save">
      <button @click="GoBack" class="ymap_common-button active_btn" style="position: absolute; top: 619px;">
        Вернуться
      </button>
      <button
        @click="SaveAndUploadMapImages()"
        class="ymap-save__button ymap_common-button active_btn"
        style="margin-left: 170px; position: absolute; top: 619px;"
      >
        Сохранить
      </button>
    </div>
    <div style="float:left; margin-top: -24px;">
      <p class="small-title">Предпросмотр</p>
      <!--<canvas id="mapCanvas" width="900" height="450"></canvas>-->
      <canvas id="mapCanvas" width="900" height="450"></canvas>
    </div>
  </div>

  <canvas id="embeddedCanvas" style="display: none"></canvas>
</template>

<style scoped>
#map {
  width: 500px;
  height: 500px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 3px 6px #00000026;
}

#mapCanvas {
  display: block;
  width: 1000px; /* 660px */
  /* height: 330px; */
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 3px 6px #00000026;
  /* margin: 0 auto; */
  /* margin-top: 15px; */
  margin-bottom: 15px;
  margin-left: 25px;
}

#editCanvas {
  display: block;
  width: 500px; /* 450px */
  height: 500px; /* 450px */
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 3px 6px #00000026;
}

.ymap-search-bar {
  display: flex;
  height: 41px;
  /* width: 80%; */
  width: 500px;
  overflow: hidden;
  /* margin: 0 auto; */
  margin-bottom: 21px;
  border-radius: 5px;
  box-shadow: 0 3px 6px #00000026;
}

.ymap-search-bar__input {
  height: 100%;
  width: calc(100% - 45px);
  border: none;
  outline: none;
  padding-left: 16px;
  padding-right: 8px;
  color: #757575;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.ymap-search-bar__button {
  width: 45px;
  height: 100%;
  border: none;
  background-color: #2f80ed;
  cursor: pointer;
}

.ymap-search-bar__button > svg {
  margin-top: 5px;
  margin-left: 2px;
  filter: invert(100%) sepia(0%) saturate(1352%) hue-rotate(87deg)
    brightness(119%) contrast(119%);
}

.ymap-select-place {
  display: flex;
  margin-top: 18px;
  margin-bottom: 18px;
  float:left;
}

.ymap-save {
  display: flex;
  margin-top: 18px;
  margin-bottom: 18px;
  float:left;
  margin-left: -500px;
}

.ymap_common-button {
  background: #e0e0e0;
  color: white;
  display: block;
  width: 140px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;
  border-width: 0px;
}

.small-title {
  /* margin-left: 218px; */
  /* text-align: left; */
  text-align: center;
  font-size: 16px;
}

.edit-canvas {
  float: left;
  position: relative;
  width: 500px;
}

.edit-tools {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 455px;
}

.edit-tools__button {
  width: 38px;
  height: 38px;
  margin-bottom: 10px;
  border: none;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px #00000026;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
}

.edit-tools__button-draw {
  background-image: url(@/assets/draw.png);
}

.edit-tools__button-line {
  background-image: url(@/assets/line.png);
}

.edit-tools__button-reset {
  background-image: url(@/assets/reset.png);
}
</style>
