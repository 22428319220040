import { createApp } from "vue";
import PrimeVue from 'primevue/config';
import Vue3TouchEvents from "vue3-touch-events";
import VCalendar from "v-calendar";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "v-calendar/dist/style.css";
import { createPinia } from "pinia";
import LoadScript from "vue-plugin-load-script";
import InputMask from 'primevue/inputmask';


import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './assets/styles/main.scss'
import './assets/styles/navbar.scss'
import './assets/styles/drafts.scss'
import './assets/styles/profile.scss'
import './assets/styles/notification.scss'
import './assets/styles/signatories.scss'
import './assets/styles/documents.scss'
import './assets/styles/register.scss'
import './assets/styles/login.scss'
import './assets/styles/statements.scss'
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
//import './assets/main.css'

import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Listbox from 'primevue/listbox';
import Calendar from "primevue/calendar";

loadFonts()

import FontAwesomeIcon from "./utilities/fontawesome-icons";

const pinia = createPinia();

Object(createApp(App))
.component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(VCalendar, {})
  .use(Vue3TouchEvents)
  .use(LoadScript)
  .use(pinia)
  .use(vuetify)
  .use(VueAwesomePaginate)
  .use(PrimeVue)
  .component('InputMask', InputMask)
  .component('Dialog', Dialog)
  .component('Dropdown', Dropdown)
  .component('Calendar', Calendar)
  .component('MultiSelect', MultiSelect)
  .component('Listbox', Listbox)
  .mount("#app");
