<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">8. Эскизный проект конструкций</span>
    <br />
    <span class="main_field">
      8.1 Подгрузите первую страницу эскизного проекта
    </span>
    <v-file-uploader
      :main_title="'На ней изображён главный вид, сверху, снизу и сзади'"
      :url_file="storeDM.save_obj.sketch_project_one_file"
      :sample_title="'Пример первой страницы эскизного проекта'"
      :sample_file="'11_1.png'"
      :file_name="'sketch_project_one_file'"
      :button_title="'Подгрузить первую страницу'"
      @file-upload="fileUpload"
      :key="sketch_project_one_file"
    >
    </v-file-uploader>
    <br />
    <br />
    <br />
    <span class="main_field">
      8.2 Подгрузите вторую страницу эскизного проекта
    </span>
    <v-file-uploader
      :main_title="'На ней изображён вид сбоку, слева, способы крепления и характеристики конструкции'"
      :url_file="storeDM.save_obj.sketch_project_two_file"
      :sample_title="'Пример второй страницы эскизного проекта'"
      :sample_file="'11_2.png'"
      :file_name="'sketch_project_two_file'"
      :button_title="'Подгрузить вторую страницу'"
      @file-upload="fileUpload"
      :key="sketch_project_two_file"
    >
    </v-file-uploader>
    <br />
    <br />
    <br />
    <span class="main_field">
      8.3 Подгрузите третью страницу эскизного проекта
    </span>
    <v-file-uploader
      :main_title="'На ней изображёно фото объекта с конструкцией'"
      :url_file="storeDM.save_obj.sketch_project_three_file"
      :sample_title="'Пример третьей страницы эскизного проекта'"
      :sample_file="'11_3.png'"
      :file_name="'sketch_project_three_file'"
      :button_title="'Подгрузить третью страницу'"
      @file-upload="fileUpload"
      :key="sketch_project_three_file"
    >
    </v-file-uploader>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";
import VFileUploader from "@/components/v-file-uploader.vue";

export default defineComponent({
  name: "StepSeven",
  components: { VFileUploader, VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "7",
      });
      router.push({
        path: "/step7/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const sketch_project_one_file = ref(0);
    const sketch_project_two_file = ref(0);
    const sketch_project_three_file = ref(0);

    const fileUpload = (file_name: any, file_name_url: any) => {
      eval(file_name).value = eval(file_name).value + 1;
      storeDM.save_obj[file_name] = file_name_url;
    };

    const go_next = () => {
      storeDM.set_info({
        step: "9",
      });
      router.push({
        path: "/step9/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
    });

    return {
      go_back,
      step_next,
      storeDM,
      go_next,
      sketch_project_one_file,
      sketch_project_two_file,
      sketch_project_three_file,
      fileUpload,
    };
  },
});
</script>

<style scoped></style>
