<template>
  <div class="step__basic" v-if="subpage == 1">
    <div class="left">
      <p>Выберите доверенность из списка</p>
      <Dropdown v-model="proxy_title"
                editable :options="document.proxy_options"
                optionLabel="name"
                :placeholder="proxy_default.name"
                style="width:480px"
                @change="proxy_change"
      />
      <span
        style="
          cursor: pointer;
          display: block;
          margin-left: 394px;
          margin-top: -18px;
        "
        @click="open_doc(document.proxy_id)"
        v-if="document.proxy_id>1"
      >
       Редактировать
      </span>

      <div v-if="document.proxy_id == 1">
        <label for="name">Номер доверенности</label>
        <input type="text" placeholder="Введите номер доверенности" v-model="document.proxy_number">
      </div>
      <div v-if="document.proxy_id == 1">
        <label for="name">Дата выдачи</label>
        <input class="input" type="date" placeholder="__.__.____"
               v-model="document.proxy_date"
               maxlength="10"
               onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
               :max="currentDay"
        >
      </div>
      <div style="width: 230px;" v-if="document.proxy_id == 1">
        <label for="name">Выберите доверенное лицо</label>
        <Dropdown v-model="signatory1_name_title"
                  editable :options="signatory_name_options"
                  optionLabel="name"
                  :placeholder="signatory_name_default.name"
                  @change="signatory_name_change($event)"
                  style="width: 480px;"
        />
        <span
          style="
            cursor: pointer;
            display: block;
            margin-left: 394px;
            margin-top: 0px;
            width: 100px;
          "
          @click="open_sign(document.proxy_person)"
          v-if="document.proxy_person>1"
        >
          Редактировать
        </span>
      </div>
      <input
        ref="proxy_file"
        type="file"
        accept=".pdf"
        hidden
        @change="submitFile('proxy_file')"
      />
      <div v-if="document.proxy_id == 1">
        <button class="btn_upload" style="width: 480px;" @click="$refs.proxy_file.click()">Загрузить файл доверенности</button>
        <span>Файл должен быть в формате pdf</span>
      </div>
      <div v-if="document.proxy_id == 1">
        <!--<a :href="'https://api.rekprof.ru/files/'+document.proxy_file" target="_blank" download><button class="btn_save" style="width: 480px;">Скачать файл доверенности</button></a>-->
        <!--<a :href="'https://api.rekprof.ru/files/'+document.proxy_file" target="_blank" :download="document.proxy_file.split('/')[document.proxy_file.split('/').length-1]"><button class="btn_save" style="width: 480px;">Скачать файл доверенности</button></a>-->
        <!--<button class="btn_save" style="width: 480px;" @click="downloadFileFromURL('https://api.rekprof.ru/files/'+document.proxy_file, document.proxy_file.split('/')[document.proxy_file.split('/').length-1]);">Скачать файл доверенности</button>-->
        <!--<button class="btn_save" style="width: 480px;" @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=power', document.proxy_file.split('/')[document.proxy_file.split('/').length-1]);">Скачать файл доверенности</button>-->
        <span class="name_power">{{document.proxy_file.split('/')[document.proxy_file.split('/').length-1] == "" ? 'не загружен' : document.proxy_file.split('/')[document.proxy_file.split('/').length-1]}}</span>
        <span class="download_power" @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=power', document.proxy_file.split('/')[document.proxy_file.split('/').length-1]);">Скачать</span>
        <span class="del_power" @click="submitFile('proxy_file', '1')"><i class="fa-solid fa-trash"></i></span>
      </div>

    </div>


  </div>

</template>

<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../assets/icons/DropdownUp.vue";
import { computed, defineComponent, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";

export default defineComponent({
  components: {
    DropdownIcon,
    DropdownUp,
  },
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){

    const storeDM = useDMStore();

    const route = useRoute();
    const router = useRouter();


    const currentDay = computed(() => {
      let date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 1).getFullYear()+"-"
                      + ("0"+(new Date(date.getFullYear(), date.getMonth(), 1).getMonth()+1)).slice(-2)+"-"
                      + ("0"+(date.getDate())).slice(-2);  
    });

    const open_doc = async (id: string) => {

      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "edit_declaration",
          id: id,
          hash: route.query.hash,
          parent: route.query.id,
          parent_step: 3,
          parent_type: "document",
        }),
      });

      const response_data = await response.json();
      
      router.push({
        query: {
          hash: route.query.hash,
          type: "proxy",
          id: id,
          step: 1,
        },
      });

      context.emit("edit-proxy");
      
    };


    const open_sign = async (id: string) => {

      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "edit_signatories",
          id: id,
          hash: route.query.hash,
          parent: route.query.id,
          parent_step: 3,
          parent_type: "document",
        }),
      });

      const response_data = await response.json();

      
      router.push({
        query: {
          hash: route.query.hash,
          type: "signatory",
          id: id,
          step: 1,
        },
      });
      
      context.emit("edit-signatory");
      
    };

    const downloadFileFromURL = async (url, fileName) => {

      /*
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      */
      if(fileName != ""){
        await axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      }

      /*
      fetch(url, {mode: 'no-cors'})
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch(error => console.error('Error downloading file:', error));
      */
    }

    let proxy_default = ref({ name: "Выберите доверенность", value: 0 });
    let proxy_complete = ref({ name: "Использовать готовую доверенность", value: 1 });
    const proxy_title = computed(() => {
      if (props.document.proxy_id == 0) {
        return proxy_default.value.name;
      } else if (props.document.proxy_id == 1) {
        return proxy_complete.value.name;
      } else {
        //return props.document.proxy_options[Number(props.document.proxy_id)-1].name;
        //const filteredItems = props.document.proxy_options.filter(item => `${item.id}`.includes(props.document.proxy_id));
        const filteredItems = props.document.proxy_options.filter(item => item.id === props.document.proxy_id);
        //const filteredItems = props.document.proxy_options.filter(item => `${item.id}`.includes(2));
        //console.log(filteredItems);
        //return filteredItems[0].name;
        return filteredItems[0];
      }
    });


    const proxy_change = (e) => {
      props.document.proxy_id = e.value.value;
      if(props.document.proxy_id == 0){
        new_proxy();
      }
    }


    const new_proxy = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "create_declaration",
          hash: route.query.hash,
          parent: route.query.id,
          parent_step: 3,
          parent_type: "document",
        }),
      });

      const response_data = await response.json();
      //proxy_id.value = response_data["id"];

      router.push({
        query: {
          hash: route.query.hash,
          type: "proxy",
          id: response_data["id"],
          step: 1,
        },
      });

      context.emit("edit-proxy");
      //setTimeout(selectComponent, 500,"newDocuments");

    };



    const new_signatory = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "create_signatory",
          hash: route.query.hash,
          parent: route.query.id,
          parent_step: 3,
          parent_type: "document",
        }),
      });

      const response_data = await response.json();
      //proxy_id.value = response_data["id"];

      router.push({
        query: {
          hash: route.query.hash,
          type: "signatory",
          id: response_data["id"],
          step: 1,
        },
      });

      context.emit("edit-signatory");

    };



    const signatory_name_change = (e) => {
      if(e.value.value=="new"){
        new_signatory();
      } else {
        props.document.proxy_person = e.value.value;
      }
    }

    //const subpage = ref(Number(route.query.substep));
    const subpage = ref(1);



    const signatory_name_default = ref({ name: "Не выбран", value: "0" });
    let signatory_name_options = props.document.signatory_options;
    signatory_name_options[0] = signatory_name_default.value;
    signatory_name_options[1] = {name: 'Создать нового подписанта', value: 'new'};

    const signatory1_name_title = computed(() => {
      if(0 == props.document.proxy_person){
        return "Не выбран";
      } else {
        console.log(signatory_name_options);
        let obj = signatory_name_options.find(el => el.value == props.document.proxy_person);
        return Object(obj);
      }
    });

    const proxy_file = ref(null);

    const submitFile = (f_name: string, del: string = '0') => {
      
      let file = Object(eval(f_name).value).files[0];
      if(del == '0'){
        if(file == undefined){
          return;
        }
      }

      let formData = new FormData();
      if(del == '0'){
        formData.append(f_name, file);
        formData.append("del", "0"); 
      } else {
        formData.append(f_name, "");
        formData.append("del", "1");
      }
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.query.id));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          props.document[Object(req.data).file_name] = Object(req.data)[
            req.data.file_name
            ];
          console.log(Object(req.data).file_name+" - "+Object(req.data)[req.data.file_name]);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    return{
      subpage,
      proxy_title,
      new_proxy,
      proxy_default,
      proxy_change,
      signatory_name_change,
      signatory_name_default,
      signatory1_name_title,
      signatory_name_options,
      submitFile,
      proxy_file,
      downloadFileFromURL,
      route,
      currentDay,
      open_doc,
      open_sign,
    }
  }
});
</script>

<style scoped>
  .download_power {
    cursor: pointer;
    display: flex;
    width: fit-content;
    position: absolute;
    margin-left: 413px;
  }

  .del_power {
    cursor: pointer;
    display: flex;
    width: fit-content;
    position: absolute;
    margin-left: 470px;
  }

  .name_power {
    display: flex;
    width: fit-content;
    position: absolute;
  }
</style>