import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faUser,
  faFlag,
  faTrash,
  faUsers,
  faList,
  faFile,
  faSliders,
  faMagnifyingGlass,
  faEllipsisVertical,
  faStar,
  faBars,
  faArrowRotateRight,
  faAddressCard,
  faPersonWalkingArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFolderOpen,
  faStar as faStar2,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faPhone,
  faUser,
  faTrash,
  faFolderOpen,
  faUsers,
  faList,
  faFile,
  faSliders,
  faMagnifyingGlass,
  faEllipsisVertical,
  faStar,
  faStar2,
  faBars,
  faArrowRotateRight,
  faAddressCard,
  faPersonWalkingArrowRight
);
dom.watch();

export default FontAwesomeIcon;
