<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, reactive, computed } from "vue";
import {useDMStore} from "@/store/dm_obj";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  props: {
    is_open: {
      type: Boolean,
      default() {
        return true;
      }
    },
    id: {
      type: Number,
      default() {
        return 0;
      }
    },
    info_num: {
      type: String,
      default() {
        return '';
      }
    },
    info_txt: {
      type: String,
      default() {
        return '';
      }
    },
  },
  emits: ["do_btn"],
  setup(props: {
    is_open: boolean;
    id: number;
    info_txt: string,
    info_num: string
  }, context) {
    const isOpen = ref(props.is_open);
    const router = useRouter();
    const route = useRoute();
    
    const close = () => {
      isOpen.value = false;
    }

    const do_btn = () => {
        context.emit("do_btn", props.info_num);
    }

    return {
      close,
      isOpen,
      do_btn,
    };
  },
});
</script>

<template>
  <div class="modal_window" v-if="isOpen">
    <div class="close" @click="close">✖</div>
    <div class="info_txt" v-if="info_txt!='этого подписанта'"><div style="width: 225px; margin-top: 120px; margin-left: 62.5px;">Вы действительно хотите удалить {{info_txt}} {{info_num}}?</div></div>
    <div class="info_txt" v-else><div style="width: 225px; margin-top: 120px; margin-left: 62.5px;">Вы действительно хотите удалить {{info_txt}}?</div></div>
    <div class="wrapper_btn"><button class="do_btn" @click="do_btn">Удалить</button></div>
  </div>
  <div class="modal_block" v-if="isOpen">
  </div>
</template>

<style scoped>
@font-face {
  font-family: "MontserratBlack";
  src: local("Montserrat"), url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

.close {
  cursor: pointer;
  color: #2F80ED;
  width: 14px;
  line-height: 14px;
  margin-left: 327px;
  margin-top: 10px;
}

.close:hover {
  color: #1D76EF;
}

.do_btn {
  width: 298px;
  height: 48px;
  border-radius: 2px;
  background-color: #2F80ED;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: white;

  margin-top: 80px;
}

.do_btn:hover {
  background-color: #1D76EF;
}

.wrapper_btn {
  justify-content: center;
  flex-direction: row !important;
  margin-top: 10px;
}

.info_txt {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  justify-content: center;
  flex-direction: row !important;
}

.end_tariff_txt {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
}

.logo_txt {
  font-family: MontserratBlack;
  font-size: 26px;
  font-weight: 900;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: #2F80ED;

  width: 130px;
}

.logo_modal {
  justify-content: center;
  flex-direction: row !important;
}

.modal_window {
  width: 350px;
  height: 340px;
  border-radius: 2px;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  background-color: white;
  position: absolute;
  /* margin: auto auto; */
  left: 50%;
  right: 0;
  top: 7%;
  bottom: 0;
  z-index: 1;
  transform: translate(-50%, 0%);
}

.modal_block{
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index: 0;
  background-color: rgba(50,50,50, 0.5);
}
</style>