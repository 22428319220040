<template>
  <div class="create__statements" v-if="document.prefix =='spb' && document.postfix =='info'">
    <div class="white__navbar">
      <p>Заявление №{{docID}}</p>
      <p>/</p>
      <p v-if="page == 1">Тип заявителя / Выбор типа заявителя</p>
      <p v-if="page == 2">Выбор способа подачи</p>
      <p v-if="page == 3 && document.send_type == 2">Самостоятельно</p>
      <p v-if="page == 3 && document.send_type != 2">Информация о заявителе</p>
      <p v-if="page == 4">Адрес местности</p>
      <p v-if="page == 5">Установка карты</p>
      <p v-if="page == 6">Информация о конструкции</p>
      <p v-if="page == 7">Фотофиксация перспективы конструкции</p>
      <p v-if="page == 8">Компьютерный монтаж перспективы конструкции</p>
      <p v-if="page == 9">Эскизный проект</p>
      <p v-if="page == 10">Данные о конструкции</p>
      <p v-if="page == 11">Данные о проектировщие и изготовителе</p>
      <p v-if="page == 12">Сохранение заявления</p>
    </div>
    <div class="create__statements__content">
      <One v-if="page == 1" :document="document" />
      <Second v-if="page == 2" :document="document" />
      <Third v-if="page == 3 && document.send_type == 2" :document="document" />
      <ThirdProxy v-if="page == 3 && document.send_type != 2" :document="document" @edit-proxy="edit_proxy" @edit-signatory="edit_signatory" />
      <Fourth v-if="page == 4" :document="document" />
      <FourthTwo v-if="page == 5" :document="document" />
      <Fifth v-if="page == 6" :document="document" />
      <Sixth v-if="page == 7" :document="document" />
      <Seventh v-if="page == 8" :document="document" />
      <EigthTwo v-if="page == 9" :document="document" />
      <Nineth v-if="page == 10" :document="document" />
      <Tenth v-if="page == 11" :document="document" />
      <Eleventh v-if="page == 12" :document="document" @change-page="change_page"/>
    </div>
    <!--
    <div class="example" v-if="page == 1 || page == 2 || page == 3 || page == 4 || page == 5">
      <p>Примеры отображения</p>
      <div class="rectangle">
        <svg width="140" height="203" viewBox="0 0 140 203" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="5.75049" y="6.77979" width="128" height="188" stroke="black"/>
          <rect x="56.3379" y="53.0396" width="29.9125" height="8" fill="#2F80ED"/>
          <rect x="39.0034" y="63.7476" width="64.5819" height="8" fill="#2F80ED"/>
          <rect x="39.0034" y="135.75" width="64.5819" height="8" fill="#2F80ED"/>
          <rect x="39.0034" y="74.4556" width="64.5819" height="8" fill="#2F80ED"/>
          <rect x="94.2505" y="13.7798" width="31.9623" height="8" fill="#E0E0E0"/>
          <rect x="94.2505" y="26.1353" width="31.9623" height="8" fill="#E0E0E0"/>
          <rect x="94.2505" y="38.4907" width="31.9623" height="8" fill="#E0E0E0"/>
        </svg>
      </div>
    </div>
    -->

    <pagination
      v-model="page"
      @modalskip = "modalskip_show"
      :check_next = "check_next"
      :doc_type = "'statement'"
      :key="pagination_key"
      @click = "update_page_query"
      :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
      :page_arr="[
        {name: '1', title: 'Тип заявителя', complete: ['client_inn']},
        {name: '2', title: 'Способ подачи', complete: ['send_type']},
        {name: '3', title: 'Настройка доверенности', complete: ['proxy_id']},
        {name: '4', title: 'Адрес местности', complete: ['map_address','map_district','map_street']},
        {name: '5', title: 'Карта местности', complete: ['mapfile_type','map_file','map_yandex_file']},
        {name: '6', title: 'Информация о конструкции', complete: ['place_install','construction_type']},
        {name: '7', title: 'Фотофиксация', complete: ['construction_perspective_file','construction_around_file','construction_away_file']},
        {name: '8', title: 'Компьютерный монтаж', complete: ['comp_arr']},
        {name: '9', title: 'Эскизный проект', complete: ['sketch_arr']},
        {name: '10', title: 'Подсветка', complete: ['construction_width','construction_height']},
        {name: '11', title: 'Проектировщик и изготовитель', complete: ['designer_name','manufacturer_name']},
        {name: '12', title: 'Сохранение заявления', complete: []}
      ]"
      :nav_btn="true"
      :page="page"
      :document="document"
      @change-page="change_page"      
    />

    <!--
    <vue-awesome-paginate
      :total-items="12"
      :items-per-page="1"
      :max-pages-shown="12"
      v-model="page"
      prev-button-content="Назад"
      next-button-content="Далее"
      @click = "update_page_query"
      :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
    />
    -->
  </div>

  <div class="create__statements" v-if="document.prefix =='other' && document.postfix =='info'">
    <div class="white__navbar">
      <p>Заявление №{{docID}}</p>
      <p>/</p>
      <p v-if="page == 1">Титульный лист</p>
      <p v-if="page == 2">Информация о здании и конструкции</p>
      <p v-if="page == 3">Карта местности</p>
      <p v-if="page == 4">Фотофиксация конструкции</p>
      <p v-if="page == 5">Эскизный проект</p>
      <p v-if="page == 6">Компьютерный монтаж</p>
      <p v-if="page == 7">Сохранение заявления</p>
    </div>
    <div class="create__statements__content">
      <InfoOtherOne v-if="page == 1" :document="document" />
      <InfoOtherTwo v-if="page == 2" :document="document" />
      <InfoOtherThree v-if="page == 3" :document="document" />
      <InfoOtherFour v-if="page == 4" :document="document" />
      <InfoOtherFive v-if="page == 5" :document="document" />
      <InfoOtherSix v-if="page == 6" :document="document" />
      <InfoOtherSeven v-if="page == 7" :document="document" />
    </div>
    <pagination
      v-model="page"
      @modalskip = "modalskip_show"
      :check_next = "check_next"
      :doc_type = "'statement'"
      :key="pagination_key"
      @click = "update_page_query"
      :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
      :page_arr="[
        {name: '1', title: 'Титульный лист', complete: ['purpose_design','address','address_law','designer_name']},
        {name: '2', title: 'Информация о здании и конструкции', complete: ['purpose_building','binding','construction_info','place','size','info_front','info_light','info_side','info_back']},
        {name: '3', title: 'Карта местности', complete: ['mapfile_type','map_file','map_yandex_file']},
        {name: '4', title: 'Фотофиксация конструкции', complete: ['construction_around_file','construction_away_file']},
        {name: '5', title: 'Эскизный проект', complete: ['sketch_project_one_file']},
        {name: '6', title: 'Компьютерный монтаж', complete: ['mounting_around_file','mounting_away_file']},
        {name: '7', title: 'Сохранение заявления', complete: []}
      ]"
      :nav_btn="true"
      :page="page"
      :document="document"
      @change-page="change_page"
    />

    <!--
    <vue-awesome-paginate
      :total-items="7"
      :items-per-page="1"
      :max-pages-shown="7"
      v-model="page"
      prev-button-content="Назад"
      next-button-content="Далее"
      @click = "update_page_query"
      :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
    />
    -->
  </div>


  <div class="create__statements" v-if="document.prefix =='surgut' && document.postfix =='info'">
    <div class="white__navbar">
      <p>Заявление №{{docID}}</p>
      <p>/</p>
      <p v-if="page == 1">Титульный лист</p>
      <p v-if="page == 2">Список подписантов</p>
      <p v-if="page == 3">Ситуационная схема</p>
      <p v-if="page == 4">Фотофиксация конструкции</p>
      <p v-if="page == 5">Эскизный проект</p>
      <p v-if="page == 6">Архитектурно-художественная подсветка</p>
      <p v-if="page == 7">Сохранение заявления</p>
    </div>
    <div class="create__statements__content">
      <InfoSurgutOne v-if="page == 1" :document="document" />
      <InfoSurgutTwo v-if="page == 2" :document="document" />
      <InfoSurgutThree v-if="page == 3" :document="document" />
      <InfoSurgutFour v-if="page == 4" :document="document" />
      <InfoSurgutFive v-if="page == 5" :document="document" />
      <InfoSurgutSix v-if="page == 6" :document="document" />
      <InfoSurgutSeven v-if="page == 7" :document="document" />
    </div>
    <pagination
      v-model="page"
      @modalskip = "modalskip_show"
      :check_next = "check_next"
      :doc_type = "'statement'"
      :key="pagination_key"
      @click = "update_page_query"
      :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
      :page_arr="[
        {name: '1', title: 'Титульный лист', complete: ['designer_name','address','address_law','purpose_design']},
        {name: '2', title: 'Список подписантов', complete: ['employee1_name','employee1_id']},
        {name: '3', title: 'Карта местности', complete: ['mapfile_type','map_file','map_yandex_file']},
        {name: '4', title: 'Фотофиксация конструкции', complete: ['photo_old_file','photo_new_file']},
        {name: '5', title: 'Эскизный проект', complete: ['sketch_count']},
        {name: '6', title: 'Подсветка вывески', complete: ['lighting_file']},
        {name: '7', title: 'Сохранение заявления', complete: []}
      ]"
      :nav_btn="true"
      :page="page"
      :document="document"
      @change-page="change_page"
    />
    <!--
    <vue-awesome-paginate
      :total-items="7"
      :items-per-page="1"
      :max-pages-shown="7"
      v-model="page"
      prev-button-content="Назад"
      next-button-content="Далее"
      @click = "update_page_query"
      :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
    />
    -->
  </div>

  <div class="create__statements" v-if="document.prefix =='msk_o' && document.postfix =='info'">
    <div class="white__navbar">
      <p>Заявление №{{docID}}</p>
      <p>/</p>
      <p v-if="page == 1">Адрес местности</p>
      <p v-if="page == 2">Информация о владельце конструкции</p>
      <p v-if="page == 3">Карта местности</p>
      <p v-if="page == 4">Фотофиксация конструкции</p>
      <p v-if="page == 5">Чертёж средства размещения информации</p>
      <p v-if="page == 6">Монтаж конструкции</p>
      <p v-if="page == 7">Эскизный проект</p>
      <p v-if="page == 8">Сохранение заявления</p>
    </div>
    <div class="create__statements__content">
      <InfoMskOOne v-if="page == 1" :document="document" />
      <InfoMskOTwo v-if="page == 2" :document="document" />
      <InfoMskOThree v-if="page == 3" :document="document" />
      <InfoMskOFour v-if="page == 4" :document="document" />
      <InfoMskOFourOne v-if="page == 5" :document="document" />
      <InfoMskOFourTwo v-if="page == 6" :document="document" />
      <InfoMskOFive v-if="page == 7" :document="document" />
      <InfoMskOSix v-if="page == 8" :document="document" />
    </div>

    <pagination
      v-model="page"
      @modalskip = "modalskip_show"
      :check_next = "check_next"
      :doc_type = "'statement'"
      :key="pagination_key"
      @click = "update_page_query"
      :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
      :page_arr="[
        {name: '1', title: 'Адрес местности', complete: ['address','characteristics_info','characteristics_building']},
        {name: '2', title: 'Информация о владельце', complete: ['information_owner','address','size','information_text']},
        {name: '3', title: 'Карта местности', complete: ['mapfile_type','map_file','map_yandex_file']},
        {name: '4', title: 'Фотофиксация конструкции', complete: ['construction_around_file','construction_away_file']},
        {name: '5', title: 'Чертёж', complete: ['sketchmini_file']},
        {name: '6', title: 'Фотомонтаж конструкции', complete: ['mounting_around_file','mounting_away_file']},
        {name: '7', title: 'Эскизный проект', complete: ['sketch_file']},
        {name: '8', title: 'Сохранение заявления', complete: []}
      ]"
      :nav_btn="true"
      :page="page"
      :document="document"
      @change-page="change_page"
    />

    <!--
    <vue-awesome-paginate
      :total-items="6"
      :items-per-page="1"
      :max-pages-shown="6"
      v-model="page"
      prev-button-content="Назад"
      next-button-content="Далее"
      @click = "update_page_query"
      :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
    />
    -->
  </div>

  <ModalSkip :key="modalskip_key" :is_open="modalskip_open" :info_num="'0'" @skip_btn="skip_btn" @unskip_change="unskip_change"></ModalSkip>

</template>

<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../assets/icons/DropdownUp.vue";
import One from './One.vue';
import Second from './Second.vue';
import Third from './Third.vue';
import ThirdProxy from './ThirdProxy.vue';
import Fourth from './Fourth.vue';
import FourthTwo from './FourthTwo.vue';
import Fifth from './Fifth.vue';
import Sixth from './Sixth.vue';
import Seventh from './SeventhTwo.vue';
import Eigth from './Eigth.vue';
import EigthTwo from './EigthTwo.vue';
import Nineth from './Nineth.vue';
import Tenth from './Tenth.vue';
import Eleventh from './Eleventh.vue';
import Pagination from "@/components/Pagination.vue";
import ModalSkip from "../ModalSkip.vue";

import { default as InfoOtherOne } from './info/other/One.vue';
import { default as InfoOtherTwo } from './info/other/Two.vue';
import { default as InfoOtherThree } from './info/other/Three.vue';
import { default as InfoOtherFour } from './info/other/Four.vue';
import { default as InfoOtherFive } from './info/other/Five.vue';
import { default as InfoOtherSix } from './info/other/Six.vue';
import { default as InfoOtherSeven } from './info/other/Seven.vue';

import { default as InfoSurgutOne } from './info/surgut/One.vue';
import { default as InfoSurgutTwo } from './info/surgut/Two.vue';
import { default as InfoSurgutThree } from './info/surgut/Three.vue';
import { default as InfoSurgutFour } from './info/surgut/Four.vue';
import { default as InfoSurgutFive } from './info/surgut/Five.vue';
import { default as InfoSurgutSix } from './info/surgut/Six.vue';
import { default as InfoSurgutSeven } from './info/surgut/Seven.vue';

import { default as InfoMskOOne } from './info/msk_o/One.vue';
import { default as InfoMskOTwo } from './info/msk_o/Two.vue';
import { default as InfoMskOThree } from './info/msk_o/Three.vue';
import { default as InfoMskOFour } from './info/msk_o/Four.vue';
import { default as InfoMskOFourOne } from './info/msk_o/Four_one.vue';
import { default as InfoMskOFourTwo } from './info/msk_o/Four_two.vue';
import { default as InfoMskOFive } from './info/msk_o/Five.vue';
import { default as InfoMskOSix } from './info/msk_o/Six.vue';

import {computed, defineComponent, reactive, ref} from "vue";
import { defineStore } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";
export default defineComponent ({
  components: {
    DropdownIcon,
    DropdownUp,
    One,
    Second,
    Third,
    Fourth,
    FourthTwo,
    Fifth,
    Sixth,
    Seventh,
    Eigth,
    EigthTwo,
    Nineth,
    Tenth,
    Eleventh,
    ThirdProxy,
    InfoOtherOne,
    InfoOtherTwo,
    InfoOtherThree,
    InfoOtherFour,
    InfoOtherFive,
    InfoOtherSix,
    InfoOtherSeven,
    InfoSurgutOne,
    InfoSurgutTwo,
    InfoSurgutThree,
    InfoSurgutFour,
    InfoSurgutFive,
    InfoSurgutSix,
    InfoSurgutSeven,
    InfoMskOOne,
    InfoMskOTwo,
    InfoMskOThree,
    InfoMskOFour,
    InfoMskOFourOne,
    InfoMskOFourTwo,
    InfoMskOFive,
    InfoMskOSix,
    Pagination,
    ModalSkip,
  },
  emits: ["edit-proxy", "edit-signatory"],
  setup(props, context){
    const check_next = ref(true);
    let api_url: string = "https://api.rekprof.ru/";
    let hash: string = "";
    let random_hash: string = "";
    let docID: string = "";
    let client_id: string = "";
    let email: string = "";
    let phone: string = "";
    let step: string = "";
    let signatory_options: any = [];
    let signatory_list: any = [];
    let signatory_options_tpm: any = { signatory_genre: 0 };
    let signatory_options_add: boolean = false;
    let save_obj: any = {};
    let dragScroll: boolean = false;
    let initDM: boolean = false;
    const route = useRoute();
    let router: any = useRouter();
    const storeDM = useDMStore();
    storeDM.showSubscription = true;

    const pagination_bottom_left = computed(() => {
      if(storeDM.rail == false){
        return '232px;';
      } else {
        return '80px;';
      }
    });

    const document = reactive({
      hash: route.query.hash,
      id: route.query.id,
      step: route.query.step,

      postfix: "",
      prefix: "",

      client: "",
      client_fullname: "",
      client_inn: "",
      client_ogrn: "",
      client_ogrnip: "",
      client_kpp: "",
      client_address: "",
      client_role_fio: "",
      client_role: "",
      client_role_fio_genitive: "",
      client_smallname: "",
      client_type: 0,

      declarer_no: <any> 0,
      declarer_genre: 0,
      declarer_firstname: "",
      declarer_lastname: "",
      declarer_passport_issued: "",
      declarer_passport_number: "",
      declarer_passport_registration: "",
      declarer_passport_seria: "",
      declarer_patronymic: "",
      declarer_patronymic_no: <any> 0,
      declarer_when_issued: "",
      declarer_division_code: "",
      proxy_date: new Date().toISOString().substr(0, 10),
      proxy_re: 0,

      signatory_list: signatory_list,
      signatory_options: <any> [],
      signatory_main: "",

      send_type: 0,
      construction_app6: <any> 0,
      annotation_yes:  <any> 0,
      annotation: "",
      construction_around_file: "",
      construction_away_file: "",
      construction_height: "",
      construction_info_height: "",
      construction_info_width: "",
      construction_perspective_file: "",
      construction_type: "",
      construction_width: "",

      designer_name: "",
      doc_signature_digital_file: "",
      doc_signature_file: "",
      doc_signature_power_attorney_file: "",
      found: 1,
      info_count: 0,
      manufacturer_name: "",
      map_district: "",
      map_file: "",
      map_build_litera: "",
      map_address: "",
      map_street: "",
      map_house: "",
      map_korpus: "",
      map_place: "",
      map_cross: "",
      mounting_around_file: "",
      mounting_away_file: "",
      mounting_perspective_file: "",
      number_type_illumination: "",
      place_install: "",
      place_number: "",

      remote_ip: "",
      signature_digital_file: "",
      signature_file: "",
      sketch_project_one_file: "",
      sketch_project_two_file: "",
      sketch_project_three_file: "",

      sketch_project_cpu: <any> 1,

      place_manual: "",
      construction_info_custom: {},
      proxy_id: 0,
      mapfile_type: <any> 0,
      map_center_x: "",
      map_center_y: "",
      map_center_zoom: "",
      map_place_set: "",
      map_place_x: "",
      map_place_y: "",
      map_yandex_file: "",

      title: <any> 1,
      contents: <any> 1,

      characteristics_info: "",
      characteristics_building: "",
      information_owner: "",
      number_sides: 1,
      number_elements: 1,
      technological_characteristic: "",
      size: "",
      information_text: "",
      frontal_file: "",
      horizontal_file: "",
      sketch_file: "",
      other_files: {},
      selected_annotations: {},
      steps: <any> [],
      app3_file: "",
      app3_file_checked: <any> 0,
      app4_file: "",
      app4_file_checked: <any> 0,
      app5_file: "",
      app5_file_checked: <any> 0,
      app6_file: "",
      app6_file_checked: <any> 0,

      resolution_number: "",
      resolution_date: "",

      unstep: <any> 0,
    });


    const set_info = async (obj: { [index: string]: any }, one = 1) => {
      for (const i in obj) {
        //???
        //signatory_list
        document[i] = obj[i];

        if (i == "designer_name") {
          if (document[i] == "") {
            document[i] = 'ООО "КГ "Север""';
          }
        }

        if (i == "manufacturer_name") {
          if (document[i] == "") {
            document[i] = 'ООО "КГ "Север""';
          }
        }

        if (i == "construction_info_custom") {
          //this.save_obj[i] = JSON.parse(this.save_obj[i]);
          //this.save_obj[i] = []; //JSON.parse(this.save_obj[i]);
          if (typeof JSON.parse(Object(document[i])) == "string") {
            document[i] = [];
          } else {
            document[i] = JSON.parse(Object(document[i]));
          }
        }

        if (i == "other_files") {
          if (typeof JSON.parse(Object(document[i])) == "string") {
            document[i] = [];
          } else {
            document[i] = JSON.parse(Object(document[i]));
          }
        }

        if (i == "selected_annotations") {
          if (typeof JSON.parse(Object(document[i])) == "string") {
            document[i] = [];
          } else {
            document[i] = JSON.parse(Object(document[i]));
          }
        }

        if (i == "sketch_arr") {
          if (typeof JSON.parse(Object(document[i])) == "string") {
            document[i] = [];
          } else {
            document[i] = JSON.parse(Object(document[i]));
            for(let ii=0; ii<document[i].length; ii++){
              if(document[i][ii]["name"] == "false"){
                document[i][ii]["name"] = false;
              } else if(document[i][ii]["name"] == "true"){
                document[i][ii]["name"] = true;
              }
            }
          }
        }

        if (i == "comp_arr") {
          if (typeof JSON.parse(Object(document[i])) == "string") {
            document[i] = [];
          } else {
            document[i] = JSON.parse(Object(document[i]));
            for(let ii=0; ii<document[i].length; ii++){
              if(document[i][ii]["name"] == "false"){
                document[i][ii]["name"] = false;
              } else if(document[i][ii]["name"] == "true"){
                document[i][ii]["name"] = true;
              }
            }
          }
        }
        

        if(i == "mapfile_type") {
          if (document.mapfile_type == 1) {
            document.mapfile_type = true;
          } else {
            document.mapfile_type = false;
          }
        }

        if(i == "title") {
          if (document.title == 1) {
            document.title = true;
          } else {
            document.title = false;
          }
        }

        if(i == "unstep") {
          if (document.unstep == 1) {
            document.unstep = true;
          } else {
            document.unstep = false;
          }
        }

        if(i == "contents") {
          if (document.contents == 1) {
            document.contents = true;
          } else {
            document.contents = false;
          }
        }

        if (i == "declarer_patronymic_no") {
          if (document.declarer_patronymic_no == 1) {
            document.declarer_patronymic_no = true;
          } else {
            document.declarer_patronymic_no = false;
          }
        }

        if (i == "construction_app6") {
          if (document.construction_app6 == 1) {
            document.construction_app6 = true;
          } else {
            document.construction_app6 = false;
          }
        }

        if (i == "annotation_yes") {
          if (document.annotation_yes == 1) {
            document.annotation_yes = true;
          } else {
            document.annotation_yes = false;
          }
        }

        if (i == "sketch_project_cpu") {
          if (document.sketch_project_cpu == 1) {
            document.sketch_project_cpu = true;
          } else {
            document.sketch_project_cpu = false;
          }
        }

        if (i == "declarer_no") {
          if (document.declarer_no == 1) {
            document.declarer_no = true;
          } else {
            document.declarer_no = false;
          }
        }

        if (i == "app3_file_checked") {
          if (document.app3_file_checked == 1) {
            document.app3_file_checked = true;
          } else {
            document.app3_file_checked = false;
          }
        }

        if (i == "app4_file_checked") {
          if (document.app4_file_checked == 1) {
            document.app4_file_checked = true;
          } else {
            document.app4_file_checked = false;
          }
        }

        if (i == "app5_file_checked") {
          if (document.app5_file_checked == 1) {
            document.app5_file_checked = true;
          } else {
            document.app5_file_checked = false;
          }
        }

        if (i == "app6_file_checked") {
          if (document.app6_file_checked == 1) {
            document.app6_file_checked = true;
          } else {
            document.app6_file_checked = false;
          }
        }

        if (signatory_options_add == true) {
          signatory_options_add = false;
          if (signatory_options_tpm.signatory_skip_patronymic == true) {
            signatory_options_tpm.signatory_skip_patronymic = 1;
          } else {
            signatory_options_tpm.signatory_skip_patronymic = 0;
          }
          await add_signatory_options();
        }
      }
      if (one == 1) {
        send_info_server(0);
      }

      document.steps = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (
        document.client_inn != "" &&
        document.client_type != 0 // &&
        //this.save_obj.client_ogrn != "" &&
        //this.save_obj.client_ogrnip != "" &&
        //this.save_obj.client_kpp != "" &&
        //this.save_obj.client_address != "" &&
        //this.save_obj.client_fullname != "" &&
        //this.save_obj.client_smallname != ""
      ) {
        document.steps[1] = 1;
      }
      if (document.send_type != 0) {
        document.steps[2] = 1;

        document.steps[3] = 1; //!!!!!!!
      }

      if (document.send_type == 1) {
        document.steps[3] = 1;
      }

      if (
        document.map_file != "" &&
        document.map_address != "" &&
        document.map_street != "" &&
        document.map_district != "" &&
        document.map_house != "" &&
        document.map_house != "" &&
        document.map_korpus != "" &&
        document.map_build_litera != "" &&
        //document.map_place != "" &&
        document.map_cross != ""
      ) {
        document.steps[4] = 1;
      }

      if (
        document.place_number !== "" &&
        document.construction_type != "" &&
        document.place_install != ""
      ) {
        document.steps[5] = 1;
      }

      if (
        document.construction_perspective_file != "" &&
        document.construction_around_file != "" &&
        document.construction_away_file != ""
      ) {
        document.steps[6] = 1;
      }

      if (
        (document.mounting_perspective_file != "" &&
          document.mounting_around_file != "" &&
          document.mounting_away_file != "") ||
        document.construction_app6 == true
      ) {
        document.steps[7] = 1;
      }

      if (
        document.sketch_project_one_file != "" &&
        document.sketch_project_two_file != ""
      ) {
        document.steps[8] = 1;
      }

      if (
        document.number_type_illumination != "" &&
        document.construction_width != "" &&
        document.construction_height != "" &&
        document.construction_info_width != "" &&
        document.construction_info_height != "" &&
        document.info_count != 0
      ) {
        document.steps[9] = 1;
      }

      if (
        document.designer_name !== "" &&
        document.manufacturer_name !== ""
      ) {
        document.steps[10] = 1;
      }

      if (
        document.steps[1] == 1 &&
        document.steps[2] == 1 &&
        document.steps[3] == 1 &&
        document.steps[4] == 1 &&
        document.steps[5] == 1 &&
        document.steps[6] == 1 &&
        document.steps[7] == 1 &&
        document.steps[8] == 1 &&
        document.steps[9] == 1 &&
        document.steps[10] == 1
      ) {
        document.steps[11] = 1;
      }

      //console.log(save_obj);
    };

    const add_signatory_options = async () => {
      document["signatory_options"].push(signatory_options_tpm);
      signatory_options_tpm = { signatory_genre: 0 };
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "add_signatory_options",
          hash: hash,
          id: docID,
          client: document["client"],
          signatory_type: "document",
          signatory_options: document["signatory_options"],
        }),
      });
      const response_data = await response.json();
    };

    const send_info_server = async (one = 1) => {

      if( document.postfix == "info" && document.prefix == "spb" ) {

        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "set_info",
            hash: hash,
            id: docID,
            client: document["client"],
            step: page.value,
            client_fullname: document["client_fullname"],
            client_inn: document["client_inn"],
            client_ogrn: document["client_ogrn"],
            client_ogrnip: document["client_ogrnip"],
            client_kpp: document["client_kpp"],
            client_address: document["client_address"],
            client_role_fio: document["client_role_fio"],
            client_role: document["client_role"],
            client_role_fio_genitive: document["client_role_fio_genitive"],
            client_smallname: document["client_smallname"],
            client_type: document["client_type"],
            send_type: document["send_type"],
            construction_app6: document["construction_app6"],
            annotation_yes: document["annotation_yes"],
            annotation: document["annotation"],
            construction_around_file: document["construction_around_file"],
            construction_away_file: document["construction_away_file"],
            construction_height: document["construction_height"],
            construction_info_height: document["construction_info_height"],
            construction_info_width: document["construction_info_width"],
            construction_perspective_file:
              document["construction_perspective_file"],
            construction_type: document["construction_type"],
            construction_width: document["construction_width"],
            declarer_no: document["declarer_no"],
            declarer_genre: document["declarer_genre"],
            declarer_firstname: document["declarer_firstname"],
            declarer_lastname: document["declarer_lastname"],
            declarer_passport_issued: document["declarer_passport_issued"],
            declarer_passport_number: document["declarer_passport_number"],
            declarer_passport_registration:
              document["declarer_passport_registration"],
            declarer_passport_seria: document["declarer_passport_seria"],
            declarer_patronymic: document["declarer_patronymic"],
            declarer_patronymic_no: document["declarer_patronymic_no"],
            declarer_when_issued: document["declarer_when_issued"],
            declarer_division_code: document["declarer_division_code"],
            designer_name: document["designer_name"],
            doc_signature_digital_file:
              document["doc_signature_digital_file"],
            doc_signature_file: document["doc_signature_file"],
            doc_signature_power_attorney_file:
              document["doc_signature_power_attorney_file"],
            found: document["found"],
            info_count: document["info_count"],
            manufacturer_name: document["manufacturer_name"],
            map_district: document["map_district"],
            map_file: document["map_file"],
            map_build_litera: document["map_build_litera"],
            map_address: document["map_address"],
            map_street: document["map_street"],
            map_house: document["map_house"],
            map_korpus: document["map_korpus"],
            map_place: document["map_place"],
            map_cross: document["map_cross"],
            mounting_around_file: document["mounting_around_file"],
            mounting_away_file: document["mounting_away_file"],
            mounting_perspective_file: document["mounting_perspective_file"],
            number_type_illumination: document["number_type_illumination"],
            place_install: document["place_install"],
            place_number: document["place_number"],
            proxy_date: document["proxy_date"],
            proxy_re: document["proxy_re"],
            remote_ip: document["remote_ip"],
            signature_digital_file: document["signature_digital_file"],
            signature_file: document["signature_file"],
            sketch_project_one_file: document["sketch_project_one_file"],
            sketch_project_two_file: document["sketch_project_two_file"],
            sketch_project_three_file: document["sketch_project_three_file"],
            sketch_project_cpu: document["sketch_project_cpu"],
            signatory_list: signatory_list,
            signatory_options: document["signatory_options"],
            signatory_main: document["signatory_main"],
            place_manual: document["place_manual"],
            construction_info_custom: JSON.stringify(
              document["construction_info_custom"]
            ),
            proxy_id: document["proxy_id"],
            mapfile_type: document["mapfile_type"],
            map_center_x: document["map_center_x"],
            map_center_y: document["map_center_y"],
            map_center_zoom: document["map_center_zoom"],
            map_place_set: document["map_place_set"],
            map_place_x: document["map_place_x"],
            map_place_y: document["map_place_y"],
            map_yandex_file: document["map_yandex_file"],
            proxy_number: document["proxy_number"],
            proxy_person: document["proxy_person"],
            other_files: document["other_files"],
            selected_annotations: JSON.stringify(document["selected_annotations"]),
            app3_file: document["app3_file"],
            app3_file_checked: document["app3_file_checked"],
            app4_file: document["app4_file"],
            app4_file_checked: document["app4_file_checked"],
            app5_file: document["app5_file"],
            app5_file_checked: document["app5_file_checked"],
            app6_file: document["app6_file"],
            app6_file_checked: document["app6_file_checked"],
            sketch_count: document["sketch_count"],
            comp_count: document["comp_count"],   
            resolution_number: document["resolution_number"],
            resolution_date: document["resolution_date"],
          }),
        });
        const response_data = await response.json();

        if (response_data.found == 0) {
          router.push({
            path: "/",
          });
          return;
        }

        if (one == 1) {
          set_info(response_data);
        }

      } else if( document.postfix == "info" && document.prefix == "other" ){

        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "set_info",
            hash: hash,
            id: docID,
            client: document["client"],
            step: page.value,
            title: document["title"],
            purpose_design: document["purpose_design"],
            address: document["address"],
            address_law: document["address_law"],
            designer_name: document["designer_name"],
            purpose_building: document["purpose_building"],
            binding: document["binding"],
            construction_info: document["construction_info"],
            place: document["place"],
            size: document["size"],
            info_front: document["info_front"],
            info_light: document["info_light"],
            info_side: document["info_side"],
            info_back: document["info_back"],
            mapfile_type: document["mapfile_type"],
            map_center_x: document["map_center_x"],
            map_center_y: document["map_center_y"],
            map_center_zoom: document["map_center_zoom"],
            map_place_set: document["map_place_set"],
            map_place_x: document["map_place_x"],
            map_place_y: document["map_place_y"],
            map_yandex_file: document["map_yandex_file"],
            construction_around_file: document["construction_around_file"],
            construction_away_file: document["construction_away_file"],
            design_file: document["design_file"],
            mounting_around_file: document["mounting_around_file"],
            mounting_away_file: document["mounting_away_file"],
          }),
        });
        const response_data = await response.json();

        if (response_data.found == 0) {
          router.push({
            path: "/",
          });
          return;
        }

        if (one == 1) {
          set_info(response_data);
        }

      } else if( document.postfix == "info" && document.prefix == "surgut" ){

        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "set_info",
            hash: hash,
            id: docID,
            client: document["client"],
            step: page.value,
            purpose_design: document["purpose_design"],
            address: document["address"],
            address_law: document["address_law"],
            designer_name: document["designer_name"],
            mapfile_type: document["mapfile_type"],
            map_center_x: document["map_center_x"],
            map_center_y: document["map_center_y"],
            map_center_zoom: document["map_center_zoom"],
            map_place_set: document["map_place_set"],
            map_place_x: document["map_place_x"],
            map_place_y: document["map_place_y"],
            employee_count: document["employee_count"],
            employee1_id: document["employee1_id"],
            employee1_name: document["employee1_name"],
            employee2_id: document["employee2_id"],
            employee2_name: document["employee2_name"],
            employee3_id: document["employee3_id"],
            employee3_name: document["employee3_name"],
            employee4_id: document["employee4_id"],
            employee4_name: document["employee4_name"],
            employee5_id: document["employee5_id"],
            employee5_name: document["employee5_name"],
            employee6_id: document["employee6_id"],
            employee6_name: document["employee6_name"],
            sketch_count: document["sketch_count"],
          }),
        });
        const response_data = await response.json();

        if (response_data.found == 0) {
          router.push({
            path: "/",
          });
          return;
        }

        if (one == 1) {
          set_info(response_data);
        }

      } else if( document.postfix == "info" && document.prefix == "msk_o" ){

        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "set_info",
            hash: hash,
            id: docID,
            client: document["client"],
            step: page.value,
            title: document["title"],
            contents: document["contents"],
            address: document["address"],
            characteristics_info: document["characteristics_info"],
            characteristics_building: document["characteristics_building"],
            information_owner: document["information_owner"],
            construction_type: document["construction_type"],
            number_sides: document["number_sides"],
            number_elements: document["number_elements"],
            technological_characteristic: document["technological_characteristic"],
            size: document["size"],
            information_text: document["information_text"],
            mapfile_type: document["mapfile_type"],
            map_center_x: document["map_center_x"],
            map_center_y: document["map_center_y"],
            map_center_zoom: document["map_center_zoom"],
            map_place_set: document["map_place_set"],
            map_place_x: document["map_place_x"],
            map_place_y: document["map_place_y"],
          }),
        });
        const response_data = await response.json();

        if (response_data.found == 0) {
          router.push({
            path: "/",
          });
          return;
        }

        if (one == 1) {
          set_info(response_data);
        }

      } else {

      }


    };

    const get_info_server = async () => {
      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_info",
          hash: hash,
          id: docID,
        }),
      });

      const response_data = await response.json();

      if (response_data.found == 0) {
        router.push({
          path: "/",
        });
        return;
      }

      signatory_list = response_data.signatory_list;
      //console.log(222);
      //this.save_obj.steps = response_data.steps;
      //console.log(signatory_list.value);
      set_info(response_data, 0);
    };

    const signatory_list_push = async (obj: any) => {
      signatory_list.push({
        id: 0,
        name: obj.name,
        signatories: obj.id,
        document: docID,
      });

      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "set_signatory_list",
          hash: hash,
          id: docID,
          signatory_list: signatory_list,
        }),
      });

      const response_data = await response.json();
      signatory_list = response_data.signatory_list;

      send_info_server(0);
    };

    const signatory_list_delete = async (obj: any) => {
      const sl_del = signatory_list.splice(obj.num, 1);

      const response = await fetch(storeDM.api_url + "api2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "set_signatory_del",
          hash: hash,
          id: docID,
          sl_del: sl_del,
        }),
      });

      const response_data = await response.json();
      signatory_list = response_data.signatory_list;

      send_info_server(0);
    };

    const init = (hash_i: any, docID_i: any) => {
      initDM = true;

      signatory_options_tpm = { signatory_genre: 0 };
      document["signatory_options"] = [];
      document["proxy_options"] = [];

      hash = hash_i;
      docID = docID_i;

      get_info_server();

      //this.set_info({}, 0);
    };


    const page = ref(Number(route.query.step));

    const pagination_key = ref(0);
    const change_page = (page_new_set: number) => {
      page.value = page_new_set;
      pagination_key.value = pagination_key.value + 1;
      update_page_query();
    }

    const update_page_query = () => {
      router.push({
        query: {
          hash: route.query.hash,
          type: "document",
          id: route.query.id,
          region: route.query.region,
          construction: route.query.region,
          step: page.value,
          mode: "edit",
        },
      });
      send_info_server(1);
    };


    init(route.query.hash, route.query.id)
    //let page = 1;

    const edit_proxy = () => {
      context.emit("edit-proxy");
    }

    const edit_signatory = () => {
      context.emit("edit-signatory");
    }

    const modalskip_key = ref(0);
    const modalskip_open = ref(false);
    const modalskip_show = () => {
      if(document.unstep == true){
        modalskip_open.value = true;
        modalskip_key.value = modalskip_key.value+1;
      }
    }

    const skip_btn = () => {
      change_page(page.value+1);
    }

    const unskip_change = (unstep_new: string) => {
      if(unstep_new == "0"){
        document.unstep = false;
      } else {
        document.unstep = true;
      }
    }

    return{
      page,
      document,
      update_page_query,
      docID,
      edit_proxy,
      edit_signatory,
      pagination_bottom_left,
      change_page,
      check_next,
      modalskip_key,
      modalskip_open,
      modalskip_show,
      skip_btn,
      pagination_key,
      unskip_change,
    }
  }
});
</script>

<style>
.create__statements__content {
	height: 735px !important;
}	
</style>