<template>
    <div class="signatories">
      <div class="white__navbar">
        <!--
          <p>Личный кабинет</p>
          <p>/</p>
        -->
        <p>Подписанты</p>
      </div>
      <div class="signatories__content">
        <div class="search">
          <button @click="createSignatories"><PlusIcon /> Новый подписант</button>
          <input type="text" placeholder="Поиск" v-model="search_value">
        </div>
        <div class="data">
        <v-table
      fixed-header
    >
  
      <thead>
        <tr style="color: rgba(0, 0, 0, 0.4509803922);">
          <th class="text-left">
            ФИО
          </th>
          <th class="text-left" style="width:62px">
            Операции
          </th>
        </tr>
      </thead>
      <tbody style="text-align: left;">
        <tr
          v-for="(input, index) in signatory_lists"
          :key="index"
        >
          <td>{{ input.name }}</td>
          <td class="text-center" style="width:62px;">
            <div class="dots" @click="context_detail=input.id"><Dots  /></div>
            <div v-if="context_detail==input.id"
                 style="position: absolute;
                      width: 200px;
                      margin-left: -180px;
                      margin-top: -35px;
                      z-index: 1;
                      text-align: center;
                      background: white;
                      -webkit-user-select: none; /* Safari */
                      -moz-user-select: none; /* Firefox */
                      -ms-user-select: none; /* IE10+/Edge */
                      user-select: none; /* Standard */
                      box-shadow: 0px 20px 22px 0px #B2BDE169;
                    ">
              <div @click="context_detail=0" style="text-align: right;"><LogoutIcon style="cursor: pointer;" /></div>
              <div style="cursor: pointer;" @click="signatory_open(input.id)">Редактировать подписанта</div>
              <div style="cursor: pointer;" @click="signatory_hide_modal(input.id)">Удалить подписанта</div>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</div>
<vue-awesome-paginate
    :total-items="signatory_list.list.length"
    :items-per-page="pages_on_page"
    :max-pages-shown="17"
    v-model="page"
    prev-button-content="Назад"
    next-button-content="Далее"
    :style="
		'position: relative; float: left; transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); margin-top: -0.5rem; top: 0px; left: 0px; margin-left: 1.5rem;'
		//'top: '+screen_h+'px;'
	"
  />
    </div>
    <ModalDel :key="del_key" :is_open="del_modal" :info_txt="'этого подписанта'" :info_num="del_num" @do_btn="signatory_hide"></ModalDel>
  </template>
  
  <script lang="ts">
  import PlusIcon from '../../assets/icons/plusIcon.vue'
  import Star from '../../assets/icons/full-star.vue'
  import EmptyStar from '../../assets/icons/emtyStar.vue'
  import Dots from '../../assets/icons/dots.vue'
  import DotsBlack from '../../assets/icons/dotsblack.vue'
  import {computed, defineComponent, onMounted, ref} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {useDMStore} from "@/store/dm_obj";
  import LogoutIcon from "@/assets/icons/logoutIcon.vue";
  import ModalDel from "../ModalDel.vue";

  export default defineComponent ({
    components: {
      PlusIcon,
      Star,
      Dots,
      DotsBlack,
      EmptyStar,
      LogoutIcon,
      ModalDel
    },
    emits: ["edit-signatory","new-signatory"],
    props: {
      signatory_list: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    setup(
      props: {
        signatory_list: any;
      }, context
    ){
      const storeDM = useDMStore();

      const search_value = ref("");

      const pagination_bottom_left = computed(() => {
        if(storeDM.rail == false){
          return '232px;';
        } else {
          return '80px;';
        }
      });

      let router = useRouter();
      const route = useRoute();

      const page = ref(1);

      const context_detail = ref(0);

      const signatory_lists = computed(() => {
        if(search_value.value != ""){
          let doc_list_filter = props.signatory_list.list.filter(val => val.name.toUpperCase().indexOf(search_value.value.toUpperCase()) !== -1 );
          //console.log(doc_list_filter);
          let tmp_arr = doc_list_filter.slice();
          return tmp_arr.slice(Number(page.value - 1) * pages_on_page.value, page.value * pages_on_page.value);
        } else {
          let tmp_arr = props.signatory_list.list.slice();
          return tmp_arr.slice(Number(page.value - 1) * pages_on_page.value, page.value * pages_on_page.value);
        }
      });

      const signatory_open = (id: string) => {
        router.push({
          query: {
            hash: route.query.hash,
            type: "signatory",
            id: id,
            step: 1,
          },
        });

        context_detail.value = 0;

        context.emit("edit-signatory");
      };

      const del_modal  = ref(false);
      const del_num = ref('');
      const del_key = ref(0);
      const signatory_hide_modal = async (id: string) => {
        context_detail.value = 0;
        del_modal.value = true;
        del_num.value = id;
        del_key.value = del_key.value+1;
      }

      const signatory_hide = async () => {
        let id = del_num.value;

        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "hide_signatory",
            hash: route.query.hash,
            id: id,
          }),
        });

        const response_data = await response.json();
        //console.log(props.signatory_list.value);
        //console.log(props.signatory_list.list);
        props.signatory_list.list = response_data["signatory_list"];
        //console.log(response_data["signatory_list"]);
        //console.log(props.signatory_list);
        context_detail.value = 0;

        del_key.value = del_key.value+1;
        del_modal.value = false;
      };

      const createSignatories = () => {
        context.emit("new-signatory");
      }

      const pages_on_page = ref(11);
      const screen_h = ref(0);
      onMounted(() => {
        /*
        if(screen.availHeight <= 800){
          screen_h.value = 650;
          pages_on_page.value = 7;
        } else {
          screen_h.value = 844;
          pages_on_page.value = 11;
        }
        */
        screen_h.value = 844;
        pages_on_page.value = 11;
        //console.log(screen_h.value);
      });

      return {
        page,
        signatory_lists,
        context_detail,
        signatory_open,
        signatory_hide,
        createSignatories,
        pagination_bottom_left,
        search_value,
        pages_on_page,
        screen_h,
        signatory_hide_modal,
        del_modal,
        del_num,
        del_key,
      }
    }
  });
  </script>
  
  <style>
	.signatories__content {
		min-height: 743px;
	}

  .dots_header {
    height: inherit;
    text-align: center;
    padding-top: 17px;
  }

  .dots {
    cursor: pointer;
    height: inherit;
    text-align: center;
    padding-top: 17px;
  }
  </style>
  