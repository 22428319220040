<template>
  <div class="address_two">
    <div class="left">
      <!--<p>Установка карты</p>-->
      <label for="title" style="margin-left: 10px;">
        <v-switch
          v-if="document.mapfile_type == true"
          style="display: flex; flex-direction: row; justify-content: flex-start"
          color="#2F80ED"
          v-model="document.mapfile_type"
          label="Яндекс Карта"
        ></v-switch>
      </label>
      <label for="title" style="margin-left: 10px;">
        <v-switch
          v-if="document.mapfile_type == false"
          style="display: flex; flex-direction: row; justify-content: flex-start"
          color="#2F80ED"
          v-model="document.mapfile_type"
          label="Свой файл карты"
        ></v-switch>
      </label>
      <br />
      <!--
      <v-radio-group
        v-model="document.mapfile_type"
      >
        <v-radio
          color="#2F80ED"
          label="Использовать сервис Яндекс.Карты"
          value="1">
        </v-radio>
        <v-radio
          color="#2F80ED"
          label="Самостоятельно загрузить файл карты, формата 1:2000"
          value="0">
        </v-radio>
      </v-radio-group>
      -->
      <div v-if="0 == document.mapfile_type" class="manual">
        <FileUpload :document="document" :file_upload_name="'map_file'" :upload_text="'Карта должна быть соотношением 1.5Шx1В, с сервиса РГИС, масштабом 1:2000 с указанным местом установки конструкции. Пример: 3480px x 2320px'" />
      </div>
      <div v-if="1 == document.mapfile_type">
        <YandexMap :document="document"
                   :address_line="document.address"
        />
      </div>
    </div>
    <div class="right">
      <div v-if="0 == document.mapfile_type" class="manual">
        <p style="margin-top: 69px;">Пример карты</p>
        <img v-bind:src="require('../../../../assets/images/exampleMap.jpg')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DropdownIcon from "../../../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../../../assets/icons/DropdownUp.vue";
import Map from '../../../../assets/icons/exampleMap.vue';
import YandexMap from "@/components/YandexMap.vue";
import FileUpload from  "@/components/FileUpload.vue";
import {computed, defineComponent, reactive, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {useDMStore} from "@/store/dm_obj";
export default defineComponent ({
  components: {
    DropdownIcon,
    DropdownUp,
    Map,
    YandexMap,
    FileUpload
  },
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){

    const begin_address_default = ref({ name: "Выберите начало адреса", value: "" });
    const begin_address_options = ref([
      { name: "Улица", value: "Улица" },
      { name: "Бульвар", value: "Бульвар" },
      { name: "Шоссе", value: "Шоссе" },
      { name: "Проспект", value: "Проспект" },
    ]);

    const begin_address_title = computed(() => {
      if (props.document.map_street == "") {
        return null;
      } else {
        return props.document.map_street;
      }
    });


    const region_default = ref({ name: "Выберите район", value: "" });
    const region_options = ref([
      { name: "Выборгский", value: "Выборгский" },
      { name: "Московский", value: "Московский" },
      { name: "Петродворцовый", value: "Петродворцовый" },
      { name: "Курортный", value: "Курортный" },
      { name: "Приморский", value: "Приморский" },
      { name: "Пушкинский", value: "Пушкинский" },
      { name: "Петроградский", value: "Петроградский" },
      { name: "Васильеостровский", value: "Васильеостровский" },
      { name: "Красносельский", value: "Красносельский" },
      { name: "Невский", value: "Невский" },
      { name: "Центральный", value: "Центральный" },
      { name: "Адмиралтейский", value: "Адмиралтейский" },
      { name: "Фрунзенский", value: "Фрунзенский" },
      { name: "Калининский", value: "Калининский" },
      { name: "Кировский", value: "Кировский" },
      { name: "Кронштадский", value: "Кронштадский" },
      { name: "Колпинский", value: "Колпинский" },
      { name: "Красногвардейский", value: "Красногвардейский" },
    ]);

    const region_title = computed(() => {
      if (props.document.map_district == "") {
        return;
      } else {
        return props.document.map_district;
      }
    });

    //const region_title = reactive({name:props.document.map_district, value:props.document.map_district});


    const isShow = ref(false);
    const isRegion = ref(false);
    const isOpen = (obj, type) => {
      if(type == "begin_address"){
        props.document.map_street = obj.value;
        isShow.value = false;
      } else if(type == "region"){
        props.document.map_district = obj.value;
        isRegion.value = false;
      }
    }

    const subpage = ref(1);

    const begin_address_change = (e) => {
      props.document.map_street = e.value.value;
    }

    const region_change = (e) => {
      props.document.map_district = e.value.value;
    }

    return{
      subpage,
      begin_address_default,
      begin_address_options,
      begin_address_title,
      begin_address_change,
      region_default,
      region_options,
      region_title,
      region_change,
      isShow,
      isRegion,
      isOpen,
    }
  },
});
</script>