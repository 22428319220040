<template>    
<svg width="129" height="149" viewBox="0 0 129 149" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M81.9388 6.55219C91.8926 10.8181 99.0025 15.0841 106.112 22.194C113.222 29.3039 118.91 36.4138 123.176 46.3677C127.442 56.3216 128.864 64.8535 128.864 74.8074C128.864 84.7612 127.442 94.7151 123.176 103.247C118.91 111.779 113.222 121.733 106.112 127.421C99.0025 134.531 90.4706 138.797 81.9388 143.063C73.4069 147.328 62.031 148.75 52.0771 148.75H0.885742V0.864258H52.0771C62.031 0.864258 71.9849 2.28624 81.9388 6.55219Z" fill="white"/>
<path d="M23.6395 100.403C29.9222 100.403 35.0154 95.3098 35.0154 89.0271C35.0154 82.7444 29.9222 77.6513 23.6395 77.6513C17.3568 77.6513 12.2637 82.7444 12.2637 89.0271C12.2637 95.3098 17.3568 100.403 23.6395 100.403Z" fill="#2F80ED"/>
<path d="M43.5438 69.1195C49.8265 69.1195 54.9197 64.0263 54.9197 57.7436C54.9197 51.4609 49.8265 46.3678 43.5438 46.3678C37.2611 46.3678 32.168 51.4609 32.168 57.7436C32.168 64.0263 37.2611 69.1195 43.5438 69.1195Z" fill="#2F80ED"/>
<path d="M62.0302 100.403C68.3129 100.403 73.406 95.3098 73.406 89.0271C73.406 82.7444 68.3129 77.6513 62.0302 77.6513C55.7474 77.6513 50.6543 82.7444 50.6543 89.0271C50.6543 95.3098 55.7474 100.403 62.0302 100.403Z" fill="#2F80ED"/>
<path d="M81.9374 69.1195C88.2201 69.1195 93.3132 64.0263 93.3132 57.7436C93.3132 51.4609 88.2201 46.3678 81.9374 46.3678C75.6547 46.3678 70.5615 51.4609 70.5615 57.7436C70.5615 64.0263 75.6547 69.1195 81.9374 69.1195Z" fill="#2F80ED"/>
<path d="M100.423 100.403C106.705 100.403 111.799 95.3098 111.799 89.0271C111.799 82.7444 106.705 77.6513 100.423 77.6513C94.14 77.6513 89.0469 82.7444 89.0469 89.0271C89.0469 95.3098 94.14 100.403 100.423 100.403Z" fill="#2F80ED"/>
</svg>
</template>
