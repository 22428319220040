<template>
  <img alt="Rekprof logo" class="logo_min" src="../../assets/logo.png" />
  <v-menu-proxy-list></v-menu-proxy-list>
  <div class="inner_step_box">
    <span class="main_field">3.1.6 Добавление нового подписанта</span>
    <br />
    <span class="main_field">3.1.7 Введите ФИО нового подписанта</span>
    <div class="hint_left hint_mini_top">Фамилия</div>
    <input
      class="main_input"
      placeholder="Введите фамилию"
      v-model="storeDM.signatory_options_tpm.signatory_lastname"
    />
    <br />
    <div class="hint_left">Имя</div>
    <input
      class="main_input"
      placeholder="Введите имя"
      v-model="storeDM.signatory_options_tpm.signatory_firstname"
    />
    <br />
    <div class="hint_left">Отчество</div>
    <input
      class="main_input"
      placeholder="Введите отчество"
      v-model="storeDM.signatory_options_tpm.signatory_patronymic"
    />
    <br />
    <div class="hint_left">Выберите пол</div>
    <v-select-open
      :options="signatory_genre_options"
      :title_select="signatory_genre_title"
      :default_select="storeDM.signatory_options_tpm.signatory_genre"
      @change-select="signatory_genre_select"
      :margin_top="'-6px'"
      :z_index="1"
    >
    </v-select-open>
    <br />
    <br />
    <br />
    <p class="main_checkbox">
      <input
        type="checkbox"
        id="signatory_skip_patronymic"
        v-model="storeDM.signatory_options_tpm.signatory_skip_patronymic"
      />
      <label for="signatory_skip_patronymic">Отчество отсутствует</label>
    </p>
    <br />
    <div class="hint_left hint_mini_top">
      {{ signature_file_hint }}
    </div>
    <input
      ref="signatory_file"
      type="file"
      hidden
      @change="submitFile('signatory_file')"
    />
    <button
      type="button"
      class="blue_btn"
      @click="$refs.signatory_file.click()"
      style="float: left; margin-left: 10px"
    >
      Загрузить файл подписи
    </button>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRouter, useRoute } from "vue-router";
import { ref, computed, defineComponent, onMounted } from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import axios from "axios";
import VMenuProxyList from "@/components/v-menu-proxy-list.vue";
import { useDMDeclStore } from "@/store/dm_decl";

export default defineComponent({
  name: "ProxyStep6",
  components: { VMenuProxyList, VSelectOpen },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMDeclStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const go_back = () => {
      storeDM.set_info({
        step: "5",
      });
      router.push({
        path: "/proxy/step5/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const signatory_genre_title = "Выберите пол";
    const signatory_genre_options = [
      { name: "Мужской", value: "1" },
      { name: "Женский", value: "2" },
    ];

    const signatory_genre_select = (opt: any) => {
      storeDM.signatory_options_tpm.signatory_genre = opt.value;
    };

    const go_next = () => {
      storeDM.signatory_options_tpm.id = 0;
      if (storeDM.signatory_options_tpm.signatory_skip_patronymic == true) {
        storeDM.signatory_options_tpm.name =
          storeDM.signatory_options_tpm.signatory_lastname +
          " " +
          storeDM.signatory_options_tpm.signatory_firstname.substr(0, 1) +
          ".";
      } else {
        storeDM.signatory_options_tpm.name =
          storeDM.signatory_options_tpm.signatory_lastname +
          " " +
          storeDM.signatory_options_tpm.signatory_firstname.substr(0, 1) +
          "." +
          storeDM.signatory_options_tpm.signatory_patronymic.substr(0, 1) +
          ".";
      }

      storeDM.set_info({
        step: "7",
      });
      router.push({
        path: "/proxy/step7/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
      /*
      if (
        storeDM.signatory_options_tpm.signatory_lastname != "" &&
        storeDM.signatory_options_tpm.signatory_firstname != "" &&
        ((storeDM.signatory_options_tpm.signatory_patronymic != "" &&
          storeDM.signatory_options_tpm.signatory_skip_patronymic == false) ||
          storeDM.signatory_options_tpm.signatory_skip_patronymic == true)
      ) {
        return true;
      } else {
        return false;
      }
      */
    });

    onMounted(() => {
      context.emit("get_info_server", {
        hash: route.query.hash,
        userID: route.params.userID,
      });
    });

    const signatory_file = ref(null);

    const submitFile = (f_name: string) => {
      let file = Object(eval(f_name).value).files[0];

      let formData = new FormData();
      formData.append(f_name, file);
      formData.append("file_name", f_name);
      formData.append("hash", String(route.query.hash));
      formData.append("id", String(route.params.docID));
      axios
        .post(storeDM.api_url + "upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (req) {
          storeDM.signatory_options_tpm[Object(req.data).file_name] = Object(
            req.data
          )[req.data.file_name];
          //console.log(req);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    };

    const signature_file_hint = computed(() => {
      if (typeof storeDM.signatory_options_tpm.signatory_file !== "undefined") {
        if (storeDM.signatory_options_tpm.signatory_file == "") {
          return "Нажмите чтобы загрузить файл подписи";
        } else {
          return (
            'Имя загруженного файла - "' +
            storeDM.signatory_options_tpm.signatory_file.split("/")[
              storeDM.signatory_options_tpm.signatory_file.split("/").length - 1
            ] +
            '"'
          );
        }
      } else {
        return 'Имя загруженного файла - "-не загружен-"';
      }
    });

    return {
      go_back,
      step_next,
      go_next,
      storeDM,
      signatory_genre_title,
      signatory_genre_options,
      signatory_genre_select,
      signatory_file,
      submitFile,
      signature_file_hint,
    };
  },
});
</script>

<style scoped></style>
