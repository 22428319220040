<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">5. Информация о конструкции</span>
    <br />
    <span class="main_field">5.1 Выберите тип конструкции</span>
    <div class="hint_left hint_mini_top">
      Нажмите чтобы выбрать тип конструкции
    </div>
    <v-select-open
      :options="construction_options"
      :title_select="construction_title"
      @change-select="construction_select"
      :default_select="storeDM.save_obj.construction_type"
      :key="storeDM.save_obj.construction_type"
      :z_index="3"
      :margin_top="'-6px'"
    >
    </v-select-open>
    <br />
    <br />
    <br />
    <br />
    <span class="main_field">5.2 Выберите место установки</span>
    <div class="hint_left hint_mini_top">
      Нажмите чтобы выбрать место установки
    </div>
    <v-select-open
      :options="placeinstall_options"
      :title_select="placeinstall_title"
      @change-select="placeinstall_select"
      :default_select="storeDM.save_obj.place_install"
      :key="storeDM.save_obj.place_install"
      :z_index="2"
      :margin_top="'-6px'"
    >
    </v-select-open>
    <br />
    <br />
    <br />
    <div class="hint_left hint_mini_top">
      Нажмите если конструкции согласовываются по факту
    </div>
    <p class="main_checkbox">
      <input
        type="checkbox"
        id="app6"
        v-model="storeDM.save_obj.construction_app6"
      />
      <label for="app6">
        Конструкции согласовываются используя приложение №6
      </label>
    </p>
    <br />
    <span class="main_field">5.3 Выберите номер места</span>
    <div class="hint_left hint_mini_top">Нажмите чтобы выбрать из списка</div>
    <v-select-open
      :options="place_options"
      :title_select="place_title"
      @change-select="place_select"
      :default_select="storeDM.save_obj.place_number"
      :key="storeDM.save_obj.place_number"
      :z_index="1"
      :margin_top="'-6px'"
    >
    </v-select-open>
    <br />
    <br />
    <br />
    <input
      v-if="storeDM.save_obj.place_number === 'manual'"
      class="main_input"
      placeholder="Ввести вручную"
      v-model="storeDM.save_obj.place_manual"
    />
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepThreeTwo",
  components: { VSelectOpen, VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const place_title = "Выберите номер места";
    const place_options: any = [
      { name: "Оставить не заполненным", value: "" },
      { name: "Место 1", value: "место 1" },
      { name: "Место 2", value: "место 2" },
      { name: "Место 3", value: "место 3" },
      { name: "Место 4", value: "место 4" },
      { name: "Место 5", value: "место 5" },
      { name: "Место 6", value: "место 6" },
      { name: "Место 7", value: "место 7" },
      { name: "Место 8", value: "место 8" },
      { name: "Место 9", value: "место 9" },
      { name: "Место 10", value: "место 10" },
      { name: "Павильон", value: "павильон" },
      { name: "Ввести вручную", value: "manual" },
    ];
    const place_select = (opt: any) => {
      storeDM.save_obj.place_number = opt.value;
    };

    const construction_title = "Выберите тип конструкции";
    const construction_options: any = [
      { name: "Настенная вывеска", value: "Настенная вывеска" },
      { name: "Вывеска", value: "Вывеска" },
      { name: "Консольная вывеска", value: "Консольная вывеска" },
      { name: "Крышная установка", value: "Крышная установка" },
      { name: "Пилон", value: "Пилон" },
      { name: "Щит общий", value: "Щит общий" },
      {
        name: "Виниловое полотно на каркасе",
        value: "Виниловое полотно на каркасе",
      },
      { name: "Лайтбокс", value: "Лайтбокс" },
      { name: "Объемные буквы", value: "Объемные буквы" },
      { name: "Отнесенная вывеска", value: "Отнесенная вывеска" },
      { name: "Световой короб", value: "Световой короб" },
      { name: "Стела", value: "Стела" },
      { name: "Указатель", value: "Указатель" },
      { name: "Флагшток", value: "Флагшток" },
      { name: "Часы", value: "Часы" },
    ];
    const construction_select = (opt: any) => {
      storeDM.save_obj.construction_type = opt.value;
    };

    const placeinstall_title = "Выберите место установки";
    const placeinstall_options: any = [
      { name: "Стена", value: "Стена" },
      { name: "Нетиповое", value: "Нетиповое" },
      { name: "Витрина", value: "Витрина" },
      { name: "Газон", value: "Газон" },
      { name: "Крыша", value: "Крыша" },
      { name: "Козырек", value: "Козырек" },
      { name: "Ворота", value: "Ворота" },
      { name: "Дверь", value: "Дазон" },
      { name: "Забор", value: "Забор" },
      { name: "Крыльцо", value: "Крыльцо" },
      { name: "Ограда", value: "Ограда" },
      { name: "Столб", value: "Столб" },
      { name: "Тротуар", value: "Тротуар" },
    ];
    const placeinstall_select = (opt: any) => {
      storeDM.save_obj.place_install = opt.value;
    };

    const app6 = ref(false);

    const go_back = () => {
      storeDM.set_info({
        step: "4_1",
      });
      router.push({
        path: "/step4_1/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "6",
      });
      router.push({
        path: "/step6/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
    });

    return {
      go_back,
      step_next,
      go_next,
      place_title,
      place_options,
      place_select,
      construction_title,
      construction_options,
      construction_select,
      placeinstall_title,
      placeinstall_options,
      placeinstall_select,
      app6,
      storeDM,
    };
  },
});
</script>

<style scoped></style>
