<template>
  <img alt="Rekprof logo" class="logo_min" src="../assets/logo.png" />
  <div class="inner_box">
    <button type="button" class="blue_btn" @click="go_step1()">
      Создать новый
    </button>
    <div class="hint">или</div>
    <button type="button" class="blue_btn">Выбрать из списка черновиков</button>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";

export default defineComponent({
  name: "SettingsUser",
  setup() {
    const storeDM = useDMStore();

    const router = useRouter();

    const go_back = () => {
      router.go(-1);
    };

    const go_step1 = async () => {
      const response = await fetch(storeDM.api_url + "api.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "clear_hash",
        }),
      });

      const response_data = await response.json();

      storeDM.hash = response_data["hash"];
      storeDM.docID = response_data["id"];

      storeDM.get_info_server();

      router.push({
        path: "/step1/" + storeDM.docID,
        query: {
          hash: storeDM.hash,
        },
      });
    };

    return {
      go_back,
      go_step1,
    };
  },
});
</script>

<style scoped lang="scss"></style>
