<template>
    <div class="create__documents">
        <div class="white__navbar">
            <!--
              <p>Доверенности</p>
              <p>/</p>
            -->
            <p style="cursor:pointer;" v-if="document.parent != 0" @click="open_doc">Заявление №{{ document.parent }}</p>
            <p v-if="document.parent != 0">/</p>
            <p>Доверенность №{{ docID }}</p>
        </div>
        <div class="create__documents__content">
          <div class="content">
            <!--
            <div class="steps">
              <button :class="{activeStep: page == 1}" @click="page = 1; update_page_query();">Шаг 1</button>
              <button :class="{activeStep: page == 2}" @click="page = 2; update_page_query();">Шаг 2</button>
              <button :class="{activeStep: page == 3}" @click="page = 3; update_page_query();">Шаг 3</button>
              <button :class="{activeStep: page == 4}" @click="page = 4; update_page_query();">Шаг 4</button>
              <button :class="{activeStep: page == 5}" @click="page = 5; update_page_query();">Шаг 5</button>
              <button :class="{activeStep: page == 6}" @click="page = 6; update_page_query();">Шаг 6</button>
            </div>

            <div class="example">
              <p>Примеры отображения</p>
              <div class="rectangle">
                <svg width="140" height="203" viewBox="0 0 140 203" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="5.75049" y="6.77979" width="128" height="188" stroke="black"/>
                  <rect x="56.3379" y="53.0396" width="29.9125" height="8" fill="#2F80ED"/>
                  <rect x="39.0034" y="63.7476" width="64.5819" height="8" fill="#2F80ED"/>
                  <rect x="39.0034" y="135.75" width="64.5819" height="8" fill="#2F80ED"/>
                  <rect x="39.0034" y="74.4556" width="64.5819" height="8" fill="#2F80ED"/>
                  <rect x="94.2505" y="13.7798" width="31.9623" height="8" fill="#E0E0E0"/>
                  <rect x="94.2505" y="26.1353" width="31.9623" height="8" fill="#E0E0E0"/>
                  <rect x="94.2505" y="38.4907" width="31.9623" height="8" fill="#E0E0E0"/>
                </svg>
              </div>
            </div>
        -->
      </div>
        <StepOne v-if="page == 1" :document="document" @update_doc="update_page_query" @edit-statements="edit_statements" />
        <StepTwo v-if="page == 2" :document="document" />
        <StepThree v-if="page == 3" :document="document" />
        <StepFour v-if="page == 4" :document="document" />
        <StepFifth v-if="page == 5" :document="document" @signatory-list-push="signatory_list_push" @signatory-list-delete="signatory_list_delete" @edit-signatory="edit_signatory" :key="uq_id" />
        <StepSix v-if="page == 6" :document="document" @update_doc="update_page_query" @edit-statements="edit_statements" />
      </div>
      <!--
      <vue-awesome-paginate
        :total-items="6"
        :items-per-page="1"
        :max-pages-shown="6"
        v-model="page"
        prev-button-content="Назад"
        next-button-content="Далее"
        @click = "update_page_query"
        :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
      />
      -->
      <pagination
        v-model="page"
        @click = "update_page_query"
        :style="'left: '+pagination_bottom_left+' transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);'"
        :page_arr="[
        {name: '1', title: 'О компании', complete: ['client_inn']},
        {name: '2', title: 'ФИО доверителя', complete: ['declarer_firstname','declarer_lastname']},
        {name: '3', title: 'Паспортные данные', complete: ['declarer_passport_seria','declarer_passport_number','declarer_when_issued','declarer_passport_issued','declarer_division_code','declarer_passport_registration']},
        {name: '4', title: 'Срок доверенности', complete: ['proxy_date','proxy_end_date']},
        {name: '5', title: 'Выбрать подписанта', complete: ['signatory_main']},
        {name: '6', title: 'Скачать доверенность', complete: []}
      ]"
        :nav_btn="true"
        :page="page"
        :document="document"
        @change-page="change_page"
      />
    </div>
</template>

<script lang="ts">
  import StepOne from './StepOne.vue'
  import StepTwo from './StepTwo.vue'
  import StepThree from './StepThree.vue'
  import StepFour from './StepFour.vue'
  import StepFifth from './StepFifth.vue'
  import StepSix from './StepSix.vue'
  import {computed, defineComponent, reactive, ref} from "vue";
  import { useRoute, useRouter } from "vue-router";
  import {useDMStore} from "@/store/dm_obj";
  import Pagination from "@/components/Pagination.vue";
  export default defineComponent ({
    components: {
      Pagination,
      StepOne,
      StepTwo,
      StepThree,
      StepFour,
      StepFifth,
      StepSix
    },
    emits: ["edit-signatory", "edit-statements"],
    setup(props, context){

      const storeDM = useDMStore();

      const uq_id = ref(0);

      let router = useRouter();
      const route = useRoute();

      let api_url = "https://api.rekprof.ru/";
      let hash = route.query.hash;
      let docID = route.query.id;
      let signatory_list:any = [];
      let signatory_options_tpm = { signatory_genre: 0 };
      let signatory_options_add = false;
      let initDM = false;

      const pagination_bottom_left = computed(() => {
        if(storeDM.rail == false){
          return '232px;';
        } else {
          return '80px;';
        }
      });


      const document = reactive({
        hash: route.query.hash,
        id: route.query.id,
        step: route.query.step,
        client: "",
        client_fullname: "",
        client_inn: "",
        client_ogrn: "",
        client_ogrnip: "",
        client_kpp: "",
        client_address: "",
        client_role_fio: "",
        client_role: "",
        client_role_fio_genitive: "",
        client_smallname: "",
        client_type: 0,
        declarer_no: <any> 0,
        declarer_genre: 0,
        declarer_firstname: "",
        declarer_lastname: "",
        declarer_passport_issued: "",
        declarer_passport_number: "",
        declarer_passport_registration: "",
        declarer_passport_seria: "",
        declarer_patronymic: "",
        declarer_patronymic_no: <any> 0,
        declarer_passport_yes: <any> 0,
        declarer_when_issued: "",
        declarer_division_code: "",
        proxy_date: new Date().toISOString().substr(0, 10),
        proxy_end_date: new Date().toISOString().substr(0, 10),
        proxy_re: 0,
        signatory_list: signatory_list,
        signatory_options: <any> [],
        signatory_main: "",
        signature_decl_file: "", //?
        parent: 0,
        parent_step: 0,
        proxy_number: "",
      });

      const set_info = async (my_document: { [index: string]: any }, one = 1) => {
        for (const i in my_document) {
          //???
          document[i] = my_document[i];

          if (i == "declarer_patronymic_no") {
            if (document["declarer_patronymic_no"] == 1) {
              document["declarer_patronymic_no"] = true;
            } else {
              document["declarer_patronymic_no"] = false;
            }
          }

          if (i == "declarer_passport_yes") {
            if (document["declarer_passport_yes"] == 1) {
              document["declarer_passport_yes"] = true;
            } else {
              document["declarer_passport_yes"] = false;
            }
          }

          if (i == "declarer_no") {
            if (document["declarer_no"] == 1) {
              document["declarer_no"] = true;
            } else {
              document["declarer_no"] = false;
            }
          }

          if (signatory_options_add == true) {
            signatory_options_add = false;
            if (signatory_options_tpm["signatory_skip_patronymic"] == true) {
              signatory_options_tpm["signatory_skip_patronymic"] = 1;
            } else {
              signatory_options_tpm["signatory_skip_patronymic"] = 0;
            }
            await add_signatory_options();
          }
        }
        if (one == 1) {
          send_info_server(0);
        }

        document["steps"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (
          document["client_inn"] != "" &&
          document["client_type"] != 0 // &&
          //this.save_obj.client_ogrn != "" &&
          //this.save_obj.client_ogrnip != "" &&
          //this.save_obj.client_kpp != "" &&
          //this.save_obj.client_address != "" &&
          //this.save_obj.client_fullname != "" &&
          //this.save_obj.client_smallname != ""
        ) {
          document["steps"][1] = 1;
        }

        if (
          document["declarer_lastname"] != "" &&
          document["declarer_firstname"] != "" &&
          document["declarer_patronymic"] != "" &&
          document["client_role"] != "" &&
          document["client_role_fio_genitive"] != "" &&
          document["declarer_genre"] != 0
        ) {
          document["steps"][2] = 1;
        }

        if (
          document["declarer_passport_number"] != "" &&
          document["declarer_passport_seria"] != "" &&
          document["declarer_passport_issued"] != "" &&
          document["declarer_when_issued"] != "" &&
          document["declarer_division_code"] != "" &&
          document["declarer_passport_registration"] != ""
        ) {
          document["steps"][3] = 1;
        }

        if (
          document["proxy_re"] != 0 &&
          document["proxy_date"] != "" &&
          document["proxy_date"] != "'0'"
        ) {
          document["steps"][4] = 1;
        }

        //console.log(this.signatory_list.length);
        if (signatory_list.length != 0) {
          document["steps"][5] = 1;
        }

        if (
          document["steps"][1] == 1 &&
          document["steps"][2] == 1 &&
          document["steps"][3] == 1 &&
          document["steps"][4] == 1 &&
          document["steps"][5] == 1
        ) {
          document["steps"][6] = 1;
        }

        //console.log(save_obj);
      };

      const add_signatory_options = async () => {
        document["signatory_options"].push(signatory_options_tpm);
        signatory_options_tpm = { signatory_genre: 0 };
        const response = await fetch(api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "add_signatory_options",
            hash: hash,
            id: docID,
            client: document["client"],
            signatory_type: "declaration",
            signatory_options: document["signatory_options"],
          }),
        });
        const response_data = await response.json();
      };

      const send_info_server = async (one = 1) => {
        const response = await fetch(api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "set_info_decl",
            hash: hash,
            id: docID,
            client: document["client"],
            client_fullname: document["client_fullname"],
            client_inn: document["client_inn"],
            client_ogrn: document["client_ogrn"],
            client_ogrnip: document["client_ogrnip"],
            client_kpp: document["client_kpp"],
            client_address: document["client_address"],
            client_role_fio: document["client_role_fio"],
            client_role: document["client_role"],
            client_role_fio_genitive: document["client_role_fio_genitive"],
            client_smallname: document["client_smallname"],
            client_type: document["client_type"],
            declarer_no: document["declarer_no"],
            declarer_genre: document["declarer_genre"],
            declarer_firstname: document["declarer_firstname"],
            declarer_lastname: document["declarer_lastname"],
            declarer_passport_yes: document["declarer_passport_yes"],
            declarer_passport_issued: document["declarer_passport_issued"],
            declarer_passport_number: document["declarer_passport_number"],
            declarer_passport_registration:
              document["declarer_passport_registration"],
            declarer_passport_seria: document["declarer_passport_seria"],
            declarer_patronymic: document["declarer_patronymic"],
            declarer_patronymic_no: document["declarer_patronymic_no"],
            declarer_when_issued: document["declarer_when_issued"],
            declarer_division_code: document["declarer_division_code"],
            designer_name: document["designer_name"],
            proxy_date: document["proxy_date"],
            proxy_end_date: document["proxy_end_date"],
            proxy_re: document["proxy_re"],
            step: document["step"],
            signatory_list: signatory_list,
            signatory_options: document["signatory_options"],
            signatory_main: document["signatory_main"],
            signature_decl_file: document["signature_decl_file"],
            parent: document["parent"],
            parent_step: document["parent_step"],
            proxy_number: document["proxy_number"],
          }),
        });
        const response_data = await response.json();

        if (response_data.found == 0) {
          router.push({
            path: "/",
          });
          return;
        }

        if (one == 1) {
          set_info(response_data);
        }

        uq_id.value = uq_id.value + 1;
      };

      const get_info_server = async() => {
        const response = await fetch(api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "get_info_decl",
            hash: hash,
            id: docID,
          }),
        });

        //console.log(docID);

        const response_data = await response.json();

        if (response_data.found == 0) {
          router.push({
            path: "/",
          });
          return;
        }

        signatory_list = response_data.signatory_list;
        document["signatory_list"] = signatory_list;
        //console.log(111);
        //this.save_obj.steps = response_data.steps;
        //console.log(signatory_list.value);
        set_info(response_data, 0);
      };

      const signatory_list_push = async (obj: any) => {
        let obj_tmp = {
          id: 0,
          name: obj.name,
          signatories: obj.id,
          document: docID,
        };
        signatory_list.push(obj_tmp);

        const response = await fetch(api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "set_signatory_list_decl",
            hash: hash,
            id: docID,
            signatory_list: signatory_list,
          }),
        });

        const response_data = await response.json();
        signatory_list = response_data.signatory_list;
        document["signatory_list"] = signatory_list;

        send_info_server(0);
      };

      const signatory_list_delete = async (obj: any) => {
        const sl_del = signatory_list.splice(obj.num, 1);

        const response = await fetch(api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "set_signatory_del_decl",
            hash: hash,
            id: docID,
            sl_del: sl_del,
          }),
        });

        const response_data = await response.json();
        signatory_list = response_data.signatory_list;
        document["signatory_list"] = signatory_list;

        send_info_server(0);
      };
      const init = (hash_i: any, docID_i: any) => {
        initDM = true;
        signatory_options_tpm = { signatory_genre: 0 };
        document["signatory_options"] = [];

        hash = hash_i;
        docID = docID_i;

        get_info_server();

        //this.set_info({}, 0);
      };

      init(route.query.hash, route.query.id);

      const page = ref(Number(route.query.step));
      const update_page_query = () => {
        router.push({
          query: {
            hash: route.query.hash,
            type: "proxy",
            id: route.query.id,
            step: page.value,
          },
        });
        send_info_server(0);
      };

      const edit_signatory = () => {
        context.emit("edit-signatory");
      }

      const edit_statements = () => {
        setTimeout(edit_statements_to,200);
      };

      const edit_statements_to = () => {
        context.emit("edit-statements");
      };

      const change_page = (page_new_set: number) => {
        page.value = page_new_set;
        update_page_query();
      }

      const open_doc = () => {
        let parent = document.parent;
        let parent_step = document.parent_step;

        document.parent = 0;
        document.parent_step = 0;
        update_page_query();

        router.push({
          query: {
            hash: route.query.hash,
            type: "document",
            id: parent,
            step: parent_step,
            mode: "edit",
          },
        });

        edit_statements();
      }

      return{
        init,
        signatory_list_delete,
        signatory_list_push,
        get_info_server,
        send_info_server,
        add_signatory_options,
        set_info,
        page,
        document,
        uq_id,
        update_page_query,
        edit_signatory,
        edit_statements,
        docID,
        pagination_bottom_left,
        change_page,
        open_doc,
      }
    }
  });
</script>

<style>
.create__documents__content {
	height: 735px !important;
}	
</style>
