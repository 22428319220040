<template>
    <div class="step__four">
      <div class="text">
        <div>
            <p>Выберите дату выдачи доверенности</p>
            <span>Выберите или введите дату выдачи доверенности</span>
        </div>

        <label for="title">
          <v-switch
          style="display: flex; flex-direction: column; justify-content: flex-start"
          color="#2F80ED"
          v-model="document.proxy_re"
          label="Право передоверия"
          ></v-switch>
        </label>
        
      </div>
      <div class="date">
        <!-- <input type="text" readonly v-model="picker"> -->
        <input class="input" type="date" v-model="document.proxy_date" :max="proxy_end_date_comp">
      </div>

      <div class="text">
        <div>
            <p>Выберите срок действия доверенности</p>
            <span>Выберите или введите дату окончания действия</span>
        </div>        
      </div>
      <div class="date" style="margin-top: 0px;">
        <!-- <input type="text" readonly v-model="picker"> -->
        <input class="input" type="date" v-model="document.proxy_end_date" :min="proxy_date_comp">
      </div>

    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){

        const proxy_end_date_comp = computed(()=>{
          return props.document.proxy_end_date;
        });
        const proxy_date_comp = computed(()=>{
          return props.document.proxy_date;
        });

        return{
            switchValue: true,
            proxy_end_date_comp,
            proxy_date_comp
        }
    }
});
</script>