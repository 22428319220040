<template>
  <div class="steps">
    <button :class="{activeStep: subpage == 1}" @click="subpage = 1">Шаг 1</button>
    <button :class="{activeStep: subpage == 2}" @click="subpage = 2">Шаг 2</button>
  </div>
  <div class="uploadfile_photo" v-if="subpage == 1">
    <div class="left">
      <div>
        <label for="name">Добавьте горизонтальную фотофиксацию издалека</label>
      </div>
      <FileUpload :document="document" :file_upload_name="'construction_around_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 3480px x 2320px'" />
    </div>
    <div class="right">
      <p style="margin-top: -6px;">Пример изображения</p>
      <img src="https://api.rekprof.ru/files/sample/regions/out.jpg" />
      <!--<img v-bind:src="require('../../assets/images/exampleMarket.jpg')" />-->
    </div>
  </div>
  <div class="uploadfile_photo" v-if="subpage == 2">
    <div class="left">
      <div>
        <label for="name">Добавьте горизонтальную фотофиксацию конструкции вблизи</label>
      </div>
      <FileUpload :document="document" :file_upload_name="'construction_away_file'" :upload_text="'Добавьте фото в поле ниже. Соотношение сторон 3Шx2В. Пример: 3480px x 2320px'" />
    </div>
    <div class="right">
      <p style="margin-top: -6px;">Пример изображения</p>
      <img src="https://api.rekprof.ru/files/sample/regions/in.jpg" />
    </div>
  </div>
</template>

<script lang="ts">
import DropdownIcon from "../../../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../../../assets/icons/DropdownUp.vue";
import Map from '../../../../assets/icons/exampleMap.vue';
import FileUpload from  "@/components/FileUpload.vue";
import {defineComponent, ref} from "vue";
import axios from "axios";
import {useDMStore} from "@/store/dm_obj";
import {useRoute} from "vue-router";
export default defineComponent({
  components: {
    DropdownIcon,
    DropdownUp,
    Map,
    FileUpload
  },
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){
    const subpage = ref(1);
    return{
      subpage,
    }
  },
});
</script>