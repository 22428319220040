<template>
  <div class="steps">
    <button :class="{activeStep: subpage == 1}" @click="subpage = 1">Шаг 1</button>
    <button :class="{activeStep: subpage == 2}" @click="subpage = 2">Шаг 2</button>
  </div>
  <div class="step__basic" v-if="subpage == 1">
    <div class="left">
      <div>
        <label for="name">Введите назначение здания</label>
        <span class="blueSpan">Пример: жилое, нежилое</span>
        <input class="input" type="text" placeholder="Введите назначение здания" v-model="document.purpose_building">
      </div>
      <div>
        <label for="name">Информация о конструкции</label>
        <span class="blueSpan">Пример: настенная вывеска "РОСБАНК"</span>
        <input class="input" type="text" placeholder="Введите информацию о конструкции" v-model="document.construction_info">
      </div>
      <div>
        <label for="name">Введите габаритные размеры</label>
        <span class="blueSpan">Введите значение текстом, в мм. Пример: 2 х 2 мм</span>
        <input class="input" type="text" placeholder="Введите значение размеров" v-model="document.size">
      </div>
    </div>
    <div class="right">
      <div>
        <label for="name">Принцип крепления</label>
        <span class="blueSpan">Пример: монтажная рама 20х20 мм</span>
        <input class="input" type="text" placeholder="Введите принцип крепления" v-model="document.binding">
      </div>
      <div>
        <label for="name">Место размещения</label>
        <span class="blueSpan">Пример: фриз козырька</span>
        <input class="input" type="text" placeholder="Введите место размещения" v-model="document.place">
      </div>
    </div>
  </div>
  <div class="step__basic" v-if="subpage == 2">
    <div class="left">
      <div>
        <label for="name">Информация о лицевой части</label>
        <span class="blueSpan">Пример: Нерж сталь 1мм, окрашенная в RAL 3020 и 5013</span>
        <textarea style="resize: none; overflow: hidden; height: 180px;" class="input" type="text" placeholder="Введите информацию о лицевой части" v-model="document.info_front"></textarea>
      </div>
      <div>
        <label for="name">Информация о боковине</label>
        <span class="blueSpan">Пример: Нерж сталь 0,7 мм, окрашенная в RAL 5013</span>
        <textarea style="resize: none; overflow: hidden; height: 180px;" class="input" type="text" placeholder="Введите информацию о боковине" v-model="document.info_side"></textarea>
      </div>
    </div>
    <div class="right">
      <div>
        <label for="name">Информация о подсветке</label>
        <span class="blueSpan">Пример: Светодиодные кластеры ECO - 2 - W</span>
        <textarea style="resize: none; overflow: hidden; height: 180px;" class="input" type="text" placeholder="Введите информацию о подсветке" v-model="document.info_light"></textarea>
      </div>
      <div>
        <label for="name">Информация о задней части</label>
        <span class="blueSpan">Пример: Орг. стекло 4 мм</span>
        <textarea style="resize: none; overflow: hidden; height: 180px;" class="input" type="text" placeholder="Введите информацию о задней части" v-model="document.info_back"></textarea>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {useDMStore} from "@/store/dm_obj";

export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){

    const storeDM = useDMStore();
    const route = useRoute();

    //const subpage = ref(Number(route.query.substep));
    const subpage = ref(1);

    return{
      subpage,
    }
  }
});
</script>