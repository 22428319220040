<template>
  <div class="step__basic">
    <div class="left">
      <div>
        <label for="name">Выберите тип конструкции</label>
        <span>Нажмите, чтобы выбрать тип конструкции из списка</span>
        <Dropdown v-model="construction_type_title"
                  editable :options="construction_type_options"
                  optionLabel="name"
                  :placeholder="construction_type_default.name"
                  style="width:480px"
                  @change="construction_type_change"
        />
      </div>
      <div>
        <label for="name">Выберите место установки</label>
        <span>Нажмите, чтобы выбрать место установки из списка</span>
        <Dropdown v-model="place_install_title"
                  editable :options="place_install_options"
                  optionLabel="name"
                  :placeholder="place_install_default.name"
                  style="width:480px"
                  @change="place_install_change"
        />
      </div>
      <div>
        <label for="name">Выберите номер места</label>
        <span>Нажмите, чтобы выбрать номер места из списка</span>
        <Dropdown v-model="place_number_title"
                  editable :options="place_number_options"
                  optionLabel="name"
                  :placeholder="place_number_default.name"
                  style="width:480px"
                  @change="place_number_change"
        />
        <input class="input" type="text" placeholder="Введите номер места" v-model="document.place_manual" v-if="document.place_number === 'manual'">
      </div>
      <section>
        <label for="title">
          <v-switch
                  style="display: flex; flex-direction: row; justify-content: flex-start"
                  color="#2F80ED"
                  v-model="document.construction_app6"
                  label="Согласовывается по факту, используя приложение №6"
                  inline
           ></v-switch>
        </label>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../assets/icons/DropdownUp.vue";
import {computed, defineComponent, ref} from "vue";
import {useDMStore} from "@/store/dm_obj";
import {useRoute, useRouter} from "vue-router";
import axios from "axios";
export default defineComponent ({
    props: {
      document: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    setup(
      props: {
        document: any;
      }, context
    ){

      const storeDM = useDMStore();

      const route = useRoute();
      const router = useRouter();

      const construction_type_default = ref({ name: "Выберите тип конструкции", value: "" });
      const construction_type_options = ref([
        { name: "Настенная вывеска", value: "Настенная вывеска" },
        { name: "Вывеска", value: "Вывеска" },
        { name: "Консольная вывеска", value: "Консольная вывеска" },
        { name: "Крышная установка", value: "Крышная установка" },
        { name: "Пилон", value: "Пилон" },
        { name: "Щит общий", value: "Щит общий" },
        { name: "Виниловое полотно на каркасе", value: "Виниловое полотно на каркасе" },
        { name: "Лайтбокс", value: "Лайтбокс" },
        { name: "Объёмные буквы", value: "Объёмные буквы" },
        { name: "Отнесённая вывеска", value: "Отнесённая вывеска" },
        { name: "Световой короб", value: "Световой короб" },
        { name: "Стелла", value: "Стелла" },
        { name: "Указатель", value: "Указатель" },
        { name: "Флагшток", value: "Флагшток" },
        { name: "Часы", value: "Часы" },
      ]);

      const construction_type_title = computed(() => {
        if (props.document.construction_type == "") {
          return null;
        } else {
          return props.document.construction_type;
        }
      });


      const place_install_default = ref({ name: "Выберите место установки", value: "" });
      const place_install_options = ref([
        { name: "Стена", value: "Стена" },
        { name: "Нетиповое", value: "Нетиповое" },
        { name: "Витрина", value: "Витрина" },
        { name: "Газон", value: "Газон" },
        { name: "Крыша", value: "Крыша" },
        { name: "Козырёк", value: "Козырёк" },
        { name: "Ворота", value: "Ворота" },
        { name: "Дверь", value: "Дверь" },
        { name: "Забор", value: "Забор" },
        { name: "Крыльцо", value: "Крыльцо" },
        { name: "Ограда", value: "Ограда" },
        { name: "Столб", value: "Столб" },
        { name: "Тротуар", value: "Тротуар" },
      ]);

      const place_install_title = computed(() => {
        if (props.document.place_install == "") {
          return null;
        } else {
          return props.document.place_install;
        }
      });

      const place_number_default = ref({ name: "Выберите номер места", value: "" });
      const place_number_options = ref([
        { name: "Оставить не заполненным", value: "" },
        { name: "Место 1", value: "место 1" },
        { name: "Место 2", value: "место 2" },
        { name: "Место 3", value: "место 3" },
        { name: "Место 4", value: "место 4" },
        { name: "Место 5", value: "место 5" },
        { name: "Место 6", value: "место 6" },
        { name: "Место 7", value: "место 7" },
        { name: "Место 8", value: "место 8" },
        { name: "Место 9", value: "место 9" },
        { name: "Место 10", value: "место 10" },
        { name: "Павильон", value: "павильон" },
        { name: "Ввести вручную", value: "manual" },
      ]);

      const place_number_title = computed(() => {
        if (props.document.place_number == "") {
          return null;
        //} else if (props.document.place_number == "manual") {
        //  return "Ввести вручную";
        } else {
          let obj = place_number_options.value.find(el => el.value == props.document.place_number);
          return Object(obj);
        }
      });

      const construction_type_change = (e) => {
        props.document.construction_type = e.value.value;
      }

      const place_install_change = (e) => {
        props.document.place_install = e.value.value;
      }

      const place_number_change = (e) => {
        props.document.place_number = e.value.value;
      }

      const downloadFileFromURL = async (url, fileName) => {

        if (fileName != "") {
          await axios
            .get(url, {responseType: "blob"})
            .then((response) => {
              const blob = new Blob([response.data], {});
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch(console.error);
        }
      }

      const app6_file = ref(null);

      const submitFile = (f_name: string) => {
        let file = Object(eval(f_name).value).files[0];
        if(file == undefined){
          return;
        }

        let formData = new FormData();
        formData.append(f_name, file);
        formData.append("file_name", f_name);
        formData.append("hash", String(route.query.hash));
        formData.append("id", String(route.query.id));
        axios
          .post(storeDM.api_url + "upload.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (req) {
            props.document[Object(req.data).file_name] = Object(req.data)[
              req.data.file_name
              ];
            console.log(Object(req.data).file_name+" - "+Object(req.data)[req.data.file_name]);
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      };

        return{
          construction_type_options,
          construction_type_title,
          construction_type_default,
          construction_type_change,
          place_install_options,
          place_install_title,
          place_install_default,
          place_install_change,
          place_number_options,
          place_number_title,
          place_number_default,
          place_number_change,
          storeDM,
          downloadFileFromURL,
          app6_file,
          submitFile,
          route,
        }
    },
    components: {
        DropdownIcon,
        DropdownUp,
    },
});
</script>

<style>

</style>