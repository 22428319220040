<template>
  <img alt="Rekprof logo" class="logo_min" src="../assets/logo.png" />
  <div class="inner_box" style="height: 830px">
    <div class="signatory_list_block" @wheel="signatory_scroll">
      <div
        class="signatory_content_block"
        :style="{ marginTop: signatory_y_pos.val + 'px' }"
      >
        <div
          class="signatory_block"
          v-for="(input, index) in doc_list"
          :key="index"
        >
          <button
            type="button"
            class="blue_next_btn active_btn"
            @click="open_doc(input.id, input.hash)"
            style="
              margin-left: 10px;
              margin-bottom: 8px;
              text-align: left;
              padding-left: 15px;
              padding-right: 15px;
              width: 1000px;
            "
          >
            {{ input.name }}
          </button>
        </div>
      </div>
      <div
        class="scroll unselectable"
        :style="{ top: 0 + signatory_y_pos.val1 + 'px' }"
        @mousedown="signatory_drag"
        @mousemove="signatory_drag"
        v-touch:drag="signatory_drag"
        v-touch:press="signatory_drag"
        v-touch:release="signatory_drag"
      >
        <div class="scroll_body"></div>
      </div>
    </div>
    <button
      type="button"
      class="white_s_btn"
      @click="go_back()"
      style="margin-left: 20px"
    >
      Назад
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDMStore } from "@/store/dm_obj";

export default defineComponent({
  name: "DocList",
  setup() {
    const doc_list = ref([]);

    const storeDM = useDMStore();

    const router = useRouter();
    const route = useRoute();

    const go_back = () => {
      router.go(-1);
    };

    const get_list = async () => {
      const response = await fetch(storeDM.api_url + "api.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "get_list",
          hash: route.query.hash,
        }),
      });

      const response_data = await response.json();

      if (response_data["status"] == "success") {
        doc_list.value = response_data["doc_list"];
      }
    };

    const open_doc = (id: string, hash: string) => {
      router.push({
        path: "/step1/" + id,
        query: {
          hash: hash,
        },
      });
    };

    get_list();

    let signatory_y_pos = reactive({ val: 0, val1: 0 });
    const signatory_scroll = (evt: WheelEvent) => {
      let step_y = 48; //78
      let step_max = 715; //181
      if (evt.deltaY > 0) {
        signatory_y_pos.val = signatory_y_pos.val - step_y;
      } else if (evt.deltaY < 0) {
        signatory_y_pos.val = signatory_y_pos.val + step_y;
      }

      signatory_scroll_clear();
    };

    const signatory_scroll_clear = () => {
      let step_y = 48;
      let step_max = 715;
      if (
        0 - ((doc_list.value as any).length - 16) * step_y >
        signatory_y_pos.val
      ) {
        signatory_y_pos.val =
          0 - ((doc_list.value as any).length - 16) * step_y;
      }
      if ((doc_list.value as any).length <= 16) {
        signatory_y_pos.val = 0;
      }

      if (signatory_y_pos.val > 0) {
        signatory_y_pos.val = 0;
      }

      let currenMT: number = Math.abs(signatory_y_pos.val);
      let maxMT: number = Math.abs(
        0 - ((doc_list.value as any).length - 16) * step_y
      );

      if (currenMT == 0) {
        signatory_y_pos.val1 = 0;
      } else {
        let deltaMT: number = maxMT / currenMT;
        signatory_y_pos.val1 = step_max / deltaMT;
      }
    };

    const signatory_scroll_set = () => {
      let step_y = 48;
      let step_max = 715;
      let maxMT: number = Math.abs(
        0 - ((doc_list.value as any).length - 16) * step_y
      );
      if ((doc_list.value as any).length <= 16) {
        maxMT = 0;
      }
      //signatory_y_pos.val;
      let deltaMT: number = step_max / signatory_y_pos.val1;
      signatory_y_pos.val = 0 - maxMT / deltaMT;
    };

    let scroll_old_offset_y = 0;

    const signatory_drag = (evt: MouseEvent | TouchEvent) => {
      //console.log(evt);
      let step_max = 715;
      let start_y = 0; //132;
      if (evt.type == "touchstart") {
        storeDM.dragScroll = true;

        scroll_old_offset_y =
          (evt as TouchEvent).touches[0].clientY -
          signatory_y_pos.val1 -
          start_y;
      } else if (evt.type == "mousedown") {
        storeDM.dragScroll = true;
        scroll_old_offset_y = (evt as MouseEvent).offsetY;
      } else if (evt.type == "touchend") {
        storeDM.dragScroll = false;
      } else if (evt.type == "touchmove" && storeDM.dragScroll) {
        signatory_y_pos.val1 =
          (evt as TouchEvent).touches[0].clientY -
          start_y -
          scroll_old_offset_y;
        if (signatory_y_pos.val1 > step_max) {
          signatory_y_pos.val1 = step_max;
        }
        if (signatory_y_pos.val1 < 0) {
          signatory_y_pos.val1 = 0;
        }
      } else if (evt.type == "mousemove" && storeDM.dragScroll) {
        signatory_y_pos.val1 =
          (evt as MouseEvent).pageY - start_y - scroll_old_offset_y;
        if (signatory_y_pos.val1 > step_max) {
          signatory_y_pos.val1 = step_max;
        }
        if (signatory_y_pos.val1 < 0) {
          signatory_y_pos.val1 = 0;
        }
        //console.log(evt);
        //signatory_scroll_clear();
      }

      signatory_scroll_set();
    };

    return {
      go_back,
      doc_list,
      open_doc,
      signatory_scroll,
      signatory_y_pos,
      signatory_drag,
      signatory_scroll_clear,
    };
  },
});
</script>

<style scoped lang="scss">
.signatory_block {
  height: 48px;
  margin-bottom: 0px;
}

.signatory_list_block {
  width: 1022px;
  overflow: hidden;
  /* height: 230px; */
  height: 760px;
  margin-left: 10px;
}

.scroll {
  width: 9.5px;
  position: absolute;
  /*left: 298px;*/
  left: 1024px; /* 292px; */
  /*padding-left: 6px;*/
  cursor: pointer;
}

.scroll_body {
  margin-left: 3px; /* 288px; */
  width: 3.5px;
  height: 45px;
  background-color: #2f80ed;
}
</style>
