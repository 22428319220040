<template>
  <div
    class="v-select-open"
    v-if="!isMobile()"
    :style="{
      zIndex: react.z_index,
      marginTop: react.margin_top,
      width: react.width,
    }"
  >
    <p
      class="main_select_desktop"
      @click="react.hide_bool = !react.hide_bool"
      :key="react.title"
    >
      {{ deff_title }}
      <span class="main_select_down">▼</span>
    </p>
    <div class="options" :class="{ hide: react.hide_bool }">
      <span
        v-for="option in options"
        :key="option.value"
        class="main_select_option"
        @click="selectOption(option)"
      >
        {{ option.name }}
      </span>
    </div>
  </div>
  <div class="v-select-open" v-else>
    <select class="main_select" @change="selectOption_sel">
      <option value="0">{{ react.title }}</option>
      <option
        v-for="option in react.options"
        :key="option.value"
        :value="option.value"
        :selected="deff_select === option.value"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
} from "vue";

export default defineComponent({
  name: "v-select-open",
  emits: ["change-select"],
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    title_select: {
      type: String,
      default() {
        return "";
      },
    },
    default_key: {
      type: String,
      default() {
        return "";
      },
    },
    default_select: {
      type: String,
      default() {
        return "";
      },
    },
    z_index: {
      type: Number,
      default() {
        return 0;
      },
    },
    margin_top: {
      type: String,
      default() {
        return "0px";
      },
    },
    width: {
      type: String,
      default() {
        return "";
      },
    },
  },
  setup(
    props: {
      title_select: string;
      default_select: string;
      options: any;
      z_index: number;
      margin_top: string;
      width: string;
    },
    context
  ) {
    //console.log("12" + props.title_select);
    let react = reactive({
      title: props.title_select,
      hide_bool: true,
      options: props.options,
      default: props.default_select,
      z_index: props.z_index,
      margin_top: props.margin_top,
      width: props.width,
    });
    //console.log(react.title);

    onMounted(() => {
      document.addEventListener("click", hideSelect.bind(this), true);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", hideSelect);
    });

    const isMobile = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    };
    const selectOption = (option: any) => {
      react.title = option.name;
      context.emit("change-select", option);
      react.hide_bool = true;
      //console.log(option.name);
      //console.log(react.title);
    };

    const selectOption_sel = (option: any) => {
      let opt = {
        name: option.target.selectedOptions[0].innerHTML,
        value: option.target.value,
      };
      context.emit("change-select", opt);
    };

    const hideSelect = () => {
      react.hide_bool = true;
    };

    //console.log(react.default);
    const deff_select = computed(() => {
      return react.default;
    });

    const deff_title = computed(() => {
      if (typeof deff_select.value != "undefined") {
        if (deff_select.value != "0") {
          for (let i = 0; i < react.options.length; i++) {
            if (deff_select.value == react.options[i]["value"]) {
              return react.options[i]["name"];
            }
          }
        }
      }
      return react.title;
    });

    return {
      isMobile,
      selectOption,
      hideSelect,
      react,
      selectOption_sel,
      deff_select,
      deff_title,
    };
  },
});
</script>

<style lang="scss" scoped>
.main_select_down {
  position: absolute;
  /* margin-left: 540px; */
  right: 7px;
}

.main_select {
  height: 40px;
  border: 1px solid #2f80ed;
  background: transparent;
  display: block;
  width: 100%;
  color: #32353a;
  font-size: 12px;
  font-weight: normal;
  vertical-align: top;
  outline: 0;
  padding-left: 15px;
}

.v-select-open {
  margin-top: 47px;
  display: block;
  position: absolute;
  background-color: white;
  width: 573px;
  margin-left: 10px;
}

.main_select_desktop {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  border: 1px solid #2f80ed;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
  color: black;
  display: flex;
  box-sizing: border-box;
  padding-left: 10px;
  cursor: pointer;
}

.main_select_option {
  display: block;
  width: 100%;
  font-size: 12px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
  color: black;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
  text-align: left;
}

.main_select_option:hover {
  background-color: #2f80ed;
  cursor: pointer;
  color: white;
}

.options {
  margin-top: -10px;
  box-shadow: 0px 4px 15px rgb(47 128 237 / 5%);
}

.hide {
  display: none;
}
</style>
