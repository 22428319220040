<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">3.1 Формирование доверенности</span>
    <br />
    <span class="main_field">3.1.3 Выберите срок действия доверенности</span>
    <br />
    <v-date-picker
      is-expanded
      title-position="left"
      :locale="{ id: 'ru', firstDayOfWeek: 2, masks: { weekdays: 'WW' } }"
      v-model="storeDM.save_obj.proxy_date"
    />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <span class="main_field">3.1.4 С правом передоверия?</span>
    <v-select-open
      :options="proxy_re_options"
      :title_select="proxy_re_title"
      :default_select="storeDM.save_obj.proxy_re"
      :key="storeDM.save_obj.proxy_re"
      @change-select="proxy_re_select"
      :margin_top="'-1px'"
    >
    </v-select-open>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import VSelectOpen from "@/components/v-select-open.vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepThreeThree",
  components: { VSelectOpen, VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const proxy_re_title = "Выберите вариант";
    const proxy_re_options = [
      { name: "Без права", value: "1" },
      { name: "С правом", value: "2" },
    ];
    const proxy_re_select = (opt: any) => {
      storeDM.save_obj.proxy_re = opt.value;
    };

    const go_back = () => {
      storeDM.set_info({
        step: "3_2",
      });
      router.push({
        path: "/step3_2/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "3_4",
      });
      router.push({
        path: "/step3_4/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
      /*
      if (
        storeDM.save_obj.proxy_date != undefined &&
        storeDM.save_obj.proxy_re != 0
      ) {
        return true;
      } else {
        return false;
      }
      */
    });

    return {
      go_back,
      step_next,
      proxy_re_options,
      proxy_re_title,
      proxy_re_select,
      go_next,
      storeDM,
    };
  },
});
</script>

<style scoped>
.vc-container.vc-is-expanded {
  min-width: 573px;
  float: left;
  margin-left: 10px;
}
</style>
