<template>
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field">4. Адрес и карта местности</span>
    <!--
    <br />
    <br />
    <div>
      <input
        type="text"
        id="search"
        v-model="searchTerm"
        placeholder="Type here..."
        class="main_input"
      />
      <ul v-if="searchCountries.length" class="ul_style">
        <li
          v-for="country in searchCountries"
          :key="country.name"
          @click="selectCountry(country.name)"
          class="li_style cursor-pointer"
        >
          {{ country.name }}
        </li>
      </ul>

      <p v-if="selectedCountry" class="text-lg pt-2 absolute">
        You have selected:
        <span class="font-semibold">{{ selectedCountry }}</span>
      </p>
    </div>
    -->
    <div class="hint_left">
      Введите начало адреса (улица, проспект,
      ...)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Введите
      продолжение адреса
    </div>
    <input
      class="first_input"
      placeholder="(улица, проспект, шоссе, переулок, бульвар,
      аллея)"
      v-model="storeDM.save_obj.map_street"
    />
    <input
      class="two_input"
      placeholder="Введите продолжение адреса"
      v-model="storeDM.save_obj.map_address"
    />
    <br />
    <div class="hint_left">
      Введите
      район&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Введите номер дома
    </div>
    <input
      class="first_input"
      placeholder="Введите район"
      v-model="storeDM.save_obj.map_district"
    />
    <input
      class="two_input"
      placeholder="Введите номер дома"
      v-model="storeDM.save_obj.map_house"
    />
    <br />
    <div class="hint_left">
      Введите
      корпус&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Введите литеру или строение
    </div>
    <input
      class="first_input"
      placeholder="Введите корпус"
      v-model="storeDM.save_obj.map_korpus"
    />
    <input
      class="two_input"
      placeholder="Введите литеру или строение"
      v-model="storeDM.save_obj.map_build_litera"
    />
    <br />
    <div class="hint_left">
      Введите пересечение с домом или павильон если имеется
    </div>
    <input
      class="main_input"
      placeholder="Введите пересечение с домом или павильон если имеется"
      v-model="storeDM.save_obj.map_cross"
    />
  </div>
  <br />
  <br />
  <br />
  <br />
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
  <button
    type="button"
    class="next_btn"
    :class="{ active_btn: step_next }"
    :style="{ display: step_next ? 'block' : 'none' }"
    :disabled="!step_next"
    @click="go_next()"
  >
    Далее
  </button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent, onMounted } from "vue";
import { useDMStore } from "@/store/dm_obj";
import VMenuList from "@/components/v-menu-list.vue";
import axios from "axios";
//import countries from "../../../data/countries.json";

export default defineComponent({
  name: "StepFour",
  components: { VMenuList },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const settings = {
      apiKey: "2bb6949d-d16e-48ec-94d5-fe78780ac796", // Индивидуальный ключ API
      lang: "ru_RU", // Используемый язык
      coordorder: "latlong", // Порядок задания географических координат
      debug: false, // Режим отладки
      version: "2.1", // Версия Я.Карт
    };

    const go_back = () => {
      if (storeDM.save_obj.send_type == "1") {
        storeDM.set_info({
          step: "2",
        });
        router.push({
          path: "/step2/" + route.params.docID,
          query: {
            hash: route.query.hash,
          },
        });
      } else {
        storeDM.set_info({
          step: "3_4",
        });
        router.push({
          path: "/step3_4/" + route.params.docID,
          query: {
            hash: route.query.hash,
          },
        });
      }
    };

    const go_next = () => {
      storeDM.set_info({
        step: "4_1",
      });
      router.push({
        path: "/step4_1/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const manual_rgis = () => {
      storeDM.set_info({
        step: "4_2",
      });
      router.push({
        path: "/step4_2/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
    });

    /*
    let searchTerm = ref("");
    const searchCountries = computed(() => {
      if (searchTerm.value === "") {
        return [];
      }
      let matches = 0;
      return countries.filter((country: any) => {
        if (
          country.name.toLowerCase().includes(searchTerm.value.toLowerCase()) &&
          matches < 10 &&
          country.name.toLowerCase() != searchTerm.value.toLowerCase()
        ) {
          matches++;
          return country;
        }
      });
    });

    const selectCountry = (country: any) => {
      //selectedCountry.value = country;
      searchTerm.value = country;
    };
    let selectedCountry = ref("");
    */

    return {
      go_back,
      step_next,
      go_next,
      manual_rgis,
      settings,
      storeDM,
      //searchTerm,
      //searchCountries,
      //selectCountry,
      //selectedCountry,
      //countries,
    };
  },
});
</script>

<style scoped>
.wrapper_map {
  width: 282px;
  height: 370px;
  border: 10px solid #2f80ed;
  overflow: hidden;
  margin-left: 10px;
}

.iframe_map {
  width: 520px;
  height: 440px;
  margin-left: -170px;
  margin-top: -38px;
}

.ul_style {
  width: 571px;
  list-style-type: none;
  margin-left: 10px;
  border: 1px solid #2f80ed;
  padding-left: 0px;
  padding-top: 38px;
  position: absolute;
  margin-top: 0px;
}

.li_style {
  color: black;
  background-color: white;
  border-top: 1px solid #2f80ed;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.li_style:hover {
  background-color: #999;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
