<template>
    <div class="save_doc">
        <div class="left">
            <div>
                <label for="name">Сохранение заявления</label>
                <span>Введите название файла. Пример: дизайн проект № 643</span>
                <input class="input" type="text" placeholder="Введите название файла" v-model="info_save">
            </div>

            <div>
              <section>
                <label for="title">
                  <v-switch
                    style="display: flex; flex-direction: row; justify-content: flex-start"
                    color="#2F80ED"
                    v-model="document.annotation_yes"
                    label="Добавить аннотацию к страницам заявления"
                    inline
                  ></v-switch>
                </label>
              </section>
              <span v-if="document.annotation_yes == '1'">Введите текст аннотации. Максимальное количество символов: 138</span>
              <input class="input" type="text" placeholder="Введите текст аннотации" maxlength="138" v-model="document.annotation" v-if="document.annotation_yes == '1'">

              <MultiSelect
                v-if="document.annotation_yes == '1'" v-model="document.selected_annotations"
                :options="annotations" optionLabel="name" placeholder="Нажмите для выбора"
                style="width: 480px !important;"

                :maxSelectedLabels="3" class="w-full md:w-20rem" />
            </div>
            

            <div class="info">
                  <label for="title">Информация о раннее выданном разрешении</label>
                  <section>
                      <div>
                          <span>Введите номер разрешения</span>
                          <input class="input" type="text" v-model="document.resolution_number">
                      </div>
                      <div>
                          <span>Выберите дату разрешения</span>
                          <input class="input" v-model="document.resolution_date"
                          type="date" placeholder="__.__.____"
                          maxlength="10"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                          >
                      </div>
                  </section>
            </div>

            <div>
                <label for="name">Сохранить заявление разбитый по приложениям</label>
                <button class="save__application" @click="download_separated">Сохранить по приложениям</button>
            </div>
            <div>
                <label for="name">Сохранить заявление одним файлом</label>
                <button class="save__statement" @click="download_all">Сохранить заявление</button>
            </div>

            <img
              v-if="load_process"
              alt="loading..."
              class="loading"
              v-bind:src="require('../../assets/loading.gif')"
              style="width:20px; height: 20px;"
            />
            <ModalTariff :is_open="modal_end_open" :key="saveIter"/>
            <!--
            <button class="new__statement">
                + Новое заявление
            </button>
            -->
        </div>


        <input
          ref="other_files"
          type="file"
          hidden
          @change="submitFile('other_files', 0, '')"
          accept=".pdf,.png,.jpg,.bmp"
        />
        <div class="right" v-if="true">
          <div style="display: block;">
            <label style="display: block;" for="name">Добавить файл к заявлению</label>
            <span style="display: block; margin-top: 8px;">Название файла</span>
            <button style="display: block; margin-top: 8px;" class="add_file_btn"
                    @click="$refs.other_files.click()"
            >Добавить файл</button>
            <div style="margin-top: 96px; width: 330px; display: inline-block;">
              <div style="display: inline-block;" v-for="n in Number(document.other_files.length)" v-if="true">
                <div style="display: inline-block; margin-bottom: 10px;" v-if="n<7 && document.other_files[n-1].name!=''">
                  <div class="filename" style="display: block; cursor: pointer;"
                       @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=other_files&element='+(n-1), document.proxy_file.split('/')[document.proxy_file.split('/').length-1]);"
                  >{{n}}. {{document.other_files[n-1].name}}</div>
                  <button class="add_file_btn"
                          @click="delete_other_file(n-1)"
                          style="display: block;"
                  >Удалить</button>
                </div>
              </div>
            </div>
            <div style="margin-top: 96px; width: 260px; display: inline-block; vertical-align: top;">
              <div style="display: inline-block;" v-for="n in Number(document.other_files.length)" v-if="true">
                <div style="display: inline-block; margin-bottom: 10px;" v-if="n>6 && document.other_files[n-1].name!=''">
                  <div class="filename" style="display: block; cursor: pointer;"
                       @click="downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=other_files&element='+(n-1), document.other_files[n-1].name);"
                  >{{n}}. {{document.other_files[n-1].name}}</div>
                  <button class="add_file_btn"
                          @click="delete_other_file(n-1)"
                          style="display: block;"
                  >Удалить</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<style>
.filename {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #2F80ED;
}
</style>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useDMStore} from "@/store/dm_obj";
import ModalTariff from "@/components/ModalTariff.vue";
import axios from "axios";
export default defineComponent({
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    components: {
      ModalTariff,
    },
    emits: ["change-page"],
    setup(
      props: {
          document: any;
      }, contextt
    ){
        const load_process = ref(false);

        const router = useRouter();
        const route = useRoute();

        const storeDM = useDMStore();
        const info_save = ref("дизайн проект №" + route.query.id);

        //const selected_annotations = ref(props.document.selected_annotations);

        const annotations = ref([
          { name: 'Приложение 3: фотофиксация', code: '3' },
          { name: 'Приложение 4: эскизный проект', code: '4' },
          { name: 'Приложение 5: компьютерный монтаж', code: '5' },
          { name: 'Приложение 6: фотофиксация', code: '6' },
        ]);


        const download_separated = async () => {
            await contextt.emit("change-page", route.query.step);

            /*
            await downloadItem(
              storeDM.api_url +
              "pdf_create.php?id=" +
              route.query.id +
              "&hash=" +
              route.query.hash +
              "&page=5",
              info_save.value + " - приложение 5"
            );
            */

            /*
            await downloadItem(
              storeDM.api_url +
              "pdf_create_a4l_eskiz.php?id=" +
              route.query.id +
              "&hash=" +
              route.query.hash +
              "&page=4" +
              "&subpage=2" +
              "&landscape=true",
              info_save.value + " - приложение 4"
            );
            */

            /*
            await downloadItem(
              storeDM.api_url +
              "pdf_create.php?id=" +
              route.query.id +
              "&hash=" +
              route.query.hash +
              "&page=4",
              info_save.value + " - приложение 4"
            );
            */

            
            if (props.document["construction_app6"] == true) {
                await downloadItem(
                  storeDM.api_url +
                  "pdf_create.php?id=" +
                  route.query.id +
                  "&hash=" +
                  route.query.hash +
                  "&page=1",
                  info_save.value + " - приложение 1"
                );

                await downloadItem(
                  storeDM.api_url +
                  "pdf_create.php?id=" +
                  route.query.id +
                  "&hash=" +
                  route.query.hash +
                  "&page=2",
                  info_save.value + " - приложение 2"
                );

              if(props.document["app4_file_checked"] == 1){
                downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app4_file', info_save.value + " - приложение 4.pdf");
              } else {
                await downloadItem(
                  storeDM.api_url +
                  "pdf_create.php?id=" +
                  route.query.id +
                  "&hash=" +
                  route.query.hash +
                  "&page=4",
                  info_save.value + " - приложение 4"
                );
              }

              if(props.document["app6_file_checked"] == 1){
                downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app6_file', info_save.value + " - приложение 6.pdf");
              } else {
                await downloadItem(
                  storeDM.api_url +
                  "pdf_create.php?id=" +
                  route.query.id +
                  "&hash=" +
                  route.query.hash +
                  "&page=6",
                  info_save.value + " - приложение 6"
                );
              }
            } else {
                 await downloadItem(
                  storeDM.api_url +
                  "pdf_create.php?id=" +
                  route.query.id +
                  "&hash=" +
                  route.query.hash +
                  "&page=1",
                  info_save.value + " - приложение 1"
                );

                await downloadItem(
                  storeDM.api_url +
                  "pdf_create.php?id=" +
                  route.query.id +
                  "&hash=" +
                  route.query.hash +
                  "&page=2",
                  info_save.value + " - приложение 2"
                );

                if(props.document["app3_file_checked"] == 1){
                  downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app3_file', info_save.value + " - приложение 3.pdf");
                } else {
                  await downloadItem(
                    storeDM.api_url +
                    "pdf_create.php?id=" +
                    route.query.id +
                    "&hash=" +
                    route.query.hash +
                    "&page=3",
                    info_save.value + " - приложение 3"
                  );
                }

                if(props.document["app4_file_checked"] == 1){
                  downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app4_file', info_save.value + " - приложение 4.pdf");
                } else {
                  await downloadItem(
                    storeDM.api_url +
                    "pdf_create.php?id=" +
                    route.query.id +
                    "&hash=" +
                    route.query.hash +
                    "&page=4",
                    info_save.value + " - приложение 4"
                  );
                }

              if(props.document["app5_file_checked"] == 1){
                downloadFileFromURL('https://api.rekprof.ru/download.php?id='+route.query.id+'&hash='+route.query.hash+'&type=app5_file', info_save.value + " - приложение 5.pdf");
              } else {
                await downloadItem(
                  storeDM.api_url +
                  "pdf_create.php?id=" +
                  route.query.id +
                  "&hash=" +
                  route.query.hash +
                  "&page=5",
                  info_save.value + " - приложение 5"
                );
              }
            }

            

        };

        const download_all = async () => {
            await contextt.emit("change-page", route.query.step);
            downloadItem(
              storeDM.api_url +
              "pdf_create_file.php?id=" +
              route.query.id +
              "&hash=" +
              route.query.hash +
              "&page=all",
              info_save.value
            );
        };


        const modal_end_open = ref(false);
        const saveIter = ref(0);

        const downloadItem = async (url: string, label: string) => {
            const response = await fetch(storeDM.api_url + "api2.php", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                type: "get_count_docs",
                hash: route.query.hash,
                id: route.query.id,
              }),
            });

            const response_data = await response.json();
            //console.log(response_data["docs_count"]);
            if(Number(response_data["docs_count"])>0){
              load_process.value = true;
              await axios
                .get(url, { responseType: "blob" })
                .then((response) => {
                  const blob = new Blob([response.data], { type: "application/pdf" });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = label;
                  link.click();
                  URL.revokeObjectURL(link.href);
                  load_process.value = false;
                })
                .catch(console.error);
            } else {
              modal_end_open.value = true;
              saveIter.value = saveIter.value+1;
            }


        };


      const other_files = ref();
      const submitFile = (f_name: string, element: number, m_name: string) => {
        let file = Object(eval(f_name).value).files[0];
        if(file == undefined){
          return;
        }

        let formData = new FormData();
        formData.append(f_name, file);
        formData.append("element", String(element));
        formData.append("m_name", m_name);
        formData.append("file_name", f_name);
        formData.append("hash", String(route.query.hash));
        formData.append("id", String(route.query.id));
        axios
          .post(storeDM.api_url + "upload.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (req) {
            if (Object(req.data).file_name == "other_files") {
              props.document["other_files"] = JSON.parse(Object(req.data)[
                req.data.file_name
                ]);
              //console.log(props.document["other_files"]);
            } else {
              props.document[Object(req.data).file_name] = Object(req.data)[
                req.data.file_name
                ];
            }
            console.log(Object(req.data).file_name+" - "+Object(req.data)[req.data.file_name]);
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      };

      const downloadFileFromURL = async (url, fileName) => {

        if(fileName != ""){
          await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], { });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch(console.error);
        }

      }

      const delete_other_file = async (element) => {

        const response = await fetch(storeDM.api_url + "api3.php", {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "delete_other_files",
            hash: route.query.hash,
            id: String(route.query.id),
            element: String(element),
          }),
        });

        const response_data = await response.json();
        props.document["other_files"] = JSON.parse(Object(response_data.other_files));

        /*
        let formData = new FormData();
        formData.append("element", String(element));
        formData.append("type", "delete_other_files");
        formData.append("hash", String(route.query.hash));
        formData.append("id", String(route.query.id));
        axios
          .post(storeDM.api_url + "api3.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (req) {
            if (Object(req.data).file_name == "other_files") {
              props.document["other_files"] = JSON.parse(Object(req.data)[
                req.data.file_name
                ]);
              //console.log(props.document["other_files"]);
            } else {
              props.document[Object(req.data).file_name] = Object(req.data)[
                req.data.file_name
                ];
            }
            console.log(Object(req.data).file_name+" - "+Object(req.data)[req.data.file_name]);
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
         */
      }

        return{
            download_separated,
            download_all,
            load_process,
            info_save,
            modal_end_open,
            saveIter,
            submitFile,
            other_files,
            downloadFileFromURL,
            route,
            delete_other_file,
            //selected_annotations,
            annotations,
        }
    },
});
</script>