<template>
  <img alt="Rekprof logo" class="logo_min" src="../../../../assets/logo.png" />
  <v-menu-list></v-menu-list>
  <div class="inner_step_box">
    <span class="main_field bold">Поздравляем с подготовкой документа!</span>
    <div class="hint_left hint_mini_top">
      Выберите удобный способ вывода документа и можете продолжать работать.
    </div>
    <div class="hint_left hint_mini_top">Всего доброго!</div>
    <br />
    <span class="main_field">11. Сохранение документа</span>
    <div class="hint_left" style="margin-top: 10px">
      Введите под каким именем сохранить
    </div>
    <input
      class="first_input"
      placeholder='например - "дизайн проект №7"'
      v-model="info_save"
    />
    <br />
    <br />
    <div class="hint_left hint_mini_top">
      Сохраните разбитый по приложениям документ
    </div>
    <button
      type="button"
      class="blue_btn"
      @click="download_separated"
      style="float: left; margin-left: 10px"
    >
      Сохранить по приложениям
    </button>
    <div class="hint_left" style="margin-top: 68px">
      Сохраните документ одним файлом
    </div>
    <button
      type="button"
      class="blue_btn"
      @click="download_all"
      style="float: left; margin-left: 10px"
    >
      Сохранить документ
    </button>
    <br />
    <br />
    <br />
    <img
      v-if="load_process"
      alt="loading..."
      class="loading"
      src="../../../../assets/loading.gif"
    />
    <!--
    <div class="hint_left">Сохраните черновик</div>
    <button
      type="button"
      class="blue_btn"
      style="float: left; margin-left: 10px"
    >
      Сохранить черновик
    </button>
    <br />
    <br />
    <br />
    <button
      type="button"
      class="transparent_btn"
      style="float: left; margin-left: 10px"
    >
      Использовать черновик
    </button>
    -->
    <br />
    <br />
    <button
      type="button"
      class="white_btn"
      @click="go_step1()"
      style="float: left; margin-left: 10px"
    >
      Создать новый документ
    </button>
    <br />
    <br />
    <br />
    <button
      type="button"
      class="white_btn"
      @click="go_list()"
      style="float: left; margin-left: 10px"
    >
      Переход к сохранённым документам
    </button>
  </div>
  <button type="button" class="white_s_btn" @click="go_back()">Назад</button>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, computed, defineComponent } from "vue";
import { useDMStore } from "@/store/dm_obj";
import axios from "axios";
import VMenuList from "@/components/v-menu-list.vue";

export default defineComponent({
  name: "StepTwelve",
  emits: ["get_info_event"],
  components: { VMenuList },
  props: {},
  setup(props, context) {
    const load_process = ref(false);

    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    if (storeDM.initDM == false) {
      storeDM.init(route.query.hash, route.params.docID);
    }

    const info_save = ref("дизайн проект №" + route.params.docID);

    const download_separated = () => {
      if (storeDM.save_obj.construction_app6 == true) {
        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=1",
          info_save.value + " - приложение 1"
        );

        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=2",
          info_save.value + " - приложение 2"
        );

        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=4",
          info_save.value + " - приложение 4"
        );

        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=6",
          info_save.value + " - приложение 6"
        );
      } else {
        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=1",
          info_save.value + " - приложение 1"
        );

        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=2",
          info_save.value + " - приложение 2"
        );

        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=3",
          info_save.value + " - приложение 3"
        );

        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=4",
          info_save.value + " - приложение 4"
        );
        downloadItem(
          storeDM.api_url +
            "pdf_create.php?id=" +
            route.params.docID +
            "&hash=" +
            route.query.hash +
            "&page=5",
          info_save.value + " - приложение 5"
        );
      }
    };

    const download_all = () => {
      downloadItem(
        storeDM.api_url +
          "pdf_create.php?id=" +
          route.params.docID +
          "&hash=" +
          route.query.hash +
          "&page=all",
        info_save.value
      );
    };

    const downloadItem = (url: string, label: string) => {
      load_process.value = true;
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
          load_process.value = false;
        })
        .catch(console.error);
    };

    const go_step1 = async () => {
      const response = await fetch(storeDM.api_url + "api.php", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "clear_hash",
          client_id: storeDM.client_id,
          email: storeDM.email,
          phone: storeDM.phone,
          random_hash: storeDM.random_hash,
        }),
      });

      const response_data = await response.json();

      storeDM.hash = response_data["hash"];
      storeDM.docID = response_data["id"];

      storeDM.get_info_server();

      router.push({
        path: "/step1/" + storeDM.docID,
        query: {
          hash: storeDM.hash,
        },
      });
    };

    const go_back = () => {
      storeDM.set_info({
        step: "10",
      });
      router.push({
        path: "/step10/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const go_next = () => {
      storeDM.set_info({
        step: "11",
      });
      router.push({
        path: "/step11/" + route.params.docID,
        query: {
          hash: route.query.hash,
        },
      });
    };

    const step_next = computed(() => {
      return true;
    });

    const go_list = () => {
      router.push({
        path: "/list",
        query: {
          hash: storeDM.hash,
        },
      });
    };

    return {
      go_back,
      step_next,
      go_next,
      download_separated,
      download_all,
      go_step1,
      go_list,
      load_process,
      info_save,
    };
  },
});
</script>

<style scoped>
.loading {
  width: 30px;
  height: 30px;
  float: left;
  margin-left: 278px;
}
</style>
