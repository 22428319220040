<template>
  <div class="save_doc">
    <div class="left">
      <div>
        <label for="name">Сохранение заявления</label>
        <span>Введите название файла</span>
        <input class="input" type="text" placeholder="дизайн проект № 643 тест" v-model="info_save">
      </div>
      <div>
        <label for="name">Сохранить заявление разбитый по приложениям</label>
        <button class="save__application" @click="download_separated">Сохранить по приложениям</button>
      </div>
      <div>
        <label for="name">Сохранить заявление одним файлом</label>
        <button class="save__statement" @click="download_all">Сохранить заявление</button>
      </div>
      <img
        v-if="load_process"
        alt="loading..."
        class="loading"
        v-bind:src="require('../../../../assets/loading.gif')"
        style="width:20px; height: 20px;"
      />
      <ModalTariff :is_open="modal_end_open" :key="saveIter"/>
      <!--
      <button class="new__statement">
          + Новое заявление
      </button>
      -->
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useDMStore} from "@/store/dm_obj";
import axios from "axios";
import ModalTariff from "@/components/ModalTariff.vue";
export default defineComponent({
  props: {
    document: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(
    props: {
      document: any;
    }, context
  ){
    const load_process = ref(false);

    const router = useRouter();
    const route = useRoute();

    const storeDM = useDMStore();
    const info_save = ref("дизайн проект №" + route.query.id);

    const download_separated = async () => {
      /*
      await downloadItem(
        storeDM.api_url +
        "pdf_create.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=4",
        info_save.value + " - приложение 4"
      );
      */

      await downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=1",
        info_save.value + " - приложение 1"
      );

      await downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=2",
        info_save.value + " - приложение 2"
      );

      await downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=3",
        info_save.value + " - приложение 3"
      );


      await downloadItem(
        storeDM.api_url +
        "pdf_create.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=4",
        info_save.value + " - приложение 4"
      );

      await downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=5",
        info_save.value + " - приложение 5"
      );

      await downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=6",
        info_save.value + " - приложение 6"
      );

      await downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=6_0",
        info_save.value + " - приложение 7"
      );

      await downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=6_1",
        info_save.value + " - приложение 8"
      );

      await downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=7",
        info_save.value + " - приложение 9"
      );

    };

    const download_all = () => {

      downloadItem(
        storeDM.api_url +
        "pdf_create_file.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=all",
        info_save.value
      );

      /*
      downloadItem(
        storeDM.api_url +
        "pdf_create_a4l.php?id=" +
        route.query.id +
        "&hash=" +
        route.query.hash +
        "&page=all",
        info_save.value
      );
      */

    };

    const modal_end_open = ref(false);
    const saveIter = ref(0);

    const downloadItem = async (url: string, label: string) => {

        const response = await fetch(storeDM.api_url + "api2.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "get_count_docs",
            hash: route.query.hash,
            id: route.query.id,
          }),
        });

        const response_data = await response.json();
        if(Number(response_data["docs_count"])>0){
          load_process.value = true;
          await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], { type: "application/pdf" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = label;
              link.click();
              URL.revokeObjectURL(link.href);
              load_process.value = false;
            })
            .catch(console.error);
        } else {
          modal_end_open.value = true;
          saveIter.value = saveIter.value+1;
        }
    };

    return{
      download_separated,
      download_all,
      load_process,
      info_save,
      modal_end_open,
      saveIter,
    }
  },
  components: {
    ModalTariff,
  },
});
</script>