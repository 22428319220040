<template>
    <!--
    <div class="steps">
        <button :class="{activeStep: subpage == 1}" @click="subpage = 1">Шаг 1</button>
        <button :class="{activeStep: subpage == 2}" @click="subpage = 2">Шаг 2</button>
        <button :class="{activeStep: subpage == 3}" @click="subpage = 3">Шаг 3</button>
    </div>
    -->
    <!-- First Step -->
    <div class="spb_light" v-if="subpage == 1">
      <div class="left">
        <div>
          <label for="name">Наличие и вид подсветки</label>
          <span>Нажмите, чтобы выбрать из списка</span>
          <Dropdown v-model="number_type_illumination_title"
                    editable :options="number_type_illumination_options"
                    optionLabel="name"
                    :placeholder="number_type_illumination_default.name"
                    style="width:497px"
                    @change="number_type_illumination_change"
          />
          <input class="input" type="text" placeholder="Введите наличие и вид подсветки" v-model="document.number_type_illumination"
                 v-if="document.number_type_illumination != 'Светодиодные модули, комплектующие и трансформаторы' && document.number_type_illumination != '-'"
                 style="width:497px;"
          />
        </div>
        <div>
            <label for="title">Габаритные размеры, м</label>
            <section>
                <div>
                    <span>Ширина</span>
                    <input class="input" placeholder="3,32" type="text" v-model="document.construction_width">
                </div>
                <div>
                    <span>Высота</span>
                    <input class="input" placeholder="0,8" type="text" v-model="document.construction_height">
                </div>
                <div class="quantitySelect">
                  <span>Количество инф. полей</span>
                  <Dropdown v-model="info_count_title"
                            editable :options="info_count_options"
                            optionLabel="name"
                            :placeholder="info_count_default.name"
                            style="width:155px"
                            @change="info_count_change"
                  />
                </div>
            </section>
        </div>
        <div class="info" v-for="(input, index) in construction_info_custom1"
             :key="index">
              <label for="title">Информационное поле №{{index+1}}</label>
              <section>
                  <div>
                      <span>Ширина</span>
                      <input class="input" placeholder="0,2" type="number" :value="input[0]" @input="info_set_width(index, $event)"
                             @change="info_set_width(index, $event)">
                  </div>
                  <div>
                      <span>Высота</span>
                      <input class="input" placeholder="0,2" type="number" :value="input[1]" @input="info_set_height(index, $event)"
                             @change="info_set_height(index, $event)">
                  </div>
              </section>
        </div>
      </div>
      <div class="right">
        <div class="info" v-for="(input, index) in construction_info_custom2"
             :key="index">

            <label for="title">Информационное поле №{{index+5}}</label>
            <section>
              <div>
                <span>Ширина</span>
                <input class="input" placeholder="0,2" type="number" :value="input[0]" @input="info_set_width(index+5, $event)"
                       @change="info_set_width(index+5, $event)">
              </div>
              <div>
                <span>Высота</span>
                <input class="input" placeholder="0,2" type="number" :value="input[1]" @input="info_set_height(index+5, $event)"
                       @change="info_set_height(index+5, $event)">
              </div>
            </section>

        </div>
      </div>
    </div>
</template>

<script lang="ts">
import DropdownIcon from "../../assets/icons/dropdownIcon.vue";
import DropdownUp from "../../assets/icons/DropdownUp.vue";
import {computed, defineComponent, ref} from "vue";
export default defineComponent({
    components: {
        DropdownIcon,
        DropdownUp
    },
    props: {
      document: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    setup(
      props: {
        document: any;
      }, context
    ){

      const number_type_illumination_default = ref({ name: "Светодиодные модули, комплектующие и трансформаторы", value: "Светодиодные модули, комплектующие и трансформаторы" });
      const number_type_illumination_options = ref([
        { name: "Светодиодные модули, комплектующие и трансформаторы", value: "Светодиодные модули, комплектующие и трансформаторы" },
        { name: "Отсутствует", value: "-" },
        { name: "Другое", value: "" },
      ]);

      const number_type_illumination_title = computed(() => {
        //if (props.document.number_type_illumination == "") {
        //  return number_type_illumination_default.name;
        //} else {
        if("Светодиодные модули, комплектующие и трансформаторы" != props.document.number_type_illumination && "-" != props.document.number_type_illumination){
          return "Другое";
        } else {
          let obj = number_type_illumination_options.value.find(el => el.value == props.document.number_type_illumination);
          return Object(obj);
        }


        //}
      });

      const info_count_default = ref({ name: "1", value: "1" });
      const info_count_options = ref([
        { name: "1", value: "1" },
        { name: "2", value: "2" },
        { name: "3", value: "3" },
        { name: "4", value: "4" },
        { name: "5", value: "5" },
        { name: "6", value: "6" },
        { name: "7", value: "7" },
        { name: "8", value: "8" },
        //{ name: "9", value: "9" },
        //{ name: "10", value: "10" },
      ]);

      const info_count_title = computed(() => {
        if (props.document.info_count == "") {
          return null;
        } else {
          return props.document.info_count;
        }
      });



      const isShow = ref(false);
      const isModule = ref(false);
      const isOpen = (obj, type) => {
        if(type == "info_count"){
          props.document.info_count = obj.value;
          isShow.value = false;

          if(props.document.construction_info_custom.length < obj.value){
            let delta_num = obj.value - props.document.construction_info_custom.length;
            for(let i=0; i<delta_num; i++){
              props.document.construction_info_custom.push(["",""]);
            }
          } else {
            props.document.construction_info_custom.length = obj.value;
          }

          /*
          for(let i=0; i<obj.value; i++){
            if(props.document.construction_info_custom.length < obj.value){

            }
          }
          */

        }
      }

      const construction_info_custom1 = computed(() => {
        //if(props.document.construction_info_custom.length>1) {
        if(props.document.construction_info_custom.length>0) {
          return props.document.construction_info_custom.slice(0, 4);
        } else {
          return [];
        }
      });

      const construction_info_custom2 = computed(() => {
        if(props.document.construction_info_custom.length>4){
          return props.document.construction_info_custom.slice(4);
        } else {
          return [];
        }
      });

      const info_set_width = (num: any, e: any) => {
        props.document.construction_info_custom[num][0] = e.target.value;
      };

      const info_set_height = (num: any, e: any) => {
        props.document.construction_info_custom[num][1] = e.target.value;
      };

      const number_type_illumination_change = (e) => {
        props.document.number_type_illumination = e.value.value;
      }

      const info_count_change = (e) => {
        props.document.info_count = e.value.value;

        if(props.document.construction_info_custom.length < e.value.value){
          let delta_num = e.value.value - props.document.construction_info_custom.length;
          for(let i=0; i<delta_num; i++){
            props.document.construction_info_custom.push(["",""]);
          }
        } else {
          props.document.construction_info_custom.length = e.value.value;
        }
      }

      const subpage = ref(1);
        return{
            subpage,
            info_count_default,
            info_count_options,
            info_count_title,
            info_count_change,
            number_type_illumination_default,
            number_type_illumination_options,
            number_type_illumination_title,
            number_type_illumination_change,
            construction_info_custom1,
            construction_info_custom2,
            info_set_width,
            info_set_height,
            isShow,
            isModule,
            isOpen,
        }
    },
});
</script>